import moment from 'moment';
import { get } from './request';
import actions from './constants';
import { toSearchQuery } from '../urls';
import getTimezone from './timezoneHelper';
import { dataToBlob, toInformation } from './exportHelper';
import orderStatisticQueryString from '../utils/api/orderStatisticQueryString';

function requestOrders() {
  return {
    type: actions.REQUEST_ORDERS,
  };
}

function receivedOrders(json) {
  return {
    type: actions.RECEIVED_ORDERS,
    payload: json,
  };
}

function requestOrderExport() {
  return {
    type: actions.REQUEST_ORDER_EXPORT,
  };
}

function receivedOrderExport(data, info) {
  return {
    type: actions.RECEIVED_ORDER_EXPORT,
    payload: dataToBlob(data, info),
  };
}

function receivedOrderExportError(error) {
  return {
    type: actions.RECEIVED_ORDER_EXPORT_ERROR,
    payload: error,
  };
}

function requestLineItemExport() {
  return {
    type: actions.REQUEST_LINE_ITEMS_FOR_EXPORT,
  };
}

function receivedLineItemExport(data, info) {
  return {
    type: actions.RECEIVED_LINE_ITEMS_FOR_EXPORT,
    payload: dataToBlob(data, info),
  };
}

function fetchOrdersForLineItemExport(
  fileInformation,
  fromDate,
  toDate,
  timezone,
  shop,
  delimiter,
) {
  if (!fileInformation) return null;

  const { info, query } =
    toInformation('snabble-line-item-export', fileInformation, fromDate, toDate, timezone, shop, delimiter);

  return (dispatch, getState) => {
    dispatch(requestLineItemExport());

    return get(dispatch, getState, `/{project}/orders${toSearchQuery(query)}`, info.contentType)
      .then(
        data => dispatch(receivedLineItemExport(data, info)),
        () => {},
      );
  };
}

function fetchOrdersForExport(
  fileInformation,
  state,
  fromDate,
  toDate,
  timezone,
  shop,
  delimiter,
) {
  if (!fileInformation) return null;

  let suffix = '';
  if (state === '') {
    suffix = '-all-states';
  } else if (state) {
    suffix = `-state-${state}`;
  }

  const baseName = `snabble-order-export-${fileInformation.label}${suffix}`;
  const { info, query } =
    toInformation(baseName, fileInformation, fromDate, toDate, timezone, shop, delimiter);

  query.state = state;

  if (state === '') {
    info.includeAllStates = true;
  }

  return async (dispatch, getState) => {
    dispatch(requestOrderExport());

    try {
      const data = await get(
        dispatch,
        getState,
        `/{project}/orders${toSearchQuery(query)}`,
        info.contentType,
      );

      dispatch(receivedOrderExport(data, info));
    } catch (error) {
      dispatch(receivedOrderExportError(error));
    }
  };
}

function fetchOrders(
  page,
  state,
  fromDate,
  toDate,
  shop,
  idPrefix,
  abortController,
) {
  const queryString = toSearchQuery({
    perPage: 50,
    state,
    q: idPrefix,
    from: fromDate,
    to: toDate,
    shopID: shop,
    page: page && page > 0 ? page - 1 : undefined,
  });

  return (dispatch, getState) => {
    dispatch(requestOrders());
    return get(dispatch, getState, `/{project}/orders${queryString}`, 'application/json', abortController)
      .then(
        json => dispatch(receivedOrders(json)),
        () => dispatch(receivedOrders({})),
      );
  };
}

function requestOrder() {
  return {
    type: actions.REQUEST_ORDER,
  };
}

function receivedOrder(json) {
  return {
    type: actions.RECEIVED_ORDER,
    payload: json,
  };
}

function fetchOrder(id) {
  if (!id) return null;
  return (dispatch, getState) => {
    dispatch(requestOrder());
    return get(dispatch, getState, `/{project}/orders/${encodeURIComponent(id)}`, 'application/json')
      .then(
        json => dispatch(receivedOrder(json)),
        () => dispatch(receivedOrder({})),
      );
  };
}

function requestOrderStatistic() {
  return {
    type: actions.REQUEST_ORDER_STATISTIC,
  };
}


function resetOrderStatistic() {
  return {
    type: actions.RESET_ORDER_STATISTIC,
  };
}

function receivedOrderStatistic(json, date, shopID, withCustomerCard, paymentMethods) {
  const filter = {
    date,
    shopID,
    withCustomerCard,
    paymentMethods,
  };

  return {
    type: actions.RECEIVED_ORDER_STATISTIC,
    payload: json ? Object.assign(json, filter) : null,
  };
}

function receivedOrderStatisticError() {
  return {
    type: actions.RECEIVED_ORDER_STATISTIC_ERROR,
  };
}

function fetchOrderStatistic(from, to, withCustomerCard, shopID, paymentMethods) {
  return (dispatch, getState) => {
    dispatch(requestOrderStatistic());

    const queryString = orderStatisticQueryString({
      from,
      to,
      withCustomerCard,
      shopID,
      paymentMethods,
    });

    return get(dispatch, getState, `/{project}/orders/statistics/totalRevenue${queryString}`, 'application/json')
      .then(
        (json) => {
          if (!json) {
            dispatch(receivedOrderStatisticError());
          } else {
            dispatch(receivedOrderStatistic(
              json,
              { from, to },
              shopID,
              withCustomerCard,
              paymentMethods,
            ));
          }
        },
        () => dispatch(receivedOrderStatisticError()),
      );
  };
}

function receivedLastWeekOrderStatistic(json, date) {
  return {
    type: actions.RECEIVED_LAST_WEEK_ORDER_STATISTIC,
    payload: json ? Object.assign(json, { date }) : null,
  };
}

function fetchLastWeekOrderStatistic() {
  const from = moment(moment().subtract(13, 'days').startOf('day')).format();
  const to = moment().format();

  return (dispatch, getState) => get(dispatch, getState, `/{project}/orders/statistics/totalRevenue?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&timezone=${encodeURIComponent(getTimezone())}&filter-sessions=true`, 'application/json')
    .then(
      json => dispatch(receivedLastWeekOrderStatistic(json, { from, to })),
      () => { },
    );
}

export {
  fetchOrders, fetchOrder, fetchOrderStatistic,
  fetchLastWeekOrderStatistic, resetOrderStatistic,
  fetchOrdersForExport, fetchOrdersForLineItemExport,
};
