import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useAppUserApi from './appUserApi';
import ViewAppUserForm from './ViewAppUserForm';

export default function EditAppUserView() {
  const api = useAppUserApi();
  const { appUserId } = useParams();

  const handleFetch = useCallback(() => api.getAppUser(appUserId), [api, appUserId]);

  return (
    <>
      <DocumentTitle translationID="appUser.headline" />
      <ResourceFormView
        actionName="viewAppUser"
        Form={ViewAppUserForm}
        fetch={handleFetch}
        submit={() => {}}
      />
    </>
  );
}
