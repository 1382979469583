import React from 'react';
import Translate from './i18n/Translate';
import PaperTable from './PaperTable';
import FulfillmentTableRow from './FulfillmentTableRow';


function OrderViewFulfillments({ order }) {
  if (!order || !order.fulfillments || !order.fulfillments.length) return null;
  const { fulfillments } = order;

  return (
    <PaperTable
      headline={<Translate id="fulfillments.headline" />}
      tableHeader={[
        <Translate id="fulfillments.type" />,
        <Translate id="fulfillments.state" />,
        <Translate id="fulfillments.refersTo" />,
      ]}>
      {(fulfillments).map(fulfillment => (
        <FulfillmentTableRow
          key={fulfillment.id}
          fulfillment={fulfillment}
        />
      ))}
    </PaperTable>
  );
}

export default OrderViewFulfillments;
