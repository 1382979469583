import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../../router/RouteWrapper';
import { ResourceProvider } from '../../resource';
import ReleasesOverviewView from '../components/ReleasesOverview';
import ReleaseDetailView from '../components/ReleaseDetailView';
import { Access } from '../../access';
import SupervisorDetailView from '../components/SupervisorDetailView';

function ReleasesRoutes({
  access,
  supervisorAccess,
}: {
  access?: Access
  supervisorAccess?: Access
}) {
  return (
    <ResourceProvider name="releases">
      <Routes>
        {access?.read &&
          <>
            <Route path="/pos_releases" element={<RouteWrapper><ReleasesOverviewView /></RouteWrapper>} />
            <Route
              path="/:repository/:id"
              element={<RouteWrapper><ReleaseDetailView /></RouteWrapper>}
            />
          </>
        }
        {supervisorAccess?.read &&
          <>
            <Route path="/supervisor_releases" element={<RouteWrapper><SupervisorDetailView /></RouteWrapper>} />
          </>
        }
        <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default ReleasesRoutes;
