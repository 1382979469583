import { AnyAction } from '@reduxjs/toolkit';
import actions from '../../actions/constants';

export default (state: unknown, action: AnyAction) => {
  switch (action.type) {
    case actions['chartFilter/updateRange']:
      return { range: action.payload };
    case actions['chartFilter/updateShop']:
      return { shopFilter: action.payload };
    case actions['chartFilter/updateWithCustomerCard']:
      return { withCustomerCardFilter: action.payload };
    case actions['chartFilter/updatePaymentMethods']:
      return { paymentMethodsFilter: action.payload };
    case actions['chartFilter/updateGenericFilter']:
      return { genericFilter: action.payload };
    default:
      return {};
  }
};
