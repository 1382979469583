const COLOR_CODE_REGEX = /^[0-9a-f]{3}([0-9a-f]{3})?$/i;

export const HEX_CODE_PREFIX = '#';

export function removeHexCodePrefix(value: string | null) {
  if (!value) return '';

  if (value.startsWith(HEX_CODE_PREFIX)) {
    return value.slice(HEX_CODE_PREFIX.length);
  }
  return value;
}

export function addHexCodePrefix(value: string | null) {
  if (!value) return '';

  if (!value.startsWith(HEX_CODE_PREFIX)) {
    return `${HEX_CODE_PREFIX}${value}`;
  }
  return value;
}

export function isValidHexCode(value: string | null) {
  return Boolean(value) &&
    COLOR_CODE_REGEX.test(removeHexCodePrefix(value));
}
