import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useCheckoutDeviceApi from './api/useCheckoutDeviceApi';
import useProjectNavigate from '../useProjectNavigate';
import ResourceFormView from '../scaffold/ResourceFormView';
import GlobalCheckoutDeviceConfigForm from './GlobalCheckoutDeviceConfigForm';
import AnchoredDialog from '../components/AnchoredDialog';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import { CustomFormRef } from '../form/CustomFormProps';
import { ApplicationState } from '../reducers/initialState';
import { DynaForm, UISchemaScope } from '../dynaForm';

export enum TABS {
  DEVICE_CONFIG = 'deviceConfig',
  APPEARANCE = 'appearance',
}

export default function UpdateGlobalCheckoutDeviceConfigView() {
  const { t } = useTranslation();
  const api = useCheckoutDeviceApi();
  const navigate = useProjectNavigate();
  const allowNewAppearanceTab = useSelector<ApplicationState, any>(state => (
    !!(state.navigation as any).checkoutDeviceAppearance
  ));
  const [uiSchema, setUiSchema] = useState<any>(null);

  const [initHint, setInitHint] = useState(false);

  const handleFetch = useCallback(async () => {
    const data = await api.getGlobalConfig(null);
    if (!data) setInitHint(true);
    return data;
  }, [api]);

  const handleFetchAppearance = useCallback(async () => {
    const data = await api.getAppearanceConfig(null);
    setUiSchema(await api.getUiSchema(null));
    return data;
  }, [api]);

  const [currentTab, setCurrentTab] = useState<TabFilterOption>((
    { id: TABS.DEVICE_CONFIG, title: t(`checkoutDevices.tabs.${TABS.DEVICE_CONFIG}`) }
  ));
  const currentForm = useRef<CustomFormRef | null>(null);
  const handleTabChange = useCallback(async (tab: TabFilterOption) => {
    if (await currentForm.current?.forceSubmit()) setCurrentTab(tab);
  }, []);
  const sharedResourceFormViewProps = {
    headline: t('checkoutDevices.actions.globalConfig'),
    additionalContent: (
      allowNewAppearanceTab && (
        <TabFilter
          options={Object.values(TABS).map(tab => ({
            id: tab, title: t(`checkoutDevices.tabs.${tab}`),
          }))}
          defaultOption={currentTab}
          onChange={handleTabChange}
          value={currentTab}
          sx={{ marginBottom: '32px' }}
        />
      )
    ),
  };

  return (
    <AnchoredDialog
      Dialog={(
        <>
          <DialogContent>
            <DialogContentText>
              {t('checkoutDevices.initHint')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => { setInitHint(false); }}>
              {t('button.ok')}
            </Button>
          </DialogActions>
        </>
      )}
      dialogOpen={initHint}
      anchorDialogToTop
    >
      {currentTab?.id === TABS.DEVICE_CONFIG && (
        <ResourceFormView
          actionName="updateGlobalConfig"
          {...sharedResourceFormViewProps}
          Form={GlobalCheckoutDeviceConfigForm}
          FormProps={{
            ref: currentForm,
          }}
          fetch={handleFetch}
          submit={data => api.updateGlobalConfig({ data })}
          onSubmitted={() => { navigate('/checkout-devices'); }}
        />
      )}

      {currentTab?.id === TABS.APPEARANCE && (
        <ResourceFormView
          actionName="updateGlobalDeviceAppearance"
          {...sharedResourceFormViewProps}
          Form={DynaForm}
          FormProps={{
            uiSchema,
            currentScope: UISchemaScope.PROJECT,
            ref: currentForm,
          }}
          fetch={handleFetchAppearance}
          submit={data => api.updateAppearanceConfig({ data })}
        />
      )}
    </AnchoredDialog>
  );
}
