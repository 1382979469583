import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';

export interface FieldStackProps {
  children: ReactNode;
}

export default function FieldStack({ children }: FieldStackProps) {
  return (
    <Stack spacing={2}>
      {children}
    </Stack>
  );
}
