import React from 'react';
import { CustomFormProps, DefaultFormActions, Fieldset, Form, useEnhancedForm } from '../form';
import { TextField } from '../form/input';
import useOrganizationNavigate from './useOrganizationNavigate';

const DEFAULT_VALUES: any = {};

interface EditOrganizationsFormProps extends CustomFormProps { }

export default function EditOrganizationForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: EditOrganizationsFormProps) {
  const navigate = useOrganizationNavigate();
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="general">
        <TextField
          control={control}
          name="id"
          disabled
        />
        <TextField
          control={control}
          name="name"
          required
          rules={{ required: true }}
        />
        <TextField
          control={control}
          name="description"
        />
        <TextField
          control={control}
          name="street"
        />
        <TextField
          control={control}
          name="city"
        />
        <TextField
          control={control}
          name="zip"
        />
        <TextField
          control={control}
          name="country"
        />
        <TextField
          control={control}
          name="website"
        />
        <TextField
          control={control}
          name="taxNumber"
        />
        <TextField
          control={control}
          name="taxID"
        />
        <TextField
          control={control}
          name="currency"
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        getValues={getValues}
        reset={reset}
        onCancel={() => navigate('/')}
      />
    </Form>
  );
}
