import React, { ReactNode, forwardRef } from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import CheckboxField from '../form/input/CheckboxField';
import { useResourceTranslator } from '../resource';
import CheckoutDeviceConfig from './CheckoutDeviceConfig';
import { FEATURE_FLAGS_LEGACY } from './featureFlags';
import {
  TERMINAL_PASSWORD_LENGTH,
  TERMINAL_PASSWORD_PATTERN,
} from './api/terminalPassword';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

const UI_RELEASE_CHANNELS = ['stable', 'preview'];

const DEFAULT_VALUES: CheckoutDeviceConfig = {
  animationType: 'default',
  cigaretteMachineURL: '',
  enableTraceLogging: false,
  featureFlags: [],
  landingpageType: '',
  scheduledEndOfDayTime: '',
  terminalPassword: '',
  terminalType: 'feig',
};

export interface CheckoutDeviceConfigFormProps
  extends CustomFormProps<CheckoutDeviceConfig> {
  additionalButtons?: ReactNode;
  onCancel?: () => void;
}

const CheckoutDeviceConfigForm = forwardRef<CustomFormRef, CheckoutDeviceConfigFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
  onCancel,
}, ref) => {
  const t = useResourceTranslator();

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="appearance">
        <SelectOptionsField
          control={control}
          name="landingpageType"
          options={['default', 'cigarette-picking', 'coffee-house']}
          includeEmpty
          autoFocus
        />

        <SelectOptionsField
          control={control}
          name="animationType"
          options={['default', 'less']}
          required
          rules={{ required: true }}
        />
      </Fieldset>

      <Fieldset category="paymentTerminal">
        <SelectOptionsField
          control={control}
          name="terminalType"
          options={['feig', 'p400', 'q30pax']}
          required
          rules={{ required: true }}
          helperText={t('checkoutDevices.form.helperText.terminalType')}
        />

        <TextField
          control={control}
          name="scheduledEndOfDayTime"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          control={control}
          name="terminalPassword"
          type="password"
          inputProps={{
            inputMode: 'numeric',
            maxLength: TERMINAL_PASSWORD_LENGTH,
          }}
          rules={{ pattern: TERMINAL_PASSWORD_PATTERN }}
          autoComplete="one-time-code"
        />
      </Fieldset>

      <Fieldset category="additionalData">
        <TextField
          control={control}
          name="cigaretteMachineURL"
          inputProps={{
            inputMode: 'url',
          }}
        />
      </Fieldset>

      <Fieldset category="features">
        <SelectOptionsField
          control={control}
          name="featureFlags"
          options={FEATURE_FLAGS_LEGACY}
          multiple
          helperText={t('helpers.inheritedFeatureFlags')}
        />
      </Fieldset>

      <Fieldset category="frontend">
        <SelectOptionsField
          control={control}
          name="uiReleaseChannel"
          options={UI_RELEASE_CHANNELS}
          includeEmpty
        />
      </Fieldset>

      <Fieldset category="devSettings">
        <CheckboxField
          control={control}
          name="enableTraceLogging"
        />
        <CheckboxField
          control={control}
          name="undergoingMaintenance"
        />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
        additionalButtons={additionalButtons}
        onCancel={onCancel}
      />
    </Form>
  );
});

export default CheckoutDeviceConfigForm;
