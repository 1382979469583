import { connect } from 'react-redux';
import { fetchClosingSchedule } from '../actions';
import ClosingScheduleDetails from './ClosingScheduleDetails';

const ClosingScheduleDetailsContainer = connect(
  ({ closings, navigation }) => ({
    schedule: closings.schedule,
    isLoading: closings.isLoading,
    count: closings.schedules.length,
    canEdit: !!closings.schedule
      && navigation.closingsSchedules
      && navigation.closingsSchedules.write,
  }),
  dispatch => ({
    fetchClosingSchedule: id =>
      dispatch(fetchClosingSchedule(id)),
  }),
)(ClosingScheduleDetails);

export default ClosingScheduleDetailsContainer;
