import React, { useState, useCallback, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import usePlacesApi, {
  Address,
  AddressField,
  AddressFieldAutomcompleteSuggestion,
  mapAddressDetailsToAddress,
} from './useAutocompleteApi';

export interface AddressAutocompleteProps {
  label?: string;
  type?: AddressField;
  onChange?: (address: Address | undefined) => void;
  onInputChange?: (text: string) => void;
  value?: any;
}

export default function AddressAutocomplete({
  label,
  type,
  onChange,
  onInputChange,
  value,
}: AddressAutocompleteProps) {
  const { t } = useTranslation();
  const { getAddressFieldSuggestions, getAddressDetails } = usePlacesApi();
  const [suggestions, setSuggestions] = useState<AddressFieldAutomcompleteSuggestion[]>([]);

  const [textFieldValue, setTextFieldValue] = useState<any>(value);
  useEffect(() => {
    setTextFieldValue(value);
  }, [value]);

  const handleSelect = useCallback(async (option: AddressFieldAutomcompleteSuggestion) => {
    // NOTE the main text is the text for the selected type of address field
    // this is to no show the full address in each of the address form inputs
    setTextFieldValue(option.structured_formatting.main_text);
    // only call the geocoding api once the user has selected an option since this call is roughly
    // double the cost of the autocomplete api
    const selection = mapAddressDetailsToAddress((await getAddressDetails(option.place_id))[0]);
    onChange?.(selection);
  }, [getAddressDetails, onChange]);

  const handleAutocompleteInput = useCallback(async (event, selectedOption?) => {
    const needsCompletion = event.type === 'change';
    if (needsCompletion) {
      const { value: eventValue } = event.target;
      const suggs = await getAddressFieldSuggestions(eventValue, type);
      setSuggestions(suggs || []);
      return;
    }
    handleSelect(selectedOption);
  }, [getAddressFieldSuggestions, handleSelect, type]);

  const debouncedHandleAutocompleteInput = useMemo(() => (
    debounce(handleAutocompleteInput, 500)
  ), [handleAutocompleteInput]);

  return (
    <Autocomplete
      filterOptions={x => x}
      options={suggestions}
      getOptionLabel={(option: AddressFieldAutomcompleteSuggestion) => (
        option.structured_formatting.main_text
      )}
      renderOption={(rProps, option: AddressFieldAutomcompleteSuggestion) => (
        <li {...rProps} key={option.description}>
          {option.description}
        </li>
      )}
      renderInput={params => (
        <TextField
          onChange={debouncedHandleAutocompleteInput}
          variant="outlined"
          label={label}
          {...params}
        />
      )}
      onChange={debouncedHandleAutocompleteInput}
      inputValue={textFieldValue}
      onInputChange={(event, newInputValue) => {
        // NOTE other events like null or blur would just clear the input
        if (event?.type !== 'change') return;
        setTextFieldValue(newInputValue);
        onInputChange?.(newInputValue);
      }}
      popupIcon={null}
      noOptionsText={t('addressAutocomplete.noOptions')}
    />
  );
}
