import React from 'react';
import Translate from '../components/i18n/Translate';
import { formatPrice } from '../components/ProductFormatPrice';
import PaperTableRow from '../components/PaperTableRow';
import LocaleDate from '../components/LocaleDate';
import PaperTable from '../components/PaperTable';
import ContentLink from '../components/ContentLink';
import SimpleStateIndicator from '../components/SimpleStateIndicator';

class ClosingViewGeneral extends React.PureComponent {
  render() {
    const { closing } = this.props;
    return (
      <PaperTable headline={<Translate id="closings.general" />}>
        <PaperTableRow label={<Translate id="closings.state" />} value={<SimpleStateIndicator useGreen={closing.state === 'finalized'} text={<Translate id={`closings.${closing.state}`} />} />} />
        <PaperTableRow label={<Translate id="closings.sequenceNumber" />} value={closing.sequenceNumber} />
        <PaperTableRow label={<Translate id="closings.date" />} value={<LocaleDate date={closing.date} />} />
        <PaperTableRow label={<Translate id="closings.total" />} value={formatPrice(closing.totalPrice, closing.currency)} />
        <PaperTableRow label={<Translate id="closings.totalWithCustomerCard" />} value={formatPrice(closing.totalPriceWithCustomerCard, closing.currency)} />
        <PaperTableRow label={<Translate id="shop.itemName" />} value={<ContentLink to={`/${closing.project}/shops/${closing.shopID}`}>{closing.shop.name}</ContentLink>} />
      </PaperTable>
    );
  }
}

export default ClosingViewGeneral;
