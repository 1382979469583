import React, { ReactNode } from 'react';
import ResourceContext from './ResourceContext';

export interface ResourceProviderProps {
  children: ReactNode;
  name?: string;
  preventFallbackToGlobals?: boolean;
}

export default function ResourceProvider({
  children,
  name,
  preventFallbackToGlobals = false,
}: ResourceProviderProps) {
  if (!name) return <>{children}</>;

  return (
    <ResourceContext.Provider value={{ name, preventFallbackToGlobals }}>
      {children}
    </ResourceContext.Provider>
  );
}
