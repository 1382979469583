import moment from 'moment';
import { Range } from './urls';

const DEFAULT_MAX_MONTHS = 12;
export const MAX_APPROVAL_STATS_MONTHS = 3;
export const MIN_APPROVAL_STATS_DATE = moment('2021-12-09', 'YYYY-MM-DD');
const SNABBLE_FOUNDING_DATE = moment('2018-01-01', 'YYYY-MM-DD');

type RangeOptions = {
  minDate?: moment.Moment;
  maxMonths?: number;
};

export const predefinedRanges = {
  currentWeek: {
    value: 'currentWeek',
    from: moment().subtract(6, 'days').startOf('day').toISOString(true),
    to: moment().endOf('day').toISOString(true),
  },
  last14Days: {
    value: 'last14Days',
    from: moment().subtract(13, 'days').startOf('day').toISOString(true),
    to: moment().endOf('day').toISOString(true),
  },
  currentMonth: {
    value: 'currentMonth',
    from: moment().startOf('month').toISOString(true),
    to: moment().endOf('month').toISOString(true),
  },
  lastMonth: {
    value: 'lastMonth',
    from: moment().subtract(1, 'months').startOf('month').toISOString(true),
    to: moment().subtract(1, 'months').endOf('month').toISOString(true),
  },
};

function error(err: string | null, data = {}) {
  return { hasError: !!err, error: err, errorData: data };
}

const NO_ERROR = error(null);

export function validateTimeRange(range: Range, options: RangeOptions = {}) {
  if (!range) {
    return NO_ERROR;
  }

  const { from, to } = range;

  let fromDate;
  if (from) {
    fromDate = moment(from, 'YYYY-MM-DD HH:mm');
    if (!fromDate.isValid()) {
      return error('invalidFrom');
    }
  }

  let toDate;
  if (to) {
    toDate = moment(to, 'YYYY-MM-DD HH:mm');
    if (!toDate.isValid()) {
      return error('invalidTo');
    }
  }

  if (!fromDate || !toDate) {
    return NO_ERROR;
  }

  let minDate = SNABBLE_FOUNDING_DATE;
  if (options.minDate) {
    minDate = moment(options.minDate);
  }

  if (fromDate.isBefore(minDate)) {
    return error('outOfRange');
  }

  if (toDate.isBefore(fromDate)) {
    return error('invalidRange');
  }

  const { maxMonths } = options;
  if (maxMonths && fromDate.add(maxMonths, 'month').isSameOrBefore(toDate)) {
    return error('rangeTooLarge', { maxMonths });
  }

  return NO_ERROR;
}

export function isValidTimeRange(range: Range, options: RangeOptions = {}) {
  const { hasError } = validateTimeRange(range, options);
  return !hasError;
}

export function validateStatsTimeRange(range: Range) {
  return validateTimeRange(range, {
    maxMonths: DEFAULT_MAX_MONTHS,
  });
}

export function isValidStatsTimeRange(range: Range) {
  const { hasError } = validateStatsTimeRange(range);
  return !hasError;
}

export function validateApprovalStatsTimeRange(range: Range) {
  return validateTimeRange(range, {
    minDate: MIN_APPROVAL_STATS_DATE,
    maxMonths: MAX_APPROVAL_STATS_MONTHS,
  });
}
