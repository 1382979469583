import { styled } from '@mui/system';

const Strikethrough = styled('div')(() => ({
  display: 'inline-block',
  position: 'relative',
  whiteSpace: 'nowrap',

  '&::after': {
    borderTop: '1px solid currentColor',
    content: '""',
    display: 'block',
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    rotate: '10deg',
    top: '50%',
    width: '100%',
  },
}));

export default Strikethrough;
