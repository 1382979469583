import moment from 'moment';
import { withTranslation, useTranslation } from 'react-i18next';
import 'moment/locale/de';

export const dateFormat = ((code, showSeconds = false) => {
  switch (code) {
    case 'de':
    case 'de-DE':
      return `DD.MM.YYYY HH:mm${showSeconds ? ':ss' : ''}`;
    default:
      return `YYYY/MM/DD hh:mm${showSeconds ? ':ss' : ''} A`;
  }
});

const dateOnlyFormat = ((code) => {
  switch (code) {
    case 'de':
      return 'DD.MM.YYYY';
    default:
      return 'YYYY/MM/DD';
  }
});

const LocaleDate = ({
  date,
  relative,
  dateOnly,
  absoluteThresholdMinutes = 59,
  showSeconds = false,
}) => {
  const { i18n } = useTranslation();

  if (relative) {
    const minutesFromNow = moment(date).diff(moment.now(), 'minutes');
    moment.relativeTimeThreshold('mm', absoluteThresholdMinutes);

    if (minutesFromNow > -absoluteThresholdMinutes) {
      return moment(date).fromNow();
    }
    return (moment(date).format(dateFormat(i18n.language, showSeconds)));
  }

  if (dateOnly) return (moment(date).format(dateOnlyFormat(i18n.language)));

  return (moment(date).format(dateFormat(i18n.language, showSeconds)));
};

export default withTranslation()(LocaleDate);
