import axios, { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import isObject from 'lodash/isObject';
import {
  defineApi,
  isNotFoundError,
  useProjectSpecificApiClient,
} from '../api';
import Categories from './Categories';
import patchErrorToSingleCategoriesField from './useCategoriesApiSupport';
import {
  arrayToCommaSeparatedString,
  commaSeparatedStringToArray,
  safeStringifyJSON,
  safeParseJSON,
} from '../sco/apiStringUtils';

interface UpdateCategoriesApiParams {
  data: FieldValues;
}

const useApi = defineApi({
  get: async (client) => {
    try {
      const { data } = await client.get('');
      return data as Categories;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  update: (client, { data }: UpdateCategoriesApiParams) => client.put('', data)
    .then(res => res, (err: Error | AxiosError<any>) => {
      if (axios.isAxiosError(err)) {
        throw patchErrorToSingleCategoriesField(err);
      }
      throw err;
    }),
});

export default function useCategoriesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices/categories' });
  return useApi(client);
}

const useLegacyApi = defineApi({
  get: async (client) => {
    try {
      const { data } = await client.get('');
      const categories = safeStringifyJSON(data.categories);
      const singleItems = arrayToCommaSeparatedString(data.singleItems || []);
      return { ...data, categories, singleItems } as Categories;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  update: (client, { data }: UpdateCategoriesApiParams) => {
    const singleItems = commaSeparatedStringToArray(data.singleItems);
    const categories = safeParseJSON(data.categories);
    return client.put('', { ...data, categories, singleItems })
      .then(res => res, (err: Error | AxiosError<any>) => {
        if (axios.isAxiosError(err)) {
          throw patchErrorToSingleCategoriesField(err);
        }
        throw err;
      });
  },
});

export function useLegacyCategoriesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices/categories' });
  return useLegacyApi(client);
}

export interface ProductData {
  'sku': string;
  'project': string;
  'name': string;
  'subtitle': string;
  'description': string;
  'imageUrl': string;
  'taxCategory':string;
  'codes': Array<{
    'code':string;
    'template':string;
  }>;
  'productType': string;
  'isDiscountable':boolean;
}

export function useCategoryProductApi() {
  const projectClient = useProjectSpecificApiClient({ basePath: '' });
  return defineApi({
    getProductSearch: async (client, { search }: { search: string }) => {
      const limit = 10;
      const query = search ? `&q=${search}` : '';
      const products = await client.get<string>(`/products?limit=${limit}${query}`).then(resp => resp.data);

      // NOTE if the query returns a single object that object is parsed as json by axios...
      if (isObject(products)) return [products] as ProductData[];

      // ...otherwise the response is a string with a json object per line (not a valid json)
      return products
        .split('\n')
        .filter((item: any) => item.length > 0)
        .map((value: any) => JSON.parse(value)) as ProductData[];
    },
    getProduct: async (client, { sku }: { sku : string }) => {
      const product = await client.get<string>(`/products/sku/${sku}`).then(resp => resp.data);
      return product as any as ProductData;
    },
  })(projectClient);
}
