import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { green, amber } from '@mui/material/colors';
import { Theme } from '@mui/system';

const styles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  state: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
  green: {
    background: green[500],
  },
  yellow: {
    background: amber[500],
  },
  red: {
    background: theme.palette.error[500],
  },
  label: {
    marginLeft: theme.spacing(1),
  },
});

interface SimpleStateIndicatorProps {
  classes: Record<string, string>;
  useGreen?: boolean;
  useErrorColor?: boolean;
  text?: React.ReactNode | string;
}

function SimpleStateIndicator({
  classes,
  useGreen,
  useErrorColor,
  text,
}: SimpleStateIndicatorProps) {
  let colorClass = useGreen ? classes.green : classes.yellow;
  if (!useGreen && useErrorColor) {
    colorClass = classes.red;
  }
  return (
    <div className={classes.wrapper}>
      <span className={`${classes.state} ${colorClass}`}>
        &nbsp;
      </span>
      {text && <span className={classes.label}>{text}</span>}
    </div>
  );
}

export default withStyles(styles)(SimpleStateIndicator);
