import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { amber } from '@mui/material/colors';
import Translate from './i18n/Translate';
import { whichEnvironment } from '../urls';

const styles = theme => ({
  stage: {
    marginLeft: theme.spacing(4),
    alignSelf: 'center',
    background: amber[500],
    padding: theme.spacing(1),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

function StageLabel({ classes }) {
  const env = whichEnvironment(window.location);
  if (env === 'prod') {
    return null;
  }
  return (
    <Typography variant="subtitle1" className={classes.stage}><Translate id={env} /></Typography>
  );
}

export default withStyles(styles)(StageLabel);
