import React from 'react';
import Translate from './i18n/Translate';
import SimpleStateIndicator from './SimpleStateIndicator';

function SupervisingSucceededCheckIndicator({
  check,
}) {
  if (!check.successful) return null;
  if (check.type === 'supervisor_approval' && !check.isRescan) return null;
  return (
    <SimpleStateIndicator
      useGreen
      text={<Translate id="supervising.success" />}
    />
  );
}

export default SupervisingSucceededCheckIndicator;
