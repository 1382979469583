import React, { useMemo } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import useToast from './useToast';
import { ToastProps } from './ToastProps';
import { useResourceTranslator } from '../resource';

export interface AlertProps extends Omit<ToastProps, 'children'> {
  actionName?: string;
}

export interface AlertPropsWithSeverity extends AlertProps {
  severity: AlertColor;
}

export const SEVERITIES: AlertColor[] = ['error', 'info', 'success', 'warning'];

interface UseAlertBaseFns {
  show: (props: AlertPropsWithSeverity) => void;
  hide: () => void;
}

type UseAlertSeverityFns = {
  [key in AlertColor]: (props: AlertProps) => void;
};

export type UseAlert = UseAlertBaseFns & UseAlertSeverityFns;

export default function useAlert() {
  const toast = useToast();
  const t = useResourceTranslator();

  return useMemo(() => {
    const show = ({
      actionName, message, severity, ...props
    }: AlertPropsWithSeverity) => {
      toast.show({
        ...props,
        children: (
          <Alert
            elevation={4}
            variant="filled"
            onClose={toast.hide}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {message ?? t(`alert.${severity}.${actionName ?? 'default'}`)}
          </Alert>
        ),
      });
    };

    const alert = { show, hide: toast.hide } as UseAlert;
    SEVERITIES.forEach((severity) => {
      alert[severity] = (props: AlertProps) => show({ ...props, severity });
    });
    return alert;
  }, [t, toast]);
}
