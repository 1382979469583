import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/system/Box';
import ResourceFormView from '../scaffold/ResourceFormView';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import useCheckoutDeviceApi from './api/useCheckoutDeviceApi';
import { CheckoutDeviceType, CheckoutDeviceWithShop } from './CheckoutDevice';
import { ApplicationState } from '../reducers/initialState';
import useShopApi, { Shop } from '../shop/useShopApi';
import CheckoutDeviceQuickEditView from './CheckoutDeviceQuickEditView';
import CheckoutDeviceForm from './CheckoutDeviceForm';
import CheckoutDeviceConfigFormNew from './CheckoutDeviceConfigFormNew';
import CheckoutDeviceConfigForm from './CheckoutDeviceConfigForm';
import { CustomFormRef } from '../form/CustomFormProps';
import ProjectContentLink from '../components/ProjectContentLink';
// @ts-ignore
import { mapDeviceStatus } from './reducers/checkoutDevices';

const NewCheckoutDeviceType: Partial<CheckoutDeviceType>[] = [
  CheckoutDeviceType.POS,
  CheckoutDeviceType['SCO-NG'],
];

enum DrawerTabs {
  SETUP = 'setup',
  CONFIGURATION = 'configuration',
}

export interface CheckoutDeviceQuickEditDrawerProps {
  selectedDevice: CheckoutDeviceWithShop | null;
  onClose: () => void;
}

export default function CheckoutDeviceQuickEditDrawer({
  selectedDevice,
  onClose,
}: CheckoutDeviceQuickEditDrawerProps) {
  const { t } = useTranslation();
  const shops = useSelector<ApplicationState, any>(state => state.shops) as Shop[];

  const TABS: TabFilterOption[] = [
    { id: DrawerTabs.SETUP, title: t(`checkoutDevices.quickEdit.tab.${DrawerTabs.SETUP}`) },
    { id: DrawerTabs.CONFIGURATION, title: t(`checkoutDevices.quickEdit.tab.${DrawerTabs.CONFIGURATION}`) },
  ];

  const DEFAULT_TAB = TABS[0];

  const [selectedTab, setSelectedTab] = useState<TabFilterOption>(DEFAULT_TAB);
  const [editSelected, setEditSelected] = useState<boolean>(false);
  const currentForm = useRef<CustomFormRef | null>(null);
  const handleTabChange = useCallback(async (tab: TabFilterOption) => {
    if (!currentForm.current) {
      setSelectedTab(tab);
      return;
    }
    if (await currentForm.current?.forceSubmit()) setSelectedTab(tab);
  }, []);

  const handleClose = useCallback(async (force?: boolean) => {
    if (!currentForm.current || force) {
      setSelectedTab(DEFAULT_TAB);
      setEditSelected(false);
      onClose();
      return;
    }
    if (await currentForm.current?.forceSubmit()) {
      setSelectedTab(DEFAULT_TAB);
      setEditSelected(false);
      onClose();
    }
  }, [DEFAULT_TAB, onClose]);

  const api = useCheckoutDeviceApi();
  const shopApi = useShopApi();

  const handleFetchQuickEdit = useCallback(async () => {
    if (!selectedDevice?.id) return null;
    // HACK status should be merged in backend
    const device = await api.getCheckoutDevice({ id: selectedDevice.id });
    const shopStatus = await api.getShopStatus({ id: device.shop });
    const deviceStatus = mapDeviceStatus(shopStatus.devices.find((d: any) => d.id === device.id));
    const shop = shops.find(s => s.id === device.shop);
    return { ...device, ...deviceStatus, shop };
  }, [api, selectedDevice?.id, shops]);

  const [availableDeviceTypes, setAvailableDeviceTypes] = useState<CheckoutDeviceType[]>([]);
  const [availableShops, setAvailableShops] = useState<Shop[]>([]);
  const handleFetchSetup = useCallback(async () => {
    if (!selectedDevice?.id) return null;
    setAvailableDeviceTypes(await api.getAvailableDeviceTypes(null));
    setAvailableShops(await shopApi.getShops(null));
    return api.getCheckoutDevice({ id: selectedDevice.id });
  }, [api, selectedDevice?.id, shopApi]);

  const sharedResourdeFormViewProps = {
    headline: selectedDevice?.name || selectedDevice?.id || '',
    additionalContent: (
      <TabFilter
        options={TABS}
        defaultOption={selectedTab}
        onChange={handleTabChange}
        value={selectedTab}
        sx={{ marginBottom: '32px' }}
      />
    ),
    onSubmitted: () => { handleClose(true); },
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={!!selectedDevice}
      onClose={() => { handleClose(); }}
      sx={{
        paddingBlock: '0',
        '& .MuiDrawer-paper': {
          width: 'min(600px, 100vw - 32px)',
          maxWidth: '100dvw',
          paddingInline: '16px',
        },
      }}
    >
      {selectedDevice && (
        <Box marginBottom="104px">
          {selectedTab.id === DrawerTabs.SETUP && (
            <>
              {!editSelected ? (
                <ResourceFormView
                  actionName="quickEditCheckoutDevice"
                  headlineButtons={(
                    <ProjectContentLink
                      to={`/checkout-devices/${selectedDevice.id}/edit`}
                      onClick={(e: Event) => { e.preventDefault(); setEditSelected(true); }}
                    >
                      <IconButton sx={{ marginLeft: 'auto' }}>
                        <EditIcon />
                      </IconButton>
                    </ProjectContentLink>
                  )}
                  {...sharedResourdeFormViewProps}
                  Form={CheckoutDeviceQuickEditView}
                  fetch={handleFetchQuickEdit}
                  submit={() => { }}
                />
              ) : (
                <ResourceFormView
                  actionName="update"
                  {...sharedResourdeFormViewProps}
                  Form={CheckoutDeviceForm}
                  FormProps={{
                    availableDeviceTypes,
                    availableShops,
                    ref: currentForm,
                    onCancel: () => { handleClose(true); },
                  }}
                  fetch={handleFetchSetup}
                  submit={data => api.updateCheckoutDevice({ id: selectedDevice.id || '', data })}
                />
              )}
            </>
          )}

          {selectedTab.id === DrawerTabs.CONFIGURATION && (
            <ResourceFormView
              actionName="updateConfig"
              {...sharedResourdeFormViewProps}
              Form={(selectedDevice.type && NewCheckoutDeviceType.includes(selectedDevice.type))
                ? CheckoutDeviceConfigFormNew
                : CheckoutDeviceConfigForm
              }
              FormProps={{
                ref: currentForm,
                onCancel: () => { handleClose(true); },
              }}
              fetch={() => api.getCheckoutDeviceConfig({ id: selectedDevice.id || '' })}
              submit={data => api.updateCheckoutDeviceConfig({ id: selectedDevice.id || '', data })}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
}
