import React, { useCallback, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CloudDownload } from '@mui/icons-material';
import { SortableTable, ColumnsProp, formatCell } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useResourceTranslator from '../resource/useResourceTranslator';
import { UnblockUserProps } from './useShopAccessApi';

export interface BlockListTableProps {
  values: any;
  onEntryRemove: (user: UnblockUserProps) => void;
  filterOverwrite?: string;
  onDownload: () => void;
}

export default function BlockListTable({
  values,
  onEntryRemove,
  filterOverwrite,
  onDownload,
}: BlockListTableProps) {
  const t = useResourceTranslator();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UnblockUserProps | undefined>();


  const removeUserFromBlocklist = useCallback((row: any) => {
    setUserToDelete({ type: row.credentialsType, value: row.credentialsValue });
    setDialogOpen(true);
  }, []);

  const confirmRemoveUserFromBlocklist = useCallback(() => {
    setDialogOpen(false);
    if (!userToDelete) return;
    onEntryRemove(userToDelete);
  }, [onEntryRemove, userToDelete]);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'credentialsType',
      disablePadding: false,
      displayName: t('shopAccess.blockList.credentialsType'),
    },
    {
      key: 'credentialsValue',
      disablePadding: false,
      displayName: t('shopAccess.blockList.credentialsValue'),
    },
    {
      key: 'reason',
      disablePadding: false,
      displayName: t('shopAccess.blockList.reason'),
    },
    {
      key: 'createdAt',
      type: 'datetime',
      disablePadding: false,
      displayName: t('shopAccess.blockList.createdAt'),
    },
    {
      key: 'createdBy',
      disablePadding: false,
      displayName: t('shopAccess.blockList.createdBy'),
    },
    {
      type: 'padding',
      disablePadding: false,
    },
  ], [t]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell align="left">
        {t(`shopAccess.credentialsTypes.${rowData.credentialsType}`)}
      </TableCell>
      <TableCell align="left" >
        {rowData.credentialsValue}
      </TableCell>
      <TableCell align="left">
        {rowData.reason}
      </TableCell>
      <TableCell align="left">
        {formatCell(rowData.createdAt, 'datetime')}
      </TableCell>
      <TableCell>
        {rowData.createdBy}
      </TableCell>
      <TableCell padding="checkbox" align="right">
        <div style={{ display: 'flex', flexGrow: 1 }} >
          <Tooltip title={t('removeFromBlockList')} placement="top" arrow>
            <IconButton size="small" onClick={() => removeUserFromBlocklist(rowData)}>
              <PersonRemoveIcon />
            </IconButton>
          </Tooltip>

        </div>
      </TableCell>
    </>
  ), [removeUserFromBlocklist, t]);


  const table = (
    (values?.list?.length > 0) ?
      (<SortableTable
        data={values.list || []}
        columnsMetadata={headCells}
        title=""
        filterPlaceholder={t('shopAccess.blockList.filterPlaceholder')}
        filterOverwrite={filterOverwrite}
        labelRowsPerPage={t('shopAccess.labelRowsPerPage')}
        CustomRowContent={TableRowEntries}
        verticalToolbarPadding={4}
      />)
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="shopAccess.blockList.empty" /></Typography>
        </div>
      )
  );

  return (
    <>
      <Dialog open={dialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('shopAccess.blockList.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('shopAccess.blockList.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmRemoveUserFromBlocklist} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={4}>
        {table}
      </Paper>

      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudDownload />}
        onClick={onDownload}
        style={{ marginTop: '32px', float: 'right' }}
      >
        <Translate id="downloadButton.download" />
      </Button>
    </>
  );
}
