import React from 'react';
import TableCell from '@mui/material/TableCell';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Translate from './i18n/Translate';
import { formatPrice } from './ProductFormatPrice';
import ColoredTableRow from './ColoredTableRow';
import ContentLink from './ContentLink';
import { AdditionalAttributesDialogButton } from '../additional-attributes';
import Strikethrough from './Strikethrough';

function formatPriceWithReferenceUnit(price, currency, referenceUnit) {
  const formatted = formatPrice(price, currency);
  if (!formatted) {
    return null;
  }
  if (referenceUnit) {
    return `${formatted}/${referenceUnit}`;
  }
  return formatted;
}

function ItemTableRow({
  item, currency, productLink, isColored, alwaysCollapse, isReferringItem,
}) {
  if (!item || !currency) return null;

  const StyledTableCell = ({ children, ...props }) => {
    if (item.priceModifiers || alwaysCollapse) {
      return (
        <TableCell size="small" sx={{ borderBottom: 'none' }} {...props}>
          {children}
        </TableCell>
      );
    }

    return (
      <TableCell {...props}>
        {children}
      </TableCell>
    );
  };

  const formattedListPrice = formatPriceWithReferenceUnit(
    item.listPrice ?? item.price,
    currency,
    item.referenceUnit,
  );

  const hasDiscount = Boolean(item.listPrice) && item.listPrice !== item.price;

  return (
    <>
      <ColoredTableRow isColored={isColored}>
        {isReferringItem ? (
          <StyledTableCell sx={{ textAlign: 'center', borderBottom: 'none' }}>
            <SubdirectoryArrowRightIcon fontSize="small" />
          </StyledTableCell >
        ) : (
          <StyledTableCell><ContentLink to={productLink}>{item.sku}</ContentLink></StyledTableCell>
        )}
        <StyledTableCell><ContentLink to={productLink}>{item.name}</ContentLink></StyledTableCell>
        <StyledTableCell><Translate id={`orders.itemType.${item.type}`} /></StyledTableCell>
        <StyledTableCell>{item.amount > 0 && item.amount}</StyledTableCell>
        {item.weight && item.weightUnit &&
          <StyledTableCell>{item.weight} {item.weightUnit}</StyledTableCell>
        }
        {item.units &&
          <StyledTableCell><Translate id="orders.units" data={{ units: item.units }} /></StyledTableCell>
        }
        {!item.weight && !item.weightUnit && !item.units &&
          <StyledTableCell>–</StyledTableCell>
        }
        <StyledTableCell>
          {hasDiscount && <Strikethrough>{formattedListPrice}</Strikethrough>}
          {!hasDiscount && formattedListPrice}
        </StyledTableCell>
        <StyledTableCell>
          {formatPriceWithReferenceUnit(item.price, currency, item.referenceUnit)}
        </StyledTableCell>
        <StyledTableCell>
          {
            !item.priceModifiers && !(item.priceModifiers || []).length &&
            formatPrice(item.totalPrice, currency)
          }
        </StyledTableCell>
        <StyledTableCell align="right">
          {item.additionalAttributes && (
          <AdditionalAttributesDialogButton attributes={item.additionalAttributes} />
          )}
        </StyledTableCell>
      </ColoredTableRow>

      {(item.priceModifiers || []).map(modifier => (
        <ColoredTableRow key={`${item.id}-${modifier.name}-${modifier.price}`} isColored={isColored}>
          <StyledTableCell sx={{ textAlign: 'center', borderBottom: 'none' }}>
            <SubdirectoryArrowRightIcon fontSize="small" />
          </StyledTableCell >
          <StyledTableCell><Translate id={`orders.priceModifierMethod.${modifier.method}`} /></StyledTableCell>
          <StyledTableCell><Translate id={`orders.priceModifierAction.${modifier.action}`} /></StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell>{formatPrice(modifier.price, currency)}</StyledTableCell>
          <StyledTableCell />
          <StyledTableCell align="right">
            {modifier.reasonCode && (
            <AdditionalAttributesDialogButton
              attributes={{ reasonCode: modifier.reasonCode }}
              />
            )}
          </StyledTableCell>
        </ColoredTableRow>
      ))}
      {item.priceModifiers && (
      <ColoredTableRow isColored={isColored}>
        <StyledTableCell />
        <StyledTableCell colSpan={6} />
        <StyledTableCell>{formatPrice(item.totalPrice, currency)}</StyledTableCell>
        <StyledTableCell />
      </ColoredTableRow>
      )}
    </>
  );
}

export default ItemTableRow;
