import React from 'react';
import Translate from './i18n/Translate';
import StatisticArea from './StatisticArea';
import StatisticPaper from './StatisticPaper';


const OrderStatistic = ({
  statistic,
}) => {
  if (!statistic?.date) return null;

  const legendItems = [
    { title: <Translate id="statistic.thisWeek" />, color: 'rgb(52, 124, 255, 1)', strokeWidth: 5 },
    { title: <Translate id="statistic.comparedSeven" />, color: '#ffc400', strokeWidth: 5 },
  ];

  return (
    <React.Fragment>
      <StatisticPaper
        headline={<Translate id="orderStatistic.totalOrdersWeek" />}
        from={statistic.date.from}
        to={statistic.date.to}
        total={statistic.count}
        legendItems={legendItems}
      >
        <StatisticArea
          values={statistic.thisWeek.orders}
          compareValues={statistic.lastWeek.orders}
          maxValue={statistic.maxOrders}
          xLabelAsDate
          translationLabelYKey="orderStatistic.totalOrders"
          translationComparedKey="statistic.comparedSeven"
          dateAsWeek
        />

      </StatisticPaper>

      <StatisticPaper
        headline={<Translate id="orderStatistic.totalRevenueWeek" />}
        from={statistic.date.from}
        to={statistic.date.to}
        total={statistic.totalSum}
        legendItems={legendItems}
      >
        <StatisticArea
          values={statistic.thisWeek.total}
          compareValues={statistic.lastWeek.total}
          maxValue={statistic.maxTotal}
          translationLabelYKey="orderStatistic.revenue"
          xLabelAsDate
          translationComparedKey="statistic.comparedSeven"
          translationData={{ currency: statistic.currency }}
          dateAsWeek
        />
      </StatisticPaper>
    </React.Fragment>
  );
};

export default OrderStatistic;
