import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCheckoutDeviceApi, { CheckoutDeviceType } from './api/useCheckoutDeviceApi';
import CheckoutDeviceForm from './CheckoutDeviceForm';
import ResourceFormView from '../scaffold/ResourceFormView';
import ConfigDialog from './ConfigDialog';
import CheckoutDevice from './CheckoutDevice';
import useShopApi, { Shop } from '../shop/useShopApi';

export default function CreateCheckoutDeviceView() {
  const api = useCheckoutDeviceApi();
  const shopApi = useShopApi();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [createdDevice, setCreatedDevice] = useState<CheckoutDevice>();
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState<CheckoutDeviceType[]>([]);
  const [availableShops, setAvailableShops] = useState<Shop[]>([]);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getAvailableCheckoutDeviceTypes = async () => {
      const availableTypes = await api.getAvailableDeviceTypes(null);
      setAvailableDeviceTypes(availableTypes);
    };
    getAvailableCheckoutDeviceTypes();

    const getAvailableShops = async () => {
      setAvailableShops(await shopApi.getShops(null));
    };
    getAvailableShops();
  }, [api, shopApi]);

  return (
    <>
      <ConfigDialog
        open={!!createdDevice}
        onClose={handleClose}
        device={createdDevice}
      />

      <ResourceFormView
        actionName="create"
        Form={CheckoutDeviceForm}
        FormProps={{
          availableDeviceTypes,
          availableShops,
          preSelectedShopId: state?.shopId,
        }}
        submit={data => api.createCheckoutDevice({ data })}
        onSubmitted={(device: CheckoutDevice) => {
          setCreatedDevice(device);
        }}
      />
    </>
  );
}
