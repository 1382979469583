import React from 'react';
import { useLocation } from 'react-router-dom';

const trackRouteChange = () => {
  // IE save way to create custom event
  const trackEvent = document.createEvent('CustomEvent');
  trackEvent.initCustomEvent('retailerPortalRouteChange', false, false);
  dispatchEvent(trackEvent);
};

function TrackingWrapper({ children }) {
  useLocation();
  trackRouteChange();

  return <React.Fragment>{children}</React.Fragment>;
}

export default TrackingWrapper;
