import axios, { AxiosError } from 'axios';
import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  isNotFoundError,
  useProjectSpecificApiClient,

} from '../api';
import Categories from './Categories';
import patchErrorToSingleCategoriesField from './useCategoriesApiSupport';
import {
  arrayToCommaSeparatedString,
  commaSeparatedStringToArray,
  safeStringifyJSON,
  safeParseJSON,
} from '../sco/apiStringUtils';

interface CategoriesApiParams {
  shopId: string;
}

interface UpdateCategoriesApiParams {
  shopId: string;
  data: FieldValues;
}

const useApi = defineApi({
  get: async (client, { shopId }: CategoriesApiParams) => {
    try {
      const { data } = await client.get(`/${shopId}`);
      return data as Categories;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  update: (client, { shopId, data }: UpdateCategoriesApiParams) => client.put(`/${shopId}`, data)
    .then(res => res, (err: Error | AxiosError<any>) => {
      if (axios.isAxiosError(err)) {
        throw patchErrorToSingleCategoriesField(err);
      }
      throw err;
    }),
});

export default function useShopCategoriesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices/categories/shop' });
  return useApi(client);
}

const useLegacyApi = defineApi({
  get: async (client, { shopId }: CategoriesApiParams) => {
    try {
      const { data } = await client.get(`/${shopId}`);
      const categories = safeStringifyJSON(data.categories);
      const singleItems = arrayToCommaSeparatedString(data.singleItems || []);
      return { ...data, categories, singleItems } as Categories;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  update: (client, { shopId, data }: UpdateCategoriesApiParams) => {
    const singleItems = commaSeparatedStringToArray(data.singleItems);
    const categories = safeParseJSON(data.categories);
    return client.put(`/${shopId}`, { ...data, categories, singleItems })
      .then(res => res, (err: Error | AxiosError<any>) => {
        if (axios.isAxiosError(err)) {
          throw patchErrorToSingleCategoriesField(err);
        }
        throw err;
      });
  },
});

export function useLegacyShopCategoriesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices/categories/shop' });
  return useLegacyApi(client);
}
