import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import set from 'lodash/set';
import { InitialApplicationState } from './reducers/initialState';

const REDACTED_VALUE = '[Filtered]';

const REDACTED_ACTIONS = [
  'RECEIVED_SHOPS',
  'RECEIVED_SHOP',
  'RECEIVED_ORDERS',
  'RECEIVED_ORDER',
  'RECEIVED_ORDER_EXPORT',
  'RECEIVED_ORDER_EXPORT_ERROR',
  'RECEIVED_LINE_ITEMS_FOR_EXPORT',
  'RECEIVED_PRODUCTS',
  'RECEIVED_PRODUCT',
  'RECEIVED_PRICING',
  'RECEIVED_PRODUCT_STATISTIC',
  'RECEIVED_ORDER_STATISTIC',
  'RECEIVED_BASKET_AVERAGE_STATISTIC',
  'RECEIVED_RECURRING_VISITORS_STATISTIC',
  'RECEIVED_LAST_WEEK_ORDER_STATISTIC',
  'RECEIVED_APPROVAL_DURATION_STATISTIC',
  'ACQUIRED_TOKEN',
  'RECEIVED_CLOSINGS',
  'RECEIVED_CLOSING',
  'RECEIVED_CLOSING_SCHEDULES',
  'RECEIVED_CLOSING_SCHEDULE',
  'RECEIVED_CHECKOUT_DEVICES',
  'RECEIVED_CHECKOUT_DEVICE',
  'RECEIVED_CHECKOUT_DEVICES_STATUS',
];

const REDACTED_STATE_PATHS = [
  'token',
  'shops',
  'shop',
  'selectedShop',
  'products',
  'product',
  'orders',
  'order',
  'ordersExport',
  'lineItemExport',
  'orderStatistic',
  'basketAverageStatistic',
  'recurringVisitorsStatistic',
  'approvalDurationStatistic',
  'closings.details',
  'closings.list',
  'closings.schedules',
  'checkoutDevices.list',
];

export default Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (REDACTED_ACTIONS.includes(action.type)) {
      return { ...action, payload: REDACTED_VALUE };
    }
    return action;
  },
  stateTransformer: (state: InitialApplicationState) => {
    const transformedState = cloneDeep(state);
    REDACTED_STATE_PATHS.forEach((path) => {
      if (has(state, path)) {
        set(transformedState, path, REDACTED_VALUE);
      }
    });
    return transformedState;
  },
  configureScopeWithState: (scope, state: InitialApplicationState) => {
    scope.setTag('project', state.selectedProjectId);
  },
});
