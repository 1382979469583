import React, { ReactNode, useMemo } from 'react';
import { Privilege } from './Privilege';
import useAccess from './useAccess';

export interface WithAccessProps {
  children: ReactNode;
  privilege: Privilege | Privilege[];
  scope: string;
}

export default function WithAccess({
  privilege,
  children,
  scope,
}: WithAccessProps) {
  const access = useAccess(scope);

  const isPermitted = useMemo(
    () => {
      let privileges!: Privilege[];
      if (Array.isArray(privilege)) {
        privileges = privilege;
      } else {
        privileges = [privilege];
      }

      return privileges.every(priv => access[priv]);
    },
    [access, privilege],
  );

  if (!isPermitted) return null;

  return (<>{children}</>);
}
