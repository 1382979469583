import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import useResourceTranslator from '../resource/useResourceTranslator';
import { RealmValidationRules, User } from './useUserManagementApi';
import ArrayWrapper from '../form/wrapper/ArrayWrapper';

const DEFAULT_VALUES: User | {} = {};

export interface EditUserFormProps extends CustomFormProps<User | {}> {
  onUserDelete?: () => void;
  onPasswordReset?: () => void;
  onPasswordOverwrite?: () => void;
  currentProject: string | undefined;
  realmValidationRules: RealmValidationRules | undefined;
  apiError: string | undefined;
  currentUserIsEditedUser: boolean;
}

export default function EditUserForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  onUserDelete,
  onPasswordOverwrite,
  onPasswordReset,
  currentProject,
  realmValidationRules,
  apiError,
  currentUserIsEditedUser,
}: EditUserFormProps) {
  const t = useResourceTranslator();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);

  const confirmDeleteUser = useCallback(() => {
    setDeleteDialogOpen(false);
    onUserDelete?.();
  }, [onUserDelete]);

  const confirmPasswordReset = useCallback(() => {
    setPasswordResetDialogOpen(false);
    onPasswordReset?.();
  }, [onPasswordReset]);

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const newRoleEntry = { project: currentProject, role: undefined };

  return (
    <>
      <Dialog open={deleteDialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('userManagement.deleteUser.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('userManagement.deleteUser.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDeleteDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmDeleteUser} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordResetDialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('userManagement.resetPassword.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('userManagement.resetPassword.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setPasswordResetDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmPasswordReset} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset category="user">
          <TextField
            control={control}
            name="realm"
            disabled
          />
          <TextField
            control={control}
            name="sub"
            disabled
            required
            rules={{ required: true }}
          />
          <TextField
            control={control}
            name="name"
            optional
          />
          {realmValidationRules?.passwordChoiceRule.email.enabled && (
            <TextField
              control={control}
              name="email"
              required={realmValidationRules?.passwordChoiceRule.email.required}
              rules={{ required: realmValidationRules?.passwordChoiceRule.email.required }}
              optional={!realmValidationRules?.passwordChoiceRule.email.required}
            />
          )}
        </Fieldset>

        <Fieldset category="roles">
          <ArrayWrapper
            control={control}
            name="roles"
            defaultValue={newRoleEntry}
            inlineDeleteButton
            disabled={currentUserIsEditedUser}
            render={(_, path) => (
              <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                <Box sx={{ width: '50%' }}>
                  <SelectOptionsField
                    control={control}
                    name={`${path}.role`}
                    options={realmValidationRules?.allowedRoles || []}
                    label={t('form.labels.role')}
                    required
                    rules={{ required: true }}
                    disabled={currentUserIsEditedUser}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <SelectOptionsField
                    control={control}
                    name={`${path}.project`}
                    options={realmValidationRules?.allowedProjects || []}
                    labelFrom="name"
                    valueFrom="id"
                    label={t('form.labels.project')}
                    required
                    rules={{ required: true }}
                    disabled={currentUserIsEditedUser}
                  />
                </Box>
              </Stack>
            )}
          />
        </Fieldset>

        {currentUserIsEditedUser && <Alert severity="warning">{t('userManagement.error.currentUserIsEditedUser')}</Alert>}
        {apiError && <Alert severity="error">{apiError}</Alert>}

        <DefaultFormActions
          formState={formState}
          additionalButtons={(
            <>
              <Button
                aria-label="delete"
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => { setDeleteDialogOpen(true); }}
                disabled={!onUserDelete || currentUserIsEditedUser}
              >
                {t('button.remove')}
              </Button>
              {realmValidationRules?.passwordChoiceRule.email.enabled && (
                <Button
                  aria-label="password reset"
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  onClick={() => { setPasswordResetDialogOpen(true); }}
                  disabled={!onPasswordReset}
                >
                  {t('editUser.button.emailPasswordReset')}
                </Button>
              )}
              {realmValidationRules?.passwordChoiceRule.password.enabled && onPasswordOverwrite && (
                <Button
                  aria-label="password reset"
                  variant="contained"
                  color="primary"
                  startIcon={<PasswordIcon />}
                  onClick={() => { onPasswordOverwrite(); }}
                >
                  {t('editUser.button.overwritePasswordReset')}
                </Button>
              )}
            </>
          )}
        />
      </Form>
    </>
  );
}
