import actions from '../../actions/constants';
import {
  Range,
  ShopFilter,
  WithCustomerCardFilter,
  PaymentMethodsFilter,
} from '../chartFilterProps';

function updateRange(json: Range) {
  return {
    type: actions['chartFilter/updateRange'],
    payload: json,
  };
}
function updateShopFilter(json: ShopFilter) {
  return {
    type: actions['chartFilter/updateShop'],
    payload: json,
  };
}
function updateWithCustomerCardFilter(json: WithCustomerCardFilter) {
  return {
    type: actions['chartFilter/updateWithCustomerCard'],
    payload: json,
  };
}
function updatePaymentMethodsFilter(json: PaymentMethodsFilter) {
  return {
    type: actions['chartFilter/updatePaymentMethods'],
    payload: json,
  };
}
function updateGenericFilter(json: Record<string, any>) {
  return {
    type: actions['chartFilter/updateGenericFilter'],
    payload: json,
  };
}

export {
  updateRange,
  updateShopFilter,
  updateWithCustomerCardFilter,
  updatePaymentMethodsFilter,
  updateGenericFilter,
};
