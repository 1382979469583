import { useCallback } from 'react';
import { defineApi, useProjectSpecificApiClient } from '../api';
import { prependApiHost } from '../urls';
import { isValidUrl } from '../utils/urlUtils';

export type Variant = `${number}x` | `${number}.${number}x`;

export interface Asset {
  name: string;
  variants: Record<Variant, string>;
}

interface Manifest {
  projectId: string;
  files: Asset[];
}

export interface AssetFilter {
  name?: RegExp;
}

interface GetFileUrlParams {
  fileName: string;
  variant: Variant;
}

export function mapAssetToVariantUrl(asset: Asset, variant: Variant) {
  const url = prependApiHost(asset.variants[variant]);
  return url;
}

export function getFilenameFromAsset(asset: Asset) {
  return asset.name;
}

// NOTE the urls stored in the variants object are absolute urls.
// This regex is used to extract the relative path for use with the assets wrappers of the SCOs.
export const FilenameRegex = /(?<=assets\/files).*[.png|.svg]/;

export function mapAssetToRelativePath(asset: Asset) {
  return asset.variants['1x'].match(FilenameRegex)?.[0];
}

const useApi = defineApi({
  getFiles: async (client, filter: AssetFilter) => {
    const { data } = await client.get<Manifest>('manifest.json');
    const filteredData: Asset[] = [];
    data?.files.forEach((asset) => {
      if (filter.name && !filter.name.test(asset.name)) return;
      filteredData.push(asset);
    });
    return filteredData;
  },
  getFileVariant: async (client, { fileName, variant }: GetFileUrlParams) => {
    const { data } = await client.get<Manifest>('manifest.json');
    const file = data.files.find(asset => (
      asset.name === fileName
    ) || (
      asset.name === fileName.slice(1)
    ));
    return file?.variants[variant] || file?.variants[(Object.keys(file?.variants) as Variant[])[0]];
  },
});

export default function useAssetsApi() {
  const client = useProjectSpecificApiClient({ basePath: '/assets' });
  return { ...useApi(client), mapAssetToVariantUrl };
}

export function useImageUrl() {
  const assetApi = useAssetsApi();

  const getAbsoluteIconUrl = useCallback(async (filePath: string) => {
    if (!filePath) return '';
    if (isValidUrl(filePath)) return filePath;
    let url = await assetApi.getFileVariant({ fileName: filePath, variant: '1x' });
    if (!url) return '';
    url = prependApiHost(url);
    return url;
  }, [assetApi]);

  return getAbsoluteIconUrl;
}
