import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import Map from '../../googlePlacesApi/Map';

export interface MapFieldProps extends FieldProps<any> {
  latName: string;
  lngName: string;
}

export default function MapField({
  control,
  latName,
  lngName,
  rules,
}: MapFieldProps) {
  const { t } = useTranslation();

  const { field: { onChange: onLatChange, value: latValue } } = useController({
    name: latName,
    control,
    rules,
  });

  const { field: { onChange: onLngChange, value: lngValue } } = useController({
    name: lngName,
    control,
    rules,
  });

  const handleChange = useCallback((lat: number, lng: number) => {
    onLatChange(lat);
    onLngChange(lng);
  }, [onLatChange, onLngChange]);

  return (
    <FieldContainer>
      <Map
        markers={(latValue && lngValue)
          ? [{
            lat: latValue,
            lng: lngValue,
            onDragend: handleChange,
          }]
          : undefined
        }
        zoomLevel={(latValue && lngValue) ? 19 : 2}
        center={(latValue && lngValue)
          ? {
            lat: parseFloat(latValue as string),
            lng: parseFloat(lngValue as string),
          }
          : {
            lat: 0,
            lng: 0,
          }
        }
        helperText={t('shop.mapHelperText')}
        onClick={({ latLng: { lat, lng } }) => { handleChange(lat(), lng()); }}
      />
    </FieldContainer>
  );
}
