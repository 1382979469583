export enum State {
  Final = 'final',
  Transferred = 'transferred',
  Success = 'successful',
  UserAborted = 'userAborted',
  SystemAborted = 'systemAborted',
  PreconditionsNotMet = 'preconditionsNotMet',
  PaymentFailed = 'paymentFailed',
}

interface SecurityDeviceModule {
  serialNumber: string;
  signatureAlgorithm: string;
  logTimeFormat: string;
  certificate: string;
  publicKey: string;
}

interface SecurityDeviceLog {
  timestamp: string;
  signatureCounter: number;
  signature: string;
  processType?: string;
  processData?: string;
  error?: string;
  errorDetail?: string;
}

interface FiscalReference {
  transactionNumber?: number; // TransactionNumber
  securityDeviceType: string; // SecurityDeviceType
  qrCodeContent?: string; // QRCodeContent
  securityDeviceModule?: SecurityDeviceModule; // SecurityDeviceModule
  logs?: SecurityDeviceLog[]; // Logs
  transactionLink?: string; // TransactionLink
}

interface Details {
  id: string; // ID
  loyaltyCardExpirationDate?: string; // LoyaltyCardExpirationDate
  loyaltyProgramID?: string; // LoyaltyProgramID
}

interface Airport {
  code: string; // Code
  name: string; // Name
  customID: string; // CustomID
}

interface Flight {
  current: boolean; // Current
  number: string; // Number
  date: string; // Date
  arrivalAirport: Airport; // ArrivalAirport
  departureAirport: Airport; // DepartureAirport
  airline: string; // Airline
  isArrival: boolean; // IsArrival
  relevantForCustomID: boolean; // RelevantForCustomID
}

interface BoardingPassData {
  boardingPassHash: string; // BoardingPassHash
  status: string; // Status
  finalDestination: Airport; // FinalDestination
  date: string; // Date
  interlining: boolean; // Interlining
  customID: string; // CustomID
  flights: Flight[]; // Flights
}

interface Customer {
  loyaltyCard?: string; // LoyaltyCard
  details?: Details; // Details
  boardingPassData?: BoardingPassData; // BoardingPassData
}

interface CheckoutDevice {
  type?: string; // Type
  externalID?: string; // ExternalID
  name?: string; // Name
}

interface Shop {
  externalID?: string; // ExternalID
  name?: string; // Name
  phone?: string; // Phone
  email?: string; // Email
  street?: string; // Street
  zip?: string; // Zip
  city?: string; // City
  state?: string; // State
  country?: string; // Country
  countryCode?: string; // CountryCode
}

export enum PaymentState {
  Transferred = 'transferred',
  Successful = 'successful',
  Pending = 'pending',
  Failed = 'failed',
  Unset = '',
}

export enum FailureCause {
  TerminalAbort = 'terminalAbort',
  TerminalErrored = 'terminalErrored',
  TerminalFailed = 'terminalFailed',
  AgeVerificationNotSupportedByCard = 'ageVerificationNotSupportedByCard',
  AgeVerificationFailed = 'ageVerificationFailed',
  UserAborted = 'userAborted',
}

interface FulfillmentError {
  type: string; // Type
  refersTo: string; // RefersTo
  message: string; // Message
}

export interface Fulfillment {
  id: string; // ID
  type: string; // Type
  state: string; // State
  refersTo?: string[]; // RefersTo
  link?: string; // Link
  errors?: FulfillmentError[]; // Errors
}

enum LineItemType {
  Default = 'default',
  Deposit = 'deposit',
  Discount = 'discount',
  ManualDiscount = 'manualDiscount',
  Coupon = 'coupon',
  DepositReturnVoucher = 'depositReturnVoucher',
}

interface PriceModifier {
  method: string; // Method
  name: string; // Name
  action: string; // Action
  price: number; // Price
  percentage?: number; // Percentage
  reasonCode?: string; // ReasonCode
  reasonMessage?: string; // ReasonMessage
}

enum PriceOrigin {
  Master = 'master',
  Promotions = 'promotions',
  Client = 'client',
  External = 'external',
}

export interface LineItem {
  id?: string; // ID
  refersTo?: string; // RefersTo
  sku: string; // SKU
  name?: string; // Name
  type: LineItemType; // Type
  amount: number; // Amount
  weight?: number; // Weight
  weightUnit?: string; // WeightUnit
  units?: number; // Units
  priceModifiers?: PriceModifier[]; // PriceModifiers
  listPrice: number; // ListPrice
  price: number; // Price
  referenceUnit?: string; // ReferenceUnit
  priceOrigin?: PriceOrigin; // PriceOrigin
  taxRate?: string; // TaxRate
  taxCategory?: string; // TaxCategory
  totalPrice?: number; // TotalPrice
  scannedCode?: string; // ScannedCode
  saleRestriction?: string; // SaleRestriction
  couponID?: string; // CouponID
  couponType?: string; // CouponType
  couponPercentage?: number; // CouponPercentage
  redeemed?: boolean; // Redeemed
  discountID?: string; // DiscountID
  discountRuleID?: string; // DiscountRuleID
  discountType?: string; // DiscountType
  discountValue?: number; // DiscountValue
  discountPercentage?: number; // DiscountPercentage
  discountReasonCode?: string; // DiscountReasonCode
  discountReasonMessage?: string; // DiscountReasonMessage
  additionalAttributes?: Record<string, string>; // AdditionalAttributes
}

interface CartPrice {
  subTotal?: number; // SubTotal
  price: number; // Price
  netPrice: number; // NetPrice
  tax?: Record<string, number>; // Tax
  taxPre?: Record<string, number>; // TaxPre
  taxNet?: Record<string, number>; // TaxNet
}

export interface Order {
  id: string; // ID
  project: string; // Project
  state: State; // State
  cashRegisterID: string; // CashRegisterID
  sequenceNumber: number; // SequenceNumber
  fiscalReference?: FiscalReference; // FiscalReference
  appUserID?: string; // AppUserID
  clientID: string; // ClientID
  session: string; // SessionID
  customer: Customer; // Customer
  date: string; // Date
  startedAt: string; // StartedAt
  createdAt: string; // CreatedAt
  finalizedAt: string; // FinalizedAt
  checkoutDeviceID?: string; // CheckoutDeviceID
  checkoutDevice?: CheckoutDevice; // CheckoutDevice
  shopID: string; // ShopID
  shop: Shop; // Shop
  paidAmount?: number; // PaidAmount
  paymentMethod: string; // PaymentMethod
  paymentState: PaymentState; // PaymentState
  paymentInformation?: Record<string, any>; // PaymentInformation
  paymentResult?: Record<string, any>; // PaymentResult
  paymentCardType?: string; // PaymentCardType
  fulfillments?: Fulfillment[]; // Fulfillments
  lineItems: LineItem[]; // LineItems
  currency?: string; // Currency
  price: CartPrice; // Price
  receiptNumber?: number; // ReceiptNumber
  receiptLink: string; // ReceiptLink
  externalCheckoutID: string; // ExternalCheckoutID
  externalCheckoutReference?: string; // ExternalCheckoutReference
  checkoutProcessID?: string; // CheckoutProcessID
  checkoutProcess: Record<string, any>; // CheckoutProcess
  cashier?: {
    subject: string; // Subject
  }; // Cashier
  links: {
    self: { href: string };
    receipt: { href: string };
    [key: string]: { href: string };
  }
}

export interface OrdersResponse {
  resources: Order[];
  pagination: {
    page: number;
    perPage: number;
    totalPages: number;
    totalItems: number;
  };
  links: Record<string, any>;
}
