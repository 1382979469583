import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useProjectConfigApi from './useProjectConfigApi';
import ResourceFormView from '../scaffold/ResourceFormView';
import EditShopServiceDialogForm from './EditShopServiceDialogForm';
import { ShopService } from './shopServices';

export interface EditShopServiceDialogViewProps {
  service: ShopService | null
  newService: boolean;
  onClose: () => void;
}
export default function EditShopServiceDialogView({
  service,
  newService,
  onClose,
}: EditShopServiceDialogViewProps) {
  const { t } = useTranslation();
  const api = useProjectConfigApi();

  const handleSubmit = useCallback(async (updatedService) => {
    if (newService) {
      await api.createShopService(updatedService as ShopService);
    } else {
      await api.updateShopService(updatedService as ShopService);
    }
    onClose();
  }, [api, newService, onClose]);

  if (!service && !newService) return null;

  return (
    <>
      <Dialog open maxWidth="lg" fullWidth>
        <DialogContent
          dividers
          sx={{
            overflow: 'hidden',
            paddingBottom: '64px',
          }}
        >
          <ResourceFormView
            actionName="editShopService"
            headline={newService ? t('shopServices.headline.createShopService') : t('shopServices.headline.editShopService')}
            Form={EditShopServiceDialogForm}
            FormProps={{
              onCancel: onClose,
            }}
            fetch={() => (service || {})}
            submit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
