import React from 'react';
import TextField from '@mui/material/TextField';

function FilterTextInput({
  label, onChange, value,
}) {
  return (
    <TextField
      onChange={onChange}
      label={label}
      value={value}
    />
  );
}

export default FilterTextInput;
