import React, { BaseSyntheticEvent, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
// @ts-ignore
import Translate from '../components/i18n/Translate';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
// @ts-ignore
import FilterTextInput from '../components/FilterTextInput';
// @ts-ignore
import Pagination from '../components/Pagination';
import useChartFilter from '../charts/useChartFilter';
import useAppUserApi from './appUserApi';
import AppUsersTable from './AppUsersTable';
import { ApplicationState } from '../reducers/initialState';
import { downloadFile } from '../utils/downloadFile';

export default function AppUsersView() {
  const api = useAppUserApi();
  const writePermission = useSelector<ApplicationState, boolean>(state => (
    !!(state.navigation as any)?.appUserAdministration?.write
  )) || true; // HACK

  const { filter, updateFilter } = useChartFilter({});

  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const [pageCount, setPageCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fetchPage, setFetchPage] = useState(false);
  const [itemCount, setItemCount] = useState<number | undefined>();

  const handleFetch = useCallback(async () => {
    if (!filter.range) return null;

    const { appUsers, totalPages, totalItems } = await api.getAppUsers({
      firstNameFilter: filter.genericFilter?.firstName,
      lastNameFilter: filter.genericFilter?.lastName,
      phoneNumberFilter: filter.genericFilter?.phoneNumber,
      emailFilter: filter.genericFilter?.email,
      appUserIdFilter: filter.genericFilter?.appUserId,
      currentPage: fetchPage ? currentPage : 1,
    }) || {};

    setCurrentPage(fetchPage ? currentPage : 1);
    setFetchPage(false);
    setPageCount(totalPages);
    setItemCount(totalItems);

    return appUsers;
  }, [api, currentPage, fetchPage, filter]);

  const handleChangePage = useCallback((_: BaseSyntheticEvent, page: number) => {
    setFetchPage(true);
    setCurrentPage(page);
  }, []);

  const handleUpdateFilter = useCallback((genericFilterKey: string, newFilter: any) => {
    updateFilter({
      genericFilter: {
        ...filter.genericFilter,
        [genericFilterKey]: newFilter,
      },
    });
  }, [filter.genericFilter, updateFilter]);

  const handleExportUserList = useCallback(async () => {
    const data = await api.getAppUserCSV(null);
    downloadFile(data, 'appUsers.csv', 'text/csv');
  }, [api]);

  return (
    <React.Fragment>
      <DocumentTitle translationID="appUser.headline" />
      <FilterWrapper
        headline={
          <>
            <Translate id="appUser.list.headline" />
            {itemCount && ` (${itemCount})`}
          </>
        }
        handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
        topBarChildren={(
          <Button
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            onClick={handleExportUserList}
          >
            <Translate id="appUser.exportUserList" />
          </Button>
        )}
      >
        <FilterTextInput
          label={<Translate id="appUser.filter.firstName" />}
          value={filter.genericFilter?.firstName || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateFilter('firstName', event.target.value);
          }}
        />
        <FilterTextInput
          label={<Translate id="appUser.filter.lastName" />}
          value={filter.genericFilter?.lastName || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateFilter('lastName', event.target.value);
          }}
        />
        <FilterTextInput
          label={<Translate id="appUser.filter.phoneNumber" />}
          value={filter.genericFilter?.phoneNumber || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateFilter('phoneNumber', event.target.value);
          }}
        />
        <FilterTextInput
          label={<Translate id="appUser.filter.email" />}
          value={filter.genericFilter?.email || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateFilter('email', event.target.value);
          }}
        />
        <FilterTextInput
          label={<Translate id="appUser.filter.appUserId" />}
          value={filter.genericFilter?.appUserId || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateFilter('appUserId', event.target.value);
          }}
        />
      </FilterWrapper>
      <ResourceChartView
        Chart={AppUsersTable}
        ChartProps={{
          writePermission,
        }}
        fetch={handleFetch}
        ref={resourceChartViewRef}
      />
      {!!pageCount && (
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
        />
      )}
    </React.Fragment>
  );
}
