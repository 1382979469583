function increaseNumberToMax(number: number, max: number): number {
  return Math.min(number + 1, max);
}

function increaseNumberWithReset(number: number, max: number): number {
  return (number + 1) % (max + 1);
}

// eslint-disable-next-line import/prefer-default-export, max-len
export function nextEnumValue<T extends Object>(enumObject: T, value: T[keyof T], resetCounter = false): T[keyof T] {
  const values = Object.values(enumObject) as (T[keyof T])[];
  const index = values.indexOf(value);
  const newIndex = resetCounter
    ? increaseNumberWithReset(index, values.length - 1)
    : increaseNumberToMax(index, values.length - 1);
  return values[newIndex];
}
