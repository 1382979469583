import axios from 'axios';
import isString from 'lodash/isString';

export enum Channel {
  STABLE = 'stable',
  BETA = 'beta',
  LATEST = 'latest',
}

export type VersionNumber = string;

export interface RolloutInformation {
  'available': Record<Channel, VersionNumber[]>,
  'current': Record<Channel, VersionNumber>,
}

const ROLLOUT_INFORMATION_SOURCE = 'https://docs.snabble.io/pos_releases/sco_rollout/rollout.json';

export default async function getRolloutInformation() {
  const { data } = await axios.get<RolloutInformation>(ROLLOUT_INFORMATION_SOURCE);
  if (isString(data)) return null;
  return data;
}
