import React from 'react';
import { styled } from '@mui/system';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { JSONCodeBlock } from '../code';

interface AttributeValueProps {
  value: any;
}

const AttributeValueWrapper = styled('span')({
  display: 'block',
  overflowX: 'auto',
});

function renderValue(value: any) {
  if (isObject(value)) {
    return <JSONCodeBlock data={value} />;
  }

  if (isNull(value) || isUndefined(value) || isBoolean(value)) {
    return String(value);
  }

  return value;
}

export default function AttributeValue({ value }: AttributeValueProps) {
  return (
    <AttributeValueWrapper>
      {renderValue(value)}
    </AttributeValueWrapper>
  );
}
