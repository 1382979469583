import React from 'react';
import moment from 'moment';
import isNull from 'lodash/isNull';
import uniq from 'lodash/uniq';
import { Translation } from 'react-i18next';
import PaperTable from '../components/PaperTable';
import FormWrapper from '../components/FormWrapper';
import FormTableRowText from '../components/FormTableRowText';
import FormTableRowSelect from '../components/FormTableRowSelect';
import FormTableRowCheckbox from '../components/FormTableRowCheckbox';
import FormTableRowTimePicker from '../components/FormTableRowTimePicker';
import FormTableRowMultiSelect from '../components/FormTableRowMultiSelect';
import { splitProject } from '../project';


const toChipSelect = (translate, translationPrefix, values) => (values || []).map((value) => {
  const [format, project] = splitProject(value);
  const translation = translate(`${translationPrefix}.${format}`);
  const name = project ? `${translation} (${project})` : `${translation}`;
  return {
    id: value,
    name,
  };
});

const parseDuration = (duration) => {
  if (!duration) return '';
  const hours = duration.match(/(\d+)\s*h/);
  const minutes = duration.match(/(\d+)\s*m/);
  const seconds = duration.match(/(\d+)\s*s/);
  const parsedDuration = moment.duration({
    hours: hours ? hours[1] : 0,
    minutes: minutes ? minutes[1] : 0,
    seconds: seconds ? seconds[1] : 0,
  });
  return parsedDuration.asMinutes();
};

const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const FORMATS = [
  'aggregated_receipts',
  'dfka_taxonomie_2023_03',
  'dsfinvk_2023_03',
  'receipt_archive',
];

const BATCH_FORMATS = [
  'aggregated_receipts',
  'receipt_archive',
  'dfka_taxonomie_2023_03',
];

class ClosingScheduleEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialLoad: !props.id,
      isNew: !props.id,
      shop: '',
      time: '',
      location: '',
      days: [],
      mode: '',
      formats: [],
      batchFormats: [],
      hour: null,
      minute: null,
      batchInterval: '',
      includeAllStates: true,
      checkExternalCheckoutIDCompleteness: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.fetchClosingSchedule(this.props.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { initialLoad } = this.state;
    const { isLoading, id } = this.props;
    if (id !== prevProps.id) {
      this.handleUrlUpdate();
    }
    if (initialLoad) return;
    if (!initialLoad && !isLoading) {
      this.setSchedule();
    }
  }

  onSubmit() {
    this.setState({
      modeError: !this.state.mode || this.state.mode === '',
      shopError: !this.state.shop || this.state.shop === '',
      daysError: !this.state.days.length,
      timeError: !this.state.time,
      locationError: !this.state.location || this.state.location === '',
    }, () => {
      if (!this.state.modeError && !this.state.shopError && !this.state.daysError) {
        const {
          shop,
          hour,
          minute,
          mode,
          days,
          formats,
          batchFormats,
          isNew,
          location,
          batchInterval,
          includeAllStates,
          checkExternalCheckoutIDCompleteness,
        } = this.state;

        const {
          projectId,
        } = this.props;

        const update = {
          project: projectId,
          shop,
          mode,
          days,
          formats,
          location,
          batchFormats,
          appendBatchEvery: batchInterval !== '' ? `${batchInterval}m` : null,
          hour,
          minute,
          includeAllStates,
          checkExternalCheckoutIDCompleteness,
        };

        if (isNew) {
          this.props.createClosingSchedule(update);
        } else {
          const { schedule } = this.props;
          if (schedule && schedule.links && schedule.links.self) {
            this.props.updateClosingSchedule(this.props.schedule.links.self.href, update);
          }
        }
      } else {
        this.props.updateStatus('errors');
      }
    });
  }

  setSchedule() {
    const { schedule } = this.props;
    if (!schedule) {
      this.setState({ initialLoad: true });
      return;
    }

    let time = '';
    if (!isNull(schedule.hour) && !isNull(schedule.minute)) {
      const t = moment();
      t.set('hour', schedule.hour);
      t.set('minute', schedule.minute);
      time = t.format('HH:mm');
    }

    this.setState({
      initialLoad: true,
      shop: schedule.shop,
      days: schedule.days,
      mode: schedule.mode,
      formats: schedule.formats,
      batchFormats: schedule.batchFormats,
      location: schedule.location,
      time,
      hour: schedule.hour,
      minute: schedule.minute,
      batchInterval: parseDuration(schedule.appendBatchEvery),
      includeAllStates: schedule.includeAllStates,
      checkExternalCheckoutIDCompleteness: schedule.checkExternalCheckoutIDCompleteness,
    });
  }

  handleUrlUpdate() {
    this.setState({ isNew: !this.props.id });
  }

  handleTimeChange(value) {
    const time = moment(value, 'HH:mm');

    this.setState({
      time: value,
      hour: time.hours(),
      minute: time.minutes(),
    });
  }

  handleChange({ name, value }) {
    this.setState({ [`${name}`]: value });
  }

  render() {
    const {
      schedule,
      isLoading,
      projectId,
      shops,
      error,
      editStatus,
      updateStatus,
      deleteClosingSchedule,
      navigate,
    } = this.props;
    const { isNew, initialLoad } = this.state;

    const availableFormats = uniq(FORMATS.concat(this.state.formats ?? []));
    const availableBatchFormats = uniq(BATCH_FORMATS.concat(this.state.batchFormats ?? []));

    const listUrl = `/${projectId}/closings/schedules`;
    return (
      <Translation>
        {translate => (
          <FormWrapper
            isNew={isNew}
            newUrl={listUrl}
            backUrl={listUrl}
            notFound={!isNew && !schedule && !isLoading}
            notFoundText={translate('scheduledClosings.notFound')}
            isLoading={isLoading}
            title="scheduledClosings.headlineSingle"
            onSubmit={this.onSubmit}
            resetEditStatus={() => updateStatus('')}
            editStatus={editStatus}
            itemName={translate('scheduledClosings.itemName')}
            serverError={error || ''}
            isDeleteable={!isNew}
            onDelete={() => deleteClosingSchedule(schedule.links.self.href)}
            navigate={navigate}
          >
            {initialLoad &&
              <PaperTable headline={translate('scheduledClosings.tableHeadline')}>
                <FormTableRowSelect
                  text={translate('scheduledClosings.shop')}
                  propertyName="shop"
                  handleChange={({ target }) => this.handleChange(target)}
                  required
                  property={this.state.shop}
                  hasError={this.state.shopError}
                >
                  {(shops || []).map(shop => (
                    <option key={shop.id} value={shop.id}>{shop.label}</option>
                  ))}
                </FormTableRowSelect>

                <FormTableRowMultiSelect
                  label={translate('scheduledClosings.days')}
                  required
                  values={this.state.days}
                  options={toChipSelect(translate, 'days', WEEKDAYS)}
                  onChange={(event) => {
                    this.handleChange({
                      name: 'days',
                      value: event.target.value,
                    });
                  }}
                  hasError={this.state.daysError}
                />

                <FormTableRowTimePicker
                  text={translate('scheduledClosings.time')}
                  required
                  propertyName="time"
                  errorText=""
                  value={this.state.time}
                  handleChange={this.handleTimeChange}
                  hasError={this.state.timeError}
                />

                <FormTableRowSelect
                  text={translate('scheduledClosings.location')}
                  propertyName="location"
                  handleChange={({ target }) => this.handleChange(target)}
                  required
                  property={this.state.location}
                  hasError={this.state.locationError}
                >
                  {(['Europe/Berlin', 'Europe/Athens', 'Europe/London'] || []).map(value => (<option value={value} key={value}>{value}</option>))}
                </FormTableRowSelect>

                <FormTableRowSelect
                  text={translate('scheduledClosings.mode')}
                  propertyName="mode"
                  handleChange={({ target }) => this.handleChange(target)}
                  required
                  property={this.state.mode}
                  hasError={this.state.modeError}
                >
                  {(['includeUntilExecution', 'includeUntilScheduled'] || []).map(value => (<option value={value} key={value}>{translate(`scheduledClosings.${value}`)}</option>))}
                </FormTableRowSelect>

                <FormTableRowCheckbox
                  text={translate('scheduledClosings.includeAllStates')}
                  checked={this.state.includeAllStates}
                  handleChange={({ target }) => this.handleChange({
                    name: 'includeAllStates', value: target.checked,
                  })}
                  colSpan={2}
                />

                <FormTableRowMultiSelect
                  label={translate('scheduledClosings.format')}
                  values={this.state.formats}
                  options={toChipSelect(translate, 'closingFormats', availableFormats)}
                  onChange={(event) => {
                    this.handleChange({
                      name: 'formats',
                      value: event.target.value,
                    });
                  }}
                />

                <FormTableRowMultiSelect
                  label={translate('scheduledClosings.batchFormat')}
                  values={this.state.batchFormats}
                  options={toChipSelect(translate, 'closingFormats', availableBatchFormats)}
                  onChange={(event) => {
                    this.handleChange({
                      name: 'batchFormats',
                      value: event.target.value,
                    });
                  }}
                />

                <FormTableRowText
                  text={translate('scheduledClosings.batchIntervalForm')}
                  propertyName="batchInterval"
                  property={this.state.batchInterval}
                  handleChange={({ target }) => this.handleChange(target)}
                  type="number"
                />

                <FormTableRowCheckbox
                  text={translate('scheduledClosings.checkExternalCheckoutIDCompleteness')}
                  checked={this.state.checkExternalCheckoutIDCompleteness}
                  handleChange={({ target }) => this.handleChange({
                    name: 'checkExternalCheckoutIDCompleteness', value: target.checked,
                  })}
                  colSpan={2}
                />


              </PaperTable>
            }

          </FormWrapper>
        )}
      </Translation>
    );
  }
}

export default ClosingScheduleEdit;
