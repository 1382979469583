import { styled } from '@mui/system';
import React from 'react';
import ContentSkeleton from './ContentSkeleton';

const Wrapper = styled('div')(() => ({
  minHeight: '100%',
  position: 'relative',
}));

type AppProps = {
  children: React.ReactNode;
};

export default function App({
  children,
}: AppProps) {
  return (
    <Wrapper>
      <ContentSkeleton>{children}</ContentSkeleton>
    </Wrapper>
  );
}
