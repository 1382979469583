import React, { useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { SortableTableDataPoint } from '../SortableTable';
import useResourceTranslator from '../resource/useResourceTranslator';
// @ts-ignore
import ColoredTableRow from '../components/ColoredTableRow';
import SearchableSelect from '../components/SearchableSelect';
import { User } from './useUserManagementApi';

const sorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.ACRONYM, key: 'sub' },
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

export interface RealmAdminDialogTableProps {
  realmName?: string;
  values: SortableTableDataPoint[];
  users: User[];
  onPromoteUser: (userID: string | undefined) => void,
  onDemoteUser: (userID: string) => void,
}

export default function RealmAdminDialogTable({
  realmName,
  values,
  users,
  onPromoteUser,
  onDemoteUser,
}: RealmAdminDialogTableProps) {
  const t = useResourceTranslator();
  const [userToAdd, setUserToAdd] = useState<User | null>(null);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <ColoredTableRow>
      <TableCell align="left">
        {rowData.name || '-/-'}
      </TableCell>
      <TableCell align="left">
        {rowData.sub || '-/-'}
      </TableCell>
      <TableCell align="left">
        {rowData.email || '-/-'}
      </TableCell>
      <TableCell align="left">
        {t(`userManagement.userList.origins.${rowData.origin}`) || '-/-'}
      </TableCell>
      <TableCell align="center">
        <Button
          size="small"
          onClick={() => onDemoteUser(rowData.id)}
          variant="text"
          color="error"
          startIcon={<DeleteIcon />}
        >
          {t('button.remove')}
        </Button>
      </TableCell>
    </ColoredTableRow>
  ), [onDemoteUser, t]);

  const table = (
    <>
      <ColoredTableRow>
        <TableCell align="left">
          {userToAdd?.name || '-/-'}
        </TableCell>
        <TableCell>
          <SearchableSelect
            options={users}
            sorterOptions={sorterOptions}
            onChange={(_, user) => setUserToAdd(user as User)}
            onInputChange={(newInput) => {
              const user = users.find(u => u.sub === newInput);
              if (!user) return;
              setUserToAdd(user);
            }}
            labelFrom="sub"
            keyFrom="id"
            value={userToAdd}
            textFieldProps={{
              variant: 'standard',
              label: t('userManagement.realmAdmins.newUserSub'),
            }}
          />
        </TableCell>
        <TableCell align="left">
          {userToAdd?.email || '-/-'}
        </TableCell>
        <TableCell align="left">
          {userToAdd?.origin ? (
            t(`userManagement.userList.origins.${userToAdd?.origin}`)
          ) : (
            '-/-'
          )}
        </TableCell>
        <TableCell align="center">
          <Button
            size="small"
            disabled={!userToAdd}
            onClick={() => onPromoteUser(userToAdd?.id)}
            variant={!userToAdd ? 'text' : 'contained'}
            startIcon={<PersonAddIcon />}
          >
            {t('button.add')}
          </Button>
        </TableCell>
      </ColoredTableRow>
      {(values || []).map(userEntry => (
        <TableRowEntries rowData={userEntry} key={userEntry.id} />
      ))}
    </>
  );

  return (
    <>
      <Typography variant="h5" component="h1" style={{ marginTop: '8px', marginBottom: '32px' }}>
        {t('userManagement.userList.manageRealmAdmins', { realm: realmName })}
      </Typography>
      <TableContainer sx={{ height: 'calc(50vh - 135px)', paddingInline: '2px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('userManagement.userList.name')}</TableCell>
              <TableCell>{t('userManagement.userList.sub')}</TableCell>
              <TableCell>{t('userManagement.userList.email')}</TableCell>
              <TableCell>{t('userManagement.userList.origin')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {table}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
