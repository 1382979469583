import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { FieldValues } from 'react-hook-form';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import useUserManagementApi, { Origin, Realm, RealmRole, RealmValidationRules } from './useUserManagementApi';
import CreateUserForm from './CreateUserForm';
import { ApplicationState } from '../reducers/initialState';
import LoadableRegion from '../loader/LoadableRegion';
import useValidationPolicyParser from './useValidationPolicyParser';

export default function CreateUserView() {
  const api = useUserManagementApi();
  const location = useLocation();
  const navigate = useProjectNavigate();
  const { projectId } = useParams();
  const projects = useSelector<ApplicationState, Record<string, any>[]>(state => state.projects);
  const token = useSelector<ApplicationState, any>(state => state.token?.grants);
  const isSnabbleUser = token?.sub?.endsWith('@snabble.io');
  const { mapValidationPolicyToRules, mapPasswordRules } = useValidationPolicyParser();

  const [defaultRealm, setDefaultRealm] = useState<Realm>();
  const [availableRealms, setAvailableRealms] = useState<Realm[]>();
  const [isRealmAdmin, setIsRealmAdmin] = useState(false);
  const [validationRules, setValidationRules] = useState<RealmValidationRules | undefined>();

  const updateValidationRules = useCallback(async (realm: string) => {
    if (!realm) return;
    const selectedRealm = availableRealms?.find(r => (r.id === realm));
    setValidationRules({
      subValidationRules: mapValidationPolicyToRules(selectedRealm?.subValidationPolicy),
      passwordValidationRules: mapValidationPolicyToRules(selectedRealm?.passwordValidationPolicy),
      allowedRoles: selectedRealm?.allowedRoles.filter(role => (
        role !== RealmRole.REALM_ADMIN
      )) || [],
      allowedProjects: selectedRealm?.allowedProjects ? (
        projects.filter(p => (selectedRealm.allowedProjects.includes(p.id)))
      ) : (
        projects
      ),
      passwordChoiceRule: mapPasswordRules(selectedRealm?.passwordChoicePolicy),
    });
  }, [availableRealms, mapPasswordRules, mapValidationPolicyToRules, projects]);

  useEffect(() => {
    const getMyRealms = async () => {
      const realms = await api.getRealms(null);
      setDefaultRealm(realms.defaultRealm);
      setAvailableRealms(realms?.realms?.map(r => ({ ...r, label: r.name, value: r.id })));
      setIsRealmAdmin(!!realms?.isRealmAdmin);
    };
    getMyRealms();
  }, [api, location.state]);

  const buildNewUser = useCallback(() => ({
    origin: Origin.ACCOUNT,
    realm: location?.state?.currentRealm || defaultRealm?.id,
    roles: [{ project: projectId, role: undefined }],
  } as any), [defaultRealm, location?.state, projectId]);

  const handleCreateUser = useCallback(async (user: FieldValues) => {
    await api.createUser({ user, filter: { realm: user.realm } });
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="userManagement.headline" />
      <LoadableRegion loading={!defaultRealm || !availableRealms}>
        <ResourceFormView
          actionName="createUser"
          Form={CreateUserForm}
          FormProps={{
            currentProject: projectId,
            availableRealms,
            isRealmAdmin,
            isSnabbleUser,
            realmValidationRules: validationRules,
            updateValidationRules,
          }}
          fetch={buildNewUser}
          submit={handleCreateUser}
          onSubmitted={() => {
            navigate('/user-management/');
          }}
        />
      </LoadableRegion>
    </>
  );
}
