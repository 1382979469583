import actions from '../actions/constants';

const checkRestrictedProject = (projects, projectId, fetchingProjects) => {
  if (!projectId) return false;
  return !fetchingProjects && projects && projects.filter(p => p.id === projectId).length === 0;
};

const findCurrency = (projects, projectId) => {
  if (!projectId || !projects || !projects.length) return null;
  const currentProject = projects.find(p => p.id === projectId);
  return currentProject ? currentProject.currency : null;
};

const findCodeTemplates = (projects, projectId) => {
  if (!projectId || !projects || !projects.length) return null;
  const currentProject = projects.find(p => p.id === projectId);
  return currentProject ? currentProject.codeTemplates : {};
};

const mapProjects = (projects) => {
  if (!projects) return [];
  return projects.map(project => Object.assign({}, project, { title: project.name }));
};

const subNavigationOrders = (navigation) => {
  const subNavigation = [];
  if (navigation.orders) {
    subNavigation.push(Object.assign({}, navigation.orders, { text: 'orders.navigation' }));
    subNavigation.push(Object.assign({}, navigation.orders, { href: `${navigation.orders.href}/export`, text: 'orders.exportHeadline' }));
    subNavigation.push(Object.assign({}, navigation.orders, { href: `${navigation.orders.href}/line-items/export`, text: 'orders.lineItemExport' }));
  }
  if (navigation.closings) {
    subNavigation.push(Object.assign({}, navigation.closings, { text: 'closings.navigation' }));
  }
  if (navigation.closingsSchedules) {
    subNavigation.push(Object.assign({}, navigation.closingsSchedules, { text: 'scheduledClosings.navigation' }));
  }
  return subNavigation;
};

export default (state, action) => {
  switch (action.type) {
    case actions.SELECTED_PROJECT:
      return {
        selectedProjectId: action.payload,
        restrictedProject: checkRestrictedProject(
          state.projects,
          action.payload,
          state.fetchingProjects,
        ),
        projectCurrency: findCurrency(state.projects, action.payload),
        codeTemplates: findCodeTemplates(state.projects, action.payload),
      };

    case actions.UNSET_PROJECT:
      return { selectedProjectId: null, restrictedProject: false };

    case actions.REQUEST_PROJECTS:
      return { fetchingProjects: true };

    case actions.RECEIVED_PROJECTS:
      return {
        projects: mapProjects(action.payload),
        fetchingProjects: false,
        restrictedProject: checkRestrictedProject(action.payload, state.selectedProjectId, false),
        projectCurrency: findCurrency(action.payload, state.selectedProjectId),
        codeTemplates: findCodeTemplates(action.payload, state.selectedProjectId),
      };

    case actions.RESTRICTED_PROJECT:
      return { restrictedProject: true };

    case actions.REQUEST_PORTAL_METADATA:
      return {
        navigation: {},
        fetchingNavigation: true,
      };

    case actions.RECEIVED_PORTAL_METADATA:
      return {
        fetchingNavigation: false,
        navigation: action.payload ?
          Object.assign({}, action.payload, {
            orderSubNavigation: subNavigationOrders(action.payload),
          }) : {},
      };

    default:
      return {};
  }
};
