import React from 'react';
import debounce from 'lodash/debounce';
import Translate from './i18n/Translate';
import { rangeFromQuery, shopFromQuery, toRangeQuery, toSearchQuery, toShopQuery } from '../urls';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import FilterWrapper from './FilterWrapper';
import QuickRangeFilter from './QuickRangeFilter';
import StatisticBar from './StatisticBar';
import StatisticStackedArea from './StatisticStackedArea';
import StatisticPaper from './StatisticPaper';
import FromToSelector from './FromToSelector';
import DocumentTitle from './DocumentTitle';
import { validateStatsTimeRange } from '../ranges';
import FilterError from './FilterError';
import StatisticToolbar from './StatisticToolbar';
import StatisticDownloadButton from './StatisticDownloadButton';
import visitorStatisticQueryString from '../utils/api/visitorStatisticQueryString';
import NoResult from './NoResult';
import StatisticError from './StatisticError';
import SearchableShopSelect from './SearchableShopSelect';

class StatisticRecurringVisitorsView extends React.Component {
  constructor(props) {
    super(props);

    const { location, defaultRange, defaultShop } = props;
    const range = rangeFromQuery(location.search, defaultRange);

    this.state = {
      range,
      shopID: shopFromQuery(location.search) || defaultShop,
      ...validateStatsTimeRange(range),
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeShop = this.handleChangeShop.bind(this);
    this.handleFetch = this.handleFetch.bind(this);

    this.fetchRecurringVisitorsStatisticDebounced = debounce(
      this.props.fetchRecurringVisitorsStatistic,
      500,
    );
  }

  componentDidMount() {
    const { hasError, range, shopID } = this.state;

    if (hasError) {
      return;
    }

    this.props.fetchRecurringVisitorsStatistic(range.from, range.to, shopID);
  }

  componentWillUnmount() {
    this.props.resetRecurringVisitorsStatistic();
  }

  handleChangeRange(range) {
    this.setState({
      range,
      ...validateStatsTimeRange(range),
    }, () => { this.handleFetch(); });
  }

  handleChangeShop(shop) {
    const shopID = shop?.id || '';
    this.setState({ shopID }, () => { this.handleFetch(); });
  }

  handleFetch() {
    const { hasError, range, shopID } = this.state;

    if (hasError) {
      return;
    }

    this.fetchRecurringVisitorsStatisticDebounced(range.from, range.to, shopID);

    this.props.navigate(toSearchQuery({
      ...toRangeQuery(range),
      ...toShopQuery(shopID),
    }));
  }

  render() {
    const { recurringVisitorsStatistic, showLoadingIndicator } = this.props;

    const legendItems = [
      { title: <Translate id="recurringVisitorsStatistic.legendRecurring" />, color: 'rgb(52, 124, 255, 1)', strokeWidth: 5 },
      { title: <Translate id="recurringVisitorsStatistic.legendVisitors" />, color: '#ffc400', strokeWidth: 5 },
    ];

    let resultContainer;
    if (!recurringVisitorsStatistic) {
      if (showLoadingIndicator) {
        resultContainer = null;
      } else {
        resultContainer = <StatisticError />;
      }
    } else if (recurringVisitorsStatistic.total > 0) {
      resultContainer = (
        <React.Fragment>
          <StatisticPaper
            headline={
              <Translate id="recurringVisitorsStatistic.absoluteDiagramHeadline" />
            }
            from={recurringVisitorsStatistic.date.from}
            to={recurringVisitorsStatistic.date.to}
            total={recurringVisitorsStatistic.total}
            legendItems={legendItems}
            isLoading={showLoadingIndicator}
          >
            <StatisticStackedArea
              withTotal
              values={recurringVisitorsStatistic.recurringVisitors}
              maxValue={recurringVisitorsStatistic.maxValue}
              compareValues={recurringVisitorsStatistic.newVisitors}
              xLabelAsDate
              translationLabelYKey="recurringVisitorsStatistic.yAxis"
              translationHintKey="recurringVisitorsStatistic.hint"
              translationComparedKey="recurringVisitorsStatistic.hintCompared"
            />
          </StatisticPaper>

          <StatisticPaper
            headline={
              <Translate id="recurringVisitorsStatistic.relativeDiagramHeadline" />
            }
            from={recurringVisitorsStatistic.date.from}
            to={recurringVisitorsStatistic.date.to}
            isLoading={showLoadingIndicator}
          >
            <StatisticBar
              stackY
              withRegression
              values={recurringVisitorsStatistic.recurringVisitorsPortion}
              maxValue={100}
              xLabelAsDate
              translationLabelYKey="recurringVisitorsStatistic.yAxisRelative"
            />
          </StatisticPaper>

          <StatisticToolbar>
            <StatisticDownloadButton
              type="visitors"
              params={this.state}
              queryStringBuilder={visitorStatisticQueryString}
              disabled={this.state.disabled}
            />
          </StatisticToolbar>
        </React.Fragment>
      );
    } else {
      resultContainer = (
        <NoResult text={<Translate id="statistic.empty" />} />
      );
    }

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={showLoadingIndicator} />
        <DocumentTitle translationID="recurringVisitorsStatistic.headline" />
        <FilterWrapper
          headline={<Translate id="recurringVisitorsStatistic.headline" />}
          handleRefresh={this.handleFetch}
          error={
            <FilterError id={this.state.error} data={this.state.errorData} />
          }
        >
          <FromToSelector
            range={this.state.range}
            hasError={this.state.hasError}
            onChange={this.handleChangeRange}
            emitFilled
          />
          <QuickRangeFilter
            range={this.state.range}
            onChange={this.handleChangeRange}
          />
          <SearchableShopSelect
            onChange={this.handleChangeShop}
            defaultShopId={this.state.shopID}
            showLabel
          />
        </FilterWrapper>

        {resultContainer}
      </React.Fragment>
    );
  }
}

export default StatisticRecurringVisitorsView;
