import { ReactNode, useMemo } from 'react';
import { useResourceTranslator } from '../../resource';

export function removeArrayIndexFromPath(path: string) {
  const regex = /(?<=\w)\[\d+\](?=(\.|$))/gm;
  return path.replace(regex, '');
}

export interface UseFormLabelOptions {
  removeIndex?: boolean;
  optionalHint?: boolean;
}

// A hook that provides a translation to be used by the label of the specified
// `field`. When `labelProp` is present its value is returned as label text.
export default function useFormLabel(
  field: string,
  labelProp?: ReactNode,
  options?: UseFormLabelOptions,
) {
  const t = useResourceTranslator();

  const translationId = options?.removeIndex ? removeArrayIndexFromPath(field) : field;
  const translatedText = t(`form.labels.${translationId}`);

  return useMemo(
    () => labelProp || `${translatedText}${options?.optionalHint ? t('form.labels.optionalHint') : ''}`,
    [labelProp, options?.optionalHint, t, translatedText],
  );
}
