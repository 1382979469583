import auth from './auth';
import orders from './orders';
import pricing from './pricing';
import products from './products';
import projects from './projects';
import shops from './shops';
import basketAverage from './basketAverage';
import recurringVisitors from './recurringVisitors';
import closings from '../closings/reducers/closings';
import checkoutDevices from '../sco/reducers/checkoutDevices';
import approvals from './approvals';
import initialState from './initialState';
import filters from '../charts/reducers/filter';

const portal = (state = initialState, action) => Object.assign(
  {},
  state,
  auth(state, action),
  orders(state, action),
  pricing(state, action),
  products(state, action),
  projects(state, action),
  shops(state, action),
  basketAverage(state, action),
  recurringVisitors(state, action),
  approvals(state, action),
  { closings: closings(state, action) },
  { checkoutDevices: checkoutDevices(state, action) },
  filters(state, action),
);

export default portal;
