import React, { Component } from 'react';
import Translate from './i18n/Translate';
import ProductAdditionalAttributesPanel from './ProductAdditionalAttributesPanel';
import ProductDetailsGeneral from './ProductDetailsGeneral';
import ProductDetailsPrices from './ProductDetailsPrices';
import ProductDetailsCodes from './ProductDetailsCodes';
import ProductDetailsControl from './ProductDetailsControl';
import ProductDetailsLinked from './ProductDetailsLinked';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import Headline from './Headline';
import NoResult from './NoResult';
import DocumentTitle from './DocumentTitle';

class ProductView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: props.id,
    };
  }

  componentDidMount() {
    if (!this.state.sku) {
      return;
    }
    this.props.fetchProduct(this.state.sku);
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    const currentSKU = this.state.sku;
    const newSKU = this.props.id;

    if (currentSKU !== newSKU) {
      this.handleProductChange(newSKU);
    }
  }

  componentWillUnmount() {
    this.props.resetProduct();
  }

  handleProductChange(sku) {
    this.setState({ sku });
    this.props.fetchProduct(sku);
  }

  render() {
    const {
      product, id, projectCurrency, fetchingProduct, codeTemplates, canSeePricings,
    } = this.props;

    if (!product && fetchingProduct) {
      return (<SnabbleLinearProgress show={fetchingProduct} />);
    }

    if (!product && !fetchingProduct) {
      return (
        <>
          <DocumentTitle translationID="product.detailsHeadline" />
          <NoResult
            headline={<Translate id="product.detailsHeadline" />}
            text={<Translate id="product.notFound" data={{ sku: id }} />}
          />
        </>
      );
    }

    return ((
      <div>
        <DocumentTitle translationID="product.detailsHeadline" />
        <Headline>
          <Translate id="product.detailsHeadline" />
        </Headline>
        <ProductDetailsGeneral product={product} />
        <ProductDetailsPrices
          product={product}
          projectCurrency={projectCurrency}
          canSeePricings={canSeePricings}
        />
        <ProductDetailsControl product={product} />
        <ProductDetailsCodes codes={product.codes} codeTemplates={codeTemplates} />
        <ProductDetailsLinked product={product} />
        <ProductAdditionalAttributesPanel attributes={product.additionalAttributes} />
      </div>
    ));
  }
}

export default ProductView;
