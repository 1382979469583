import padStart from 'lodash/padStart';
import parseInt from 'lodash/parseInt';

export const TERMINAL_PASSWORD_LENGTH = 6;
export const TERMINAL_PASSWORD_PATTERN = /^[0-9]{6}$/;

export function terminalPasswordToString(password: number): string {
  if (password < 0 || !Number.isInteger(password)) {
    throw new Error('terminal password must be a positive integer');
  }

  const str = padStart(password.toString(), TERMINAL_PASSWORD_LENGTH, '0');
  if (str.length > TERMINAL_PASSWORD_LENGTH) {
    throw new Error('invalid terminal password length');
  }

  return str;
}

export function terminalPasswordToNumber(password: string): number {
  if (!password.match(TERMINAL_PASSWORD_PATTERN)) {
    throw new Error('invalid terminal password');
  }

  return parseInt(password);
}
