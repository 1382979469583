import actions from '../../actions/constants';
import { get, requestApi, resolveURL } from '../../actions/request';
import { toSearchQuery } from '../../urls';
import handlePromiseResponseStatus from '../../components/RequestUtils';


function requestClosings() {
  return {
    type: actions.REQUEST_CLOSINGS,
  };
}

function receivedClosings(json) {
  return {
    type: actions.RECEIVED_CLOSINGS,
    payload: json || { list: [], pagination: {} },
  };
}

function fetchClosings(shopID, page) {
  const queryString = toSearchQuery({
    perPage: 50,
    page: page && page > 0 ? page - 1 : undefined,
    shopID,
  });

  return (dispatch, getState) => {
    dispatch(requestClosings());
    return get(dispatch, getState, `/{project}/closings${queryString}`, 'application/json')
      .then(
        json => dispatch(receivedClosings(json)),
        () => dispatch(receivedClosings()),
      );
  };
}

function requestClosing() {
  return {
    type: actions.REQUEST_CLOSING,
  };
}

function receivedClosing(json) {
  return {
    type: actions.RECEIVED_CLOSING,
    payload: json,
  };
}

function fetchClosing(shopID, sequenceNumber) {
  if (!shopID || !sequenceNumber) return null;
  return (dispatch, getState) => {
    dispatch(requestClosing());
    return get(dispatch, getState, `/{project}/closings/shops/${shopID}/sequenceNumbers/${sequenceNumber}`, 'application/json')
      .then(
        json => dispatch(receivedClosing(json)),
        () => dispatch(receivedClosing()),
      );
  };
}

function requestClosingSchedules() {
  return {
    type: actions.REQUEST_CLOSING_SCHEDULES,
  };
}

function receivedClosingSchedules(json) {
  return {
    type: actions.RECEIVED_CLOSING_SCHEDULES,
    payload: json.schedules,
  };
}

function fetchClosingSchedules() {
  return (dispatch, getState) => {
    dispatch(requestClosingSchedules());
    return get(dispatch, getState, '/{project}/closings/schedules', 'application/json')
      .then(
        json => dispatch(receivedClosingSchedules(json)),
        () => dispatch(receivedClosingSchedules()),
      );
  };
}

function requestClosingSchedule() {
  return {
    type: actions.REQUEST_CLOSING_SCHEDULE,
  };
}

function receivedClosingSchedule(json) {
  return {
    type: actions.RECEIVED_CLOSING_SCHEDULE,
    payload: json,
  };
}

function fetchClosingSchedule(id) {
  return (dispatch, getState) => {
    dispatch(requestClosingSchedule());
    return get(dispatch, getState, `/{project}/closings/schedules/id/${id}`, 'application/json')
      .then(
        json => dispatch(receivedClosingSchedule(json)),
        () => dispatch(receivedClosingSchedule()),
      );
  };
}

function editClosingScheduleStatusUpdate(newStatus, error) {
  return {
    type: actions.EDIT_CLOSING_SCHEDULE_STATUS_UPDATE,
    payload: { newStatus, error },
  };
}

function createClosingSchedule(schedule) {
  return (dispatch, getState) => {
    fetch(
      resolveURL(getState, '/{project}/closings/schedules'),
      {
        headers: {
          'Client-Token': getState().token.raw,
          'content-type': 'application/json',
        },
        body: JSON.stringify(schedule),
        method: 'POST',
      },
    )
      .then(handlePromiseResponseStatus)
      .then((response) => {
        dispatch(receivedClosingSchedule(Object.assign({}, schedule, { links: { self: { href: response.headers.get('location') } } })));
        dispatch(editClosingScheduleStatusUpdate('saved'));
      })
      .catch((err) => {
        err.json().then((error) => {
          dispatch(editClosingScheduleStatusUpdate('savingFailed', error ? error.type : ''));
        });
      });
  };
}

function updateClosingSchedule(link, schedule) {
  return (dispatch, getState) => {
    fetch(
      resolveURL(getState, link),
      {
        headers: {
          'Client-Token': getState().token.raw,
          'content-type': 'application/json',
        },
        body: JSON.stringify(schedule),
        method: 'PUT',
      },
    )
      .then(handlePromiseResponseStatus)
      .then(() => {
        dispatch(editClosingScheduleStatusUpdate('saved'));
      })
      .catch((err) => {
        if (!err) return;
        err.json().then((error) => {
          dispatch(editClosingScheduleStatusUpdate('savingFailed', error ? error.type : ''));
        });
      });
  };
}

function deleteClosingSchedule(link) {
  return (dispatch, getState) => {
    requestApi(getState, link, 'DELETE')
      .then((response) => {
        if (response.ok) {
          dispatch(editClosingScheduleStatusUpdate('deleted'));
        } else {
          dispatch(editClosingScheduleStatusUpdate('deletingFailed'));
        }
      });
  };
}

export {
  fetchClosings, fetchClosing, fetchClosingSchedules, fetchClosingSchedule, createClosingSchedule,
  editClosingScheduleStatusUpdate, updateClosingSchedule, deleteClosingSchedule,
};
