import React, { ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

const PaperWithStyle = styled(Paper)({
  overflow: 'hidden',
});

export interface PanelProps {
  children: ReactNode;
}

export default function Panel({ children }: PanelProps) {
  return (
    <PaperWithStyle elevation={4} role="group">
      {children}
    </PaperWithStyle>
  );
}

