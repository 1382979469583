import actions from '../../actions/constants';
import { get } from '../../actions/request';


function requestCheckoutDevice() {
  return {
    type: actions.REQUEST_CHECKOUT_DEVICE,
  };
}

function receivedCheckoutDevice(json: any) {
  return {
    type: actions.RECEIVED_CHECKOUT_DEVICE,
    payload: json,
  };
}

function fetchCheckoutDevice(id: string) {
  return (dispatch: (value: any) => void, getState: () => any) => {
    dispatch(requestCheckoutDevice());
    return get(dispatch, getState, `/{project}/checkout-devices/id/${id}`, 'application/json')
      .then(
        json => dispatch(receivedCheckoutDevice(json)),
        () => receivedCheckoutDevice({}),
      );
  };
}

function requestCheckoutDevices() {
  return {
    type: actions.REQUEST_CHECKOUT_DEVICES,
  };
}

function receivedCheckoutDevices(json: any) {
  return {
    type: actions.RECEIVED_CHECKOUT_DEVICES,
    payload: json,
  };
}

function fetchCheckoutDevices() {
  return (dispatch: (value: any) => void, getState: () => any) => {
    dispatch(requestCheckoutDevices());
    return get(dispatch, getState, '/{project}/checkout-devices', 'application/json')
      .then(
        json => dispatch(receivedCheckoutDevices(json)),
        () => dispatch(receivedCheckoutDevices({})),
      );
  };
}

function receivedCheckoutDevicesStatus(json: any) {
  return {
    type: actions.RECEIVED_CHECKOUT_DEVICES_STATUS,
    payload: json,
  };
}

function fetchCheckoutDevicesStatus(shopID: string) {
  return (dispatch: (value: any) => void, getState: () => any) =>
    get(dispatch, getState, `/{project}/checkout-devices/shop/${shopID}/status`, 'application/json')
      .then(
        json => dispatch(receivedCheckoutDevicesStatus(json)),
        () => dispatch(receivedCheckoutDevicesStatus({})),
      );
}

export { fetchCheckoutDevices, fetchCheckoutDevicesStatus, fetchCheckoutDevice };
