import React from 'react';

export enum DeviceStatus {
  Online = 'online',
  Offline = 'offline',
  Ok = 'ok',
  Problem = 'problem',
  Unknown = 'unknown',
  NA = 'na',
}

interface BuildOSVersionStringProps {
  name: string;
  platform: string;
  version: string;
}

export function buildOSVersionString({ name, platform, version }: BuildOSVersionStringProps) {
  if (!name) return undefined;
  if (platform === 'windows' || !version) return name;
  return `${name} (${version})`;
}

interface BuildTransactionInfoStringProps {
  successful: number | undefined;
  failed: number | undefined;
  overall: number | undefined;
}

export function buildTransactionInfoString({
  successful,
  failed,
  overall,
}: BuildTransactionInfoStringProps) {
  const successfulPercentage = (successful && overall) ? `${((successful / overall) * 100).toFixed(0)}%` : '-';
  return `${successful ?? '-'} (${successfulPercentage}) / ${failed ?? '-'} / ${overall ?? '-'}`;
}

export function buildTimestampString(timestamp: string) {
  if (!timestamp) return timestamp;

  const parsedDatetime = new Date(Date.parse(timestamp));
  return parsedDatetime.toLocaleString();
}

export function buildTerminalTypesList(supportedTerminalTypes: string[]) {
  return (
    <ul style={{ paddingLeft: '16px' }} >
      {
        supportedTerminalTypes.map(terminalType => (
          <li>{terminalType}</li>
        ))
      }
    </ul>
  );
}

export function buildTerminalVersionsList(installedTerminalVersions: Record<string, string>) {
  return (
    <ul style={{ paddingLeft: '16px' }} >
      {
        Object.keys(installedTerminalVersions).map(terminalType => (
          <li>{terminalType}: {installedTerminalVersions[terminalType]}</li>
        ))
      }
    </ul>
  );
}
