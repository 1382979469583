import React from 'react';
import Translate from './i18n/Translate';
// @ts-ignore
import PaperTableRow from './PaperTableRow';
// @ts-ignore
import LocaleDate from './LocaleDate';
// @ts-ignore
import PaperTable from './PaperTable';
import { MappedOrder } from '../orders/orderHelper';

interface OrderViewMetaProps {
  order: MappedOrder
}

function OrderViewMeta({ order }: OrderViewMetaProps) {
  if (!order) return null;
  return (
    <PaperTable headline={<Translate id="orders.meta" />}>
      <PaperTableRow label={<Translate id="orders.startedAt" />} value={<LocaleDate date={order.startedAt} showSeconds />} />
      <PaperTableRow label={<Translate id="orders.finalizedAt" />} value={<LocaleDate date={order.finalizedAt} showSeconds />} />
      <PaperTableRow label={<Translate id="orders.createdAt" />} value={<LocaleDate date={order.createdAt} showSeconds />} />
      <PaperTableRow label={<Translate id="orders.date" />} value={<LocaleDate date={order.date} showSeconds />} />
      <PaperTableRow label={<Translate id="orders.checkoutProcessID" />} value={order.checkoutProcessID} />
    </PaperTable>
  );
}

export default OrderViewMeta;
