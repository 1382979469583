import React from 'react';
import Translate from '../components/i18n/Translate';
import PaperTableRow from '../components/PaperTableRow';
import PaperTable from '../components/PaperTable';

class ClosingViewMessages extends React.PureComponent {
  render() {
    const { messages } = this.props;

    return (
      <PaperTable
        headline={<Translate id="closings.messages" data={{ count: messages.length || 0 }} />}
        tableHeader={[
          <Translate id="closings.messagesCode" />,
          <Translate id="closings.messagesText" />,
        ]}
      >
        {
          messages.map(message => (
            <PaperTableRow
              key={`${message.code} + ${message.text}`}
              label={message.code}
              value={message.text}
            />
          ))
        }
      </PaperTable >
    );
  }
}

export default ClosingViewMessages;
