import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import useResourceTranslator from '../resource/useResourceTranslator';
import useOrganizationNavigate from '../organizations/useOrganizationNavigate';

export interface ListAdminsTableProps {
  values: SortableTableDataPoint[];
  demoteAdmin: (userID: string) => void;
}

export default function ListAdminsTable({
  values,
  demoteAdmin,
}: ListAdminsTableProps) {
  const t = useResourceTranslator();
  const navigate = useOrganizationNavigate();

  const addAdmin = useCallback(() => {
    navigate('/organization-admins/new');
  }, [navigate]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.name || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.sub || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.email || '-/-'}
      </TableCell>
    </>
  ), []);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'name',
      disablePadding: false,
      displayName: t('organization.adminManagement.adminList.name'),
    },
    {
      key: 'sub',
      disablePadding: false,
      displayName: t('organization.adminManagement.adminList.sub'),
    },
    {
      key: 'email',
      disablePadding: false,
      displayName: t('organization.adminManagement.adminList.email'),
    },
  ], [t]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        CustomRowContent={TableRowEntries}
        title=""
        filterPlaceholder={t('organization.adminManagement.adminList.filterPlaceholder')}
        labelRowsPerPage={t('organization.adminManagement.adminList.labelRowsPerPage')}
        rowButtons={[
          { icon: <DeleteIcon />, tooltip: 'editUser', callback: (row) => { demoteAdmin(row.id); } },
        ]}
      />)
      :
      <Typography><Translate id="organization.adminManagement.adminList.empty" /></Typography>
  );

  return (
    <>
      <StatisticPaper
        headline={
          <Translate id="organization.adminManagement.headline" />
        }
        total={values?.length}
        additionalHeaderItems={
          <Stack direction="row">
            <Button variant="contained" endIcon={<PersonAddAlt1Icon />} onClick={addAdmin}>
              {t('organization.adminManagement.adminList.addAdmin')}
            </Button>
          </Stack>
        }
      >
        {table}
      </StatisticPaper>
    </>
  );
}
