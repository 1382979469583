import React from 'react';
import Translate from '../components/i18n/Translate';
import SnabbleLinearProgress from '../components/SnabbleLinearProgress';
import { extractQueries, toSearchQuery } from '../urls';
import PaperTable from '../components/PaperTable';
import ClosingListItem from './ClosingListItem';
import FilterWrapper from '../components/FilterWrapper';
import NoResult from '../components/NoResult';
import DocumentTitle from '../components/DocumentTitle';
import Pagination from '../components/Pagination';
import SearchableShopSelect from '../components/SearchableShopSelect';

class ClosingList extends React.Component {
  constructor(props) {
    super(props);
    const query = extractQueries(props.location.search);
    this.state = {
      currentShopId: query.shop || '',
      currentPage: query.page && query.page > 0 ? parseInt(query.page, 10) : 1,
    };
    this.handleFetch = this.handleFetch.bind(this);
    this.handleShopChange = this.handleShopChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleFetch();
  }

  handleFetch() {
    const { currentShopId, currentPage } = this.state;
    const { navigate, fetchClosings } = this.props;
    const queries = { page: currentPage };
    if (currentShopId !== '') queries.shop = currentShopId;
    navigate(toSearchQuery(queries));
    fetchClosings(currentShopId, currentPage);
  }

  handlePageChange(_, page) {
    this.setState({ currentPage: page }, this.handleFetch);
  }

  handleShopChange(shop) {
    this.setState({ currentShopId: shop?.id || '' }, this.handleFetch);
  }

  render() {
    if (this.props.closings.length > 0) {
      return (
        <React.Fragment>
          <SnabbleLinearProgress show={this.props.isLoading} onShopChange={this.handleShopChange} />
          <DocumentTitle translationID="closings.navigation" />
          <FilterWrapper
            handleRefresh={this.handleFetch}
            headline={<Translate id="closings.headline" data={{ count: this.props.totalItems }} />}
          >
            <SearchableShopSelect
              onChange={this.handleShopChange}
              defaultShopId={this.state.currentShopId}
              showLabel
            />
          </FilterWrapper>

          <PaperTable
            headerAlignment={['left', 'left', 'left', 'left', 'center', 'right']}
            tableHeader={
              [
                <Translate id="closings.state" />,
                <Translate id="closings.sequenceNumber" />,
                <Translate id="shop.itemName" />,
                <Translate id="closings.date" />,
                <Translate id="closings.totalOrders" />,
                <Translate id="closings.total" />,
                '',
              ]
            }>

            {this.props.closings.map(closing => (
              <ClosingListItem
                closing={closing}
                key={`${closing.shopID}-${closing.sequenceNumber}`}
              />
            ))}
          </PaperTable>

          {this.props.totalPages > 1 &&
            <Pagination
              page={this.state.currentPage}
              count={this.props.totalPages}
              onChange={this.handlePageChange}
            />
          }
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={this.props.isLoading} onShopChange={this.handleShopChange} />
        <DocumentTitle translationID="closings.navigation" />
        <FilterWrapper
          handleRefresh={() => { this.fetchClosings?.(); }}
          headline={<Translate id="closings.headline" data={{ count: this.props.closings.length }} />}
        >
          <SearchableShopSelect
            onChange={this.handleShopChange}
            defaultShopId={this.state.currentShopId}
            showLabel
        />
        </FilterWrapper>

        <NoResult text={<Translate id="closings.notFound" />} />
      </React.Fragment>
    );
  }
}

export default ClosingList;
