import actions from '../../actions/constants';
import shopLabel from '../../shop';

function mapShopToScheduledClosing(schedule, shops) {
  if (!schedule) return null;

  const shopInfo = (shops || []).find(shop => shop.id === schedule.shop);

  if (shopInfo) {
    return Object.assign({}, schedule, { shopInformation: shopInfo });
  }

  return schedule;
}

function mapClosing(closing) {
  if (!closing) return null;
  const mapped = closing;
  mapped.shop = Object.assign({}, mapped.shop, { label: shopLabel(mapped.shop) });
  return mapped;
}

function mapClosings(closings) {
  if (!closings || !closings.length) return [];
  const mapped = closings;
  mapped.forEach(closing => mapClosing(closing));
  return mapped;
}

function mapShopToScheduledClosings(schedules, shops) {
  const mapped = [];
  (schedules || []).forEach(schedule => mapped.push(mapShopToScheduledClosing(schedule, shops)));
  return mapped;
}

export default ({ closings, shops }, action) => {
  switch (action.type) {
    case actions.REQUEST_CLOSINGS:
      return Object.assign({}, closings, { isLoading: true, list: [] });

    case actions.RECEIVED_CLOSINGS:
      return Object.assign(
        {}, closings,
        {
          isLoading: false,
          list: mapClosings(action.payload.closings),
          pagination: action.payload.pagination,
        },
      );

    case actions.REQUEST_CLOSING:
      return Object.assign({}, closings, { isLoading: true, details: null });

    case actions.RECEIVED_CLOSING:
      return Object.assign({}, closings, { isLoading: false, details: mapClosing(action.payload) });

    case actions.REQUEST_CLOSING_SCHEDULES:
      return Object.assign({}, closings, { isLoading: true, schedules: [] });

    case actions.RECEIVED_CLOSING_SCHEDULES:
      return Object.assign({}, closings, {
        isLoading: false, schedules: mapShopToScheduledClosings(action.payload, shops),
      });

    case actions.REQUEST_CLOSING_SCHEDULE:
      return Object.assign({}, closings, { isLoading: true, schedule: null });

    case actions.RECEIVED_CLOSING_SCHEDULE:
      return Object.assign({}, closings, {
        isLoading: false, schedule: mapShopToScheduledClosing(action.payload, shops),
      });

    case actions.EDIT_CLOSING_SCHEDULE_STATUS_UPDATE:
      return Object.assign(
        {}, closings,
        { editStatus: action.payload.newStatus, serverError: action.payload.error },
      );

    default:
      return closings;
  }
};
