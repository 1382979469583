import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { feedbackStatisticQueryString, FeedbackApiParams } from '../utils/api/feedbackStatisticQueryString';
import { StatisticMultipleBarProps, VerticalBarSeriesProps } from '../charts';

export interface RatingsPerDay {
  date: string,
  count: number
  ratings: Record<string, number>,
}

export interface Comment {
  rating?: string;
  message?: string;
  date?: string;
  shopID?: string;
}

export interface FeedbackData extends StatisticMultipleBarProps {
  comments: Comment[]
  ratingsPerDay: RatingsPerDay[] | null
}

export const ratingsMetadata = {
  keys: [
    '1',
    '2',
    '3',
  ],
  legendText: [
    '😐',
    '🙂',
    '😍',
  ],
  legendColors: [
    '#ff0707',
    '#ffc107',
    '#17d214',
  ],
};

export const mapRatingsDataToChartData = (data: FeedbackData) => {
  let countMax = 0;
  let countSum = 0;
  let chartData: StatisticMultipleBarProps = [];

  const availableRatings = ratingsMetadata.keys;

  data?.ratingsPerDay?.forEach((day) => {
    countMax = (day.count > countMax) ? day.count : countMax;
    countSum += day.count;
  });

  availableRatings.forEach((ratingKey: string) => {
    const chartDataPerRating: VerticalBarSeriesProps =
      data?.ratingsPerDay?.map((value: RatingsPerDay, index: number) => ({
        // NOTE if the index for the chart starts at 0 and there is only one value, the chart breaks
        x: index + 1,
        y: value.ratings[ratingKey] || 0,
        xLabel: value.date,
      }));
    chartData = [...chartData, (chartDataPerRating || [])];
  });

  return { chartData, countMax, countSum };
};

const useApi = defineApi({
  getFeedback: (client, params: FeedbackApiParams) =>
    client.get<FeedbackData>(`/ratings${feedbackStatisticQueryString(params)}`).then(resp => resp.data),
});

export default function useFeedbackApi() {
  const client = useProjectSpecificApiClient({ basePath: '/events/statistics' });
  return useApi(client);
}
