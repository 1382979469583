import React from 'react';
import { useTranslation } from 'react-i18next';

export interface TranslateProps {
  id: string;
  data?: any;
  defaultValue?: string;
}

const Translate: React.FunctionComponent<TranslateProps> =
  ({ id, data = {}, defaultValue }) => {
    const { t } = useTranslation();
    return t(id, defaultValue, data);
  };

export default Translate;
