import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import qs from 'qs';
import Typography from '@mui/material/Typography';
import { extractQueries } from '../urls';
import Translate from './i18n/Translate';
import ProductListFilter from './ProductListFilter';
import ProductListTable from './ProductListTable';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import DocumentTitle from './DocumentTitle';

const toUrlQuery = (searchText, ean) => {
  const query = {};
  if (ean) {
    query.ean = 'on';
  }
  if (searchText) {
    query.term = searchText;
  }
  return `?${qs.stringify(query)}`;
};

class ProductList extends Component {
  constructor(props) {
    super(props);
    const queries = extractQueries(props.location.search);
    this.state = {
      searchText: queries.term || '',
      showBarcodes: queries.ean === 'on',
    };
    this.toggleBarcodes = this.toggleBarcodes.bind(this);
    this.handleSearchUpdate = this.handleSearchUpdate.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.abortController = new AbortController();
  }

  componentDidMount() {
    const { searchText } = this.state;
    if (searchText !== this.props.searchTerm) {
      this.props.fetchProducts(200, searchText, this.abortController);
    }
  }

  changeUrl(text, barcodesVisible) {
    this.props.navigate(toUrlQuery(text, barcodesVisible));
  }

  toggleBarcodes(isVisible) {
    this.setState({ showBarcodes: isVisible });
    this.changeUrl(this.state.searchText, isVisible);
  }

  handleSearchUpdate(value) {
    this.setState({ searchText: value }, debounce(() => this.fetchData(), 500));
    this.changeUrl(value, this.state.showBarcodes);
  }

  fetchData() {
    this.abortController.abort();
    this.abortController = new AbortController();
    this.props.fetchProducts(200, this.state.searchText, this.abortController);
  }

  render() {
    const {
      fetchingProducts, projectCurrency, products, codeTemplates, fetchingProductsError,
    } = this.props;
    const { showBarcodes, searchText } = this.state;

    return (
      <>
        <SnabbleLinearProgress
          show={fetchingProducts}
        />
        <DocumentTitle translationID="product.headline" />
        <ProductListFilter
          initialInputValue={searchText}
          initialSelectState={showBarcodes}
          handleSearchUpdate={this.handleSearchUpdate}
          fetchData={this.fetchData}
          onSelectChange={this.toggleBarcodes}
        />
        {!fetchingProductsError &&
          <ProductListTable
            products={products}
            codeTemplates={codeTemplates}
            projectCurrency={projectCurrency}
            showBarcodes={showBarcodes}
          />
        }
        {fetchingProductsError &&
          <Typography><Translate id="product.fetchError" /></Typography>
        }
      </>
    );
  }
}

export default ProductList;
