import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import Translate from './i18n/Translate';
import { rangeFromQuery, shopFromQuery, toRangeQuery, toSearchQuery, toShopQuery } from '../urls';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import FilterWrapper from './FilterWrapper';
import QuickRangeFilter from './QuickRangeFilter';
import FromToSelector from './FromToSelector';
import DocumentTitle from './DocumentTitle';
import {
  MIN_APPROVAL_STATS_DATE,
  validateApprovalStatsTimeRange,
} from '../ranges';
import NoResult from './NoResult';
import StatisticDownloadButton from './StatisticDownloadButton';
import FilterError from './FilterError';
import StatisticToolbar from './StatisticToolbar';
import StatisticApprovalsTable from './StatisticApprovalsTable';
import approvalStatisticQueryString from '../utils/api/approvalStatisticQueryString';
import StatisticError from './StatisticError';
import SearchableShopSelect from './SearchableShopSelect';

class StatisticApprovalsView extends React.Component {
  constructor(props) {
    super(props);

    const { location, defaultRange, defaultShop } = props;

    if (
      defaultRange.from &&
      moment(defaultRange.from).isBefore(MIN_APPROVAL_STATS_DATE)
    ) {
      defaultRange.from = MIN_APPROVAL_STATS_DATE.toISOString(true);
    }

    const range = rangeFromQuery(location.search, defaultRange);

    this.state = {
      range,
      shopID: shopFromQuery(location.search) || defaultShop,
      ...validateApprovalStatsTimeRange(range),
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeShop = this.handleChangeShop.bind(this);
    this.handleFetch = this.handleFetch.bind(this);

    this.fetchApprovalDurationStatisticDebounced = debounce(
      this.props.fetchApprovalDurationStatistic,
      500,
    );
  }

  componentDidMount() {
    const { range, shopID, hasError } = this.state;

    if (hasError) {
      return;
    }

    this.props.fetchApprovalDurationStatistic(range.from, range.to, shopID);
  }

  componentWillUnmount() {
    this.props.resetApprovalDurationStatistic();
  }

  handleChangeRange(range) {
    this.setState({
      range,
      ...validateApprovalStatsTimeRange(range),
    }, () => { this.handleFetch(); });
  }

  handleChangeShop(shop) {
    const shopID = shop?.id || '';
    this.setState({ shopID }, () => { this.handleFetch(); });
  }

  handleFetch() {
    const { hasError, range, shopID } = this.state;

    if (hasError) {
      return;
    }

    this.fetchApprovalDurationStatisticDebounced(range.from, range.to, shopID);

    this.props.navigate(toSearchQuery({
      ...toRangeQuery(range),
      ...toShopQuery(shopID),
    }));
  }

  render() {
    const { showLoadingIndicator, approvalDurationStatistic } = this.props;

    const showTable =
      showLoadingIndicator || approvalDurationStatistic?.items?.length > 0;
    const showError = !showLoadingIndicator && isNull(approvalDurationStatistic);

    let resultContainer;
    if (showError) {
      resultContainer = <StatisticError />;
    } else if (showTable) {
      resultContainer = (
        <React.Fragment>
          <StatisticApprovalsTable
            statistic={approvalDurationStatistic}
            loading={showLoadingIndicator}
          />

          <StatisticToolbar>
            <StatisticDownloadButton
              type="averageApprovalDurations"
              params={this.state}
              queryStringBuilder={approvalStatisticQueryString}
              disabled={this.state.hasError}
            />
          </StatisticToolbar>
        </React.Fragment>
      );
    } else {
      resultContainer = <NoResult text={<Translate id="statistic.empty" />} />;
    }

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={showLoadingIndicator} />
        <DocumentTitle translationID="approvalDurationStatistic.headline" />

        <FilterWrapper
          headline={<Translate id="approvalDurationStatistic.headline" />}
          handleRefresh={this.handleFetch}
          error={
            <FilterError id={this.state.error} data={this.state.errorData} />
          }
        >
          <FromToSelector
            range={this.state.range}
            hasError={this.state.hasError}
            onChange={this.handleChangeRange}
            emitFilled
          />
          <QuickRangeFilter
            range={this.state.range}
            onChange={this.handleChangeRange}
          />
          <SearchableShopSelect
            onChange={this.handleChangeShop}
            defaultShopId={this.state.shopID}
            showLabel
          />
        </FilterWrapper>

        {resultContainer}
      </React.Fragment>
    );
  }
}

export default StatisticApprovalsView;
