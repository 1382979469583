import React from 'react';
import DocumentTitle from './DocumentTitle';
import ProductStatistic from './ProductStatistic';
import OrderStateFilterButtonGroup from './statistics/OrderStateFilterButtonGroup';
import OrderLastWeekStatistic from './OrderLastWeekStatistic';
import OrdersOfTheDay from './OrdersOfTheDay';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orderState: 'final' };

    this.handleChangeOrderState = this.handleChangeOrderState.bind(this);
  }

  componentDidMount() {
    const { fetchProductStatistic, fetchOrderStatistic } = this.props;
    fetchProductStatistic();
    fetchOrderStatistic();
  }

  handleChangeOrderState(_, orderState) {
    if (!orderState) return;
    this.setState({ orderState });
  }

  render() {
    const { productStatistic, orderStatistic } = this.props;
    const { orderState } = this.state;
    const selectedStatistic = orderStatistic?.[orderState] || {};

    return (
      <React.Fragment>
        <DocumentTitle translationID="dashboard.headline" />
        <OrdersOfTheDay
          totalFinal={orderStatistic?.final?.today?.total ?? '...'}
          ordersFinal={orderStatistic?.final?.today?.orders ?? '...'}
          totalTransferred={orderStatistic?.transferred?.today?.total ?? '...'}
          ordersTransferred={orderStatistic?.transferred?.today?.orders ?? '...'}
        />
        <OrderStateFilterButtonGroup
          onChange={this.handleChangeOrderState}
          value={orderState}
          withMargin
        />
        <OrderLastWeekStatistic statistic={selectedStatistic} />
        <ProductStatistic statistic={productStatistic} />
      </React.Fragment>
    );
  }
}

export default Dashboard;
