import React, { useCallback, useState } from 'react';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectConfigApi from '../projectConfig/useProjectConfigApi';
import { DynaForm, UISchemaScope } from '../dynaForm';

export default function EditAppAppearanceView() {
  const api = useProjectConfigApi();

  const [uiSchema, setUiSchema] = useState<any>(null);

  const handleFetch = useCallback(async () => {
    setUiSchema(await api.getAppCustomizationUISchema(null));
    const data = await api.getAppCustomizationConfig(null);
    return data;
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="appAppearance.headline" />
      <ResourceFormView
        actionName="editAppAppearance"
        Form={DynaForm}
        FormProps={{
          uiSchema,
          currentScope: UISchemaScope.PROJECT,
        }}
        fetch={handleFetch}
        submit={data => api.updateAppCustomizationConfig({ data })}
      />
    </>
  );
}
