import { styled } from '@mui/system';
import React, { ReactNode } from 'react';
import Headline from '../components/Headline';

const Container = styled('div')(({ theme }) => ({
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(4),
    marginBottom: 0,
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
  },
}));

const HeadlineContainer = styled('div')({
  flexGrow: 1,
});

export interface ButtonHeadlineProps {
  headline: ReactNode;
  children: ReactNode;
}

export default function ButtonHeadline({
  children,
  headline,
}: ButtonHeadlineProps) {
  return (
    <Container>
      <HeadlineContainer>
        <Headline>
          {headline}
        </Headline>
      </HeadlineContainer>
      <ButtonContainer>
        {children}
      </ButtonContainer>
    </Container>
  );
}
