import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  label: {
    fontSize: '0.875rem',
  },
});

class FormTableRowCheckbox extends React.PureComponent {
  render() {
    const {
      text, value, checked, handleChange, colSpan, classes,
    } = this.props;
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <FormControlLabel
            className={classes.checkbox}
            label={text}
            classes={{ label: classes.label }}
            control={<Checkbox
              color="primary"
              value={value}
              checked={checked}
              onChange={handleChange}
            />}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(FormTableRowCheckbox);
