import * as Sentry from '@sentry/react';
import { WrappedFunctionProps } from '../types';

class UISchemaUnknownComponent extends Error {
  constructor(key: string) {
    super(`No mapping was found for the component requested for "${key}". Please make sure the ui schema is correct or add a new mapping.`);
    this.name = 'UISchemaUnknownComponent';
  }
}

export default function Fallback({ key }: WrappedFunctionProps) {
  Sentry.captureException(new UISchemaUnknownComponent(key));

  return null;
}
