import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { mapping } from './DynaForm';

export enum UISchemaLanguage {
  DE = 'de',
  EN = 'en',
}

export enum UISchemaScope {
  PROJECT = 'project',
  SHOP = 'shop',
  DEVICE = 'device',
}

export type UISchemaTranslation = Record<UISchemaLanguage, string>;

export interface UISchemaElementTranslations {
  title: UISchemaTranslation
  description?: UISchemaTranslation
  enumNames?: Record<string, UISchemaTranslation>;
}

export interface UISchemaElement {
  component: string;
  translation: UISchemaElementTranslations
  scopes: UISchemaScope;
  children?: Record<string, UISchemaElement>;
}

export interface UISchema {
  properties: Record<string, UISchemaElement>;
}

export type WrappedFunctionProps = {
  key: string;
  translation: UISchemaElementTranslations;
  language: UISchemaLanguage;
  [key: string]: any;
} & ({
  control: Control;
} | {
  children: ReactNode[];
});

export type WrappedFunction = (props: WrappedFunctionProps) => ReactNode;

export type FunctionMapping = Record<Capitalize<string>, WrappedFunction>;

export interface ElementDescription extends Omit<UISchemaElement, 'component'> {
  key: string;
  component: keyof typeof mapping;
}
