import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  initReactI18next,
} from 'react-i18next';

import deTranslations from './de/translations.json';
import enTranslations from './en/translations.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    resources: {
      en: {
        translation: enTranslations,
      },
      de: {
        translation: deTranslations,
      },
    },

    // CI Mode is useful for rendering i18n keys instead of text:
    appendNamespaceToCIMode: process.env.NODE_ENV === 'development',

    // Allows 'en-US' and 'en-UK' to be implicitly supported when 'en' is supported
    nonExplicitSupportedLngs: true,

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },

    // debug: process.env.NODE_ENV === 'development',

    detection: {
      order: ['localStorage', 'navigator'],
      excludeCacheFor: ['cimode'],
    },
  });

export default i18next;
