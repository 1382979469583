import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import useShopApi, { Shop, emptyShop } from './useShopApi';
import useAutocompleteApi, { mapAddressDetailsToAddress } from '../googlePlacesApi/useAutocompleteApi';
import CreateShopForm from './CreateShopForm';

export default function CreateShopView() {
  const api = useShopApi();
  const navigate = useProjectNavigate();
  const { projectId } = useParams();
  const { resolveAddress } = useAutocompleteApi();

  const handleSubmit = useCallback(async (data) => {
    const dataWithProject = { ...data, project: projectId };
    return api.createShop(dataWithProject);
  }, [api, projectId]);

  const handleResolveCoordinates = useCallback(async (addressString: string) => {
    const address = mapAddressDetailsToAddress((await resolveAddress(addressString))[0]);
    if (!address) return { lat: undefined, lng: undefined };
    return { lat: address.lat, lng: address.lng };
  }, [resolveAddress]);

  return (
    <>
      <DocumentTitle translationID="headlines.createShop" />

      <ResourceFormView
        actionName="createShop"
        Form={CreateShopForm}
        FormProps={{
          hideShopId: true,
          onRequestCoordinates: handleResolveCoordinates,
        }}
        fetch={() => emptyShop}
        submit={handleSubmit}
        onSubmitted={({ id }: Shop) => {
          navigate(`/shops/${id}/edit`);
        }}
      />
    </>
  );
}
