import actions from '../actions/constants';
import { prepareRecurringVisitorsStatistic } from './helper';

export default (state, action) => {
  switch (action.type) {
    case actions.RECEIVED_RECURRING_VISITORS_STATISTIC:
      return {
        recurringVisitorsStatistic:
          prepareRecurringVisitorsStatistic(action.payload),
        fetchingRecurringVisitorsStatistic: false,
        range: action.payload.date,
        shopFilter: action.payload.shopID,
      };

    case actions.RECEIVED_RECURRING_VISITORS_STATISTIC_ERROR:
      return {
        recurringVisitorsStatistic: null,
        fetchingRecurringVisitorsStatistic: false,
      };

    case actions.REQUEST_RECURRING_VISITORS_STATISTIC:
      return { fetchingRecurringVisitorsStatistic: true };

    case actions.RESET_RECURRING_VISITORS_STATISTIC:
      return { recurringVisitorsStatistic: null };

    default:
      return {};
  }
};
