import { connect } from 'react-redux';
import LineItemExport from '../components/LineItemExport';
import { fetchOrdersForLineItemExport } from '../actions';

const LineItemExportContainer = connect(
  ({
    lineItemExport, fetchingLineItemExport, shops,
  }) => ({
    lineItemExport,
    fetchingLineItemExport,
    shops,
  }),
  dispatch => ({
    fetchOrdersForLineItemExport: (fileType, fromDate, toDate, timezone, shop, delimiter) =>
      dispatch(fetchOrdersForLineItemExport(fileType, fromDate, toDate, timezone, shop, delimiter)),
  }),
)(LineItemExport);

export default LineItemExportContainer;
