import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  useParams,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
// @ts-ignore
import TrackingWrapper from './TrackingWrapper';
// @ts-ignore
import AppContainer from '../containers/AppContainer';
import RestrictedProjectDialog from '../components/RestrictedProjectDialog';
import LoadingCircle from '../components/LoadingCircle';
import { ApplicationState } from '../reducers/initialState';
import OrganizationsLayout from '../organizationsLayout/App';
import OrganizationRoutesForAuthorizedUsers from './OrganizationRoutesForAuthorizedUsers';
import ProjectSelector from '../components/ProjectSelector';
import RoutesForAuthorizedUsers from './RoutesForAuthorizedUsers';

function App() {
  const params = useParams();

  return (
    <AppContainer projectId={params.projectId}>
      <RoutesForAuthorizedUsers />
    </AppContainer>
  );
}

function OrganizationsApp() {
  return (
    <OrganizationsLayout>
      <OrganizationRoutesForAuthorizedUsers />
    </OrganizationsLayout>
  );
}

function RouterMixin() {
  // NOTE The TrackingWrapper sits around all router routes to intercept navigations but has to
  // use the useLocation hook to work. The new data router has a different way of placing
  // components around routes:
  // https://reactrouter.com/en/main/upgrading/v6-data#but-ive-got-stuff-between-browserrouter-and-routes
  return (
    <TrackingWrapper>
      <Outlet />
    </TrackingWrapper>
  );
}

function AppRouter() {
  const fetchingProjects =
    useSelector<ApplicationState, boolean>(state => (state.fetchingProjects));
  const authorized =
    useSelector<ApplicationState, any>(state => (state.authorized));

  const router = useMemo(() => createBrowserRouter(createRoutesFromElements((
    <Route element={<RouterMixin />}>
      <Route path="/" element={<ProjectSelector />} />
      <Route path="/index.html" element={<Navigate to="/" replace />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="organizations/:organizationId/*" element={<OrganizationsApp />} />
      <Route path="/:projectId/*" element={<App />} />
      <Route path="/selectProject" element={<ProjectSelector />} />
    </Route>
  ))), []);

  if (fetchingProjects) {
    return (
      <React.Fragment>
        <RestrictedProjectDialog />
        <LoadingCircle show />
      </React.Fragment>
    );
  }

  if (!authorized) {
    return (
      <React.Fragment>
        <RestrictedProjectDialog />
      </React.Fragment>
    );
  }

  return (
    <RouterProvider router={router} />
  );
}

export default AppRouter;
