import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import Translate from '../components/i18n/Translate';
import useUserManagementApi, { Realm } from './useUserManagementApi';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import ListUserTable from './ListUserTable';
import { searchableProjectSelectSorterOptions } from '../components/SearchableProjectSelect';
import LoadableRegion from '../loader/LoadableRegion';
import useChartFilter from '../charts/useChartFilter';
import { ApplicationState } from '../reducers/initialState';
import checkUserIsProjectAdmin from './claimUtils';
import TabFilter from '../components/TabFilter';
import SearchableSelect from '../components/SearchableSelect';
import RealmAdminDialogView from './RealmAdminDialogView';

export default function ListUserView() {
  const api = useUserManagementApi();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const projects = useSelector<ApplicationState, Record<string, any>[]>(state => state.projects);

  const [realmAdminDialogOpen, setRealmAdminDialogOpen] = useState(false);

  const [defaultRealm, setDefaultRealm] = useState<any>();
  const [availableRealms, setAvailableRealms] = useState<any>();
  const [availableProjects, setAvailableProjects] = useState(projects);
  const [isRealmAdmin, setIsRealmAdmin] = useState(false);
  const token = useSelector<ApplicationState, any>(state => state.token?.grants);

  const { filter, updateFilter } = useChartFilter({});
  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const [realmFilter, setRealmFilter] = useState<string | undefined>(filter.genericFilter?.realm);
  const [projectFilter, setProjectFilter] =
    useState<string | undefined>(('project' in filter.genericFilter) ? filter.genericFilter.project : projectId);
  const [userIsProjectAdminForFilter, setUserIsProjectAdminForFilter] = useState(false);
  const currentRealmIsDefaultRealm = realmFilter === defaultRealm?.id;

  const handleUpdateRealmFilter = useCallback((realm: Record<string, any> | null) => {
    setRealmFilter(realm?.id);
    setAvailableProjects(realm?.allowedProjects ? (
      projects.filter(p => (realm.allowedProjects.includes(p?.id)))
    ) : (
      projects
    ));
    updateFilter({ genericFilter: { ...filter.genericFilter, realm: realm?.id } });
  }, [filter.genericFilter, projects, updateFilter]);

  const handleUpdateProjectFilter = useCallback((project: Record<string, any> | null) => {
    setProjectFilter(project?.id);
    updateFilter({ genericFilter: { ...filter.genericFilter, project: project?.id } });
  }, [filter.genericFilter, updateFilter]);

  useEffect(() => {
    setUserIsProjectAdminForFilter(checkUserIsProjectAdmin(token.cs, projectFilter));
  }, [projectFilter, token.cs]);

  useEffect(() => {
    const getMyRealms = async () => {
      const realms = await api.getRealms(null) as any;
      setDefaultRealm({ ...realms.defaultRealm, title: realms.defaultRealm.name });
      setAvailableRealms(realms.realms.map((r: any) => ({ ...r, title: r.name })));
      setIsRealmAdmin(realms.isRealmAdmin);
      if (!realmFilter) setRealmFilter(realms.defaultRealm?.id);
    };
    getMyRealms();
  // NOTE realmFilter should not trigger this effect since this would load the user list multiple
  // times. If a realmFilter is already in redux it will be set when intializing the state. Only
  // if this is not the case should it be set here. Therefore the eslint-disable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const tableFilter = useMemo(() => (
    { project: projectFilter, realm: realmFilter }
  ), [projectFilter, realmFilter]);

  return (
    <>
      <DocumentTitle translationID="userManagement.headline" />

      {realmAdminDialogOpen && (
        <RealmAdminDialogView
          realmName={availableRealms?.find((r: Realm) => r.id === realmFilter)?.name}
          filter={{ project: projectFilter, realm: realmFilter }}
          onClose={() => setRealmAdminDialogOpen(false)}
        />
      )}

      <LoadableRegion loading={!defaultRealm || !availableRealms}>
        <FilterWrapper
          headline={<Translate id="userManagement.headline" />}
          topBarChildren={<></>}
          handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
        >
          {isRealmAdmin && (
            <TabFilter
              options={availableRealms}
              defaultOption={
                  realmFilter ?
                    availableRealms.find((r: any) => r.id === realmFilter)
                    :
                    defaultRealm
                }
              onChange={handleUpdateRealmFilter}
              sx={{ gridColumn: '1/-1', marginBottom: '16px' }}
            />
          )}
          <SearchableSelect
            onChange={(_, selectedProject) => { handleUpdateProjectFilter(selectedProject); }}
            options={availableProjects}
            sorterOptions={searchableProjectSelectSorterOptions}
            label={<Translate id="filter.byProject" />}
            defaultValue={projects?.find(project => (project.id === projectFilter))}
            labelFrom="name"
          />
        </FilterWrapper>

        {userIsProjectAdminForFilter ? (
          <ResourceChartView
            Chart={ListUserTable}
            ChartProps={{
              currentRealm: realmFilter,
              currentRealmName: availableRealms?.find((r: Realm) => r.id === realmFilter)?.name,
              isRealmAdmin,
              onOpenRealmAdminDialog: !currentRealmIsDefaultRealm && (
                () => setRealmAdminDialogOpen(true)
              ),
            }}
            fetch={api.getUsers}
            filter={tableFilter}
            ref={resourceChartViewRef}
          />
        ) : (
          <Alert severity="warning">{t('userManagement.error.notProjectAdmin')}</Alert>
        )}
      </LoadableRegion>
    </>
  );
}
