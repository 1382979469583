import actions from '../../actions/constants';
import shopLabel from '../../shop';
import { DeviceStatus } from '../CheckoutDeviceHelper';
import { CheckoutDeviceErrorType } from '../CheckoutDevice';

function getShopInformation(device, shops) {
  if (device && device.shop && shops && shops.length) {
    return shops.find(s => device.shop === s.id) || {};
  }
  return {};
}

function mapDevice(device, shops) {
  if (!device) return null;
  const shop = getShopInformation(device, shops);
  return Object.assign({}, device, {
    shortID: device.id?.length >= 4 ? device.id.substring(0, 4) : device.id,
    shopInformation: shop,
    shopLabel: shopLabel(shop) || device.shop,
  });
}

function mapDevices(devices, shops) {
  const mapped = {};
  (devices || []).forEach((device) => {
    if (!mapped[device.shop]) {
      mapped[device.shop] = [];
    }
    mapped[device.shop].push(mapDevice(device, shops));
  });
  return mapped;
}

export function mapDeviceStatus(device) {
  const mapped = {
    messages: [],
    status: DeviceStatus.Unknown,
    // subcomponents are ok by default
    uiStatus: DeviceStatus.Ok,
    serviceStatus: DeviceStatus.Ok,
    printerStatus: DeviceStatus.Ok,
    terminalStatus: DeviceStatus.Ok,
    posStatus: DeviceStatus.Ok,
  };

  if (device.systemInfo) mapped.systemInfo = device.systemInfo;
  if (device.lastSeenAt) mapped.lastSeenAt = device.lastSeenAt;

  if (device.status === 'UP') {
    mapped.status = DeviceStatus.Online;
  } else if (device.status === 'DEGRADED') {
    mapped.status = DeviceStatus.Problem;
  } else {
    mapped.status = DeviceStatus.Offline;
    // if a device is offline we most likely do not know the state of its subcomponents
    // if we do the state is overwritten by an error
    mapped.uiStatus = DeviceStatus.Unknown;
    mapped.serviceStatus = DeviceStatus.Unknown;
    mapped.printerStatus = DeviceStatus.Unknown;
    mapped.terminalStatus = DeviceStatus.Unknown;
    mapped.posStatus = DeviceStatus.Unknown;
  }
  if (device.errors && device.errors.length) {
    device.errors.forEach((error) => {
      switch (error.type) {
        case CheckoutDeviceErrorType.PRINTER_ERROR:
          mapped.printerStatus = DeviceStatus.Problem;
          break;
        case CheckoutDeviceErrorType.UI_COMPONENT_OFFLINE:
          mapped.uiStatus = DeviceStatus.Problem;
          break;
        case CheckoutDeviceErrorType.SERVICE_COMPONENT_OFFLINE:
          mapped.serviceStatus = DeviceStatus.Problem;
          break;
        case CheckoutDeviceErrorType.POS_COMPONENT_OFFLINE:
          mapped.posStatus = DeviceStatus.Problem;
          break;
        case CheckoutDeviceErrorType.TERMINAL_ERROR:
          mapped.terminalStatus = DeviceStatus.Problem;
          break;
        default:
          break;
      }
    });
    mapped.messages = [...mapped.messages, ...device.errors];
  }
  // remove all the subcomponents the device does not have
  if (device.kind === 'sco' || device.kind === 'gatekeeper') {
    mapped.posStatus = DeviceStatus.NA;
  } else if (device.kind === 'sco-ng' || device.kind === 'pos') {
    mapped.uiStatus = DeviceStatus.NA;
    mapped.serviceStatus = DeviceStatus.NA;
  }

  return mapped;
}

function mapDevicesStatus(devices, status) {
  const mapped = {};
  (devices || []).forEach((device) => {
    mapped[device.id] = mapDeviceStatus(device);
  });

  return Object.assign({}, mapped, status);
}

export default ({ checkoutDevices, shops }, action) => {
  switch (action.type) {
    case actions.REQUEST_CHECKOUT_DEVICES:
      return Object.assign({}, checkoutDevices, { isFetchingList: true, list: {} });

    case actions.RECEIVED_CHECKOUT_DEVICES: {
      const devices = action.payload && action.payload.devices ? action.payload.devices : [];
      return Object.assign({}, checkoutDevices, {
        isFetchingList: false,
        list: mapDevices(devices, shops),
      });
    }

    case actions.REQUEST_CHECKOUT_DEVICE:
      return Object.assign({}, checkoutDevices, { isFetchingDevice: true, device: {} });

    case actions.RECEIVED_CHECKOUT_DEVICE: {
      return Object.assign({}, checkoutDevices, {
        isFetchingDevice: false, device: mapDevice(action.payload, shops),
      });
    }

    case actions.RECEIVED_CHECKOUT_DEVICES_STATUS: {
      const devices = action.payload && action.payload.devices ? action.payload.devices : [];
      return Object.assign({}, checkoutDevices, {
        status: mapDevicesStatus(devices, checkoutDevices ? checkoutDevices.status : {}),
      });
    }

    default:
      return checkoutDevices;
  }
};
