import { useCallback } from 'react';
import { NavigateOptions, To, useNavigate, useParams } from 'react-router';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';

export default function useProjectNavigate() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      if (!projectId) {
        navigate(to, options);
        return;
      }

      const href = `/${projectId}${to}`;

      let rewrittenTo = to;
      if (isString(to)) {
        rewrittenTo = href;
      }
      if (isPlainObject(to)) {
        rewrittenTo = Object.assign({}, to, { pathname: href });
      }

      navigate(rewrittenTo, options);
    },
    [projectId, navigate],
  );
}
