import { connect } from 'react-redux';
import StatisticTotalRevenueView from '../components/StatisticTotalRevenueView';
import { fetchOrderStatistic, resetOrderStatistic } from '../actions';

const StatisticTotalRevenueContainer = connect(
  ({
    orderStatistic,
    fetchingOrderStatistic,
    range,
    shopFilter,
    withCustomerCardFilter,
    paymentMethodsFilter,
  }) => ({
    showLoadingIndicator: fetchingOrderStatistic,
    orderStatistic,
    defaultRange: range,
    defaultShop: shopFilter,
    defaultWithCustomerCard: withCustomerCardFilter,
    defaultPaymentMethods: paymentMethodsFilter,
  }),
  dispatch => ({
    fetchOrderStatistic: (from, to, withCustomerCard, shopID, paymentMethods) =>
      dispatch(fetchOrderStatistic(from, to, withCustomerCard, shopID, paymentMethods)),
    resetOrderStatistic: () => dispatch(resetOrderStatistic()),
  }),
)(StatisticTotalRevenueView);

export default StatisticTotalRevenueContainer;
