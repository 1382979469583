import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useShopAccessApi from './useShopAccessApi';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import BlockUserForm from './BlockUserForm';
import useProjectNavigate from '../useProjectNavigate';

export default function BlockUserView() {
  const api = useShopAccessApi();
  const location = useLocation();
  const navigate = useProjectNavigate();

  const getValuesFromLocationState = useCallback(() => {
    if (!location.state) return {};
    const { credentialsType, credentialsValue } = location.state;
    return {
      credentialsType,
      credentialsValue,
    };
  }, [location.state]);

  return (
    <>
      <DocumentTitle translationID="shopAccess.headline" />
      <ResourceFormView
        actionName="blockUser"
        Form={BlockUserForm}
        fetch={getValuesFromLocationState}
        submit={data => api.blockUser(data as any)}
        onSubmitted={() => {
          navigate('/shop-access/block-list');
        }}
      />
    </>
  );
}
