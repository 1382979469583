import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import useResourceTranslator from '../resource/useResourceTranslator';
import ArrayWrapper from '../form/wrapper/ArrayWrapper';
import { CustomerNetwork, DayOfWeek, OpeningHoursSpecification, Shop } from './useShopApi';
import CheckboxField from '../form/input/CheckboxField';
import COUNTRY_CODES, { CountryCode } from '../googlePlacesApi/Countries';
import { nextEnumValue } from '../utils/enumUtils';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from './useForceSubmitDialog';
import { useShopServiceEdit } from './useShopServiceEdit';
import { ShopServiceLanguage } from '../projectConfig/shopServices';

const DEFAULT_VALUES: Shop | {} = {};

export interface EditShopFormProps extends CustomFormProps<Shop | {}> { }

const EditShopForm = forwardRef<CustomFormRef, EditShopFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}, ref) => {
  const t = useResourceTranslator();
  const { id } = useParams();
  const [, i18n] = useTranslation();
  const language = useMemo(() => i18n.resolvedLanguage || 'de', [i18n.resolvedLanguage]);

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  const handleSetDirty = useCallback(() => {
    if (formState.isDirty) return;
    // there is no good way to manually set a react-hook-form to dirty
    setValue('services', getValues('services') ? null : [], { shouldDirty: true });
  }, [formState.isDirty, getValues, setValue]);

  const { ShopServiceSelect, submitShopServices } = useShopServiceEdit({
    shopID: id ? parseInt(id, 10) : undefined,
    language: language as ShopServiceLanguage,
    onSetDirty: handleSetDirty,
  });

  const selectedCountryCode = watch('countryCode');
  // HACK: prevent country to be overwritten on initial load
  const [countryCodeInited, setCountryCodeInited] = useState(false);
  useEffect(() => {
    if (!countryCodeInited) {
      setCountryCodeInited(true);
      return;
    }
    const countryCode: CountryCode = selectedCountryCode;
    setValue('country', COUNTRY_CODES[countryCode]?.[language === 'en' ? 'englishName' : 'germanName'] || '');
    // NOTE disabled because the language overwrite text should not change if the user changed the
    // language. Only if a new language code is selected should the new language entry be set in
    // the users selected language.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode, setValue]);

  const currentOpeningHoursSpecification = watch('openingHoursSpecification');
  const generateNextOpeningHoursSpecification = useCallback(() => {
    const entry = currentOpeningHoursSpecification.slice(-1)[0] as OpeningHoursSpecification;

    if (!entry) {
      return {
        opens: '08:00',
        closes: '20:00',
        dayOfWeek: DayOfWeek.MONDAY,
      } as OpeningHoursSpecification;
    }

    entry.dayOfWeek = nextEnumValue(DayOfWeek, entry.dayOfWeek);
    return entry;
  }, [currentOpeningHoursSpecification]);

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  const onSubmitWrapper = useCallback(async (eventData) => {
    eventData.preventDefault();
    await submitShopServices();
    handleSubmit(onSubmit)(eventData);
  }, [handleSubmit, onSubmit, submitShopServices]);

  return (
    <>
      <Form onSubmit={onSubmitWrapper}>
        <Fieldset category="general" legend={t('shop.general')}>
          <CheckboxField
            control={control}
            name="visible"
            label={t('shop.visible')}
          />
          <CheckboxField
            control={control}
            name="isPreLaunch"
            label={t('shop.isPreLaunch')}
          />
        </Fieldset>

        <Fieldset category="openingHoursSpecification" legend={t('shop.opening')}>
          <ArrayWrapper
            control={control}
            name="openingHoursSpecification"
            defaultValue={generateNextOpeningHoursSpecification}
            inlineDeleteButton
            // Validation for opening hours. copy from old shop form
            render={(_, path) => (
              <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                <Box sx={{ width: '33%' }}>
                  <SelectOptionsField
                    control={control}
                    name={`${path}.dayOfWeek`}
                    options={Object.values(DayOfWeek)}
                    labelFrom={(day: string) => t(`days.${day}`)}
                    label={t('shop.weekday')}
                  />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <TextField
                    control={control}
                    name={`${path}.opens`}
                    type="time"
                    label={t('filter.from')}
                  />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <TextField
                    control={control}
                    name={`${path}.closes`}
                    type="time"
                    label={t('filter.to')}
                  />
                </Box>
              </Stack>
            )}
          />
        </Fieldset>

        <Fieldset category="customerNetworks" legend={t('shop.networks')}>
          <ArrayWrapper
            control={control}
            name="customerNetworks"
            defaultValue={{ ssid: '' } as CustomerNetwork}
            inlineDeleteButton
            render={(_, path) => (
              <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    control={control}
                    name={`${path}.ssid`}
                    fullWidth
                    label={t('shop.ssid')}
                  />
                </Box>
              </Stack>
            )}
          />
        </Fieldset>

        <Fieldset category="services" legend={t('shop.services')}>
          <ShopServiceSelect />
        </Fieldset>

        <ConfirmationDialog />
        <DefaultFormActions
          formState={formState}
          getValues={getValues}
          reset={reset}
        />
      </Form>
    </>
  );
});

export default EditShopForm;
