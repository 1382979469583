import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import { formatPrice } from './ProductFormatPrice';
import ContentLink from './ContentLink';

const styles = {
  cell: {
    width: '20%',
  },
};

function ProductAttributePrice({
  price, currency, classes, referenceUnit, project, canSeePricings,
}) {
  if (!price) return null;

  return ((
    <TableRow>
      <TableCell className={classes.cell}>
        {canSeePricings &&
          <ContentLink
            to={`/${project}/pricing/categories/id/${price.category}`}
          >
            {price.category}
          </ContentLink>
        }
        {!canSeePricings &&
          price.category
        }
      </TableCell>
      <TableCell className={classes.cell}>
        {formatPrice(price.listPrice, currency, referenceUnit)}
      </TableCell>
      <TableCell className={classes.cell}>
        {formatPrice(price.discountedPrice, currency, referenceUnit) || '–'}
      </TableCell>
      <TableCell className={classes.cell}>
        {formatPrice(price.customerCardPrice, currency, referenceUnit) || '–'}
      </TableCell>
      <TableCell className={classes.cell}>{price.basePrice || '–'}</TableCell>
    </TableRow>
  ));
}

export default withStyles(styles)(ProductAttributePrice);
