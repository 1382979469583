import React, { ReactNode } from 'react';

export interface UnorderedListProps {
  children: ReactNode[];
  defaultValue?: ReactNode;
}

export default function UnorderedList({
  children,
  defaultValue,
}: UnorderedListProps) {
  if (!children?.length) return defaultValue || '-/-';

  return (
    <ul>
      {children.map(c => (<li>{c}</li>))}
    </ul>
  );
}
