import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import CodeWithTemplate from './CodeWithTemplate';

const styles = theme => ({
  barcodes: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(20),
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
  cell: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down(700)]: {
      border: 0,
    },
  },
});

function hasScannableBarCodes(codes, codeTemplates) {
  if (!codeTemplates) return false;

  let notScannableLen = 0;
  (codes || []).forEach((code) => {
    const template = codeTemplates[code.template];
    // do not show barcodes which will not work while testing app
    if (!template || template.indexOf('ec') !== -1 || template.indexOf('embed') !== -1 || template.indexOf('{i}') !== -1) {
      notScannableLen += 1;
    }
  });

  return codes.length > notScannableLen;
}

class EanCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasGeneratedBarcodes: hasScannableBarCodes(props.codes, props.codeTemplates),
    };
  }

  render() {
    const {
      classes, codes, visible, codeTemplates,
    } = this.props;
    const hasCodes = codes && codes.length;

    const noEan = (
      <TableCell className={classes.cell}>
        <div className={classes.barcodes}>
          <Typography variant="caption">- <Translate id="product.noEan" /> -</Typography>
        </div>
      </TableCell>);

    if (visible && !hasCodes) {
      return noEan;
    }

    if (visible && !this.state.hasGeneratedBarcodes) {
      return noEan;
    }

    if (!visible || !hasCodes) {
      return null;
    }

    return (
      <TableCell className={classes.cell}>
        {codes.map(c => (
          <CodeWithTemplate
            codeTemplates={codeTemplates}
            code={c.code}
            barcodesOnly
            template={c.template}
            key={c.code}
        />
    ))}
      </TableCell>
    );
  }
}

export default withStyles(styles)(EanCell);
