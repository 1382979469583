import React, { useCallback, useState } from 'react';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectConfigApi from './useProjectConfigApi';
import EditProjectConfigForm from './EditProjectConfigForm';
import useProjectNavigate from '../useProjectNavigate';

export default function EditProjectConfigView() {
  const navigate = useProjectNavigate();
  const api = useProjectConfigApi();
  const [etag, setEtag] = useState('');

  const fetch = useCallback(async () => {
    const { projectConfig, responseEtag } = await api.getProjectConfig(null);
    setEtag(responseEtag);
    return projectConfig;
  }, [api]);

  const navigateToViewForm = useCallback(() => {
    navigate('/project-config');
  }, [navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.viewProjectConfig" />

      <ResourceFormView
        actionName="editProjectConfig"
        Form={EditProjectConfigForm}
        fetch={fetch}
        submit={projectConfig => api.updateProjectConfig({ projectConfig, etag })}
        onSubmitted={navigateToViewForm}
      />
    </>
  );
}
