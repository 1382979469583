import React from 'react';
import Stack from '@mui/material/Stack';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Order } from './orderTypes';
import ContentLink from '../components/ContentLink';
import TranslatedTooltip from '../components/i18n/TranslatedTooltip';
// @ts-ignore
import { ReactComponent as TypeSCO } from '../images/type-sco.svg';
import TextWithSubText from '../components/TextWithSubText';

export interface CheckeoutDeviceInfoProps {
  appUserID: Order['appUserID'];
  checkoutDeviceID: Order['checkoutDeviceID'];
  checkoutDevice: Order['checkoutDevice'];
  project: Order['project'];
}

export default function CheckoutDeviceInfo({
  appUserID,
  checkoutDeviceID,
  checkoutDevice,
  project,
}: CheckeoutDeviceInfoProps) {
  const isAppUser = !!appUserID;
  const isPosOrSco = !!checkoutDeviceID;
  const isPOS = checkoutDevice?.type === 'pos';
  const isSCO = checkoutDevice?.type === 'sco' || checkoutDevice?.type === 'sco-ng';

  const shortAppUserID = appUserID?.slice(0, 8);
  const shortCheckoutDeviceID = checkoutDeviceID?.slice(0, 8);

  // if there is no app user show the checkout device with more information (name)
  if (!isAppUser && isPosOrSco) {
    return (
      <Stack direction="column" alignItems="flex-start">
        <ContentLink to={`/${project}/checkout-devices/id/${checkoutDeviceID}`}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            {isPOS && <TranslatedTooltip title="pos"><PointOfSaleIcon /></TranslatedTooltip>}
            {isSCO && <TranslatedTooltip title="sco"><TypeSCO /></TranslatedTooltip>}
            {isPosOrSco && (
            <TextWithSubText
              text={shortCheckoutDeviceID}
              subText={checkoutDevice?.name}
            />
            )}
          </Stack>
        </ContentLink>
      </Stack>
    );
  }

  return (
    <Stack direction="column" alignItems="flex-start">
      <Stack direction="row" alignItems="center">
        {isAppUser && <TranslatedTooltip title="app"><PhoneIphoneIcon /></TranslatedTooltip>}
        {isAppUser && shortAppUserID}
      </Stack>

      <ContentLink to={`/${project}/checkout-devices/id/${checkoutDeviceID}`}>
        <Stack direction="row" alignItems="center">
          {isPOS && <TranslatedTooltip title="pos"><PointOfSaleIcon /></TranslatedTooltip>}
          {isSCO && <TranslatedTooltip title="sco"><TypeSCO /></TranslatedTooltip>}
          {isPosOrSco && shortCheckoutDeviceID}
        </Stack>
      </ContentLink>

      {!isAppUser && !isPOS && !isSCO && !isPosOrSco && <div>-/-</div>}
    </Stack>
  );
}
