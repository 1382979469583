import React from 'react';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Add from '@mui/icons-material/Add';

const styles = theme => ({
  withMargin: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class ButtonAdd extends React.PureComponent {
  render() {
    const { onClick, classes, withMargin } = this.props;

    return (
      <Button
        className={withMargin ? classes.withMargin : ''}
        variant="contained"
        color="primary"
        onClick={onClick}>
        <Add />
      </Button>
    );
  }
}

export default withStyles(styles)(ButtonAdd);
