import { connect } from 'react-redux';
import ProductView from '../components/ProductView';
import { fetchProduct, resetProduct } from '../actions';

const ProductViewContainer = connect(
  ({
    projectCurrency, fetchingProduct, product, codeTemplates, navigation,
  }) => ({
    projectCurrency,
    fetchingProduct,
    product,
    codeTemplates,
    canSeePricings: navigation.pricingCategories && navigation.pricingCategories.read,
  }),
  dispatch => ({
    fetchProduct: sku => dispatch(fetchProduct(sku)),
    resetProduct: () => dispatch(resetProduct()),
  }),
)(ProductView);

export default ProductViewContainer;
