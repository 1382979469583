import React, { ReactNode } from 'react';

export interface AnchoredDialogProps {
  Dialog: ReactNode;
  dialogOpen?: boolean;
  anchorDialogToTop?: boolean;
  children: ReactNode;
}

export default function AnchoredDialog({
  Dialog,
  dialogOpen,
  anchorDialogToTop = false,
  children,
}: AnchoredDialogProps) {
  return (
    <div style={{ position: 'relative' }} >
      {children}
      {dialogOpen && (
        <>
          <div style={{
            position: 'absolute',
            borderRadius: '4px',
            inset: '0',
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          }}
          />
          <div style={{
            backgroundColor: 'white',
            position: 'absolute',
            borderRadius: '4px',
            top: anchorDialogToTop ? '32px' : '50%',
            left: '50%',
            transform: anchorDialogToTop ? 'translate(-50%, 0)' : 'translate(-50%, -50%)',
            zIndex: 101,
          }}
          >
            {Dialog}
          </div>
        </>
      )}
    </div>
  );
}
