import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import EditOrganizationView from './EditOrganizationView';

function OrganizationConfigRoutes() {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="userManagement">
      <Routes>
        <Route path="/" element={<RouteWrapper><EditOrganizationView /></RouteWrapper>} />
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default OrganizationConfigRoutes;
