import { createContext } from 'react';
import { ApiClientFactory } from '../ApiClientFactory';

export interface ApiClientFactoryContextSchema {
  factory: ApiClientFactory | null;
}

export default createContext<ApiClientFactoryContextSchema>({
  factory: null,
});
