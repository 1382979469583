import React, { ReactNode } from 'react';
import { Panel, PanelHeader, PanelBody } from '../panel';
import FieldStack from './FieldStack';
import { useResourceTranslator } from '../resource';

export interface FieldsetProps {
  category?: string;
  children: ReactNode;
  legend?: string;
}

export default function Fieldset({
  category,
  children,
  legend,
}: FieldsetProps) {
  const t = useResourceTranslator();
  const hasHeader = Boolean(category || legend);

  return (
    <Panel>
      {hasHeader && (
        <PanelHeader>
          {legend ?? t(`form.fieldsets.${category}`, { defaultValue: legend })}
        </PanelHeader>
      )}

      <PanelBody>
        <FieldStack>
          {children}
        </FieldStack>
      </PanelBody>
    </Panel>
  );
}
