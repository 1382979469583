import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import CreateUserView from './CreateUserView';
import ListUserView from './ListUserView';
import EditUserView from './EditUserView';
import ChangePasswordView from './ChangePasswordView';
import { Access } from '../access';

function UserManagementRoutes({ access }: { access?: Access }) {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="userManagement">
      <Routes>
        {access?.read &&
          <>
            <Route path="/" element={<RouteWrapper><ListUserView /></RouteWrapper>} />
          </>
        }
        {access?.write &&
        <>
          <Route path="/change-password" element={<RouteWrapper><ChangePasswordView /></RouteWrapper>} />
          <Route path="/new" element={<RouteWrapper><CreateUserView /></RouteWrapper>} />
          <Route path="/:realmID/:userID/edit" element={<RouteWrapper><EditUserView /></RouteWrapper>} />
        </>
        }
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default UserManagementRoutes;
