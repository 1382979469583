import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export default createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#07b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
}));
