import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Translate from './i18n/Translate';
import { ApplicationState } from '../reducers/initialState';

const redirectToLocation = (e: React.MouseEvent, location: string) => {
  e.preventDefault();
  window.location.assign(location ?? '/login');
};

export default function RestrictedProjectDialog() {
  const restrictedProject = useSelector<ApplicationState>(state => state.restrictedProject);
  const authorized = useSelector<ApplicationState>(state => state.authorized);
  const fetchingProjects = useSelector<ApplicationState>(state => state.fetchingProjects);
  const fetchingNavigation = useSelector<any>(state => state.fetchingNavigation);
  const fetchingInitialShops = useSelector<ApplicationState>(state => state.fetchingInitialShops);
  const projects: Array<any> = useSelector<ApplicationState, Array<any>>(state => state.projects);

  const notAuthorized = authorized === false;
  const fetchingData = fetchingNavigation || fetchingProjects || fetchingInitialShops;

  if (fetchingData) {
    return null;
  }

  if (!restrictedProject && !notAuthorized) {
    return null;
  }

  // Redirect to the old location as default, when the project id
  // is not valid this would cause an infinite redirect loop.
  let location = `/login?backTo=${encodeURIComponent(window.location.href)}`;
  if (restrictedProject) {
    // Redirect to the first project or the project selector, when there are
    // projects but we are not authorized anymore. This could happen
    // when a invalid project id was passed as path param.
    if (projects.length === 1) {
      // Redirect to the correct project if there is only one project
      location = `/${projects[0].id}`;
    } else {
      // Redirect to root when there are no projects, multiple projects to
      // select from, invalid project name or no project permissions.
      location = '/';
    }
  }

  return (
    <Dialog open>
      <DialogTitle>
        <Translate id="project.restrictedHeadline" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="project.restricted" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={e => redirectToLocation(e, location)}>
          <Translate id="project.login" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
