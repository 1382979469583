const CURRENCIES = {
  AUD: {
    currency: 'AUD',
    englishName: 'Australian dollar',
    germanName: 'Australischer Dollar',
    englishCountryNames: [
      'Australia',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Heard Island and McDonald Islands',
      'Kiribati',
      'Nauru',
      'Norfolk Island',
      'Tuvalu',
    ],
    germanCountryNames: [
      'Australien',
      'Kiribati',
      'Nauru',
      'Tuvalu',
    ],
  },
  CAD: {
    currency: 'CAD',
    englishName: 'Canadian dollar',
    germanName: 'Kanadischer Dollar',
    englishCountryNames: [
      'Canada',
    ],
    germanCountryNames: [
      'Kanada',
    ],
  },
  CHF: {
    currency: 'CHF',
    englishName: 'Swiss franc',
    germanName: 'Schweitzer Franken',
    englishCountryNames: [
      'Switzerland',
      'Liechtenstein',
    ],
    germanCountryNames: [
      'Schweiz',
      'Liechtenstein',
    ],
  },
  DKK: {
    currency: 'DKK',
    englishName: 'Danish krone',
    germanName: 'Dänische Krone',
    englishCountryNames: [
      'Denmark',
      'Faroe Islands',
      'Greenland',
    ],
    germanCountryNames: [
      'Dänemark (inkl. Färöer und Grönland)',
    ],
  },
  EUR: {
    currency: 'EUR',
    englishName: 'Euro',
    germanName: 'Euro',
    englishCountryNames: [
      'Åland Islands',
      'Andorra',
      '[c]',
      'Austria',
      'Belgium',
      'Croatia',
      'Cyprus',
      'Estonia',
      'European Union',
      'Finland',
      'France',
      'French Guiana',
      'French Southern and Antarctic Lands',
      'Germany',
      'Greece',
      'Guadeloupe',
      'Ireland',
      'Italy',
      'Kosovo',
      '[d]',
      'Latvia',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Martinique',
      'Mayotte',
      'Monaco',
      '[c]',
      'Montenegro',
      '[d]',
      'Netherlands',
      'Portugal',
      'Réunion',
      'Saint Barthélemy',
      'Saint Martin',
      'Saint Pierre and Miquelon',
      'San Marino',
      '[c]',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Vatican City',
      '[c]',
    ],
    germanCountryNames: [
      'Belgien',
      'Deutschland',
      'Estland',
      'Finnland',
      'Frankreich',
      'Griechenland',
      'Irland',
      'Italien',
      'Kroatien',
      'Lettland',
      'Litauen',
      'Luxemburg',
      'Malta',
      'Niederlande',
      'Österreich',
      'Portugal',
      'Slowakei',
      'Slowenien',
      'Spanien',
      'Zypern',
      'Andorra',
      'Kosovo',
      'Monaco',
      'Montenegro',
      'San Marino',
      'Vatikanstadt',
    ],
  },
  GBP: {
    currency: 'GBP',
    englishName: 'Pound sterling',
    germanName: 'Pfund',
    englishCountryNames: [
      'United Kingdom',
      'Isle of Man',
      'Manx pound',
      'Jersey',
      'Jersey pound',
      'Guernsey',
      'Guernsey pound',
      'Tristan da Cunha',
    ],
    germanCountryNames: [
      'Vereinigtes Königreich',
    ],
  },
  HUF: {
    currency: 'HUF',
    englishName: 'Hungarian forint',
    germanName: 'Forint',
    englishCountryNames: [
      'Hungary',
    ],
    germanCountryNames: [
      'Ungarn',
    ],
  },
  NOK: {
    currency: 'NOK',
    englishName: 'Norwegian krone',
    germanName: 'Norwegische Krone',
    englishCountryNames: [
      'Norway',
      'Svalbard',
      'Jan Mayen',
      'Bouvet Island',
    ],
    germanCountryNames: [
      'Norwegen',
    ],
  },
  USD: {
    currency: 'USD',
    englishName: 'United States dollar',
    germanName: 'US Dollar',
    englishCountryNames: [
      'United States',
      'American Samoa',
      'British Indian Ocean Territory',
      'British Virgin Islands',
      'Caribbean Netherlands',
      'Ecuador',
      'El Salvador',
      'Guam',
      'Marshall Islands',
      'Federated States of Micronesia',
      'Northern Mariana Islands',
      'Palau',
      'Panama',
      'Panamanian Balboa',
      'Puerto Rico',
      'Timor-Leste',
      'Turks and Caicos Islands',
      'U.S. Virgin Islands',
      'United States Minor Outlying Islands',
    ],
    germanCountryNames: [
      'Vereinigte Staaten',
      'Ecuador',
      'El Salvador',
      'Föderierte Staaten von Mikronesien',
      'Marshallinseln',
      'Osttimor',
      'Palau',
    ],
  },
} as const;

export type Currency = keyof typeof CURRENCIES;

export const PREFERRED_CURRENCIES: Currency[] = [
  'USD',
  'EUR',
  'GBP',
  'CHF',
  'DKK',
];

export default CURRENCIES;
