import React from 'react';
import TableCell from '@mui/material/TableCell';
import ColoredTableRow from './ColoredTableRow';
import ContentLink from './ContentLink';

function PricingCategoryTableRow({ category, canEdit }) {
  if (!category) return null;
  const url = `/${category.project}/pricing/categories/id/${category.id}`;
  return (
    <ColoredTableRow>
      <TableCell>
        <ContentLink to={url}>{category.id}</ContentLink>
      </TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>
        {(category.shops || []).map((shop, index) => (
          <React.Fragment key={shop.id}>
            {index !== 0 && ', '}<ContentLink to={`/${category.project}/shops/${shop.id}`}>{shop.label}</ContentLink>
          </React.Fragment>
        ))}
      </TableCell>
      <TableCell style={{ width: '60px' }} align="center">
        <ContentLink to={url} view />
        {canEdit && <ContentLink to={`${url}/edit`} edit />}
      </TableCell>
    </ColoredTableRow>
  );
}

export default PricingCategoryTableRow;

