import React, { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useOrganizationNavigate from '../organizations/useOrganizationNavigate';
import useOrganizationApi, { TemplateProject } from '../organizations/useOrganizationApi';
import CreateProjectForm from './CreateProjectForm';
import useProjectApi, { Project } from '../projects/useProjectApi';

export default function CreateProjectView() {
  const api = useOrganizationApi();
  const projectApi = useProjectApi();
  const navigate = useOrganizationNavigate();
  const { organizationId } = useParams();
  const [templateProjectIds, setTemplateProjectIds] = useState<string[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);

  const handleFetchTemplateProjects = useCallback(async () => {
    if (!organizationId) return {};

    const fetchedProjects = (await projectApi.getProjects(null))?.projects;
    setProjects(fetchedProjects || []);

    const { data } = await api.getOrganization(organizationId);
    setTemplateProjectIds(data.templateProjects || []);

    const project: Partial<TemplateProject> = {
      templateProjectId: data.templateProjects?.length === 1 ? data.templateProjects[0] : undefined,
    };
    return project;
  }, [api, organizationId, projectApi]);

  const handleCreateProject = useCallback(async (projectConfigOverwrite: FieldValues) => {
    if (!organizationId) return;
    await api.createProjectFromTemplate({
      organizationId,
      projectConfigOverwrite: projectConfigOverwrite as TemplateProject,
    });
  }, [api, organizationId]);

  return (
    <>
      <DocumentTitle translationID="organization.project.headline" />
      <ResourceFormView
        actionName="createOrganizationProject"
        Form={CreateProjectForm}
        FormProps={{
          templateProjectIds,
          projects,
        }}
        fetch={handleFetchTemplateProjects}
        submit={handleCreateProject}
        onSubmitted={() => {
          navigate('/organization-projects');
        }}
      />
    </>
  );
}
