import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import FormStatusBar from './FormStatusBar';
import Headline from './Headline';
import NoResult from './NoResult';
import DocumentTitle from './DocumentTitle';
import ButtonRow from './ButtonRow';

const styles = theme => ({
  errorMessage: {
    color: theme.palette.error[500],
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
});

class FormWrapper extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetStatusbar = this.resetStatusbar.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0);
    this.props.onSubmit();
  }

  resetStatusbar() {
    const {
      navigate,
      backUrl,
      isNew,
      newUrl,
      editStatus,
      resetEditStatus,
    } = this.props;

    resetEditStatus();

    if (editStatus === 'saved' && isNew) {
      navigate(newUrl);
    } else if (editStatus === 'deleted') {
      navigate(backUrl);
    }
  }

  render() {
    const {
      classes, serverError, title, notFound, notFoundText, isLoading,
      itemName, isDeleteable, backUrl, onDelete, children,
    } = this.props;

    const onMissingTranslation = () => 'Something went wrong. Please check your input and try again.';

    if (notFound) {
      return (
        <React.Fragment>
          <DocumentTitle translationID={title} />
          <NoResult
            headline={<Translate id={title} />}
            text={notFoundText}
          />
        </React.Fragment>
      );
    }

    if (isLoading) {
      return <SnabbleLinearProgress show />;
    }

    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <Headline>
          <Translate id={title} />
        </Headline>

        <DocumentTitle translationID={title} />

        {serverError !== '' &&
          <Typography className={classes.errorMessage}>
            <Translate id={serverError} options={{ onMissingTranslation }} />
          </Typography>
        }

        {children}

        <ButtonRow
          itemName={itemName}
          showDelete={isDeleteable}
          cancelButtonHref={backUrl}
          handleDelete={onDelete}
        />

        <FormStatusBar
          itemName={itemName}
          reset={this.resetStatusbar}
          status={this.props.editStatus}
        />

      </form>
    );
  }
}

FormWrapper.defaultProps = {
  onDelete: () => { },
  navigate: () => { },
  isDeleteable: false,

};

FormWrapper.propTypes = {
  onDelete: PropTypes.func,
  navigate: PropTypes.func,
  isDeleteable: PropTypes.bool,

};

export default withStyles(styles)(FormWrapper);
