import uniqBy from 'lodash/uniqBy';
import actions from '../actions/constants';

export default (state, action) => {
  switch (action.type) {
    case actions.REQUEST_PRODUCTS:
      return { fetchingProducts: true, fetchingProductsError: false };

    case actions.RECEIVED_PRODUCTS:
      return {
        fetchingProducts: false,
        products: uniqBy(action.payload.result, 'sku'),
        searchTerm: action.payload.searchTerm,
        fetchingProductsError: false,
      };

    case actions.RECEIVED_PRODUCTS_ERROR:
      return { fetchingProducts: false, products: [], fetchingProductsError: true };

    case actions.REQUEST_PRODUCT:
      return { fetchingProduct: true };

    case actions.RECEIVED_PRODUCT:
      return { fetchingProduct: false, product: action.payload };

    case actions.RECEIVED_PRODUCT_STATISTIC:
      return { productStatistic: action.payload };

    case actions.RESET_PRODUCT:
      return { fetchingProduct: false, product: {} };

    default:
      return {};
  }
};
