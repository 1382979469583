import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers/initialState';
import { Access } from './Access';
import { availablePrivileges } from './Privilege';

type NavigationState = Record<string, Access | undefined>;

export default function useAccess(scope: string) {
  const navState = useSelector<ApplicationState, NavigationState>(state => state.navigation);

  return useMemo(
    () => availablePrivileges.reduce((access, privilege) => ({
      ...access,
      [privilege]: navState[scope]?.[privilege] ?? false,
    }), {} as Access),
    [navState, scope],
  );
}
