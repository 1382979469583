import React, { useMemo, useRef } from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import uniqueId from 'lodash/uniqueId';

function FilterSelect({
  label, children, onChange, value,
}) {
  const idRef = useRef(`filter-select-${uniqueId()}`);
  const labelId = useMemo(() => `${idRef.current}-label`, [idRef]);
  const inputId = useMemo(() => `${idRef.current}-input`, [idRef]);

  return (
    <FormControl fullWidth>
      <InputLabel
        id={labelId}
        htmlFor={inputId}
      >
        {label}
      </InputLabel>

      <Select
        labelId={labelId}
        id={inputId}
        value={value || ''}
        label={label}
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default FilterSelect;
