import React from 'react';
import withStyles from '@mui/styles/withStyles';

const styles = {
  container: {
    whiteSpace: 'nowrap',
  },
};

function Percentage({
  value,
  locale,
  classes,
  maximumFractionDigits = 1,
}) {
  const str = Number(value)
    .toLocaleString(locale, { maximumFractionDigits });

  return (<span className={classes.container}>{str} %</span>);
}

export default withStyles(styles)(Percentage);
