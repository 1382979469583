import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import useResourceTranslator from '../resource/useResourceTranslator';
import useOrganizationNavigate from '../organizations/useOrganizationNavigate';

export interface ListProjectsTableProps {
  values: SortableTableDataPoint[];
}

export default function ListProjectsTable({
  values,
}: ListProjectsTableProps) {
  const t = useResourceTranslator();
  const navigate = useOrganizationNavigate();

  const addProject = useCallback(() => {
    navigate('/organization-projects/new');
  }, [navigate]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.id || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.name || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.companyName || '-/-'}
      </TableCell>
    </>
  ), []);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      disablePadding: false,
      displayName: t('organization.project.projectList.id'),
    },
    {
      key: 'name',
      disablePadding: false,
      displayName: t('organization.project.projectList.name'),
    },
    {
      key: 'companyName',
      disablePadding: false,
      displayName: t('organization.project.projectList.companyName'),
    },
  ], [t]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        CustomRowContent={TableRowEntries}
        title=""
        filterPlaceholder={t('organization.project.projectList.filterPlaceholder')}
        labelRowsPerPage={t('organization.project.projectList.labelRowsPerPage')}
      />)
      :
      <Typography><Translate id="organization.project.projectList.empty" /></Typography>
  );

  return (
    <>
      <StatisticPaper
        headline={
          <Translate id="organization.project.headline" />
        }
        total={values?.length}
        additionalHeaderItems={
          <Stack direction="row">
            <Button variant="contained" endIcon={<AddIcon />} onClick={addProject}>
              {t('organization.project.projectList.addProject')}
            </Button>
          </Stack>
        }
      >
        {table}
      </StatisticPaper>
    </>
  );
}
