import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Translation } from 'react-i18next';

const CustomerCardFilter = ({ value, onChange }) => (
  <Translation>
    {translate => (
      <FormControl fullWidth>
        <InputLabel
          id="customer-card-filter-label"
          htmlFor="customer-card-filter-input"
        >
          {translate('customerCardFilter.label')}
        </InputLabel>

        <Select
          labelId="customer-card-filter-label"
          id="customer-card-filter-input"
          value={value || ''}
          label={translate('customerCardFilter.label')}
          onChange={onChange}
        >
          <MenuItem value="">
            <em>{translate('customerCardFilter.all')}</em>
          </MenuItem>
          <MenuItem value="true">
            {translate('customerCardFilter.with')}
          </MenuItem>
          <MenuItem value="false">
            {translate('customerCardFilter.without')}
          </MenuItem>
        </Select>
      </FormControl>
    )}
  </Translation>
);

export default CustomerCardFilter;
