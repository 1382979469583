import { connect } from 'react-redux';
import { fetchCheckoutDevice, fetchCheckoutDevicesStatus, fetchShops } from '../actions';
import CheckoutDeviceView from './CheckoutDeviceView';

const CheckoutDeviceViewContainer = connect(
  ({ checkoutDevices, selectedProjectId, shops }) => ({
    isLoading: checkoutDevices.isFetchingDevice,
    checkoutDevice: checkoutDevices.device,
    canFetchStatus: !!checkoutDevices.device && !!checkoutDevices.device.shop,
    status: checkoutDevices.status
      && checkoutDevices.device
      && checkoutDevices.status[checkoutDevices.device.id],
    shops,
    projectId: selectedProjectId,
  }),
  dispatch => ({
    fetchCheckoutDevice: id => dispatch(fetchCheckoutDevice(id)),
    fetchCheckoutDevicesStatus: shopID => dispatch(fetchCheckoutDevicesStatus(shopID)),
    fetchShops: () => dispatch(fetchShops()),
  }),
)(CheckoutDeviceView);

export default CheckoutDeviceViewContainer;
