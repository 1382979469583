import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Loader } from '@googlemaps/js-api-loader';
import { useTranslation } from 'react-i18next';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import COUNTRY_CODES from './Countries';

const API_KEY = 'AIzaSyCCCMQwVKFdqABX4mGRND1nL-43w8TzPB0';

export enum AddressField {
  STREET = 'route',
  NUMBER = 'street_number',
  ZIP = 'postal_code',
  CITY = 'locality',
  STATE = 'administrative_area_level_1',
  COUNTRY = 'country',
}

export type AddressFieldAutomcompleteSuggestion = google.maps.places.AutocompletePrediction;
export type AddressDetails = google.maps.places.PlaceResult;

export interface Address {
  [AddressField.STREET]: string;
  [AddressField.ZIP]: string;
  [AddressField.CITY]: string;
  [AddressField.STATE]: string;
  [AddressField.COUNTRY]: string;
  country_code: string;
  lat?: number;
  lng?: number;
}

export function mapAddressDetailsToAddress(addressDetails: AddressDetails): Address | undefined {
  if (!addressDetails?.address_components) return undefined;

  let streetNumber: string | undefined;

  const address = {} as Partial<Address>;
  addressDetails.address_components.forEach((component) => {
    if (component.types.includes(AddressField.STREET)) {
      address[AddressField.STREET] = component.long_name;
    } else if (component.types.includes(AddressField.NUMBER)) {
      streetNumber = component.long_name;
    } else if (component.types.includes(AddressField.ZIP)) {
      address[AddressField.ZIP] = component.long_name;
    } else if (component.types.includes(AddressField.CITY)) {
      address[AddressField.CITY] = component.long_name;
    } else if (component.types.includes(AddressField.STATE)) {
      address[AddressField.STATE] = component.long_name;
    } else if (component.types.includes(AddressField.COUNTRY)) {
      address[AddressField.COUNTRY] = component.long_name;
      address.country_code = Object.keys(COUNTRY_CODES).find(code => (
        // code is a key of COUNTRY_CODES so using code as a key of COUNTRY_CODES
        // should work, but no.
        (COUNTRY_CODES as Record<string, any>)[code]['ALPHA-2'] === component.short_name
      ));
    }
  });

  if (address[AddressField.STREET] && streetNumber) {
    address[AddressField.STREET] += ` ${streetNumber}`;
  }

  if (addressDetails.geometry?.location) {
    address.lat = addressDetails.geometry.location.lat();
    address.lng = addressDetails.geometry.location.lng();
  }

  return address as Address;
}

type OnLoadListener = () => void;

export interface CreateMapProps {
  targetElement: HTMLElement;
  zoomLevel?: number;
  center?: {
    lat: number;
    lng: number;
  }
}

export interface MarkerPosition {
  lat: number | string;
  lng: number | string;
  name?: string;
  popupContent?: JSX.Element;
  onDragend?: (lat: number, lng: number) => void;
}

// TODO: function and file should be renamed to useGooglePlacesApi
export default function usePlacesApi() {
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const geocodingService = useRef<google.maps.Geocoder | null>(null);
  // FIXME should be google.maps.MapsLibrary but that does not work
  const MapConstructor = useRef<any | null>(null);
  const MarkerConstructor = useRef<any | null>(null);

  const [onLoadListeners, setOnLoadListeners] = useState<OnLoadListener[]>([]);
  const registerOnLoadListener = useCallback((listener: OnLoadListener) => {
    setOnLoadListeners((prev: OnLoadListener[]) => [...prev, listener]);
    return onLoadListeners.length;
  }, [onLoadListeners]);
  const waitForLibraryLoaded = useCallback(async () => {
    if (autocompleteService.current
      && geocodingService.current
      && MapConstructor.current
      && MarkerConstructor.current) return;
    await new Promise<void>((resolve) => {
      registerOnLoadListener(resolve);
    });
  }, [registerOnLoadListener]);

  const [, i18n] = useTranslation();
  const language = useMemo(() => i18n.resolvedLanguage || 'de', [i18n.resolvedLanguage]);

  useEffect(() => {
    const loadService = async () => {
      // NOTE: Google does not just provide the libraries as a npm package. They still advise to
      // use a script tag. An alternative they provide though is the js-api-loader. For this reason
      // the auto complete service is loaded when the hook is first mounted and stored in the ref.
      const googleLibraryLoader = new Loader({
        apiKey: API_KEY,
        version: 'weekly',
        libraries: ['places', 'geocoding', 'maps', 'marker', 'core'],
        language,
      });
      const { AutocompleteService } = await googleLibraryLoader.importLibrary('places');
      const autocompleteServiceInstance = new AutocompleteService();
      autocompleteService.current = autocompleteServiceInstance;

      const { Geocoder } = await googleLibraryLoader.importLibrary('geocoding');
      const geocoderInstance = new Geocoder();
      geocodingService.current = geocoderInstance;

      const { AdvancedMarkerElement } = await googleLibraryLoader.importLibrary('marker') as google.maps.MarkerLibrary;
      MarkerConstructor.current = AdvancedMarkerElement;

      // NOTE: the cast is suggested by Google for whatever reason
      const { Map, InfoWindow } = await googleLibraryLoader.importLibrary('maps') as google.maps.MapsLibrary;
      const { LatLngBounds } = await googleLibraryLoader.importLibrary('core');
      // That's enough. I am not going to store the references to all the markers and clusters and
      // stuff just to change or delete markers. That system is bs.
      // So here is an extended class that is able to handle markers like a grown up.
      // See Google? Not that hard.
      MapConstructor.current = class extends Map {
        markers = undefined as any[] | undefined;

        cluster = undefined as MarkerClusterer | undefined;

        infoWindow = new InfoWindow();

        setMarkers(markerPositions?: MarkerPosition[] | undefined, fitBounds = false) {
          if (this.cluster) this.cluster.clearMarkers(); // clusters have to be cleared first
          if (this.markers) {
            // markers are removed by setting their reference to the map to null
            // eslint-disable-next-line no-param-reassign
            this.markers.forEach((marker) => { marker.map = null; });
            this.markers = undefined;
          }

          if (!markerPositions) return;
          try {
            const bounds = new LatLngBounds();

            const markers = markerPositions?.map((marker) => {
              if (!marker.lat || !marker.lng) return undefined;
              const markerInstance = new AdvancedMarkerElement({
                // passing an instance of a class to the constructor of a class of a child
                // is not very javascripty.
                map: this,
                position: {
                  lat: parseFloat((marker.lat) as string),
                  lng: parseFloat((marker.lng) as string),
                },
                title: marker.name || '',
                gmpDraggable: !!marker.onDragend,
              });

              if (marker.onDragend) {
                markerInstance.addListener('dragend', (event: any) => {
                  // there is no object invoked that is possible undefined!
                  marker!.onDragend(event.latLng.lat(), event.latLng.lng());
                });
              }

              if (markerInstance.position) bounds.extend(markerInstance.position);

              markerInstance.addListener('click', () => {
                this.infoWindow.close();
                if (marker.popupContent) {
                  const content = renderToStaticMarkup(marker.popupContent);
                  this.infoWindow.setContent(content);
                  this.infoWindow.open(this, markerInstance);
                }
              });

              return markerInstance;
            }).filter(m => !!m) as any;
            if (fitBounds) this.fitBounds(bounds);
            this.markers = markers;
          } catch {
            this.markers = undefined;
          }

          // NOTE: remember when I said that Google does not provide their libraries as npm packages
          // but rather as scripts loaded through their loader package? Well, the MarkerCluster
          // library is only available as a npm package and not through the loader. Even tough it
          // should be part of the same freaking library. The F Google?
          try {
            const cluster = new MarkerClusterer({ markers: this.markers, map: this });
            this.cluster = cluster;
          } catch {
            this.cluster = undefined;
          }
        }

        clearMarkers() {
          this.setMarkers();
        }
      };

      onLoadListeners.forEach(listener => listener());
    };

    try {
      loadService();
    } catch {
      onLoadListeners.forEach(listener => listener());
    }
  // NOTE: the loader cannot be loaded twice. So this effect should only run once.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE cost per call: 0.00283$
  const getAddressFieldSuggestions = useCallback(async (
    input: string,
    field: AddressField | undefined,
  ) => {
    if (!geocodingService.current || !input) return [];

    // NOTE: Google only uses callbacks to return values from their api functions. So these
    // functions are wrapped in a promise
    return new Promise<AddressFieldAutomcompleteSuggestion[]>((resolve) => {
      try {
        autocompleteService.current?.getPlacePredictions({
          input,
          ...(field ? { types: [field] } : {}),
          language,
        }, (suggestion) => {
          resolve(suggestion || []);
        });
      } catch {
        resolve([]);
      }
    });
  }, [language]);

  // NOTE cost per call: 0.005$
  const getAddressDetails = useCallback(async (placeId: string) => {
    if (!geocodingService.current || !placeId) return [];

    // NOTE: Google only uses callbacks to return values from their api functions. So these
    // functions are wrapped in a promise and the resolve function is passed as the callback
    // function.
    return new Promise<AddressDetails[]>((resolve) => {
      try {
        geocodingService.current?.geocode({
          placeId,
          language,
        }, (suggestion) => {
          resolve(suggestion || []);
        });
      } catch {
        resolve([]);
      }
    });
  }, [language]);

  // NOTE cost per call: 0.005$
  const resolveAddress = useCallback(async (address: string) => {
    if (!geocodingService.current || !address) return [];

    // NOTE: Google only uses callbacks to return values from their api functions. So these
    // functions are wrapped in a promise and the resolve function is passed as the callback
    // function.
    return new Promise<AddressDetails[]>((resolve) => {
      try {
        geocodingService.current?.geocode({
          address,
          language,
        }, (suggestion) => {
          resolve(suggestion || []);
        });
      } catch {
        resolve([]);
      }
    });
  }, [language]);

  const createMap = useCallback(({ targetElement, zoomLevel, center }: CreateMapProps) => {
    if (!MapConstructor.current) return null;

    // NOTE MapConstructor.current must be renamed to Map because .current is lowercase and
    // apparently I am not allowed to create instances of that.
    const Map = MapConstructor.current;

    try {
      const map = new Map(targetElement, {
        center: { lat: 0, lng: 0 },
        zoom: zoomLevel || 2,
        ...(center ? { center } : {}),
        mapId: 'google-map',
      });
      return map;
    } catch {
      return null;
    }
  }, []);

  return {
    waitForLibraryLoaded,
    getAddressFieldSuggestions,
    getAddressDetails,
    resolveAddress,
    createMap,
  };
}
