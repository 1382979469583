import React, { useState, useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { matchSorter } from 'match-sorter';
import differenceWith from 'lodash/differenceWith';
import Stack from '@mui/material/Stack';
import { ShopServices, ShopService, getShopServiceFromRelation, ShopServiceLanguage } from '../projectConfig/shopServices';
import useShopApi from './useShopApi';
import SearchableChipSelect from '../components/SearchableChipSelect';
import useResourceTranslator from '../resource/useResourceTranslator';
import useProjectConfigApi from '../projectConfig/useProjectConfigApi';
import ShopServiceIconPreview from '../projectConfig/ShopServiceIconPreview';

function equalServices(a: ShopService, b: ShopService) {
  return a.id === b.id;
}

const servicesSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'id' },
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'translations.de' },
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'translations.en' },
  ],
};

export interface UseShopServiceEditProps {
  shopID: number | undefined;
  language: ShopServiceLanguage;
  onSetDirty: () => void;
}

export const useShopServiceEdit = ({
  shopID,
  language,
  onSetDirty,
}: UseShopServiceEditProps) => {
  const t = useResourceTranslator();

  const projectApi = useProjectConfigApi();
  const shopApi = useShopApi();

  const [availableServices, setAvailableServices] = useState<ShopServices['projectServices']>([]);
  const [initialRelations, setInitialRelations] = useState<ShopServices['projectServices']>([]);
  const [targetRelations, setTargetRelations] = useState<ShopServices['projectServices']>(initialRelations);

  useEffect(() => {
    const fetchRelations = async () => {
      if (!shopID) return;

      const allServices = (await projectApi.getAvailableShopServices(null)).projectServices;
      setAvailableServices(allServices);

      const relations = await shopApi.getShopServiceRelations({ shopID });
      const services = relations.shopServices.map(r => getShopServiceFromRelation(r, allServices));
      setInitialRelations(services);
      setTargetRelations(services);
    };
    fetchRelations();
  }, [projectApi, shopApi, shopID]);

  const handleRemoveService = useCallback((id) => {
    onSetDirty();
    setTargetRelations(tr => tr.filter(s => s.id !== id));
  }, [onSetDirty]);

  const handleAddService = useCallback((id) => {
    onSetDirty();
    const ser = availableServices.find(s => s.id === id);
    if (!ser) return;
    setTargetRelations(tr => [...tr, ser]);
  }, [availableServices, onSetDirty]);

  const submitShopServices = useCallback(async () => {
    if (!shopID) return;

    setInitialRelations(targetRelations);

    const toDelete = differenceWith(
      initialRelations,
      targetRelations,
      equalServices,
    );
    const toAdd = differenceWith(
      targetRelations,
      initialRelations,
      equalServices,
    );

    const promises: Promise<AxiosResponse<any, any>>[] = [];
    toDelete.forEach((s) => {
      promises.push(shopApi.unsetShopServiceRelations({ shopID, serviceID: s.id }));
    });
    toAdd.forEach((s) => {
      promises.push(shopApi.setShopServicRelations({ shopID, serviceID: s.id }));
    });
    await Promise.all(promises);
  }, [initialRelations, shopApi, shopID, targetRelations]);

  const ShopServiceSelect = () => (
    <SearchableChipSelect
      options={availableServices || []}
      sorterOptions={servicesSorterOptions}
      onAdd={handleAddService}
      onRemove={handleRemoveService}
      labelFrom={(option: ShopService | undefined) => {
        if (!option) return null;
        return (
          <Stack direction="row" justifyContent="center" gap={1}>
            <ShopServiceIconPreview service={option} />
            <span>{(option?.translations as any)[language as any]}</span>
          </Stack>
        );
      }}
      valueFrom="id"
      textFieldProps={{
        variant: 'standard',
        label: t('shopServices.selectShopService'),
      }}
      label={t('shop.services')}
      value={targetRelations}
      isOptionEqualToValue={equalServices}
    />
  );

  return {
    ShopServiceSelect,
    submitShopServices,
  };
};
