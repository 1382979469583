import actions from '../actions/constants';
import { mapOrders, mapOrder, prepareOrderStatistic, prepareWeekOnWeekOrderStatistic } from './helper';

export default (state, action) => {
  switch (action.type) {
    case actions.RECEIVED_ORDER_STATISTIC:
      return {
        orderStatistic:
          prepareOrderStatistic(action.payload, state.projectCurrency),
        fetchingOrderStatistic: false,
        range: action.payload.date,
        shopFilter: action.payload.shopID,
        withCustomerCardFilter: action.payload.withCustomerCard,
        paymentMethodsFilter: action.payload.paymentMethods,
      };

    case actions.RECEIVED_ORDER_STATISTIC_ERROR:
      return {
        orderStatistic: null,
        fetchingOrderStatistic: false,
      };

    case actions.REQUEST_ORDER_STATISTIC:
      return { fetchingOrderStatistic: true };

    case actions.RESET_ORDER_STATISTIC:
      return { orderStatistic: null };

    case actions.RECEIVED_LAST_WEEK_ORDER_STATISTIC:
      return {
        orderStatisticLastWeek:
            prepareWeekOnWeekOrderStatistic(action.payload, state.projectCurrency),
        fetchingOrderStatistic: false,
      };

    case actions.REQUEST_ORDERS:
      return { fetchingOrders: true, orders: { list: [], pagination: {} } };

    case actions.RECEIVED_ORDERS:
      return {
        orders: mapOrders(action.payload),
        fetchingOrders: false,
      };

    case actions.REQUEST_LINE_ITEMS_FOR_EXPORT:
      return { fetchingLineItemExport: true };

    case actions.RECEIVED_LINE_ITEMS_FOR_EXPORT: {
      const givenExports = state.lineItemExport || [];
      const lineItemExport = [...givenExports];
      const isInExports = lineItemExport.find(e => e.name === action.payload.name);
      if (!isInExports) {
        const { payload } = action;
        payload.shopLabel = ((state.shops || [])
          .find(shop => shop.id === payload.shop) || {}).label;
        lineItemExport.unshift(payload);
      }
      return {
        fetchingLineItemExport: false,
        lineItemExport,
      };
    }

    case actions.REQUEST_ORDER_EXPORT:
      return { fetchingOrdersExport: true };

    case actions.RECEIVED_ORDER_EXPORT: {
      const givenExports = state.ordersExport || [];
      const ordersExport = [...givenExports];
      const isInExports = ordersExport.find(e => e.name === action.payload.name);
      if (!isInExports) {
        const orderExport = action.payload;
        orderExport.shopLabel = ((state.shops || [])
          .find(shop => shop.id === orderExport.shop) || {}).label;
        ordersExport.unshift(orderExport);
      }
      return {
        ordersExport,
        ordersExportError: null,
        fetchingOrdersExport: false,
      };
    }

    case actions.RECEIVED_ORDER_EXPORT_ERROR:
      return { fetchingOrdersExport: false, ordersExportError: action.payload };

    case actions.REQUEST_ORDER:
      return { fetchingOrder: true, order: {} };

    case actions.RECEIVED_ORDER:
      return {
        order: mapOrder(action.payload),
        fetchingOrder: false,
      };

    default:
      return {};
  }
};
