import { toISOStringInTz } from '../exportOptions';

function dataToBlob(data, info) {
  const payload = Object.assign({}, info);
  if (!data) {
    payload.blob = null;
  } else {
    switch (info.contentType) {
      case 'application/json':
        payload.blob = new Blob(
          [JSON.stringify(data.resources, null, 2)],
          { type: info.mimeType },
        );
        break;
      case 'application/vnd.snabble.dfka+json':
        payload.blob = new Blob(
          [JSON.stringify(data, null, 2)],
          { type: info.mimeType },
        );
        break;
      case 'application/vnd.snabble.dsfinvk+zip':
        payload.blob = data;
        break;
      default:
        payload.blob = new Blob([data], { type: info.mimeType });
        break;
    }
  }
  return payload;
}

function toInformation(baseName, fileInformation, fromDate, toDate, timezone, shop, delimiter) {
  const info = Object.assign({
    fromDate,
    toDate,
    timezone,
    shop,
    name: baseName || '',
  }, fileInformation);

  const query = { timezone };
  if (fromDate) {
    const isoFromDate = toISOStringInTz(fromDate, timezone, false);
    query.from = isoFromDate;
    info.fromDate = isoFromDate;
    info.name += `-from-${isoFromDate.split('T')[0]}`;
  }

  if (toDate) {
    const isoToDate = toISOStringInTz(toDate, timezone, true);
    query.to = isoToDate;
    info.toDate = isoToDate;
    info.name += `-to-${isoToDate.split('T')[0]}`;
  }

  if (timezone) {
    info.name += `-tz-${timezone}`;
  }

  if (shop && shop !== 'all') {
    const shopID = encodeURIComponent(shop);
    if (encodeURIComponent(shop) !== 'all') {
      query.shopID = shopID;
    }
    info.name += `-shop-${shopID}`;
  }

  if (delimiter) {
    const encDelimiter = encodeURIComponent(delimiter);
    info.delimiter = encDelimiter;
    info.name += `-delimiter-${encDelimiter}`;
    query['format.delimiter'] = delimiter;
  }

  if (info.fileEnding) {
    info.name += `.${info.fileEnding}`;
  }

  return { info, query };
}

export { dataToBlob, toInformation };
