import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps } from '@mui/material/Select';
import FieldContainer from './FieldContainer';
import { FieldProps } from './FieldProps';
import useFormLabel from './useFormLabel';
import useFieldIds from './useFieldIds';
import ValidationError from './ValidationError';

export interface SelectFieldProps extends FieldProps<SelectProps> {
  helperText?: ReactNode;
}

export default function SelectField({
  children,
  control,
  disabled,
  helperText,
  multiple,
  name,
  required,
  rules,
  ...props
}: SelectFieldProps) {
  const label = useFormLabel(name, props.label);
  const [inputId, labelId] = useFieldIds(props.id);

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <InputLabel
              id={labelId}
              htmlFor={inputId}
              required={required}
            >
              {label}
            </InputLabel>

            <Select
              labelId={labelId}
              id={inputId}
              value={value || (multiple ? [] : '')}
              label={label}
              onChange={onChange}
              multiple={multiple}
              disabled={disabled}
              variant="outlined"
              required={required}
              {...props}
            >
              {children}
            </Select>

            {error && (
              <FormHelperText>
                <ValidationError error={error} />
              </FormHelperText>
            )}

            {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
          </FormControl>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
