import { AxiosError } from 'axios';
import isAuthorizationError from './isAuthorizationError';

type ResponseErrorInterceptor = (error: AxiosError) => Promise<AxiosError>;

export default function logoutInterceptor(logoutCallback: () => void): ResponseErrorInterceptor {
  return (error: AxiosError): Promise<AxiosError> => {
    if (isAuthorizationError(error)) {
      logoutCallback();
    }
    return Promise.reject(error);
  };
}
