import React from 'react';
import Translate from './i18n/Translate';
import SimpleStateIndicator from './SimpleStateIndicator';

function SupervisingCheckedItemsList({ checkedItems }) {
  if (!checkedItems) return null;
  return (
    <React.Fragment>
      {checkedItems.length === 0 &&
        <SimpleStateIndicator
          useErrorColor
          text={<Translate id="supervising.failed" />}
        />
      }
      {(checkedItems || []).map(item => (
        <SimpleStateIndicator
          key={`rescan-${item.sku}`}
          useGreen={!item.hasFailure}
          useErrorColor
          text={
            item.isInCart ?
              <Translate id="supervising.checkedItem" data={{ name: item.name, foundAmount: item.foundAmount, amount: item.amount }} /> : <Translate id="supervising.missingItem" data={{ name: item.name }} />
          }
        />
      ))}
    </React.Fragment>
  );
}

export default SupervisingCheckedItemsList;
