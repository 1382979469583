import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Percentage from './Percentage';

const styles = theme => ({
  percentage: {
    color: theme.palette.grey[500],
  },
});

function ValueWithPercentage({
  value, percentage, locale, classes,
}) {
  return (
    <span>
      <span>{value}</span>
      &nbsp;
      <span className={classes.percentage}>
        (<Percentage value={percentage} locale={locale} />)
      </span>
    </span>
  );
}

export default withStyles(styles)(ValueWithPercentage);
