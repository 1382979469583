import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import UploadHandler from './UploadHandler';
import useAlert from '../../../toast/useAlert';

interface UploadDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<any> | any;
}

export default function UploadDialog({
  open,
  onClose,
  onUpload,
}: UploadDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();

  const [imgPreviewSrc, setImgPreviewSrc] = useState<string | null>(null);
  const [imgFile, setImgFile] = useState<File | null>(null);
  useEffect(() => {
    setImgPreviewSrc(null);
  }, [open]);

  const handleFileChange = useCallback(async (file: File | null) => {
    if (!file) {
      setImgPreviewSrc(null);
      return;
    }
    setImgPreviewSrc(URL.createObjectURL(file));
    setImgFile(file);
  }, []);

  const handleUpload = useCallback(async () => {
    if (!imgFile) return;
    try {
      await onUpload(imgFile);
    } catch {
      alert.error({ message: t('form.imageField.uploadError') });
    }
  }, [alert, imgFile, onUpload, t]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('form.imageField.uploadDialogTitle')}</DialogTitle>
      <DialogContent>
        {!imgPreviewSrc ? (
          <UploadHandler onFileChange={handleFileChange} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                maxHeight: '600px',
                maxWidth: '80%',
                borderRadius: '5px',
              }}
              alt="preview of uploaded file"
              src={imgPreviewSrc}
            />
          </div>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: '16px' }}
        >
          <Button
            variant="outlined"
            color="error"
            disabled={!imgPreviewSrc}
            onClick={() => { setImgPreviewSrc(null); }}
          >
            <DeleteIcon />
          </Button>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              {t('form.imageField.cancelButton')}
            </Button>
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={!imgPreviewSrc}
              onClick={handleUpload}
            >
              {t('form.imageField.uploadButton')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog >
  );
}
