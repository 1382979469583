import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { matchSorter } from 'match-sorter';
import PowerSettingsNewOutlined from '@mui/icons-material/PowerSettingsNewOutlined';
import DocumentTitle from './DocumentTitle';
import SearchableSelect from './SearchableSelect';
import Translate from './i18n/Translate';
import useProjectApi, { Project, sortProjectsAlphabetically } from '../projects/useProjectApi';
import useOrganizationApi, { Organization } from '../organizations/useOrganizationApi';

const styles = (theme: any) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      width: '100%',
      color: 'inherit',
      textDecoration: 'none',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    maxWidth: theme.spacing(70),
  },
  intro: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  tabs: {
    marginInline: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  select: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: `calc(100% - ${theme.spacing(2) * 2})`,
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerText: {
    width: 'max-content',
    paddingInline: theme.spacing(1),
    backgroundColor: '#fff',
    textTransform: 'uppercase',
    transform: 'translateY(100%)',
  },
  dividerLine: {
    width: '100%',
    color: '#707070',
  },
  logoutButtonContainer: {
    width: `calc(100% - ${theme.spacing(2) * 2})`,
    padding: theme.spacing(2),
    color: '#fff',
  },
});

const sorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

function ProjectSelector({ classes }: any) {
  const navigate = useNavigate();
  const projectApi = useProjectApi();
  const [projects, setProjects] = useState<Project[] | null>(null);
  const organizationsApi = useOrganizationApi();
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const fetchedProjects = await projectApi.getProjects(null);
      const sortedProjects = sortProjectsAlphabetically(fetchedProjects.projects);
      setProjects(sortedProjects);
      const fetchedOrganizations = await organizationsApi.getOrganizations(null);
      setOrganizations(fetchedOrganizations);
    };
    fetch();
  }, [organizationsApi, projectApi]);

  if (!projects || !organizations) return null;

  if (organizations && organizations.length > 0) {
    return (
      <div className={classes.wrapper}>
        <DocumentTitle translationID="project.headline" />
        <Paper className={classes.root} elevation={4}>
          <div className={classes.tabs}>
            <Tabs value={tab} onChange={(_, index) => setTab(index)}>
              <Tab label={<Translate id="project.headline" />} />
              <Tab label={<Translate id="organization.selectOrganization" />} />
            </Tabs>
          </div>

          {
            tab === 0 && (
              <div className={classes.select}>
                <SearchableSelect
                  onChange={(_, project) => { navigate(`/${(project as Project).id}`); }}
                  options={projects || []}
                  labelFrom="name"
                  sorterOptions={sorterOptions}
                />
              </div>
            )
          }

          {
            tab === 1 && (
              <div className={classes.select}>
                <SearchableSelect
                  onChange={(_, organization) => {
                    navigate(`/organizations/${(organization as Organization).id}`);
                  }}
                  options={organizations || []}
                  labelFrom="name"
                  sorterOptions={sorterOptions}
                />
              </div>
            )
          }

          <div className={classes.divider}>
            <div className={classes.dividerText}>or</div>
            <hr className={classes.dividerLine} />
          </div>
          <div className={classes.logoutButtonContainer}>
            <Button href="/login?logout=true" variant="contained" startIcon={<PowerSettingsNewOutlined />}>
              <Translate id="project.supportLogout" />
            </Button>
          </div>
        </Paper >
      </div >
    );
  }

  if (projects.length === 1) {
    return <Navigate to={`/${projects[0].id}`} />;
  }

  if (projects.length > 0) {
    return (
      <div className={classes.wrapper}>
        <DocumentTitle translationID="project.headline" />
        <Paper className={classes.root} elevation={4}>
          <div className={classes.intro}>
            <Typography variant="h5" component="h1">
              <Translate id="project.headline" />
            </Typography>
          </div>

          <div className={classes.select}>
            <SearchableSelect
              onChange={(_, project) => { navigate(`/${(project as Project).id}`); }}
              options={projects}
              labelFrom="name"
              sorterOptions={sorterOptions}
            />
          </div>


          <div className={classes.divider}>
            <div className={classes.dividerText}>or</div>
            <hr className={classes.dividerLine} />
          </div>
          <div className={classes.logoutButtonContainer}>
            <Button href="/login?logout=true" variant="contained" startIcon={<PowerSettingsNewOutlined />}>
              <Translate id="project.supportLogout" />
            </Button>
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Card className={classes.root} elevation={6}>
        <CardContent className={classes.intro}>
          <Typography align="center" variant="h5" component="h2">
            <Translate id="project.supportProjectsNotFound" />
          </Typography>
          <br />
          <Typography align="center" component="p">
            <Translate id="project.supportText" />
          </Typography>
          <Typography align="right" variant="body2" component="p">
            <Translate id="project.supportFooter" />
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button sx={{ mt: 1 }} href="/login?logout=true" variant="contained">
              <Translate id="project.supportLogout" />
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withStyles(styles)(ProjectSelector);
