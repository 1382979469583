import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import JournalView from './JournalView';
import BlockListView from './BlockListView';
import BlockUserView from './BlockUserView';
import { Access } from '../access';

function ShopAccessRoutes({ access }: { access?: Access }) {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="shopAccess">
      <Routes>
        {access?.read &&
          <React.Fragment>
            <Route path="/" element={<RouteWrapper><JournalView /></RouteWrapper>} />
            <Route path="/block-list" element={<RouteWrapper><BlockListView /></RouteWrapper>} />
          </React.Fragment>
        }
        {access?.write &&
          <React.Fragment>
            <Route path="/block-list/new" element={<RouteWrapper><BlockUserView /></RouteWrapper>} />
          </React.Fragment>
        }
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default ShopAccessRoutes;
