import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const ErrorBoxContainer = styled('div')(({ theme }) => ({
  color: theme.palette.error[500],
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

type ErrorBoxProps = { children: React.ReactNode };

function ErrorBox({ children }: ErrorBoxProps) {
  if (!children) return null;

  return (
    <ErrorBoxContainer>
      <Typography>{children}</Typography>
    </ErrorBoxContainer>
  );
}

export default ErrorBox;
