import React, { ReactNode } from 'react';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Collapse from '@mui/material/Collapse';
import Panel from './Panel';
import PanelHeader from './PanelHeader';

export interface CollapsiblePanelProps {
  headline: ReactNode;
  children: ReactNode;
  expanded?: boolean;
  onToggle?: (expanded: boolean) => void;
}

export default function CollapsiblePanel({
  headline,
  children,
  expanded = false,
  onToggle,
}: CollapsiblePanelProps) {
  const handleToggle = () => {
    onToggle?.(!expanded);
  };

  return (
    <Panel>
      <PanelHeader
        extra={
          <>
            {expanded && <ArrowDropUp />}
            {!expanded && <ArrowDropDown />}
          </>
        }
        onClick={handleToggle}
      >
        {headline}
      </PanelHeader>
      <Collapse in={expanded}>
        {children}
      </Collapse>
    </Panel>
  );
}
