import { get, requestApi } from './request';
import actions from './constants';
import { fetchShops } from './index';

function requestPricing() {
  return {
    type: actions.REQUEST_PRICING,
  };
}

function receivedPricing(json) {
  return {
    type: actions.RECEIVED_PRICING,
    payload: json,
  };
}

function fetchPricing(sku) {
  return (dispatch, getState) => {
    dispatch(requestPricing());
    return get(dispatch, getState, `/{project}/pricing/products/sku/${encodeURIComponent(sku)}`, 'application/json')
      .then(
        json => dispatch(receivedPricing(json)),
        () => dispatch(receivedPricing({})),
      );
  };
}

function requestPricingCategories() {
  return {
    type: actions.REQUEST_PRICING_CATEGORIES,
  };
}

function receivedPricingCategories(json) {
  return {
    type: actions.RECEIVED_PRICING_CATEGORIES,
    payload: json,
  };
}

function fetchPricingCategories() {
  return (dispatch, getState) => {
    dispatch(requestPricingCategories());
    return get(dispatch, getState, '/{project}/pricing/categories', 'application/json')
      .then(
        (json) => {
          dispatch(receivedPricingCategories(json));
          const state = getState();
          if (!state.fetchShops && !state.shops.length) {
            dispatch(fetchShops());
          }
        },
        () => dispatch(receivedPricingCategories({})),
      );
  };
}

function requestPricingCategory() {
  return {
    type: actions.REQUEST_PRICING_CATEGORY,
  };
}

function receivedPricingCategory(json) {
  return {
    type: actions.RECEIVED_PRICING_CATEGORY,
    payload: json,
  };
}

function fetchPricingCategory(id) {
  return (dispatch, getState) => {
    if (!id) {
      dispatch(receivedPricingCategory(null));
      return dispatch(fetchShops());
    }

    dispatch(requestPricingCategory());
    return get(dispatch, getState, `/{project}/pricing/categories/id/${encodeURIComponent(id)}`, 'application/json')
      .then(
        (json) => {
          dispatch(receivedPricingCategory(json));
          const state = getState();
          if (!state.fetchShops && !state.shops.length) {
            dispatch(fetchShops());
          }
        },
        () => dispatch(receivedPricingCategory({})),
      );
  };
}

function editPricingCategoryStatusUpdate(newStatus, error) {
  return {
    type: actions.EDIT_PRICING_CATEGORY_STATUS_UPDATE,
    payload: { newStatus, error },
  };
}

function updatePricingCategory(category) {
  return (dispatch, getState) => {
    requestApi(getState, `/{project}/pricing/categories/id/${encodeURIComponent(category.id)}`, 'PUT', JSON.stringify(category))
      .then(
        (r) => {
          const response = r.json();
          if (!r.ok) {
            response.then((json) => {
              dispatch(editPricingCategoryStatusUpdate('savingFailed', json.error));
            });
          } else {
            response.then((json) => {
              dispatch(receivedPricingCategory(json));
            });
            dispatch(editPricingCategoryStatusUpdate('saved'));
          }
        },
        () => dispatch(editPricingCategoryStatusUpdate('savingFailed', '')),
      );
  };
}

function deletePricingCategory(category) {
  return (dispatch, getState) => {
    requestApi(getState, `/{project}/pricing/categories/id/${encodeURIComponent(category)}`, 'DELETE')
      .then(
        (response) => {
          if (response.ok) {
            dispatch(editPricingCategoryStatusUpdate('deleted'));
          } else {
            dispatch(editPricingCategoryStatusUpdate('deletingFailed', response));
          }
        },
        err => dispatch(editPricingCategoryStatusUpdate('deletingFailed', err)),
      );
  };
}

export {
  fetchPricing, fetchPricingCategories,
  updatePricingCategory, editPricingCategoryStatusUpdate,
  deletePricingCategory, fetchPricingCategory,
};
