import { useEffect } from 'react';
import forOwn from 'lodash/forOwn';
import {
  useForm,
  UseFormProps,
  FieldValues,
  ErrorOption,
} from 'react-hook-form';

export interface UseEnhancedFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends UseFormProps<TFieldValues, TContext> {
  errors?: Record<string, ErrorOption>
}

export default function useEnhancedForm({
  errors,
  ...options
}: UseEnhancedFormProps = {}) {
  const form = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    ...options,
  });

  const { clearErrors, setError } = form;

  useEffect(() => {
    forOwn(errors, (error, field) => {
      setError(field, error);
    });
  }, [errors, clearErrors, setError, options]);

  return form;
}
