import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
import useShopApi, { Shop } from './useShopApi';
import ShopListTable from './ShopListTable';
import Headline from '../components/Headline';
import Translate from '../components/i18n/Translate';
import useResourceTranslator from '../resource/useResourceTranslator';
import { ApplicationState } from '../reducers/initialState';
import Map from '../googlePlacesApi/Map';
import MapPopupContent from './MapPopupContent';

export default function ShopListView() {
  const api = useShopApi();
  const { projectId } = useParams();
  const t = useResourceTranslator();
  const writePermission =
    useSelector<ApplicationState, boolean>(state => !!(state.navigation as any)?.shops?.write);
  const [fetchedShops, setFetchedShops] = useState<Shop[]>([]);

  const handleFetch = useCallback(async () => {
    let shops = await api.getShops(null);
    shops = shops.map(shop => ({
      ...shop,
      // NOTE add the address as it is displayed so it can be searched
      searchableAddress: `${shop.street} ${shop.zip} ${shop.city} ${shop.state}`,
    }));
    setFetchedShops(shops);
    return shops;
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="shop.headline" />

      {writePermission ? (
        <Headline to={`/${projectId}/shops/new`} linkText={t('shop.add')} withLink>
          <Translate id="shop.headline" />
        </Headline>
      ) : (
        <Headline>
          <Translate id="shop.headline" />
        </Headline>
      )}

      <ResourceChartView
        Chart={ShopListTable}
        ChartProps={{
          writePermission,
        }}
        fetch={handleFetch}
      />

      {fetchedShops.length > 0 && (
        <Map
          markers={fetchedShops.map(shop => ({
            lat: shop.lat,
            lng: shop.lon,
            name: shop.name,
            popupContent: <MapPopupContent shop={shop} />,
          }))}
          autoFitBounds
          style={{ marginTop: '32px' }}
        />
      )}
    </>
  );
}
