import React, { Fragment, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { Control, FieldError, useController, useFieldArray } from 'react-hook-form';
import isFunction from 'lodash/isFunction';

export type Value = any | (() => any) | undefined;

type RenderProps = (
  index: number,
  add: () => void,
  remove: () => void,
) => any;

export interface ArrayScaffoldProps {
  control: Control;
  name: string;
  defaultValue: any | (() => any);
  render: RenderProps;
  getKey?: (name: string, index: number) => string | undefined;
  placeholder?: ReactNode;
  rules?: Record<string, any>;
  onError?: (error: FieldError | undefined) => void;
}

export interface ArrayScaffoldRef {
  add: (value?: Value) => void;
  remove: (index: number) => void;
}

function resolveValue(value: Value) {
  return isFunction(value) ? value() : value;
}

const ArrayScaffold = forwardRef<ArrayScaffoldRef, ArrayScaffoldProps>(({
  control,
  name,
  defaultValue,
  render,
  getKey,
  placeholder,
  rules,
  onError,
}, ref) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    rules,
  });

  const { fieldState: { error } } = useController({
    control,
    name,
  });

  useEffect(() => {
    onError?.(error);
  }, [error, onError]);

  const add = useCallback((value?: Value) => {
    append(resolveValue(value) || resolveValue(defaultValue));
  }, [append, defaultValue]);

  useImperativeHandle(ref, () => ({
    add(value?: Value) {
      add(value);
    },
    remove(index: number) {
      remove(index);
    },
  }));

  if (!!placeholder && !fields.length) {
    return (
      <>
        {placeholder}
      </>
    );
  }

  return (
    <>
      {fields.map((_: any, index: number) => (
        // NOTE no index in key does not make sense for an array wrapper
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={getKey?.(name, index) || `${name}.${index}`}>
          {render(
            index,
            add,
            () => { remove(index); },
          )}
        </Fragment >
      ))}
    </>
  );
});

export default ArrayScaffold;
