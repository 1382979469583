import React from 'react';
import moment from 'moment';
import { Translation } from 'react-i18next';
import Translate from '../components/i18n/Translate';
import SnabbleLinearProgress from '../components/SnabbleLinearProgress';
import PaperTable from '../components/PaperTable';
import DocumentTitle from '../components/DocumentTitle';
import Headline from '../components/Headline';
import PaperTableRow from '../components/PaperTableRow';
import ContentLink from '../components/ContentLink';
import NoResult from '../components/NoResult';
import removeProject from '../project';


class ClosingScheduleDetails extends React.Component {
  componentDidMount() {
    this.props.fetchClosingSchedule(this.props.id);
  }

  render() {
    const {
      schedule, isLoading, id, canEdit,
    } = this.props;
    return (
      <React.Fragment>
        <SnabbleLinearProgress show={isLoading} />
        <DocumentTitle translationID="scheduledClosings.navigation" />

        {!schedule &&
          <NoResult
            headline={<Translate id="scheduledClosings.navigation" />}
            text={<Translate id="scheduledClosings.singleNotFound" data={{ id }} />}
          />
        }

        {!!schedule &&
          <Translation >
            { translate => (
              <React.Fragment>
                <Headline withLink={canEdit} to="edit" linkText={translate('scheduledClosings.edit')}>
                  {translate('scheduledClosings.headlineSingle')}
                </Headline>
                <PaperTable headline={translate('scheduledClosings.tableHeadline')}>
                  <PaperTableRow label={translate('scheduledClosings.id')} value={schedule.id} />

                  <PaperTableRow
                    label={translate('scheduledClosings.shop')}
                    value={
                      <ContentLink to={`/${schedule.project}/shops/${schedule.shop}`}>
                        {schedule.shopInformation?.label || schedule.shop}
                      </ContentLink>
                } />

                  <PaperTableRow label={translate('scheduledClosings.time')} value={`${moment(`${schedule.hour}:${schedule.minute}`, 'HH:mm').format('HH:mm')} ${schedule.location}`} />

                  <PaperTableRow
                    label={translate('scheduledClosings.days')}
                    value={(schedule.days || []).map((day, i) => (<React.Fragment key={day}>{i !== 0 && ', '}{translate(`days.${day}`)}</React.Fragment>
                  ))} />

                  <PaperTableRow
                    label={translate('scheduledClosings.mode')}
                    value={translate(`scheduledClosings.${schedule.mode}`)} />

                  <PaperTableRow
                    label={translate('scheduledClosings.format')}
                    value={(schedule.formats || [])
                    .map((format, i) => (<React.Fragment key={format}>{i !== 0 && ', '}{translate(`closingFormats.${removeProject(format)}`)}</React.Fragment>
                    ))}
                  />

                  <PaperTableRow
                    label={translate('scheduledClosings.batchFormat')}
                    value={(schedule.batchFormats || [])
                    .map((format, i) => (<React.Fragment key={format}>{i !== 0 && ', '}{translate(`closingFormats.${removeProject(format)}`)}</React.Fragment>
                    ))}
                />

                  <PaperTableRow
                    label={translate('scheduledClosings.batchInterval')}
                    value={schedule.appendBatchEvery} />

                  <PaperTableRow
                    label={translate('scheduledClosings.checkExternalCheckoutIDCompleteness')}
                    value={schedule.checkExternalCheckoutIDCompleteness ?
                      translate('scheduledClosings.active') :
                      translate('scheduledClosings.inactive')} />
                </PaperTable>
              </React.Fragment>

            )}
          </Translation>
        }
      </React.Fragment>
    );
  }
}

export default ClosingScheduleDetails;
