import { useMemo } from 'react';
import useUniqueId from '../../useUniqueId';

// Hook that generates a unique ID for an input and the associated label. The ID
// will not change on rerender. When an `idProp` is specified as first argument,
// this ID is used for the input and a label ID is generated based on it.
export default function useFieldIds(idProp?: string) {
  const defaultId = useUniqueId('form_input_');
  const inputId = useMemo(() => idProp || defaultId, [idProp, defaultId]);
  const labelId = useMemo(() => `${inputId}_label`, [inputId]);
  return [inputId, labelId];
}
