import React from 'react';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import Translate from '../components/i18n/Translate';
import PaperTable from '../components/PaperTable';
import { prependApiHost } from '../urls';
import removeProject from '../project';
import ColoredTableRow from '../components/ColoredTableRow';

const styles = theme => ({
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
});


class ClosingViewLogs extends React.PureComponent {
  download(event, link) {
    const element = event.target;
    if (event.target.href) {
      return;
    }
    fetch(prependApiHost(link), {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    }).then((r) => {
      if (r.status === 200) {
        r.blob().then((b) => {
          element.href = window.URL.createObjectURL(b);
          element.click();
        });
      }
    });
  }

  render() {
    const { logs, classes } = this.props;
    if (!logs || !logs.length) return null;

    return (
      <PaperTable
        headline={<Translate id="closings.logFiles" />}
        headerAlignment={['left', 'left']}
        tableHeader={[
          <Translate id="closings.logID" />,
          <Translate id="closings.logFormat" />,
          '',
        ]}
      >
        {(logs).map(log => (
          <ColoredTableRow key={log.id}>
            <TableCell className={classes.keyCell}>{log.id}</TableCell>
            <TableCell
              className={classes.keyCell}
            >
              <Translate id={`closingFormats.${removeProject(log.format)}`} />
            </TableCell>
            <TableCell>{log.links && log.links.file &&
              /* eslint-disable */
              <a
                className={classes.link}
                onClick={event => this.download(event, log.links.file.href)}
                name={log.id}
                download={log.id}
              >
                <Translate id="closings.download" />
              </a>
            }</TableCell>
          </ColoredTableRow>
        ))}
      </PaperTable>
    );
  }
}

export default withStyles(styles)(ClosingViewLogs);
