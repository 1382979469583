import React, { Component } from 'react';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Translate from './i18n/Translate';
import DeleteButton from '../button/DeleteButton';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  buttonMargin: {
    marginLeft: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
});

class ButtonRow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick() {
    const { props } = this;
    props.handleDelete();
  }

  render() {
    const { props } = this;
    const { classes } = props;

    const deleteButton = (<DeleteButton onConfirm={this.handleDeleteClick} />);

    const cancelButtonWithLink = (
      <Link to={props.cancelButtonHref || '/'} className={classes.link}>
        <Button variant="contained" color="secondary" className={classes.buttonMargin}>
          <Translate id="button.cancel" />
        </Button>
      </Link>
    );

    return ((
      <div className={classes.container}>
        <div>{props.showDelete ? deleteButton : <span />}</div>
        <div style={{ textAlign: 'right' }}>
          <span>{cancelButtonWithLink}</span>
          <Button variant="contained" color="primary" className={classes.buttonMargin} type="submit">
            <Translate id="button.save" />
          </Button>
        </div>
      </div>
    ));
  }
}

export default withStyles(styles)(ButtonRow);
