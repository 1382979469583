import React from 'react';
import { Asset, mapAssetToVariantUrl } from '../assets/useAssetApi';

export interface CategoriesIconDialogGridProps {
  values: Asset[];
  onSelect: (asset: Asset) => void,
}

export default function CategoriesIconDialogGrid({
  values,
  onSelect,
}: CategoriesIconDialogGridProps) {
  // NOTE this is supposed to look like the MUI image grid. But that component is very buggy.
  return (
    <div
      style={{
        display: 'grid',
        width: '100%',
        height: 'calc(50vh - 135px)',
        marginTop: '8px',
        grid: 'auto-flow dense / repeat(auto-fill, 150px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        gap: '16px',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <>
        {values?.map(asset => (
          <button
            key={asset.name}
            style={{
              background: 'unset',
              border: '1px solid #ccc',
              borderRadius: '5px',
              width: '100%',
              aspectRatio: '1 / 1',
              padding: '4px',
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              onSelect(asset);
            }}
          >
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              src={mapAssetToVariantUrl(asset, '1x')}
              alt={asset.name}
              loading="lazy"
            />
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: 1,
                background: 'rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(1px)',
                color: 'white',
                padding: '4px',
              }}
            >
              {asset.name}
            </div>
          </button>
        ))}
      </>
    </div>
  );
}
