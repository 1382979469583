import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';

const styles = (theme: any) => ({
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
});

interface DownloadAsLinkProps {
  blob: Blob | null;
  name: string;
  type: string;
  classes: any;
}

function DownloadAsLink({
  blob,
  name,
  type,
  classes,
}: DownloadAsLinkProps) {
  if (!blob) return <em><Translate id="button.emptyDownload" /></em>;
  return (
    <a data-testid="order-export-download-as-link" href={window.URL.createObjectURL(blob)} className={classes.link} download={name}><Translate id="button.download" data={{ type }} /></a>
  );
}

export default withStyles(styles)(DownloadAsLink);
