import React from 'react';
import Translate from '../components/i18n/Translate';
import Headline from '../components/Headline';
import SnabbleLinearProgress from '../components/SnabbleLinearProgress';
import DocumentTitle from '../components/DocumentTitle';
import ClosingViewGeneral from './ClosingViewGeneral';
import ClosingViewShareByMethods from './ClosingViewShareByMethods';
import ClosingViewLogs from './ClosingViewLogs';
import ClosingViewMessages from './ClosingViewMessages';
import ClosingViewOrders from './ClosingViewOrders';
import ClosingViewTaxShares from './ClosingViewTaxShares';

class ClosingView extends React.Component {
  componentDidMount() {
    const { shop, id } = this.props;
    this.props.fetchClosing(shop, id);
  }

  render() {
    const {
      closing, id, isLoading, token,
    } = this.props;
    if (isLoading || !closing) {
      return (
        <SnabbleLinearProgress show={isLoading} />
      );
    }

    return (
      <React.Fragment>
        <DocumentTitle translationID="closings.navigation" />
        <Headline>
          <Translate id="closings.headlineSingle" data={{ sequenceNumber: id, shop: closing.shop.name }} />
        </Headline>

        <ClosingViewGeneral closing={closing} />

        {closing.state !== 'open' &&
          <React.Fragment>
            <ClosingViewShareByMethods closing={closing} />
            <ClosingViewTaxShares closing={closing} />

            <ClosingViewLogs logs={closing.logs || []} token={token} />
            <ClosingViewMessages messages={closing.messages || []} />
            <ClosingViewOrders closing={closing} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default ClosingView;
