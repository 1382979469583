import React from 'react';
import { Translation } from 'react-i18next';
import FormTableRowMultiSelect from './FormTableRowMultiSelect';
import PaperTable from './PaperTable';
import FormTableRowText from './FormTableRowText';

class PricingCategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || '',
      name: props.name || '',
      shopIds: props.shopIds || [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleShopChange = this.handleShopChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.props.handleChange(name, value);
  }

  handleShopChange(event) {
    const shopIds = event.target.value;
    this.setState({ shopIds });
    this.props.handleChange('shopIds', shopIds);
  }

  render() {
    const {
      shops, idIsDisabled, idMissing,
    } = this.props;
    const { name, id, shopIds } = this.state;

    return (
      <Translation>
        { translate => (
          <PaperTable
            headline={translate('pricingCategory.itemName')}
            hint={`* ${translate('form.required')}`}
          >

            <FormTableRowText
              disabled={idIsDisabled}
              text={translate('pricingCategory.id')}
              propertyName="id"
              property={id}
              handleChange={this.handleChange}
              hasError={idMissing}
              required
            />

            <FormTableRowText
              text={translate('pricingCategory.name')}
              propertyName="name"
              property={name}
              handleChange={this.handleChange}
            />

            <FormTableRowMultiSelect
              label={translate('shop.headline')}
              values={shopIds}
              options={shops}
              onChange={this.handleShopChange}
            />

          </PaperTable>
        )}
      </Translation>
    );
  }
}

export default PricingCategoryForm;
