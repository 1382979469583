import { whichEnvironment } from '../urls';

const SCO_NG_PROJECTS_STAGING : string[] = [
  'test-project-checkout-ns-f112cc40',
  'test-project-telecash-payments-g343fo12',
  'test-project-datatrans-payments-toh8beit',
  'test-project-payone-payments-zee5ooth',
  'test-vpos-a51152',
  'pan-oston-6093v2',
  'pyramid-3429g8',
  'astor-demo-8h19c0nu',
  'test-ieme8a',
  'snabble-supermarket-71u283',
  'heinemann-vie-fc5315',
  'eichhorn-jptadopn',
];

const SCO_NG_PROJECTS_PROD : string[] = [
  'astor-demo-8h19c0nu',
  'snabble-supermarket-71u283',
  'eichhorn-jptadopn',
];

export function projectCanSeeScoNg(projectId: string) {
  const env = whichEnvironment();
  if (env === 'testing') {
    return true;
  } else if (env === 'staging') {
    return SCO_NG_PROJECTS_STAGING.includes(projectId);
  }
  return SCO_NG_PROJECTS_PROD.includes(projectId);
}

const OLD_CATEGORIES_EDITOR_PROJECTS : string[] = [
  'tegut-eixae6',
];

// eslint-disable-next-line import/prefer-default-export
export function projectUsesOldCategoriesEditor(projectId: string) {
  return OLD_CATEGORIES_EDITOR_PROJECTS.includes(projectId);
}
