import React from 'react';
import debounce from 'lodash/debounce';
import Translate from './i18n/Translate';
import {
  toRangeQuery,
  rangeFromQuery,
  customerCardFromQuery,
  paymentMethodsFromQuery,
  toCustomerCardQuery,
  toSearchQuery,
  toShopQuery,
  shopFromQuery,
  toPaymentMethodQuery,
} from '../urls';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import FilterWrapper from './FilterWrapper';
import QuickRangeFilter from './QuickRangeFilter';
import StatisticBar from './StatisticBar';
import StatisticPaper from './StatisticPaper';
import FromToSelector from './FromToSelector';
import CustomerCardFilter from './CustomerCardFilter';
import StatisticSwitch from './StatisticSwitch';
import DocumentTitle from './DocumentTitle';
import { validateStatsTimeRange } from '../ranges';
import PaymentMethodFilter from './PaymentMethodFilter';
import StatisticDownloadButton from './StatisticDownloadButton';
import StatisticToolbar from './StatisticToolbar';
import OrderStateFilterButtonGroup from './statistics/OrderStateFilterButtonGroup';
import orderStatisticQueryString from '../utils/api/orderStatisticQueryString';
import NoResult from './NoResult';
import FilterError from './FilterError';
import StatisticError from './StatisticError';
import SearchableShopSelect from './SearchableShopSelect';

class StatisticBasketAverageView extends React.Component {
  constructor(props) {
    super(props);

    const {
      location,
      defaultRange,
      defaultShop,
      defaultWithCustomerCard,
      defaultPaymentMethods,
    } = props;

    const range = rangeFromQuery(location.search, defaultRange);

    const withCustomerCard = customerCardFromQuery(
      location.search,
      defaultWithCustomerCard,
    );

    this.state = {
      range,
      ...validateStatsTimeRange(range),
      withCustomerCard,
      paymentMethods:
        paymentMethodsFromQuery(location.search) ||
        defaultPaymentMethods,
      shopID: shopFromQuery(location.search) || defaultShop,
      showNet: false,
      revenueHeadline: 'basketStatistic.diagramHeadline',
      orderState: 'final',
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeCustomerCard = this.handleChangeCustomerCard.bind(this);
    this.handleChangeShop = this.handleChangeShop.bind(this);
    this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
    this.handleNetSwitch = this.handleNetSwitch.bind(this);
    this.handleChangeOrderState = this.handleChangeOrderState.bind(this);
    this.handleFetch = this.handleFetch.bind(this);

    this.fetchBasketAverageStatisticDebounced = debounce(
      this.props.fetchBasketAverageStatistic,
      500,
    );
  }

  componentDidMount() {
    const {
      hasError,
      range,
      withCustomerCard,
      shopID,
      paymentMethods,
    } = this.state;

    if (hasError) {
      return;
    }

    this.props.fetchBasketAverageStatistic(
      range.from,
      range.to,
      withCustomerCard,
      shopID,
      paymentMethods,
    );
  }

  componentWillUnmount() {
    this.props.resetBasketAverageStatistic();
  }

  handleChangeRange(range) {
    this.setState({
      range,
      ...validateStatsTimeRange(range),
    }, () => { this.handleFetch(); });
  }

  handleChangeCustomerCard(event) {
    this.setState(
      { withCustomerCard: event.target.value },
      () => { this.handleFetch(); },
    );
  }

  handleChangePaymentMethod(event) {
    this.setState(
      { paymentMethods: event.target.value },
      () => { this.handleFetch(); },
    );
  }

  handleNetSwitch(event) {
    if (!event.target.value) return;
    const { checked } = event.target;
    const newHeadline = checked
      ? 'basketStatistic.diagramHeadlineNet'
      : 'basketStatistic.diagramHeadline';
    this.setState({ showNet: checked, revenueHeadline: newHeadline });
  }

  handleChangeShop(shop) {
    this.setState({ shopID: shop?.id || '' }, () => this.handleFetch());
  }

  handleChangeOrderState(_, orderState) {
    if (!orderState) return;
    this.setState({ orderState });
  }

  handleFetch() {
    const {
      hasError, range, withCustomerCard, shopID, paymentMethods,
    } = this.state;

    if (hasError) {
      return;
    }

    this.fetchBasketAverageStatisticDebounced(
      range.from,
      range.to,
      withCustomerCard,
      shopID,
      paymentMethods,
    );

    this.props.navigate(toSearchQuery({
      ...toRangeQuery(range),
      ...toCustomerCardQuery(withCustomerCard),
      ...toShopQuery(shopID),
      ...toPaymentMethodQuery(paymentMethods),
    }));
  }

  render() {
    const { basketAverageStatistic, showLoadingIndicator } = this.props;
    const {
      showNet,
      revenueHeadline,
      orderState,
    } = this.state;

    const availablePaymentMethods = basketAverageStatistic?.availablePaymentMethods || [];
    const selectedStatistic = basketAverageStatistic?.[orderState] || {};

    let resultContainer;
    if (!basketAverageStatistic) {
      if (showLoadingIndicator) {
        resultContainer = null;
      } else {
        resultContainer = <StatisticError />;
      }
    } else if (selectedStatistic.count > 0) {
      resultContainer = (
        <React.Fragment>
          <StatisticPaper
            headline={
              <Translate id={revenueHeadline} data={{ currency: '' }} />
            }
            from={basketAverageStatistic.date.from}
            to={basketAverageStatistic.date.to}
            total={
              showNet
                ? selectedStatistic.totalNetSum
                : selectedStatistic.totalSum
            }
            isLoading={showLoadingIndicator}
            additionalHeaderItems={
              <StatisticSwitch
                label={
                  <Translate
                    id="orderStatistic.revenueNet"
                    data={{ currency: '' }}
                  />
                }
                onChange={this.handleNetSwitch}
              />
            }
          >
            {!showNet && (
              <StatisticBar
                withRegression
                values={selectedStatistic.total}
                maxValue={selectedStatistic.maxTotal}
                xLabelAsDate
                translationLabelYKey="basketStatistic.diagramHeadline"
                translationData={{
                  currency: selectedStatistic.currency,
                }}
              />
            )}

            {showNet && (
              <StatisticBar
                withRegression
                values={selectedStatistic.totalNet}
                maxValue={selectedStatistic.maxTotal}
                xLabelAsDate
                translationLabelYKey="basketStatistic.diagramHeadlineNet"
                translationData={{
                  currency: selectedStatistic.currency,
                }}
              />
            )}
          </StatisticPaper>

          <StatisticToolbar>
            <StatisticDownloadButton
              type="basketAverage"
              params={this.state}
              queryStringBuilder={orderStatisticQueryString}
              disabled={this.state.hasError}
            />
          </StatisticToolbar>
        </React.Fragment>
      );
    } else {
      resultContainer = (
        <NoResult text={<Translate id="statistic.empty" />} />
      );
    }

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={showLoadingIndicator} />
        <DocumentTitle translationID="basketStatistic.headline" />
        <FilterWrapper
          headline={<Translate id="basketStatistic.headline" />}
          handleRefresh={this.handleFetch}
          error={
            <FilterError id={this.state.error} data={this.state.errorData} />
          }
          topBarChildren={
            <OrderStateFilterButtonGroup
              onChange={this.handleChangeOrderState}
              value={orderState}
            />
          }
        >
          <FromToSelector
            range={this.state.range}
            hasError={this.state.hasError}
            onChange={this.handleChangeRange}
            emitFilled
          />
          <QuickRangeFilter
            range={this.state.range}
            onChange={this.handleChangeRange}
          />
          <CustomerCardFilter
            value={this.state.withCustomerCard}
            onChange={this.handleChangeCustomerCard}
          />
          <SearchableShopSelect
            onChange={this.handleChangeShop}
            defaultShopId={this.state.shopID}
            showLabel
          />
          <PaymentMethodFilter
            value={this.state.paymentMethods}
            paymentMethods={availablePaymentMethods}
            onChange={this.handleChangePaymentMethod}
          />
        </FilterWrapper>

        {resultContainer}
      </React.Fragment>
    );
  }
}

export default StatisticBasketAverageView;
