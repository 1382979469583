import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import SearchableProjectSelect from '../components/SearchableProjectSelect';

const StyledSearchableProjectSelect = withStyles({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      color: 'white',
      paddingLeft: '32px',
      paddingBlock: '4px',
    },
    '& ::selection': {
      backgroundColor: 'white',
      color: '#07b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      '& .MuiSvgIcon-root': {
        fill: 'white',
      },
    },
  },
})(SearchableProjectSelect);

export default function SearchableProjectNavigator() {
  const { projectId } = useParams();

  const handleProjectChange = useCallback((targetProject: any) => {
    const url = `${document.location.origin}/${targetProject.id}`;
    document.location.assign(url);
  }, []);

  return (
    <StyledSearchableProjectSelect
      onChange={handleProjectChange}
      showLabel={false}
      defaultProjectId={projectId}
      sx={{
        color: 'white',
      }}
      fullWidth
      disableClearable
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          multiline
        />
      )}
    />
  );
}
