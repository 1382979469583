import React from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import ProgressButton from '../../button/ProgressButton';
import { useResourceTranslator } from '../../resource';

export type SubmitButtonFormState = Pick<FormState<FieldValues>, 'isSubmitting' | 'isDirty'>;

export interface SubmitButtonProps {
  actionName?: string;
  formState: SubmitButtonFormState;
}

export default function SubmitButton({
  actionName = 'submit',
  formState,
}: SubmitButtonProps) {
  const { isSubmitting, isDirty } = formState;

  const t = useResourceTranslator();

  return (
    <ProgressButton
      disabled={!isDirty}
      loading={isSubmitting}
      variant="contained"
      color="primary"
      type="submit"
    >
      {t(`form.actions.${actionName}`)}
    </ProgressButton>
  );
}
