import { useRef } from 'react';

export interface UseDragAndDropProps {
  onDrop: (draggedElement: HTMLElement, target: HTMLElement | null) => void;
  onDraggedOverClassName?: string;
  onDraggingClassName?: string;
}

function getDragAndDropDraggableElement(e: DragEvent) {
  const draggedOverElement = e.target as HTMLElement;
  const draggedOverDrabbaleElement = draggedOverElement
    .closest('[data-draganddropdraggable]') as HTMLElement;
  return draggedOverDrabbaleElement;
}

export default function useDragAndDrop({
  onDrop,
  onDraggedOverClassName,
  onDraggingClassName,
}: UseDragAndDropProps) {
  const draggedElement = useRef<HTMLElement | null>(null);
  const targetElement = useRef<HTMLElement | null>(null);

  const resetTargetElementAnimation = () => {
    if (!targetElement.current) return;
    if (onDraggedOverClassName) targetElement?.current.classList.remove(onDraggedOverClassName);
    targetElement.current = null;
  };

  const setTargetElementAnimation = (target: HTMLElement) => {
    resetTargetElementAnimation();
    if (onDraggedOverClassName) target?.classList.add(onDraggedOverClassName);
    targetElement.current = target;
  };

  const resetDraggedElementAnimation = () => {
    if (!draggedElement.current) return;
    if (onDraggingClassName) draggedElement.current?.classList.remove(onDraggingClassName);
  };

  const setDraggedElementAnimation = (target: HTMLElement) => {
    resetDraggedElementAnimation();
    if (onDraggingClassName) target?.classList.add(onDraggingClassName);
  };

  const handleDragStart = (e: DragEvent) => {
    const target = getDragAndDropDraggableElement(e);
    draggedElement.current = target;
    setDraggedElementAnimation(target);
  };

  const handleDragEnd = () => {
    resetDraggedElementAnimation();
    resetTargetElementAnimation();
  };

  const handleDragOver = (e: DragEvent) => { e.preventDefault(); };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    const target = getDragAndDropDraggableElement(e);
    setTargetElementAnimation(target);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    resetTargetElementAnimation();
    resetDraggedElementAnimation();
    const target = getDragAndDropDraggableElement(e);
    if (draggedElement.current) onDrop(draggedElement.current, target);
  };

  // this prop must be spread on the draggable elements that can be dragged and reordered.
  // the onDrop prop is called with the dragged element and the nearest other draggable element.
  // to get an identifier for the dragged elements, like an index, a data attribute can be set
  // like data-index={index} and then accessed with draggedElement.dataset.index in the handler
  // e.g. <img {...draggableProps} data-index={index} />
  const draggableProps = {
    draggable: true,
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    'data-draganddropdraggable': true,
  } as any;

  // this prop must be spread on the container element that holds the draggable elements.
  // e.g. <div {...droppableProps} />
  const droppableProps = {
    onDragOver: handleDragOver,
    onDragEnter: handleDragEnter,
    onDrop: handleDrop,
  } as any;

  return {
    draggableProps,
    droppableProps,
  };
}
