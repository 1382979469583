import React from 'react';
import { TOptionsBase } from 'i18next';
import useResourceTranslator from './useResourceTranslator';

export interface ResourceTranslateProps {
  id: string | string[];
  options?: string | TOptionsBase;
}

export default function ResourceTranslate({
  id,
  options,
}: ResourceTranslateProps) {
  const t = useResourceTranslator();

  return <>{t(id, options)}</>;
}
