import { toSearchQuery } from '../../urls';
// @ts-ignore
import getTimezone from '../../actions/timezoneHelper';


export interface FeedbackApiParams {
  range?: { from: any, to: any },
  shopID?: any,
  [key: string]: any,
}

export function feedbackStatisticQueryString({ range, shopID }: FeedbackApiParams): string {
  return toSearchQuery({
    from: range?.from,
    to: range?.to,
    timezone: getTimezone(),
    shopID,
  });
}
