import React, { ReactNode, isValidElement, useMemo } from 'react';
import Chip from '@mui/material/Chip';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Error';
import ErrorIcon from '@mui/icons-material/Cancel';

export const enum State {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type AdvancedStateIndicatorLabelList = Partial<Record<State, string | ReactNode>>;

interface AdvancedStateIndicatorProps {
  state?: State;
  label?: string | ReactNode | AdvancedStateIndicatorLabelList;
  [key: string]: any;
}

export default function AdvancedStateIndicator({
  state = State.Success,
  label,
  ...props
}: AdvancedStateIndicatorProps) {
  const chipColor = useMemo(() => {
    switch (state) {
      case State.Error:
        return 'error';
      case State.Warning:
        return 'warning';
      case State.Success:
      default:
        return 'success';
    }
  }, [state]);

  const ChipIcon = useMemo(() => {
    switch (state) {
      case State.Error:
        return ErrorIcon;
      case State.Warning:
        return WarningIcon;
      case State.Success:
      default:
        return SuccessIcon;
    }
  }, [state]);

  const chipLabel = useMemo<string | ReactNode | undefined>(() => {
    if (typeof label === 'string' || isValidElement(label)) return label;
    return (label as AdvancedStateIndicatorLabelList)?.[state];
  }, [label, state]);

  return (
    <Chip
      color={chipColor}
      icon={<ChipIcon />}
      label={chipLabel}
      size="small"
      {...props}
    />
  );
}
