import {
  defineApi,
  useApiClient,
} from '../api';
import { buildLabelFromArray } from '../utils/stringUtils';

interface Version {
  major: number; // Major
  minor: number; // Minor
}

interface Consent {
  version: Version; // Version
  createdAt: string; // CreatedAt
}

interface PortalAppUserDetails {
  firstName?: string; // FirstName
  lastName?: string; // LastName
  email?: string; // Email
}

interface RequiredField {
  id: string; // ID
}

export interface AppUserWithDetails {
  ID: string; // ID
  currentConsent?: Consent; // CurrentConsent
  phoneNumber?: string; // PhoneNumber
  details?: PortalAppUserDetails; // Details
  requiredFields?: RequiredField[]; // RequiredFields
  createdAt?: string;
  lastModifiedAt?: string;
}

export interface GetAppUsersParams {
  firstNameFilter?: string;
  lastNameFilter?: string;
  phoneNumberFilter?: string;
  emailFilter?: string;
  appUserIdFilter?: string;
  currentPage?: number;
}

export interface UpdateAppUserParams {
  appUserId?: string;
  appUserDetails?: PortalAppUserDetails;
}

const MOCK_APPID = 'tegut-app-eiw8ni';

const useApi = defineApi({
  getAppUsers: async (client, {
    firstNameFilter, lastNameFilter, phoneNumberFilter, emailFilter, appUserIdFilter, currentPage,
  }: GetAppUsersParams) => {
    const firstNameQuery = firstNameFilter ? `firstName=${encodeURIComponent(firstNameFilter)}` : '';
    const lastNameQuery = lastNameFilter ? `lastName=${encodeURIComponent(lastNameFilter)}` : '';
    const phoneNumberQuery = phoneNumberFilter ? `phoneNumber=${encodeURIComponent(phoneNumberFilter)}` : '';
    const emailQuery = emailFilter ? `email=${encodeURIComponent(emailFilter)}` : '';
    const appUserIdQuery = appUserIdFilter ? `appUserID=${encodeURIComponent(appUserIdFilter)}` : '';
    const pageQuery = currentPage ? `page=${currentPage}` : '';
    const query = buildLabelFromArray({ input: [firstNameQuery, lastNameQuery, phoneNumberQuery, emailQuery, appUserIdQuery, pageQuery], separator: '&' });

    const url = `/${MOCK_APPID}/users`;
    const urlWithQuery = query ? `${url}?${query}` : url;

    const { data } = await client.get<any>(urlWithQuery);

    return {
      appUsers: data,
      page: data.pagination.page,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    };
  },
  getAppUser: async (client, appUserId: string | undefined) => {
    if (!appUserId) return null;

    const { data } = await client.get<AppUserWithDetails>(`/${MOCK_APPID}/users/${appUserId}`);
    return data;
  },
  updateAppUser: async (client, { appUserId, appUserDetails }: UpdateAppUserParams) => {
    if (!appUserId) return null;

    const { data } = await client.put<AppUserWithDetails>(`/${MOCK_APPID}/users/${appUserId}/details`, appUserDetails);
    return data;
  },
  eraseAppUser: async (client, appUserId: string | undefined) => {
    if (!appUserId) return null;

    // NOTE this is not a delete that deletes the user itself,
    // but rather a request to clean up the user to conform with the DSGVO
    const { data } = await client.post(`/${MOCK_APPID}/users/${appUserId}/erase`);
    return data;
  },
  getAppUserCSV: async (client) => {
    const { data } = await client.get<string>(`/${MOCK_APPID}/users/export`);
    return data;
  },
});

export default function useAppUserApi() {
  const client = useApiClient({ basePath: '/portal/apps' });
  return useApi(client);
}
