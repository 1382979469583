import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import { SortableTable, ColumnsProp, SortableTableDataPoint } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import useResourceTranslator from '../resource/useResourceTranslator';
import useProjectNavigate from '../useProjectNavigate';

export interface ListUserTableProps {
  values: SortableTableDataPoint[];
  currentRealm?: string;
  currentRealmName?: string;
  isRealmAdmin?: boolean | undefined;
  onOpenRealmAdminDialog?: () => void;
}

export default function ListUserTable({
  values,
  currentRealm,
  currentRealmName,
  isRealmAdmin = false,
  onOpenRealmAdminDialog,
}: ListUserTableProps) {
  const t = useResourceTranslator();
  const navigate = useProjectNavigate();

  const addUser = useCallback(() => {
    navigate('/user-management/new', { state: { currentRealm } });
  }, [currentRealm, navigate]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.name || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.sub || '-/-'}
      </TableCell>
      <TableCell align="left" sx={{ overflowX: 'hidden', overflowWrap: 'anywhere', wordBreak: 'normal' }}>
        {rowData.email || '-/-'}
      </TableCell>
      <TableCell align="left">
        {t(`userManagement.userList.origins.${rowData.origin}`) || '-/-'}
      </TableCell>
      <TableCell align="right">
        {rowData.roles.length || '-/-'}
      </TableCell>
      <TableCell />
    </>
  ), [t]);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'name',
      disablePadding: false,
      displayName: t('userManagement.userList.name'),
    },
    {
      key: 'sub',
      disablePadding: false,
      displayName: t('userManagement.userList.sub'),
    },
    {
      key: 'email',
      disablePadding: false,
      displayName: t('userManagement.userList.email'),
    },
    {
      key: 'origin',
      disablePadding: false,
      displayName: t('userManagement.userList.origin'),
    },
    {
      key: 'projects',
      type: 'numeric',
      disablePadding: false,
      displayName: t('userManagement.userList.numberProjects'),
    },
    {
      type: 'padding',
      disablePadding: false,
    },
  ], [t]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        CustomRowContent={TableRowEntries}
        title=""
        filterPlaceholder={t('userManagement.userList.filterPlaceholder')}
        labelRowsPerPage={t('userManagement.labelRowsPerPage')}
        rowButtons={[
          { icon: <EditIcon />, tooltip: 'editUser', callback: (row) => { navigate(`/user-management/${currentRealm}/${row.id}/edit`); } },
        ]}
      />)
      :
      <Typography><Translate id="userManagement.userList.empty" /></Typography>
  );

  return (
    <>
      <StatisticPaper
        headline={
          <Translate id="userManagement.userList.headline" />
        }
        total={values?.length}
        additionalHeaderItems={
          <Stack direction="row">
            {isRealmAdmin && onOpenRealmAdminDialog && (
              <Button variant="outlined" sx={{ marginRight: '8px' }} endIcon={<AdminPanelSettingsIcon />} onClick={onOpenRealmAdminDialog}>
                {t('userManagement.userList.manageRealmAdmins', { realm: currentRealmName })}
              </Button>
            )}
            <Button variant="contained" endIcon={<PersonAddAlt1Icon />} onClick={addUser}>
              {t('userManagement.userList.addUser')}
            </Button>
          </Stack>
        }
      >
        {table}
      </StatisticPaper>
    </>
  );
}
