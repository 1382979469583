import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import LoadableRegion from '../loader/LoadableRegion';
import useTaxRulesApi, { TaxRuleType, castStringToTaxRuleType } from './useTaxRulesApi';
import EditTaxRuleForm from './EditTaxRuleForm';

export default function EditTaxRuleView() {
  const api = useTaxRulesApi();
  const { t } = useTranslation();
  const navigate = useProjectNavigate();
  const { type, value } = useParams();
  const taxRuleType = useMemo(() => (
    castStringToTaxRuleType(type)
  ), [type]);

  const [otherRules, setOtherRules] = useState<any | null>(null);
  const [lastEtag, setLastEtag] = useState<string | undefined>();

  const availableTaxRules = useMemo(() => (
    Object.values(TaxRuleType).map(rule => ({
      value: rule,
      label: t(`taxRules.value.${rule}`),
    }))
  ), [t]);

  const handleFetchEntry = useCallback(async () => {
    if (!taxRuleType || !value) navigate('/404');

    const { data, etag } = await api.getPermanentTaxRulesSet(null);
    setLastEtag(etag);

    const ruleIndex = data?.[taxRuleType!]?.findIndex(d => d.value === value) ?? -1;
    if (ruleIndex < 0) navigate('/404');
    // split rules into the one that should be edited (rule) and all the other
    // ones the edited rule has to be merged into later since the whole dataset
    // must be PUT to the server for an update
    const rule = data?.[taxRuleType!]?.splice(ruleIndex!, 1)[0];
    setOtherRules(data);

    return { ...rule, type: taxRuleType! };
  }, [api, navigate, taxRuleType, value]);

  const handleSubmit = useCallback(async (rule: FieldValues) => {
    if (!taxRuleType || !lastEtag) return;
    const editedRule = { ...rule };
    delete editedRule.type;

    const rules = structuredClone(otherRules);
    rules[taxRuleType!] = [...rules[taxRuleType!], editedRule];
    await api.updatePermanentTaxRules({ rules, etag: lastEtag });
  }, [api, lastEtag, otherRules, taxRuleType]);

  return (
    <>
      <DocumentTitle translationID="taxRules.headline" />
      <LoadableRegion loading={false}>
        <ResourceFormView
          actionName="editTaxRule"
          Form={EditTaxRuleForm}
          FormProps={{
            availableTaxRules,
          }}
          fetch={handleFetchEntry}
          submit={handleSubmit}
          onSubmitted={() => {
            navigate('/taxRules');
          }}
        />
      </LoadableRegion>
    </>
  );
}
