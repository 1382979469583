import {
  defineApi,
  useApiClient,
} from '../api';
import { Project } from '../projects/useProjectApi';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';

export interface Organization {
  id: string;
  name: string;
  description?: string;
  street?: string;
  city?: string;
  zip?: string;
  country?: string;
  website?: string;
  taxNumber?: string;
  taxID?: string;
  currency?: string;
  templateProjects?: string[];
}

export interface UpdateOrganizationProps {
  organizationData: Organization,
  organizationId: string;
  etag: string;
}

export interface CreateProjectProps {
  projectData: Omit<Project, 'id'> & { idPrefix: string; }
  organizationId: string;
}

export interface TemplateProject extends Omit<Project, 'id'> {
  idPrefix: string;
  templateProjectId: string;
}

export interface CreateProjectFromTemplateProps {
  organizationId: string;
  projectConfigOverwrite: TemplateProject;
}

export class TemplateProjectError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TemplateProjectError';
  }
}

const useApi = defineApi({
  getOrganizations: async (client) => {
    const { data, status } = await client.get<Organization[]>('', {
      validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [403]),
    });
    if (status === 403) return [];
    return data || [];
  },
  getOrganization: async (client, organizationId: string) => {
    const { data, headers } = await client.get<Organization>(`/${organizationId}`);
    return { data, etag: headers.etag };
  },
  updateOrganization: async (client, {
    organizationId,
    organizationData,
    etag,
  }: UpdateOrganizationProps) => {
    await client({
      url: `/${organizationId}`,
      method: 'PUT',
      data: organizationData,
      headers: {
        'If-Match': etag,
      },
    });
  },
  getProjects: async (client, organizationId) => {
    const { data } = await client.get<Project[]>(`/${organizationId}/projects`);
    return data || [];
  },
  createProject: async (client, { projectData, organizationId }: CreateProjectProps) => {
    await client.post(`/${organizationId}/projects`, projectData);
  },
  createProjectFromTemplate: async (client, {
    organizationId,
    projectConfigOverwrite,
  }: CreateProjectFromTemplateProps) => {
    const { templateProjectId, idPrefix, name } = projectConfigOverwrite;
    if (!templateProjectId) throw new TemplateProjectError('Template project id not set');
    if (!idPrefix) throw new TemplateProjectError('Id prefix not set');
    if (!name) throw new TemplateProjectError('Name not set');

    const { data } = await client.get<Project[]>(`/${organizationId}/projects`);
    const templateProject = data?.find(p => p.id === templateProjectId);
    if (!templateProject) throw new TemplateProjectError(`Template project with id ${templateProjectId} not found`);

    const projectData: TemplateProject = {
      ...templateProject,
      ...projectConfigOverwrite,
    };
    delete projectData.id;

    await client.post(`/${organizationId}/projects`, projectData);
  },
});

export default function useOrganizationApi() {
  const client = useApiClient({ basePath: '/organizations' });
  return useApi(client);
}
