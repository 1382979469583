import React from 'react';
import Typography from '@mui/material/Typography';
import Code from './Code';

function toBarcode(template, code) {
  let codeWithTemplate = template.replace(template.match(/\{code([^}]+)\}/g), code);
  let generateBarcode = true;
  const templateParts = codeWithTemplate.match(/\{([^}]+)\}/g);

  (templateParts || []).forEach((part) => {
    if (part.indexOf('ec') !== -1 || part.indexOf('embed') !== -1 || part === '{i}') {
      generateBarcode = false; // do not show barcodes which will not work while testing app
    }

    let len = part.match(/\d+/g);
    len = len ? parseInt(len, 10) : 1;
    codeWithTemplate = codeWithTemplate.replace(part, '0'.repeat(len));
  });

  return { value: codeWithTemplate, show: generateBarcode };
}

class CodeWithTemplate extends React.Component {
  constructor(props) {
    super(props);

    const { code, template, codeTemplates } = props;
    let barcode = null;
    if (codeTemplates && codeTemplates[template] && code) {
      barcode = toBarcode(codeTemplates[template], code);
    }

    this.state = {
      barcode,
    };
  }

  render() {
    const {
      template, barcodesOnly,
    } = this.props;
    const { barcode } = this.state;

    if (!barcode) return null;

    if (!barcode.show && !barcodesOnly) {
      // return filled up code as text
      return <Typography variant="caption">{barcode.value}</Typography>;
    }

    if (!barcode.show && barcodesOnly) {
      return null;
    }

    return <Code code={barcode.value} forceITF={template === 'ikea_itf14'} />;
  }
}

export default CodeWithTemplate;
