import { useMemo } from 'react';
import {
  defineApi,
  useApiClient,
} from '../api';
import useUserManagementApi, { OrganizationRole, Permission, User } from '../userManagement/useUserManagementApi';

export class MissingOrganizationPermissionError extends Error {
  constructor() {
    super('User must have an organization permission to be promoted to an organization admin.');
    this.name = 'MissingOrganizationPermissionError';
  }
}

export type OrganizationAdmin = User;

interface CreateAdminProps {
  user: Omit<User, 'roles'>;
  organizationId: string;
}

interface RemoveAdminProps {
  userID: string;
  organizationId: string;
}

export default function useOrganizationAdminManagementApi() {
  const userManagementApi = useUserManagementApi();

  const useApi = useMemo(() => (defineApi({
    getAdmins: async (_, organizationId: string) => {
      let users = await userManagementApi.getUsers({});
      users = users.filter(user => user.roles.some((role: Permission) => (
        role.role === OrganizationRole.ORGANIZATION_ADMIN
        && role.organization === organizationId
      )));
      return users;
    },
    createAdmin: async (_, { user, organizationId }: CreateAdminProps) => {
      const adminRole = {
        organization: organizationId,
        role: OrganizationRole.ORGANIZATION_ADMIN,
      };

      const users = await userManagementApi.getUsers({});
      const existingUser = users?.find(u => u.sub === user.sub);

      if (!existingUser) {
        const newUser: User = { ...user, roles: [adminRole] };
        await userManagementApi.createUser({ user: newUser, filter: {} });
        return;
      }

      existingUser.roles.push(adminRole);
      await userManagementApi.updateUser({
        userID: existingUser.id,
        user: existingUser,
        filter: {},
      });
    },
    removeAdmin: async (_, { userID, organizationId }: RemoveAdminProps) => {
      const user = await userManagementApi.getUser({
        userID,
        filter: {},
      });
      if (!user) return;
      user.roles = user.roles.filter(permission => (
        !(
          permission.role === OrganizationRole.ORGANIZATION_ADMIN
          && permission.organization === organizationId
        )
      ));

      if (user.roles.length === 0) {
        await userManagementApi.deleteUser({
          userID,
          filter: {},
        });
        return;
      }

      await userManagementApi.updateUser({
        userID,
        user,
        filter: {},
      });
    },
  })), [userManagementApi]);

  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
