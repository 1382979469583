import React from 'react';
import Moment from 'moment';
import debounce from 'lodash/debounce';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { Translation } from 'react-i18next';
import Translate from './i18n/Translate';
import getTimezone from '../actions/timezoneHelper';
import DocumentTitle from './DocumentTitle';
import FormTableRowSelect from './FormTableRowSelect';
import FormTableRowRadio from './FormTableRowRadio';
import { isValidStatsTimeRange } from '../ranges';
import Headline from './Headline';
import PaperTable from './PaperTable';
import FormTableRowDateRange from './FormTableRowDateRange';
import OrderExportTableRow from './OrderExportTableRow';
import { delimiterOptions, files, dateFormat } from '../exportOptions';
import APIErrorBox from './APIErrorBox';

const styles = theme => ({
  button: {
    float: 'right',
    marginBottom: theme.spacing(2),
  },
});

class OrderExport extends React.Component {
  constructor(props) {
    super(props);

    const today = Moment().format(dateFormat);
    const guessedTimezone = getTimezone();

    this.state = {
      fromDate: today,
      toDate: today,
      currentShopID: 'all',
      fileFormat: 'csv',
      state: 'succeeded',
      disableButton: false,
      delimiter: ';',
      guessedTimezone,
      timezone: guessedTimezone,
    };

    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.handleShopChange = this.handleShopChange.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleDelimiterChange = this.handleDelimiterChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);

    this.fetchOrders = debounce(
      (fileFormat, state, fromDate, toDate, timezone, currentShopID, delimiter) => {
        this.props.fetchOrdersForExport(
          files[fileFormat],
          state,
          fromDate,
          toDate,
          timezone,
          currentShopID,
          delimiter,
        );
      },
      500,
    );
  }

  handleFromDateChange(value) {
    this.setState({
      fromDate: value,
      disableButton: !isValidStatsTimeRange({ from: value, to: this.state.toDate }),
    });
  }

  handleToDateChange(value) {
    this.setState({
      toDate: value,
      disableButton: !isValidStatsTimeRange({ from: this.state.fromDate, to: value }),
    });
  }

  handleFormatChange(event) {
    this.setState({ fileFormat: event.target.value });
  }

  handleShopChange(event) {
    this.setState({ currentShopID: event.target.value });
  }

  handleState(state) {
    this.setState({ state });
  }

  handleDelimiterChange(event) {
    this.setState({ delimiter: event.target.value });
  }

  handleTimezoneChange(event) {
    this.setState({ timezone: event.target.value });
  }

  handleFetch() {
    const {
      fileFormat, fromDate, toDate, timezone, currentShopID, state, delimiter,
    } = this.state;
    this.fetchOrders(
      fileFormat,
      state,
      fromDate,
      toDate,
      timezone,
      currentShopID,
      delimiter,
    );
  }

  render() {
    const { classes, error, shops } = this.props;

    const {
      currentShopID, fromDate, toDate, timezone, guessedTimezone, state, disableButton,
    } = this.state;

    const additionalTzOption = (translate) => {
      if (guessedTimezone !== 'Europe/Berlin' && guessedTimezone !== 'UTC') {
        return (
          <option value={guessedTimezone} key={guessedTimezone}>
            {translate('orders.additionalTimezone', guessedTimezone)}
          </option>
        );
      }
      return null;
    };

    return (
      <Translation>
        { translate => (
          <React.Fragment>
            <DocumentTitle translationID="orders.exportHeadline" />
            <Headline>{translate('orders.exportHeadline')}</Headline>

            <APIErrorBox error={error} />

            <PaperTable headline={translate('orders.export')}>
              <FormTableRowDateRange
                text={translate('orders.range')}
                from={fromDate}
                to={toDate}
                fromCallback={this.handleFromDateChange}
                toCallback={this.handleToDateChange}
              />

              <FormTableRowSelect
                property={timezone}
                propertyName="timezone"
                text={translate('orders.timezone')}
                handleChange={this.handleTimezoneChange}
              >
                {additionalTzOption(translate)}
                <option value="Europe/Berlin" key="EuropeBerlin">
                  {translate('orders.berlinTimezone')}
                </option>
                <option value="UTC" key="UTC">
                  {translate('orders.utcTimezone')}
                </option>
              </FormTableRowSelect>

              <FormTableRowSelect
                property={currentShopID}
                text={translate('orders.shop')}
                handleChange={this.handleShopChange}
                propertyName="shopID"
              >
                <option value="all" key="all">
                  {translate('orders.defaultShop')}
                </option>
                {(shops || []).map(value => (
                  <option value={value.id} key={value.id}>
                    {value.label}
                  </option>
                ))}
              </FormTableRowSelect>
              <FormTableRowSelect
                text={translate('orders.filetype')}
                property={this.state.fileFormat}
                handleChange={this.handleFormatChange}
                propertyName="fileType"
              >
                {Object.keys(files).map(value => (
                  <option value={value} key={value}>
                    {files[value].label}
                  </option>
                ))}
              </FormTableRowSelect>
              {this.state.fileFormat === 'csv' && (
                <FormTableRowSelect
                  text={translate('orders.delimiter')}
                  property={this.state.delimiter}
                  handleChange={this.handleDelimiterChange}
                  propertyName="delimiter"
                >
                  {delimiterOptions.map(value => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </FormTableRowSelect>
              )}
              <FormTableRowRadio
                colSpan={2}
                currentValue={state}
                text={translate('orders.exportStatesLabel')}
                options={[
                  {
                    value: 'succeeded',
                    text: translate('orders.exportSucceeded'),
                  },
                  {
                    value: 'succeededNotTransferred',
                    text: translate('orders.exportNonTransferred'),
                  },
                  { value: '', text: translate('orders.exportAllStates') },
                ]}
                handleChange={this.handleState}
              />
            </PaperTable>

            <Button
              variant="contained"
              color="primary"
              disabled={disableButton || this.props.fetchingOrdersExport}
              onClick={this.handleFetch}
              className={classes.button}
            >
              {translate('orders.requestExport')}
            </Button>

            {!!this.props.ordersExport.length && (
              <PaperTable
                headline={translate('orders.exportFiles')}
                headerAlignment={['left', 'left', 'left']}
                tableHeader={[
                  <Translate id="orders.range" />,
                  ' ',
                  <Translate id="orders.shop" />,
                  <Translate id="orders.exportAllStates" />,
                  '',
                ]}
              >
                {this.props.ordersExport.map(e => (
                  <OrderExportTableRow item={e} key={e.name} />
                ))}
              </PaperTable>
            )}
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(OrderExport);
