import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Range } from '../charts/chartFilterProps';
import { SortableTable, ColumnsProp } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import { Comment } from './useFeedbackApi';

export interface CommentTableProps {
  values: Comment[];
  range?: Range;
}

export default function CommentTable({
  values,
  range,
}: CommentTableProps) {
  const { t } = useTranslation();

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'rating',
      disablePadding: true,
      displayName: t('feedbackStatistic.comments.ratingColumnTitle'),
    },
    {
      key: 'message',
      disablePadding: false,
      displayName: t('feedbackStatistic.comments.messageColumnTitle'),
    },
    {
      key: 'date',
      type: 'datetime',
      disablePadding: false,
      displayName: t('feedbackStatistic.comments.dateColumnTitle'),
    },
    {
      key: 'shopName',
      disablePadding: false,
      displayName: t('feedbackStatistic.comments.shopNameColumnTitle'),
    },
  ], [t]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        title=""
        filterPlaceholder={t('feedbackStatistic.comments.filterPlaceholder')}
        labelRowsPerPage={t('feedbackStatistic.comments.labelRowsPerPage')}
      />)
      :
      <Typography><Translate id="feedbackStatistic.comments.empty" /></Typography>
  );

  return (
    <StatisticPaper
      headline={
        <Translate id="feedbackStatistic.comments.headline" />
            }
      additionalHeaderItems={
        // NOTE this is an api limitation which will only return the newest 100 entries
        (values?.length >= 100) &&
          <Typography color="#e75454"><Translate id="feedbackStatistic.comments.commentQuantityWarning" /></Typography>
        }
      from={range?.from}
      to={range?.to}
      total={values?.length}
    >
      {table}
    </StatisticPaper>
  );
}
