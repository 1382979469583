import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import LoadingCircle from './LoadingCircle';
// @ts-ignore
import RestrictedProjectDialog from '../components/RestrictedProjectDialog';
import ContentSkeleton from './ContentSkeleton';

const Wrapper = styled('div')(() => ({
  minHeight: '100%',
  position: 'relative',
}));

type AppProps = {
  fetchingData?: boolean;
  validProjectSelected?: boolean;
  projectId: string;
  children: React.ReactNode;
  selectProject: (projectId: string) => void;
};

export default function App({
  fetchingData, validProjectSelected, projectId, selectProject, children,
}: AppProps) {
  useEffect(() => {
    selectProject(projectId);
  }, [selectProject, projectId]);

  if (fetchingData) {
    return (
      <React.Fragment>
        <RestrictedProjectDialog />
        <LoadingCircle show />
      </React.Fragment>
    );
  }

  if (validProjectSelected) {
    return (
      <Wrapper>
        <RestrictedProjectDialog />
        <ContentSkeleton>{children}</ContentSkeleton>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <RestrictedProjectDialog />
    </Wrapper>
  );
}
