import isPlainObject from 'lodash/isPlainObject';

// This function flattens a deep object until an atomic value or an array.
// eslint-disable-next-line import/prefer-default-export
export function flattenObject(inputObject: any, currentPrefix = '') {
  if (!inputObject) return {};
  return Object.keys(inputObject).reduce((flattenedObject, currentKey) => {
    const prefix = currentPrefix.length ? `${currentPrefix}.` : '';

    if (isPlainObject(inputObject[currentKey])) {
      Object.assign(flattenedObject, flattenObject(inputObject[currentKey], prefix + currentKey));
    } else {
      // eslint-disable-next-line no-param-reassign
      (flattenedObject as any)[prefix + currentKey] = inputObject[currentKey];
    }
    return flattenedObject;
  }, {});
}
