import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from '../components/DocumentTitle';
import useTaxRulesApi, { TaxRuleType } from './useTaxRulesApi';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import Translate from '../components/i18n/Translate';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import ListTaxRulesTable from './ListTaxRulesTable';
import useProjectNavigate from '../useProjectNavigate';
import useChartFilter from '../charts/useChartFilter';

export default function ListTaxRuleView() {
  const api = useTaxRulesApi();
  const navigate = useProjectNavigate();
  const { filter, updateFilter } = useChartFilter({});
  const { t } = useTranslation();

  const options = useMemo<TabFilterOption[]>(() => (
    Object.values(TaxRuleType).map(rule => ({
      id: rule,
      title: t(`taxRules.tabs.${rule}`),
    }))
  ), [t]);

  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);
  const [typeFilter, setTypeFilter] =
    useState<TabFilterOption>(filter.genericFilter?.taxRuleTypeFilter || options[0]);

  const handleFetchRules = useCallback(async (ruleFilter) => {
    const { data } = await api.getPermanentTaxRules(ruleFilter?.typeFilter.id);
    return data;
  }, [api]);

  const handleDeleteRule = useCallback(async (value: TaxRuleType) => {
    const rule = { type: (typeFilter.id as TaxRuleType), value };
    await api.deletePermamentTaxRule(rule);
    resourceChartViewRef.current?.updateData();
  }, [api, typeFilter.id]);

  const handleAddRule = useCallback(() => {
    navigate('/taxRules/new', { state: { taxRuleType: typeFilter } });
  }, [navigate, typeFilter]);

  const handleEditRule = useCallback((value: string) => {
    navigate(`/taxRules/edit/${typeFilter.id}/${value}`);
  }, [navigate, typeFilter.id]);

  const handleUpdateTypeFilter = useCallback((newTypeFilter) => {
    setTypeFilter(newTypeFilter);
    updateFilter({ genericFilter: { ...filter.genericFilter, taxRuleTypeFilter: newTypeFilter } });
  }, [filter.genericFilter, updateFilter]);

  return (
    <>
      <DocumentTitle translationID="taxRules.headline" />
      <FilterWrapper
        headline={<Translate id="taxRules.headline" />}
        subHeadline={<Translate id="taxRules.subHeadline" />}
        handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
      >
        <TabFilter
          options={options}
          defaultOption={typeFilter}
          onChange={handleUpdateTypeFilter}
          sx={{ gridColumn: '1/-1', marginBottom: '16px' }}
        />
      </FilterWrapper>

      <ResourceChartView
        Chart={ListTaxRulesTable}
        ChartProps={{
          onEntryRemove: handleDeleteRule,
          onEntryAdd: handleAddRule,
          onEntryEdit: handleEditRule,
          idType: typeFilter.id,
        }}
        fetch={handleFetchRules}
        filter={{ typeFilter }}
        ref={resourceChartViewRef}
      />
    </>
  );
}
