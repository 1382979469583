import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard';
import { fetchProductStatistic, fetchLastWeekOrderStatistic } from '../actions';

const DashboardContainer = connect(
  ({ productStatistic, orderStatisticLastWeek }) => ({
    productStatistic,
    orderStatistic: orderStatisticLastWeek,
  }),
  dispatch => ({
    fetchOrderStatistic: () => dispatch(fetchLastWeekOrderStatistic()),
    fetchProductStatistic: () => dispatch(fetchProductStatistic()),
  }),
)(Dashboard);

export default DashboardContainer;
