import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import {
  ElementDescription,
  FunctionMapping,
  UISchemaLanguage,
  UISchemaScope,
} from './types';
import { flattenUISchema, validateTranslation } from './helper';

export interface ToElementsMapping {
  elementDescriptions: ElementDescription[];
  mapping: FunctionMapping;
}

export interface ToElementsProps {
  control: Control;
  language: UISchemaLanguage;
  currentScope: UISchemaScope;
}

export default function toElements(
  { elementDescriptions, mapping }: ToElementsMapping,
  { control, language, currentScope }: ToElementsProps,
): ReactNode[] {
  return elementDescriptions
    .filter(description => (
      (!description.scopes || description.scopes.includes(currentScope))
    ))
    .map((description) => {
      const {
        component, key, translation, children, ...data
      } = description;

      if (!key) return null;

      return (mapping[component] || mapping.Fallback)({
        key,
        translation: validateTranslation({ translation, key }),
        ...(children && {
          children: toElements(
            {
              elementDescriptions: flattenUISchema(children),
              mapping,
            },
            {
              control,
              language,
              currentScope,
            },
          ),
        }),
        control,
        language,
        data,
      });
    });
}
