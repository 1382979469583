const COUNTRY_CODES = {
  afg: {
    'ALPHA-3': 'AFG',
    'ALPHA-2': 'AF',
    englishName: 'Afghanistan',
    germanName: 'Afghanistan',
  },
  ala: {
    'ALPHA-3': 'ALA',
    'ALPHA-2': 'AX',
    englishName: 'Åland Islands',
    germanName: 'Åland',
  },
  alb: {
    'ALPHA-3': 'ALB',
    'ALPHA-2': 'AL',
    englishName: 'Albania',
    germanName: 'Albanien',
  },
  dza: {
    'ALPHA-3': 'DZA',
    'ALPHA-2': 'DZ',
    englishName: 'Algeria',
    germanName: 'Algerien',
  },
  asm: {
    'ALPHA-3': 'ASM',
    'ALPHA-2': 'AS',
    englishName: 'American Samoa',
    germanName: 'Amerikanisch-Samoa',
  },
  and: {
    'ALPHA-3': 'AND',
    'ALPHA-2': 'AD',
    englishName: 'Andorra',
    germanName: 'Andorra',
  },
  ago: {
    'ALPHA-3': 'AGO',
    'ALPHA-2': 'AO',
    englishName: 'Angola',
    germanName: 'Angola',
  },
  aia: {
    'ALPHA-3': 'AIA',
    'ALPHA-2': 'AI',
    englishName: 'Anguilla',
    germanName: 'Anguilla',
  },
  ata: {
    'ALPHA-3': 'ATA',
    'ALPHA-2': 'AQ',
    englishName: 'Antarctica',
    germanName: 'Antarktis',
  },
  atg: {
    'ALPHA-3': 'ATG',
    'ALPHA-2': 'AG',
    englishName: 'Antigua and Barbuda',
    germanName: 'Antigua und Barbuda',
  },
  arg: {
    'ALPHA-3': 'ARG',
    'ALPHA-2': 'AR',
    englishName: 'Argentina',
    germanName: 'Argentinien',
  },
  arm: {
    'ALPHA-3': 'ARM',
    'ALPHA-2': 'AM',
    englishName: 'Armenia',
    germanName: 'Armenien',
  },
  abw: {
    'ALPHA-3': 'ABW',
    'ALPHA-2': 'AW',
    englishName: 'Aruba',
    germanName: 'Aruba',
  },
  aus: {
    'ALPHA-3': 'AUS',
    'ALPHA-2': 'AU',
    englishName: 'Australia',
    germanName: 'Australien',
  },
  aut: {
    'ALPHA-3': 'AUT',
    'ALPHA-2': 'AT',
    englishName: 'Austria',
    germanName: 'Österreich',
  },
  aze: {
    'ALPHA-3': 'AZE',
    'ALPHA-2': 'AZ',
    englishName: 'Azerbaijan',
    germanName: 'Aserbaidschan',
  },
  bhs: {
    'ALPHA-3': 'BHS',
    'ALPHA-2': 'BS',
    englishName: 'Bahamas',
    germanName: 'Bahamas',
  },
  bhr: {
    'ALPHA-3': 'BHR',
    'ALPHA-2': 'BH',
    englishName: 'Bahrain',
    germanName: 'Bahrain',
  },
  bgd: {
    'ALPHA-3': 'BGD',
    'ALPHA-2': 'BD',
    englishName: 'Bangladesh',
    germanName: 'Bangladesch',
  },
  brb: {
    'ALPHA-3': 'BRB',
    'ALPHA-2': 'BB',
    englishName: 'Barbados',
    germanName: 'Barbados',
  },
  blr: {
    'ALPHA-3': 'BLR',
    'ALPHA-2': 'BY',
    englishName: 'Belarus',
    germanName: 'Belarus',
  },
  bel: {
    'ALPHA-3': 'BEL',
    'ALPHA-2': 'BE',
    englishName: 'Belgium',
    germanName: 'Belgien',
  },
  blz: {
    'ALPHA-3': 'BLZ',
    'ALPHA-2': 'BZ',
    englishName: 'Belize',
    germanName: 'Belize',
  },
  ben: {
    'ALPHA-3': 'BEN',
    'ALPHA-2': 'BJ',
    englishName: 'Benin',
    germanName: 'Benin',
  },
  bmu: {
    'ALPHA-3': 'BMU',
    'ALPHA-2': 'BM',
    englishName: 'Bermuda',
    germanName: 'Bermuda',
  },
  btn: {
    'ALPHA-3': 'BTN',
    'ALPHA-2': 'BT',
    englishName: 'Bhutan',
    germanName: 'Bhutan',
  },
  bol: {
    'ALPHA-3': 'BOL',
    'ALPHA-2': 'BO',
    englishName: 'Bolivia',
    germanName: 'Bolivien',
  },
  bes: {
    'ALPHA-3': 'BES',
    'ALPHA-2': 'BQ',
    englishName: 'Bonaire, Sint Eustatius and Saba',
    germanName: 'Bonaire, Saba, Sint Eustatius',
  },
  bih: {
    'ALPHA-3': 'BIH',
    'ALPHA-2': 'BA',
    englishName: 'Bosnia and Herzegovina',
    germanName: 'Bosnien und Herzegowina',
  },
  bwa: {
    'ALPHA-3': 'BWA',
    'ALPHA-2': 'BW',
    englishName: 'Botswana',
    germanName: 'Botswana',
  },
  bvt: {
    'ALPHA-3': 'BVT',
    'ALPHA-2': 'BV',
    englishName: 'Bouvet Island',
    germanName: 'Bouvetinsel',
  },
  bra: {
    'ALPHA-3': 'BRA',
    'ALPHA-2': 'BR',
    englishName: 'Brazil',
    germanName: 'Brasilien',
  },
  iot: {
    'ALPHA-3': 'IOT',
    'ALPHA-2': 'IO',
    englishName: 'British Indian Ocean Territory',
    germanName: 'Britisches Territorium im Indischen Ozean',
  },
  brn: {
    'ALPHA-3': 'BRN',
    'ALPHA-2': 'BN',
    englishName: 'Brunei Darussalam',
    germanName: 'Brunei',
  },
  bgr: {
    'ALPHA-3': 'BGR',
    'ALPHA-2': 'BG',
    englishName: 'Bulgaria',
    germanName: 'Bulgarien',
  },
  bfa: {
    'ALPHA-3': 'BFA',
    'ALPHA-2': 'BF',
    englishName: 'Burkina Faso',
    germanName: 'Burkina Faso',
  },
  bdi: {
    'ALPHA-3': 'BDI',
    'ALPHA-2': 'BI',
    englishName: 'Burundi',
    germanName: 'Burundi',
  },
  cpv: {
    'ALPHA-3': 'CPV',
    'ALPHA-2': 'CV',
    englishName: 'Cabo Verde',
    germanName: 'Kap Verde (Cabo Verde)',
  },
  khm: {
    'ALPHA-3': 'KHM',
    'ALPHA-2': 'KH',
    englishName: 'Cambodia',
    germanName: 'Kambodscha',
  },
  cmr: {
    'ALPHA-3': 'CMR',
    'ALPHA-2': 'CM',
    englishName: 'Cameroon',
    germanName: 'Kamerun',
  },
  can: {
    'ALPHA-3': 'CAN',
    'ALPHA-2': 'CA',
    englishName: 'Canada',
    germanName: 'Kanada',
  },
  cym: {
    'ALPHA-3': 'CYM',
    'ALPHA-2': 'KY',
    englishName: 'Cayman Islands',
    germanName: 'Cayman Islands (Kaimaninseln)',
  },
  caf: {
    'ALPHA-3': 'CAF',
    'ALPHA-2': 'CF',
    englishName: 'Central African Republic',
    germanName: 'Zentralafrikanische Republik',
  },
  tcd: {
    'ALPHA-3': 'TCD',
    'ALPHA-2': 'TD',
    englishName: 'Chad',
    germanName: 'Tschad',
  },
  chl: {
    'ALPHA-3': 'CHL',
    'ALPHA-2': 'CL',
    englishName: 'Chile',
    germanName: 'Chile',
  },
  chn: {
    'ALPHA-3': 'CHN',
    'ALPHA-2': 'CN',
    englishName: 'China',
    germanName: 'Volksrepublik China',
  },
  cxr: {
    'ALPHA-3': 'CXR',
    'ALPHA-2': 'CX',
    englishName: 'Christmas Island',
    germanName: 'Weihnachtsinsel',
  },
  cck: {
    'ALPHA-3': 'CCK',
    'ALPHA-2': 'CC',
    englishName: 'Cocos (Keeling) Islands',
    germanName: 'Kokosinseln',
  },
  col: {
    'ALPHA-3': 'COL',
    'ALPHA-2': 'CO',
    englishName: 'Colombia',
    germanName: 'Kolumbien',
  },
  com: {
    'ALPHA-3': 'COM',
    'ALPHA-2': 'KM',
    englishName: 'Comoros',
    germanName: 'Komoren',
  },
  cok: {
    'ALPHA-3': 'COK',
    'ALPHA-2': 'CK',
    englishName: 'Cook Islands',
    germanName: 'Cookinseln',
  },
  cri: {
    'ALPHA-3': 'CRI',
    'ALPHA-2': 'CR',
    englishName: 'Costa Rica',
    germanName: 'Costa Rica',
  },
  civ: {
    'ALPHA-3': 'CIV',
    'ALPHA-2': 'CI',
    englishName: 'Côte d\'Ivoire',
    germanName: 'Elfenbeinküste (Côte d’Ivoire)',
  },
  hrv: {
    'ALPHA-3': 'HRV',
    'ALPHA-2': 'HR',
    englishName: 'Croatia',
    germanName: 'Kroatien',
  },
  cub: {
    'ALPHA-3': 'CUB',
    'ALPHA-2': 'CU',
    englishName: 'Cuba',
    germanName: 'Kuba',
  },
  cuw: {
    'ALPHA-3': 'CUW',
    'ALPHA-2': 'CW',
    englishName: 'Curaçao',
    germanName: 'Curaçao',
  },
  cyp: {
    'ALPHA-3': 'CYP',
    'ALPHA-2': 'CY',
    englishName: 'Cyprus',
    germanName: 'Zypern',
  },
  cze: {
    'ALPHA-3': 'CZE',
    'ALPHA-2': 'CZ',
    englishName: 'Czechia',
    germanName: 'Tschechien',
  },
  cod: {
    'ALPHA-3': 'COD',
    'ALPHA-2': 'CD',
    englishName: 'Democratic Republic of the Congo',
    germanName: 'Demokratische Republik Kongo',
  },
  dnk: {
    'ALPHA-3': 'DNK',
    'ALPHA-2': 'DK',
    englishName: 'Denmark',
    germanName: 'Dänemark',
  },
  dji: {
    'ALPHA-3': 'DJI',
    'ALPHA-2': 'DJ',
    englishName: 'Djibouti',
    germanName: 'Dschibuti',
  },
  dma: {
    'ALPHA-3': 'DMA',
    'ALPHA-2': 'DM',
    englishName: 'Dominica',
    germanName: 'Dominica',
  },
  dom: {
    'ALPHA-3': 'DOM',
    'ALPHA-2': 'DO',
    englishName: 'Dominican Republic',
    germanName: 'Dominikanische Republik',
  },
  ecu: {
    'ALPHA-3': 'ECU',
    'ALPHA-2': 'EC',
    englishName: 'Ecuador',
    germanName: 'Ecuador',
  },
  egy: {
    'ALPHA-3': 'EGY',
    'ALPHA-2': 'EG',
    englishName: 'Egypt',
    germanName: 'Ägypten',
  },
  slv: {
    'ALPHA-3': 'SLV',
    'ALPHA-2': 'SV',
    englishName: 'El Salvador',
    germanName: 'El Salvador',
  },
  gnq: {
    'ALPHA-3': 'GNQ',
    'ALPHA-2': 'GQ',
    englishName: 'Equatorial Guinea',
    germanName: 'Äquatorialguinea',
  },
  eri: {
    'ALPHA-3': 'ERI',
    'ALPHA-2': 'ER',
    englishName: 'Eritrea',
    germanName: 'Eritrea',
  },
  est: {
    'ALPHA-3': 'EST',
    'ALPHA-2': 'EE',
    englishName: 'Estonia',
    germanName: 'Estland',
  },
  swz: {
    'ALPHA-3': 'SWZ',
    'ALPHA-2': 'SZ',
    englishName: 'Eswatini',
    germanName: 'Eswatini',
  },
  eth: {
    'ALPHA-3': 'ETH',
    'ALPHA-2': 'ET',
    englishName: 'Ethiopia',
    germanName: 'Äthiopien',
  },
  flk: {
    'ALPHA-3': 'FLK',
    'ALPHA-2': 'FK',
    englishName: 'Falkland Islands',
    germanName: 'Falklandinseln',
  },
  fro: {
    'ALPHA-3': 'FRO',
    'ALPHA-2': 'FO',
    englishName: 'Faroe Islands',
    germanName: 'Färöer',
  },
  fsm: {
    'ALPHA-3': 'FSM',
    'ALPHA-2': 'FM',
    englishName: 'Federated States of Micronesia',
    germanName: 'Föderierte Staaten von Mikronesien',
  },
  fji: {
    'ALPHA-3': 'FJI',
    'ALPHA-2': 'FJ',
    englishName: 'Fiji',
    germanName: 'Fidschi',
  },
  fin: {
    'ALPHA-3': 'FIN',
    'ALPHA-2': 'FI',
    englishName: 'Finland',
    germanName: 'Finnland',
  },
  fra: {
    'ALPHA-3': 'FRA',
    'ALPHA-2': 'FR',
    englishName: 'France',
    germanName: 'Frankreich',
  },
  guf: {
    'ALPHA-3': 'GUF',
    'ALPHA-2': 'GF',
    englishName: 'French Guiana',
    germanName: 'Französisch-Guayana',
  },
  pyf: {
    'ALPHA-3': 'PYF',
    'ALPHA-2': 'PF',
    englishName: 'French Polynesia',
    germanName: 'Französisch-Polynesien',
  },
  atf: {
    'ALPHA-3': 'ATF',
    'ALPHA-2': 'TF',
    englishName: 'French Southern Territories',
    germanName: 'Französische Süd- und Antarktisgebiete',
  },
  gab: {
    'ALPHA-3': 'GAB',
    'ALPHA-2': 'GA',
    englishName: 'Gabon',
    germanName: 'Gabun',
  },
  gmb: {
    'ALPHA-3': 'GMB',
    'ALPHA-2': 'GM',
    englishName: 'Gambia',
    germanName: 'Gambia',
  },
  geo: {
    'ALPHA-3': 'GEO',
    'ALPHA-2': 'GE',
    englishName: 'Georgia',
    germanName: 'Georgien',
  },
  deu: {
    'ALPHA-3': 'DEU',
    'ALPHA-2': 'DE',
    englishName: 'Germany',
    germanName: 'Deutschland',
  },
  gha: {
    'ALPHA-3': 'GHA',
    'ALPHA-2': 'GH',
    englishName: 'Ghana',
    germanName: 'Ghana',
  },
  gib: {
    'ALPHA-3': 'GIB',
    'ALPHA-2': 'GI',
    englishName: 'Gibraltar',
    germanName: 'Gibraltar',
  },
  grc: {
    'ALPHA-3': 'GRC',
    'ALPHA-2': 'GR',
    englishName: 'Greece',
    germanName: 'Griechenland',
  },
  grl: {
    'ALPHA-3': 'GRL',
    'ALPHA-2': 'GL',
    englishName: 'Greenland',
    germanName: 'Grönland',
  },
  grd: {
    'ALPHA-3': 'GRD',
    'ALPHA-2': 'GD',
    englishName: 'Grenada',
    germanName: 'Grenada',
  },
  glp: {
    'ALPHA-3': 'GLP',
    'ALPHA-2': 'GP',
    englishName: 'Guadeloupe',
    germanName: 'Guadeloupe',
  },
  gum: {
    'ALPHA-3': 'GUM',
    'ALPHA-2': 'GU',
    englishName: 'Guam',
    germanName: 'Guam',
  },
  gtm: {
    'ALPHA-3': 'GTM',
    'ALPHA-2': 'GT',
    englishName: 'Guatemala',
    germanName: 'Guatemala',
  },
  ggy: {
    'ALPHA-3': 'GGY',
    'ALPHA-2': 'GG',
    englishName: 'Guernsey',
    germanName: 'Guernsey (Kanalinsel)',
  },
  gin: {
    'ALPHA-3': 'GIN',
    'ALPHA-2': 'GN',
    englishName: 'Guinea',
    germanName: 'Guinea',
  },
  gnb: {
    'ALPHA-3': 'GNB',
    'ALPHA-2': 'GW',
    englishName: 'Guinea-Bissau',
    germanName: 'Guinea-Bissau',
  },
  guy: {
    'ALPHA-3': 'GUY',
    'ALPHA-2': 'GY',
    englishName: 'Guyana',
    germanName: 'Guyana',
  },
  hti: {
    'ALPHA-3': 'HTI',
    'ALPHA-2': 'HT',
    englishName: 'Haiti',
    germanName: 'Haiti',
  },
  hmd: {
    'ALPHA-3': 'HMD',
    'ALPHA-2': 'HM',
    englishName: 'Heard Island and McDonald Islands',
    germanName: 'Heard und McDonaldinseln',
  },
  vat: {
    'ALPHA-3': 'VAT',
    'ALPHA-2': 'VA',
    englishName: 'Holy See',
    germanName: 'Vatikanstadt',
  },
  hnd: {
    'ALPHA-3': 'HND',
    'ALPHA-2': 'HN',
    englishName: 'Honduras',
    germanName: 'Honduras',
  },
  hkg: {
    'ALPHA-3': 'HKG',
    'ALPHA-2': 'HK',
    englishName: 'Hong Kong',
    germanName: 'Hongkong',
  },
  hun: {
    'ALPHA-3': 'HUN',
    'ALPHA-2': 'HU',
    englishName: 'Hungary',
    germanName: 'Ungarn',
  },
  isl: {
    'ALPHA-3': 'ISL',
    'ALPHA-2': 'IS',
    englishName: 'Iceland',
    germanName: 'Island',
  },
  ind: {
    'ALPHA-3': 'IND',
    'ALPHA-2': 'IN',
    englishName: 'India',
    germanName: 'Indien',
  },
  idn: {
    'ALPHA-3': 'IDN',
    'ALPHA-2': 'ID',
    englishName: 'Indonesia',
    germanName: 'Indonesien',
  },
  irn: {
    'ALPHA-3': 'IRN',
    'ALPHA-2': 'IR',
    englishName: 'Iran',
    germanName: 'Iran',
  },
  irq: {
    'ALPHA-3': 'IRQ',
    'ALPHA-2': 'IQ',
    englishName: 'Iraq',
    germanName: 'Irak',
  },
  irl: {
    'ALPHA-3': 'IRL',
    'ALPHA-2': 'IE',
    englishName: 'Ireland',
    germanName: 'Irland',
  },
  imn: {
    'ALPHA-3': 'IMN',
    'ALPHA-2': 'IM',
    englishName: 'Isle of Man',
    germanName: 'Isle of Man',
  },
  isr: {
    'ALPHA-3': 'ISR',
    'ALPHA-2': 'IL',
    englishName: 'Israel',
    germanName: 'Israel',
  },
  ita: {
    'ALPHA-3': 'ITA',
    'ALPHA-2': 'IT',
    englishName: 'Italy',
    germanName: 'Italien',
  },
  jam: {
    'ALPHA-3': 'JAM',
    'ALPHA-2': 'JM',
    englishName: 'Jamaica',
    germanName: 'Jamaika',
  },
  jpn: {
    'ALPHA-3': 'JPN',
    'ALPHA-2': 'JP',
    englishName: 'Japan',
    germanName: 'Japan',
  },
  jey: {
    'ALPHA-3': 'JEY',
    'ALPHA-2': 'JE',
    englishName: 'Jersey',
    germanName: 'Jersey (Kanalinsel)',
  },
  jor: {
    'ALPHA-3': 'JOR',
    'ALPHA-2': 'JO',
    englishName: 'Jordan',
    germanName: 'Jordanien',
  },
  kaz: {
    'ALPHA-3': 'KAZ',
    'ALPHA-2': 'KZ',
    englishName: 'Kazakhstan',
    germanName: 'Kasachstan',
  },
  ken: {
    'ALPHA-3': 'KEN',
    'ALPHA-2': 'KE',
    englishName: 'Kenya',
    germanName: 'Kenia',
  },
  kir: {
    'ALPHA-3': 'KIR',
    'ALPHA-2': 'KI',
    englishName: 'Kiribati',
    germanName: 'Kiribati',
  },
  kwt: {
    'ALPHA-3': 'KWT',
    'ALPHA-2': 'KW',
    englishName: 'Kuwait',
    germanName: 'Kuwait',
  },
  kgz: {
    'ALPHA-3': 'KGZ',
    'ALPHA-2': 'KG',
    englishName: 'Kyrgyzstan',
    germanName: 'Kirgisistan',
  },
  lao: {
    'ALPHA-3': 'LAO',
    'ALPHA-2': 'LA',
    englishName: 'Lao People\'s Democratic Republic',
    germanName: 'Laos',
  },
  lva: {
    'ALPHA-3': 'LVA',
    'ALPHA-2': 'LV',
    englishName: 'Latvia',
    germanName: 'Lettland',
  },
  lbn: {
    'ALPHA-3': 'LBN',
    'ALPHA-2': 'LB',
    englishName: 'Lebanon',
    germanName: 'Libanon',
  },
  lso: {
    'ALPHA-3': 'LSO',
    'ALPHA-2': 'LS',
    englishName: 'Lesotho',
    germanName: 'Lesotho',
  },
  lbr: {
    'ALPHA-3': 'LBR',
    'ALPHA-2': 'LR',
    englishName: 'Liberia',
    germanName: 'Liberia',
  },
  lby: {
    'ALPHA-3': 'LBY',
    'ALPHA-2': 'LY',
    englishName: 'Libya',
    germanName: 'Libyen',
  },
  lie: {
    'ALPHA-3': 'LIE',
    'ALPHA-2': 'LI',
    englishName: 'Liechtenstein',
    germanName: 'Liechtenstein',
  },
  ltu: {
    'ALPHA-3': 'LTU',
    'ALPHA-2': 'LT',
    englishName: 'Lithuania',
    germanName: 'Litauen',
  },
  lux: {
    'ALPHA-3': 'LUX',
    'ALPHA-2': 'LU',
    englishName: 'Luxembourg',
    germanName: 'Luxemburg',
  },
  mac: {
    'ALPHA-3': 'MAC',
    'ALPHA-2': 'MO',
    englishName: 'Macao',
    germanName: 'Macau',
  },
  mdg: {
    'ALPHA-3': 'MDG',
    'ALPHA-2': 'MG',
    englishName: 'Madagascar',
    germanName: 'Madagaskar',
  },
  mwi: {
    'ALPHA-3': 'MWI',
    'ALPHA-2': 'MW',
    englishName: 'Malawi',
    germanName: 'Malawi',
  },
  mys: {
    'ALPHA-3': 'MYS',
    'ALPHA-2': 'MY',
    englishName: 'Malaysia',
    germanName: 'Malaysia',
  },
  mdv: {
    'ALPHA-3': 'MDV',
    'ALPHA-2': 'MV',
    englishName: 'Maldives',
    germanName: 'Malediven',
  },
  mli: {
    'ALPHA-3': 'MLI',
    'ALPHA-2': 'ML',
    englishName: 'Mali',
    germanName: 'Mali',
  },
  mlt: {
    'ALPHA-3': 'MLT',
    'ALPHA-2': 'MT',
    englishName: 'Malta',
    germanName: 'Malta',
  },
  mhl: {
    'ALPHA-3': 'MHL',
    'ALPHA-2': 'MH',
    englishName: 'Marshall Islands',
    germanName: 'Marshallinseln',
  },
  mtq: {
    'ALPHA-3': 'MTQ',
    'ALPHA-2': 'MQ',
    englishName: 'Martinique',
    germanName: 'Martinique',
  },
  mrt: {
    'ALPHA-3': 'MRT',
    'ALPHA-2': 'MR',
    englishName: 'Mauritania',
    germanName: 'Mauretanien',
  },
  mus: {
    'ALPHA-3': 'MUS',
    'ALPHA-2': 'MU',
    englishName: 'Mauritius',
    germanName: 'Mauritius',
  },
  myt: {
    'ALPHA-3': 'MYT',
    'ALPHA-2': 'YT',
    englishName: 'Mayotte',
    germanName: 'Mayotte',
  },
  mex: {
    'ALPHA-3': 'MEX',
    'ALPHA-2': 'MX',
    englishName: 'Mexico',
    germanName: 'Mexiko',
  },
  mco: {
    'ALPHA-3': 'MCO',
    'ALPHA-2': 'MC',
    englishName: 'Monaco',
    germanName: 'Monaco',
  },
  mng: {
    'ALPHA-3': 'MNG',
    'ALPHA-2': 'MN',
    englishName: 'Mongolia',
    germanName: 'Mongolei',
  },
  mne: {
    'ALPHA-3': 'MNE',
    'ALPHA-2': 'ME',
    englishName: 'Montenegro',
    germanName: 'Montenegro',
  },
  msr: {
    'ALPHA-3': 'MSR',
    'ALPHA-2': 'MS',
    englishName: 'Montserrat',
    germanName: 'Montserrat',
  },
  mar: {
    'ALPHA-3': 'MAR',
    'ALPHA-2': 'MA',
    englishName: 'Morocco',
    germanName: 'Marokko',
  },
  moz: {
    'ALPHA-3': 'MOZ',
    'ALPHA-2': 'MZ',
    englishName: 'Mozambique',
    germanName: 'Mosambik',
  },
  mmr: {
    'ALPHA-3': 'MMR',
    'ALPHA-2': 'MM',
    englishName: 'Myanmar',
    germanName: 'Myanmar',
  },
  nam: {
    'ALPHA-3': 'NAM',
    'ALPHA-2': 'NA',
    englishName: 'Namibia',
    germanName: 'Namibia',
  },
  nru: {
    'ALPHA-3': 'NRU',
    'ALPHA-2': 'NR',
    englishName: 'Nauru',
    germanName: 'Nauru',
  },
  npl: {
    'ALPHA-3': 'NPL',
    'ALPHA-2': 'NP',
    englishName: 'Nepal',
    germanName: 'Nepal',
  },
  nld: {
    'ALPHA-3': 'NLD',
    'ALPHA-2': 'NL',
    englishName: 'Netherlands',
    germanName: 'Niederlande',
  },
  ncl: {
    'ALPHA-3': 'NCL',
    'ALPHA-2': 'NC',
    englishName: 'New Caledonia',
    germanName: 'Neukaledonien',
  },
  nzl: {
    'ALPHA-3': 'NZL',
    'ALPHA-2': 'NZ',
    englishName: 'New Zealand',
    germanName: 'Neuseeland',
  },
  nic: {
    'ALPHA-3': 'NIC',
    'ALPHA-2': 'NI',
    englishName: 'Nicaragua',
    germanName: 'Nicaragua',
  },
  ner: {
    'ALPHA-3': 'NER',
    'ALPHA-2': 'NE',
    englishName: 'Niger',
    germanName: 'Niger',
  },
  nga: {
    'ALPHA-3': 'NGA',
    'ALPHA-2': 'NG',
    englishName: 'Nigeria',
    germanName: 'Nigeria',
  },
  niu: {
    'ALPHA-3': 'NIU',
    'ALPHA-2': 'NU',
    englishName: 'Niue',
    germanName: 'Niue',
  },
  nfk: {
    'ALPHA-3': 'NFK',
    'ALPHA-2': 'NF',
    englishName: 'Norfolk Island',
    germanName: 'Norfolkinsel',
  },
  mnp: {
    'ALPHA-3': 'MNP',
    'ALPHA-2': 'MP',
    englishName: 'Northern Mariana Islands',
    germanName: 'Nördliche Marianen',
  },
  nor: {
    'ALPHA-3': 'NOR',
    'ALPHA-2': 'NO',
    englishName: 'Norway',
    germanName: 'Norwegen',
  },
  omn: {
    'ALPHA-3': 'OMN',
    'ALPHA-2': 'OM',
    englishName: 'Oman',
    germanName: 'Oman',
  },
  pak: {
    'ALPHA-3': 'PAK',
    'ALPHA-2': 'PK',
    englishName: 'Pakistan',
    germanName: 'Pakistan',
  },
  plw: {
    'ALPHA-3': 'PLW',
    'ALPHA-2': 'PW',
    englishName: 'Palau',
    germanName: 'Palau',
  },
  pse: {
    'ALPHA-3': 'PSE',
    'ALPHA-2': 'PS',
    englishName: 'Palestine, State of',
    germanName: 'Palästina',
  },
  pan: {
    'ALPHA-3': 'PAN',
    'ALPHA-2': 'PA',
    englishName: 'Panama',
    germanName: 'Panama',
  },
  png: {
    'ALPHA-3': 'PNG',
    'ALPHA-2': 'PG',
    englishName: 'Papua New Guinea',
    germanName: 'Papua-Neuguinea',
  },
  pry: {
    'ALPHA-3': 'PRY',
    'ALPHA-2': 'PY',
    englishName: 'Paraguay',
    germanName: 'Paraguay',
  },
  per: {
    'ALPHA-3': 'PER',
    'ALPHA-2': 'PE',
    englishName: 'Peru',
    germanName: 'Peru',
  },
  phl: {
    'ALPHA-3': 'PHL',
    'ALPHA-2': 'PH',
    englishName: 'Philippines',
    germanName: 'Philippinen',
  },
  pcn: {
    'ALPHA-3': 'PCN',
    'ALPHA-2': 'PN',
    englishName: 'Pitcairn',
    germanName: 'Pitcairninseln',
  },
  pol: {
    'ALPHA-3': 'POL',
    'ALPHA-2': 'PL',
    englishName: 'Poland',
    germanName: 'Polen',
  },
  prt: {
    'ALPHA-3': 'PRT',
    'ALPHA-2': 'PT',
    englishName: 'Portugal',
    germanName: 'Portugal',
  },
  pri: {
    'ALPHA-3': 'PRI',
    'ALPHA-2': 'PR',
    englishName: 'Puerto Rico',
    germanName: 'Puerto Rico',
  },
  qat: {
    'ALPHA-3': 'QAT',
    'ALPHA-2': 'QA',
    englishName: 'Qatar',
    germanName: 'Katar',
  },
  kor: {
    'ALPHA-3': 'KOR',
    'ALPHA-2': 'KR',
    englishName: 'Republic of Korea',
    germanName: 'Südkorea',
  },
  mda: {
    'ALPHA-3': 'MDA',
    'ALPHA-2': 'MD',
    englishName: 'Republic of Moldova',
    germanName: 'Moldau',
  },
  mkd: {
    'ALPHA-3': 'MKD',
    'ALPHA-2': 'MK',
    englishName: 'Republic of North Macedonia',
    germanName: 'Nordmazedonien',
  },
  reu: {
    'ALPHA-3': 'REU',
    'ALPHA-2': 'RE',
    englishName: 'Réunion',
    germanName: 'Réunion',
  },
  rou: {
    'ALPHA-3': 'ROU',
    'ALPHA-2': 'RO',
    englishName: 'Romania',
    germanName: 'Rumänien',
  },
  rus: {
    'ALPHA-3': 'RUS',
    'ALPHA-2': 'RU',
    englishName: 'Russian Federation',
    germanName: 'Russland',
  },
  rwa: {
    'ALPHA-3': 'RWA',
    'ALPHA-2': 'RW',
    englishName: 'Rwanda',
    germanName: 'Ruanda',
  },
  blm: {
    'ALPHA-3': 'BLM',
    'ALPHA-2': 'BL',
    englishName: 'Saint Barthélemy',
    germanName: 'Saint-Barthélemy',
  },
  shn: {
    'ALPHA-3': 'SHN',
    'ALPHA-2': 'SH',
    englishName: 'Saint Helena, Ascension and Tristan da Cunha',
    germanName: 'St. Helena, Ascension und Tristan da Cunha',
  },
  kna: {
    'ALPHA-3': 'KNA',
    'ALPHA-2': 'KN',
    englishName: 'Saint Kitts and Nevis',
    germanName: 'St. Kitts und Nevis',
  },
  lca: {
    'ALPHA-3': 'LCA',
    'ALPHA-2': 'LC',
    englishName: 'Saint Lucia',
    germanName: 'St. Lucia',
  },
  maf: {
    'ALPHA-3': 'MAF',
    'ALPHA-2': 'MF',
    englishName: 'Saint Martin (French part)',
    germanName: 'Saint-Martin (französischer Teil)',
  },
  spm: {
    'ALPHA-3': 'SPM',
    'ALPHA-2': 'PM',
    englishName: 'Saint Pierre and Miquelon',
    germanName: 'Saint-Pierre und Miquelon',
  },
  vct: {
    'ALPHA-3': 'VCT',
    'ALPHA-2': 'VC',
    englishName: 'Saint Vincent and the Grenadines',
    germanName: 'St. Vincent und die Grenadinen',
  },
  wsm: {
    'ALPHA-3': 'WSM',
    'ALPHA-2': 'WS',
    englishName: 'Samoa',
    germanName: 'Samoa',
  },
  smr: {
    'ALPHA-3': 'SMR',
    'ALPHA-2': 'SM',
    englishName: 'San Marino',
    germanName: 'San Marino',
  },
  stp: {
    'ALPHA-3': 'STP',
    'ALPHA-2': 'ST',
    englishName: 'Sao Tome and Principe',
    germanName: 'São Tomé und Príncipe',
  },
  sau: {
    'ALPHA-3': 'SAU',
    'ALPHA-2': 'SA',
    englishName: 'Saudi Arabia',
    germanName: 'Saudi-Arabien',
  },
  sen: {
    'ALPHA-3': 'SEN',
    'ALPHA-2': 'SN',
    englishName: 'Senegal',
    germanName: 'Senegal',
  },
  srb: {
    'ALPHA-3': 'SRB',
    'ALPHA-2': 'RS',
    englishName: 'Serbia',
    germanName: 'Serbien',
  },
  syc: {
    'ALPHA-3': 'SYC',
    'ALPHA-2': 'SC',
    englishName: 'Seychelles',
    germanName: 'Seychellen',
  },
  sle: {
    'ALPHA-3': 'SLE',
    'ALPHA-2': 'SL',
    englishName: 'Sierra Leone',
    germanName: 'Sierra Leone',
  },
  sgp: {
    'ALPHA-3': 'SGP',
    'ALPHA-2': 'SG',
    englishName: 'Singapore',
    germanName: 'Singapur',
  },
  sxm: {
    'ALPHA-3': 'SXM',
    'ALPHA-2': 'SX',
    englishName: 'Sint Maarten (Dutch part)',
    germanName: 'Sint Maarten',
  },
  svk: {
    'ALPHA-3': 'SVK',
    'ALPHA-2': 'SK',
    englishName: 'Slovakia',
    germanName: 'Slowakei',
  },
  svn: {
    'ALPHA-3': 'SVN',
    'ALPHA-2': 'SI',
    englishName: 'Slovenia',
    germanName: 'Slowenien',
  },
  slb: {
    'ALPHA-3': 'SLB',
    'ALPHA-2': 'SB',
    englishName: 'Solomon Islands',
    germanName: 'Salomonen',
  },
  som: {
    'ALPHA-3': 'SOM',
    'ALPHA-2': 'SO',
    englishName: 'Somalia',
    germanName: 'Somalia',
  },
  zaf: {
    'ALPHA-3': 'ZAF',
    'ALPHA-2': 'ZA',
    englishName: 'South Africa',
    germanName: 'Südafrika',
  },
  sgs: {
    'ALPHA-3': 'SGS',
    'ALPHA-2': 'GS',
    englishName: 'South Georgia and the South Sandwich Islands',
    germanName: 'Südgeorgien und die Südlichen Sandwichinseln',
  },
  ssd: {
    'ALPHA-3': 'SSD',
    'ALPHA-2': 'SS',
    englishName: 'South Sudan',
    germanName: 'Südsudan',
  },
  esp: {
    'ALPHA-3': 'ESP',
    'ALPHA-2': 'ES',
    englishName: 'Spain',
    germanName: 'Spanien',
  },
  lka: {
    'ALPHA-3': 'LKA',
    'ALPHA-2': 'LK',
    englishName: 'Sri Lanka',
    germanName: 'Sri Lanka',
  },
  sur: {
    'ALPHA-3': 'SUR',
    'ALPHA-2': 'SR',
    englishName: 'Suriname',
    germanName: 'Suriname',
  },
  sjm: {
    'ALPHA-3': 'SJM',
    'ALPHA-2': 'SJ',
    englishName: 'Svalbard and Jan Mayen',
    germanName: 'Spitzbergen und Jan Mayen',
  },
  swe: {
    'ALPHA-3': 'SWE',
    'ALPHA-2': 'SE',
    englishName: 'Sweden',
    germanName: 'Schweden',
  },
  che: {
    'ALPHA-3': 'CHE',
    'ALPHA-2': 'CH',
    englishName: 'Switzerland',
    germanName: 'Schweiz',
  },
  syr: {
    'ALPHA-3': 'SYR',
    'ALPHA-2': 'SY',
    englishName: 'Syrian Arab Republic',
    germanName: 'Syrien',
  },
  twn: {
    'ALPHA-3': 'TWN',
    'ALPHA-2': 'TW',
    englishName: 'Taiwan (Province of China)',
    germanName: 'Taiwan',
  },
  tjk: {
    'ALPHA-3': 'TJK',
    'ALPHA-2': 'TJ',
    englishName: 'Tajikistan',
    germanName: 'Tadschikistan',
  },
  tza: {
    'ALPHA-3': 'TZA',
    'ALPHA-2': 'TZ',
    englishName: 'Tanzania, United Republic of',
    germanName: 'Tansania',
  },
  tha: {
    'ALPHA-3': 'THA',
    'ALPHA-2': 'TH',
    englishName: 'Thailand',
    germanName: 'Thailand',
  },
  tls: {
    'ALPHA-3': 'TLS',
    'ALPHA-2': 'TL',
    englishName: 'Timor-Leste',
    germanName: 'Osttimor (Timor-Leste)',
  },
  tgo: {
    'ALPHA-3': 'TGO',
    'ALPHA-2': 'TG',
    englishName: 'Togo',
    germanName: 'Togo',
  },
  tkl: {
    'ALPHA-3': 'TKL',
    'ALPHA-2': 'TK',
    englishName: 'Tokelau',
    germanName: 'Tokelau',
  },
  ton: {
    'ALPHA-3': 'TON',
    'ALPHA-2': 'TO',
    englishName: 'Tonga',
    germanName: 'Tonga',
  },
  tto: {
    'ALPHA-3': 'TTO',
    'ALPHA-2': 'TT',
    englishName: 'Trinidad and Tobago',
    germanName: 'Trinidad und Tobago',
  },
  tun: {
    'ALPHA-3': 'TUN',
    'ALPHA-2': 'TN',
    englishName: 'Tunisia',
    germanName: 'Tunesien',
  },
  tur: {
    'ALPHA-3': 'TUR',
    'ALPHA-2': 'TR',
    englishName: 'Turkey',
    germanName: 'Türkei',
  },
  tkm: {
    'ALPHA-3': 'TKM',
    'ALPHA-2': 'TM',
    englishName: 'Turkmenistan',
    germanName: 'Turkmenistan',
  },
  tca: {
    'ALPHA-3': 'TCA',
    'ALPHA-2': 'TC',
    englishName: 'Turks and Caicos Islands (the)',
    germanName: 'Turks- und Caicosinseln',
  },
  tuv: {
    'ALPHA-3': 'TUV',
    'ALPHA-2': 'TV',
    englishName: 'Tuvalu',
    germanName: 'Tuvalu',
  },
  uga: {
    'ALPHA-3': 'UGA',
    'ALPHA-2': 'UG',
    englishName: 'Uganda',
    germanName: 'Uganda',
  },
  ukr: {
    'ALPHA-3': 'UKR',
    'ALPHA-2': 'UA',
    englishName: 'Ukraine',
    germanName: 'Ukraine',
  },
  are: {
    'ALPHA-3': 'ARE',
    'ALPHA-2': 'AE',
    englishName: 'United Arab Emirates',
    germanName: 'Vereinigte Arabische Emirate',
  },
  gbr: {
    'ALPHA-3': 'GBR',
    'ALPHA-2': 'GB',
    englishName: 'United Kingdom of Great Britain and Northern Ireland',
    germanName: 'Vereinigtes Königreich (Großbritannien und Nordirland)',
  },
  umi: {
    'ALPHA-3': 'UMI',
    'ALPHA-2': 'UM',
    englishName: 'United States Minor Outlying Islands',
    germanName: 'United States Minor Outlying Islands',
  },
  usa: {
    'ALPHA-3': 'USA',
    'ALPHA-2': 'US',
    englishName: 'United States of America (the)',
    germanName: 'Vereinigte Staaten',
  },
  ury: {
    'ALPHA-3': 'URY',
    'ALPHA-2': 'UY',
    englishName: 'Uruguay',
    germanName: 'Uruguay',
  },
  uzb: {
    'ALPHA-3': 'UZB',
    'ALPHA-2': 'UZ',
    englishName: 'Uzbekistan',
    germanName: 'Usbekistan',
  },
  vut: {
    'ALPHA-3': 'VUT',
    'ALPHA-2': 'VU',
    englishName: 'Vanuatu',
    germanName: 'Vanuatu',
  },
  ven: {
    'ALPHA-3': 'VEN',
    'ALPHA-2': 'VE',
    englishName: 'Venezuela (Bolivarian Republic of)',
    germanName: 'Venezuela',
  },
  vnm: {
    'ALPHA-3': 'VNM',
    'ALPHA-2': 'VN',
    englishName: 'Viet Nam',
    germanName: 'Vietnam',
  },
  vgb: {
    'ALPHA-3': 'VGB',
    'ALPHA-2': 'VG',
    englishName: 'Virgin Islands (British)',
    germanName: 'Britische Jungferninseln',
  },
  vir: {
    'ALPHA-3': 'VIR',
    'ALPHA-2': 'VI',
    englishName: 'Virgin Islands (U.S.)',
    germanName: 'Amerikanische Jungferninseln',
  },
  wlf: {
    'ALPHA-3': 'WLF',
    'ALPHA-2': 'WF',
    englishName: 'Wallis and Futuna',
    germanName: 'Wallis und Futuna',
  },
  esh: {
    'ALPHA-3': 'ESH',
    'ALPHA-2': 'EH',
    englishName: 'Western Sahara',
    germanName: 'Westsahara',
  },
  yem: {
    'ALPHA-3': 'YEM',
    'ALPHA-2': 'YE',
    englishName: 'Yemen',
    germanName: 'Jemen',
  },
  zmb: {
    'ALPHA-3': 'ZMB',
    'ALPHA-2': 'ZM',
    englishName: 'Zambia',
    germanName: 'Sambia',
  },
  zwe: {
    'ALPHA-3': 'ZWE',
    'ALPHA-2': 'ZW',
    englishName: 'Zimbabwe',
    germanName: 'Simbabwe',
  },
} as const;

export type CountryCode = keyof typeof COUNTRY_CODES;

export const PREFERRED_COUNTRY_CODES: CountryCode[] = [
  'deu',
  'aut',
  'che',
  'hun',
  'svk',
];

export default COUNTRY_CODES;
