import React, { BaseSyntheticEvent, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import union from 'lodash/union';
import Button from '@mui/material/Button';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import Translate from '../components/i18n/Translate';
import { ApplicationState } from '../reducers/initialState';
import useCheckoutDeviceApi from './api/useCheckoutDeviceApi';
import CheckoutDeviceListTable from './CheckoutDeviceListTable';
import { Shop } from '../shop/useShopApi';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import SearchableShopSelect from '../components/SearchableShopSelect';
import useChartFilter from '../charts/useChartFilter';
// @ts-ignore
import FilterSelect from '../components/FilterSelect';
import { ensureArray } from '../utils/arrayUtils';
import { CheckoutDeviceType, CheckoutDeviceWithShop } from './CheckoutDevice';
import useProjectNavigate from '../useProjectNavigate';
import CheckoutDeviceQuickEditDrawer from './CheckoutDeviceQuickEditDrawer';

export default function CheckoutDeviceListView() {
  const api = useCheckoutDeviceApi();
  const writePermission = useSelector<ApplicationState, boolean>(state => (
    !!(state.navigation as any)?.checkoutDevices?.write
  ));
  const shops = useSelector<ApplicationState, any>(state => state.shops) as Shop[];
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState<CheckoutDeviceType[] | []>([]);
  const navigate = useProjectNavigate();
  const resourceTableRef = useRef<ResourceChartViewRef | null>(null);

  // NOTE this component uses the filter hook to to keep the filter through navigations
  // but it prefixes all the filters since syncing them with other views might be confusing
  // in this case
  const { filter, updateFilter } = useChartFilter({
    genericFilterInUrl: ['deviceListShopIDs', 'deviceListDeviceType', 'deviceListDeviceStatus'],
  });

  const handleFetch = useCallback(async (fetchFilter) => {
    const allDevices = await api.getCheckoutDevices(null);
    const usedDeviceTypes = new Set<CheckoutDeviceType>();
    const devicesWithShops: CheckoutDeviceWithShop[] = allDevices.map((device) => {
      usedDeviceTypes.add(device.type);
      const currentShop = shops.find(s => s.id === device.shop);
      return ({
        ...device,
        shop: currentShop,
        shopName: currentShop?.name,
      });
    });
    // NOTE the endpoint to get available device types defines which types are allowed for new
    // devices. There might be devices already created with other devices types. So while iterating
    // through the devices to map the shops their types are added to the set of types that can
    // be filtered
    setAvailableDeviceTypes(union(
      await api.getAvailableDeviceTypes(null),
      Array.from(usedDeviceTypes),
    ));

    // NOTE filtering for shops here means that the api hook still calls the status endpoints
    // of each shop. This will change when a new api endpoint is added that includes the status
    const filteredDevices = devicesWithShops.filter((device) => {
      if (fetchFilter.genericFilter?.deviceListShopIDs?.length) {
        if (!fetchFilter.genericFilter.deviceListShopIDs.includes(device.shop?.id)) {
          return false;
        }
      }
      if (fetchFilter.genericFilter?.deviceListDeviceType) {
        if (fetchFilter.genericFilter.deviceListDeviceType !== device.type) {
          return false;
        }
      }
      if (fetchFilter.genericFilter?.deviceListDeviceStatus) {
        if (fetchFilter.genericFilter.deviceListDeviceStatus !== device.aggregateStatus) {
          return false;
        }
      }
      return true;
    });
    return filteredDevices;
  }, [api, shops]);

  const [selectedDevice, setSelectedDevice] = useState<CheckoutDeviceWithShop | null>(null);

  return (
    <>
      <DocumentTitle translationID="checkoutDevices.headlines.list" />

      <FilterWrapper
        headline={<Translate id="checkoutDevices.headlines.list" />}
        topBarChildren={writePermission && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => { navigate('/checkout-devices/new'); }}
            sx={{ marginLeft: 'auto' }}
          >
            <Translate id="checkoutDevices.actions.add" />
          </Button>
        )}
        handleRefresh={() => { resourceTableRef.current?.updateData(); }}
      >
        <SearchableShopSelect
          defaultShopId={ensureArray(filter.genericFilter?.deviceListShopIDs) || []}
          onChange={(newShops) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                deviceListShopIDs: (newShops as Shop[])?.map(s => s.id) || [],
              },
            });
          }}
          showLabel
          multiple
        />
        <FilterSelect
          value={filter.genericFilter?.deviceListDeviceType}
          onChange={(event: BaseSyntheticEvent) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                deviceListDeviceType: event.target.value,
              },
            });
          }}
          label={<Translate id="checkoutDevices.form.filter.typeFilter" />}
        >
          <MenuItem value="">
            <em><Translate id="filter.none" /></em>
          </MenuItem>
          {availableDeviceTypes.map(type => (
            <MenuItem key={type} value={type}>
              <Translate id={`checkoutDevices.form.options.type.${type}`} />
            </MenuItem>
          ))}
        </FilterSelect >
        <FilterSelect
          value={filter.genericFilter?.deviceListDeviceStatus}
          onChange={(event: BaseSyntheticEvent) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                deviceListDeviceStatus: event.target.value,
              },
            });
          }}
          label={<Translate id="checkoutDevices.form.filter.statusFilter" />}
        >
          <MenuItem value="">
            <em><Translate id="filter.none" /></em>
          </MenuItem>
          <MenuItem value="UP">
            <Translate id="checkoutDevices.status.online" />
          </MenuItem>
          <MenuItem value="DOWN">
            <Translate id="checkoutDevices.status.offline" />
          </MenuItem>
        </FilterSelect>
      </FilterWrapper>

      <ResourceChartView
        Chart={CheckoutDeviceListTable}
        ChartProps={{
          writePermission,
          onDeviceQuickEdit: setSelectedDevice,
        }}
        fetch={handleFetch}
        filter={filter}
        ref={resourceTableRef}
      />

      <CheckoutDeviceQuickEditDrawer
        selectedDevice={selectedDevice}
        onClose={() => setSelectedDevice(null)}
      />

    </>
  );
}
