import moment from 'moment';
import { filesize } from 'filesize';
import { fetchGithubReleases, fetchGithubRelease, GithubRelease } from './githubReleases';
import { App, Asset, Release } from '../entity/state';
// @ts-ignore
import { dateFormat } from '../../components/LocaleDate';
import repositoryToAppName from '../entity/repositoryNames';

const HIDDEN_FILENAMES = ['build.json'];

const filterAssets = (releases: Asset[]): Asset[] => (
  releases
    .filter(r => !HIDDEN_FILENAMES.includes(r.name))
);

const mapGithubRelease = (
  repository: string,
  githubRelease: GithubRelease,
  languageCode: string,
): Release => ({
  id: githubRelease.id,
  name: repositoryToAppName.get(repository) || repository,
  version: githubRelease.tag_name,
  changelog: githubRelease.body,
  date: moment(githubRelease.published_at).format(dateFormat(languageCode)),
  assets: filterAssets(githubRelease.assets.map(asset => ({
    id: asset.id,
    name: asset.name,
    type: asset.content_type,
    size: filesize(asset.size, { base: 2, standard: 'jedec' }).toString(),
    downloadLink: asset.browser_download_url,
  }))),
});

export const fetchReleases = async (
  repository: string,
  languageCode: string,
): Promise<App[]> => {
  const name = 'snabble POS';
  const response = await fetchGithubReleases(repository);
  return [
    {
      id: 0,
      name,
      repository,
      releases: response.map(githubRelease => mapGithubRelease(name, githubRelease, languageCode)),
    },
  ];
};

export const fetchRelease = async (
  repository: string,
  id: string,
  languageCode: string,
): Promise<Release> => {
  const githubRelease = await fetchGithubRelease(repository, id);
  return mapGithubRelease(repository, githubRelease, languageCode);
};
