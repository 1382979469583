import React, { ForwardedRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FieldValues, FormState } from 'react-hook-form';
import { CustomFormRef } from '../form/CustomFormProps';

export interface ForceSubmitDialogProps {
  open: boolean;
  onDecline?: () => void;
  onConfirm?: () => void;
}

function ForceSubmitConfirmationDialog({
  open,
  onDecline,
  onConfirm,
}: ForceSubmitDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {t('form.confirmation.actions.forceSubmit.title')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          {t('form.confirmation.actions.forceSubmit.body')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={onDecline}>
          {t('confirmation.buttons.decline')}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {t('confirmation.buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface UseForceSubmitDialogProps {
  formState: Pick<FormState<FieldValues>, 'isSubmitting' | 'isDirty'>
  ref: ForwardedRef<CustomFormRef>;
}

export const useForceSubmitDialog = ({
  formState,
  ref,
}: UseForceSubmitDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const resolver = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    forceSubmit: () => {
      const promise = new Promise<boolean>((resolve) => {
        const { isDirty } = formState;
        if (!isDirty) {
          resolve(true);
        }
        setDialogOpen(true);
        resolver.current = resolve;
      });
      return promise;
    },
  }));

  const ConfirmationDialog = () => (
    <ForceSubmitConfirmationDialog
      open={dialogOpen}
      onDecline={() => {
        setDialogOpen(false);
        resolver.current?.(false);
      }}
      onConfirm={() => {
        setDialogOpen(false);
        resolver.current?.(true);
      }}
    />
  );

  return {
    ConfirmationDialog,
  };
};
