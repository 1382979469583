import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import EanCell from './EanCell';
import ProductListTableCell from './ProductListTableCell';
import ProductOutOfStock from './ProductOutOfStock';
import ContentLink from './ContentLink';


const styles = theme => ({
  wrapper: {
    overflow: 'auto',
    marginTop: theme.spacing(4),
    width: '100%',

    [theme.breakpoints.down(700)]: {
      margin: `${theme.spacing(2)} 0`,
      width: '100%',
    },
  },
  tableBody: {
    display: 'block',
  },
  tableHead: {
    display: 'block',
    backgroundColor: '#07b',
    color: theme.palette.common.white,
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  tableRow: {
    display: 'flex',
    height: 'auto',
    flexWrap: 'wrap',
    minHeight: '60px',
    boxSizing: 'border-box',
    [theme.breakpoints.down(700)]: {
      borderBottom: '1px solid #ccc',
      paddingBottom: theme.spacing(2),
    },
    '&:nth-of-type(even)': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
});

function ProductListTable({
  products, classes, showBarcodes, codeTemplates,
}) {
  const productList = products && products.length ? products : [];
  return (
    <Paper elevation={4} className={classes.wrapper}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            <ProductListTableCell
              content={<Translate id="product.sku" />}
              small
            />
            <ProductListTableCell
              content={<Translate id="product.name" />}
              light
            />
            <ProductListTableCell
              content={<Translate id="product.stock" />}
              small
            />
            <ProductListTableCell
              content=""
              xs
            />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {productList.map(product => (
            <TableRow
              className={classes.tableRow}
              key={`${product.sku}`}
            >
              <ProductListTableCell
                small
                content={
                  <ContentLink to={`/${product.project}/products/${product.sku}`}>
                    {product.sku}
                  </ContentLink>
                }
              />

              <ProductListTableCell
                content={product.name}
              />

              <ProductListTableCell
                content={<ProductOutOfStock outOfStock={product.outOfStock} />}
                small
                hideOnMobile
              />

              <ProductListTableCell
                content={<ContentLink to={`/${product.project}/products/${product.sku}`} view />}
                xs
              />

              <EanCell codeTemplates={codeTemplates} codes={product.codes} visible={showBarcodes} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(ProductListTable);
