import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Refresh from '@mui/icons-material/Refresh';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Headline from './Headline';

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),

    [theme.breakpoints.up(600)]: {
      gridTemplateColumns: '1fr 1fr',
    },

    [theme.breakpoints.up(800)]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  formWrapperWithShowFilter: {
    display: 'block',
  },
  formWrapperWithoutShowFilter: {
    display: 'none',

    [theme.breakpoints.up(600)]: {
      display: 'block',
    },
  },
  buttonRefresh: {
    marginTop: '15px',
    marginLeft: theme.spacing(2),
  },
  button: {
    display: 'flex',
    marginTop: '15px',
    marginLeft: 'auto',

    [theme.breakpoints.up(600)]: {
      display: 'none',
    },
  },
  error: {
    marginTop: theme.spacing(2),
    color: theme.palette.error[500],
  },
});

class FilterWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    this.handleFilterVisibility = this.handleFilterVisibility.bind(this);
  }

  handleFilterVisibility() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  render() {
    const {
      children, topBarChildren, classes, headline, subHeadline, handleRefresh, error,
    } = this.props;

    let errorContainer = null;
    if (error) {
      errorContainer = (
        <div className={classes.error}>
          <Typography variant="caption">
            {error}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Headline>{headline}</Headline>

          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleFilterVisibility}
            className={classes.button}
          >
            <TuneOutlined />
          </Button>

          {handleRefresh && (
            <Button
              variant="outlined"
              id="refresh"
              data-testid="refresh"
              className={classes.buttonRefresh}
              onClick={handleRefresh}
            >
              <Refresh />
            </Button>
          )}

          <div
            className={classes.buttonRefresh}
          >
            {topBarChildren}
          </div>
        </div>
        {subHeadline && (
          <div className={classes.container}>
            <Typography>{subHeadline}</Typography>
          </div>
        )}

        <div
          className={
            this.state.showFilter
              ? classes.formWrapperWithShowFilter
              : classes.formWrapperWithoutShowFilter
          }
        >
          <div className={classes.form}>{children}</div>
          {errorContainer}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FilterWrapper);
