import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import LocaleDate from './LocaleDate';

const styles = theme => ({
  wrapper: {
    margin: `${theme.spacing(4)} 0`,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: 'border-box',

    [theme.breakpoints.down(700)]: {
      margin: `${theme.spacing(2)} 0`,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  label: {
    padding: '0',
    margin: '0 0 12px',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  headline: {
    width: '100%',
    margin: '0',
  },
  text: {
    marginTop: '5px',
    marginBottom: theme.spacing(4),
  },
});

const ImportStatistic = withStyles(styles)(({
  classes, label, value, color,
}) => (
  <div className={classes.paperStyle}>
    <Typography variant="body1" className={classes.label}>{ label }</Typography>
    <Typography variant="h5" component="p" style={color} className={classes.value}>{ value }</Typography>
  </div>
));

function ProductStatistic({ classes, statistic }) {
  if (!statistic) { return null; }
  const colors = {
    added: {
      color: 'green',
    },
    updated: {
      color: 'orange',
    },
    deleted: {
      color: 'red',
    },
  };
  return (
    <Paper className={classes.wrapper}>
      <Typography variant="h5" component="h2"><Translate id="productStatistic.headline" /></Typography>
      <Typography variant="caption" className={classes.text}><Translate id="filter.from" /> <LocaleDate date={statistic.date} /> <Translate id="filter.untilNow" /></Typography>
      <div className={classes.container}>
        <ImportStatistic label={<Translate id="productStatistic.total" />} value={statistic.count} />
        <ImportStatistic label={<Translate id="productStatistic.stock" />} value={statistic.inStock} />
        <ImportStatistic label={<Translate id="productStatistic.new" />} color={colors.added} value={statistic.created} />
        <ImportStatistic label={<Translate id="productStatistic.updated" />} color={colors.updated} value={statistic.updated} />
        <ImportStatistic label={<Translate id="productStatistic.deleted" />} color={colors.deleted} value={statistic.deleted} />
      </div>
    </Paper>
  );
}

export default withStyles(styles)(ProductStatistic);
