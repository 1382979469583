import actions from '../actions/constants';
import { prepareBasketAverageStatistic } from './helper';

export default (state, action) => {
  switch (action.type) {
    case actions.RECEIVED_BASKET_AVERAGE_STATISTIC:
      return {
        basketAverageStatistic:
          prepareBasketAverageStatistic(
            action.payload,
            state.projectCurrency,
            state.basketAverageStatistic,
          ),
        fetchingBasketAverageStatistic: false,
        range: action.payload.date,
        shopFilter: action.payload.shopID,
        withCustomerCardFilter: action.payload.withCustomerCard,
        paymentMethodsFilter: action.payload.paymentMethods,
      };


    case actions.RECEIVED_BASKET_AVERAGE_STATISTIC_ERROR:
      return {
        basketAverageStatistic: null,
        fetchingBasketAverageStatistic: false,
      };

    case actions.REQUEST_BASKET_AVERAGE_STATISTIC:
      return { fetchingBasketAverageStatistic: true };

    case actions.RESET_BASKET_AVERAGE_STATISTIC:
      return { basketAverageStatistic: null };

    default:
      return {};
  }
};
