import { get } from './request';
import actions from './constants';
import { fetchInitialShops } from './initialShops';

export function restrictedProject() {
  return { type: actions.RESTRICTED_PROJECT };
}

export function unsetProject() {
  return { type: actions.UNSET_PROJECT };
}

export function requestPortalMetadata() {
  return {
    type: actions.REQUEST_PORTAL_METADATA,
  };
}

export function receivedPortalMetadata(data) {
  return {
    type: actions.RECEIVED_PORTAL_METADATA,
    payload: data ? data.links : {},
  };
}

export function fetchPortalMetadata() {
  return (dispatch, getState) => {
    dispatch(requestPortalMetadata());
    return get(dispatch, getState, '/metadata/project/{project}/portal', 'application/json')
      .then(
        json => dispatch(receivedPortalMetadata(json)),
        () => dispatch(receivedPortalMetadata({})),
      );
  };
}

export function selectProject(projectId) {
  return (dispatch) => {
    dispatch({ type: actions.SELECTED_PROJECT, payload: projectId });
    dispatch(fetchInitialShops());
    dispatch(fetchPortalMetadata());
  };
}

function requestProjects() {
  return {
    type: actions.REQUEST_PROJECTS,
  };
}

function receivedProjects(json) {
  return {
    type: actions.RECEIVED_PROJECTS,
    payload: json.projects,
  };
}

export function fetchProjects() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.authorized) {
      return Promise.resolve();
    }

    dispatch(requestProjects());
    // HACK the retailer portal was never meant to be used without access to a project.
    // However due to the organization management this is now possible.
    // This old part of the code is still used in some parts of the code, but cannot be updated
    // to work with 403 responses when fetching projects, since the error handling is hardcoded
    // further down in the code. To work around this problem the first argument of the get-function
    // was replaced with a dummy function instead of the dispatch function. This way the error
    // cannot be stored. Also the error path of the then-function was replaced to return an empty
    // project list instead of activating the restricted state.
    // All of this is only possible since the new ProjectSelector component now handles the error
    // state itself.
    return get(() => { }, getState, '/metadata/project', 'application/json')
      .then(
        json => dispatch(receivedProjects(json)),
        () => dispatch(receivedProjects({ projects: [] })),
      );
  };
}
