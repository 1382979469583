const checksum = (chars: any[]) => {
  const sum = chars
    .splice(1)
    .map((d, i) => d * ((i % 2) === 0 ? 3 : 1))
    .reduce((acc, d) => acc + d, 0) % 10;
  return sum === 0 ? 0 : 10 - sum;
};

function validateEANChecksum(code: string) {
  if (!code) {
    return false;
  }

  const chars = code
    .split('')
    .map((c: string) => parseInt(c, 10))
    .reverse();

  return checksum(chars) === chars[0];
}

const isValidEAN13 = (code: string) => code.length === 13 && validateEANChecksum(code);
const isValidEAN8 = (code: string) => code.length === 8 && validateEANChecksum(code);

export { validateEANChecksum, isValidEAN13, isValidEAN8 };
