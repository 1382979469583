import { connect } from 'react-redux';
import PricingCategoryView from '../components/PricingCategoryView';
import { fetchPricingCategories } from '../actions';

const selectCategory = (categories, id) => {
  const category = (categories || []).find(item => item.id === id);
  if (category) {
    return {
      id: category.id,
      name: category.name,
      currentShops: category.shops,
    };
  }
  return null;
};

const PricingCategoryViewContainer = connect(
  ({
    shops,
    pricingCategories,
    fetchingPricingCategories,
    navigation,
  }, { id }) => ({
    shops,
    showLoadingIndicator: fetchingPricingCategories,
    selectedCategory: selectCategory(pricingCategories, id),
    canEdit: navigation.pricingCategories && navigation.pricingCategories.write,
  }),
  dispatch => ({
    fetchPricingCategories: () => dispatch(fetchPricingCategories()),
  }),
)(PricingCategoryView);

export default PricingCategoryViewContainer;
