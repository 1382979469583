import { useCallback, useMemo } from 'react';
import { NavigateOptions, To, useNavigate, useParams } from 'react-router';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';

export default function useOrganizationNavigate() {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      if (!organizationId) {
        navigate(to, options);
        return;
      }

      const href = `/organizations/${organizationId}${to}`;

      let rewrittenTo = to;
      if (isString(to)) {
        rewrittenTo = href;
      }
      if (isPlainObject(to)) {
        rewrittenTo = Object.assign({}, to, { pathname: href });
      }

      navigate(rewrittenTo, options);
    },
    [organizationId, navigate],
  );
}

export function useOrganizationBaseURL() {
  const { organizationId } = useParams();
  return useMemo(() => `/organizations/${organizationId}`, [organizationId]);
}
