import React, { ReactNode, forwardRef, useEffect } from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { NumberField, SelectOptionsField, TextField } from '../form/input';
import CheckboxField from '../form/input/CheckboxField';
import { useResourceTranslator } from '../resource';
import CheckoutDeviceConfig from './CheckoutDeviceConfig';
import { FEATURE_FLAGS_POS } from './featureFlags';
import {
  TERMINAL_PASSWORD_LENGTH,
  TERMINAL_PASSWORD_PATTERN,
} from './api/terminalPassword';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

const TERMINAL_TYPES = ['feig', 'p400', 'q30pax', 'adyen'];
const UI_DEFAULT_LANGUAGES = ['de', 'en'];
const UI_SCREEN_ORIENTATIONS = ['system', 'portrait', 'landscape', 'reversePortrait', 'reverseLandscape'];
const UI_RELEASE_CHANNELS = ['stable', 'beta', 'latest'];
const TERMINAL_IP_PATTERN = /^\d{0,3}\.\d{0,3}\.\d{0,3}\.\d{0,3}$/;
const TERMINAL_PROTOCOLS = ['zvt', 'opi', 'softpay', 'tap-on-mobile', 'rs2', 'adyen', 'mock'];

const FILTER_HARDWARE_TERMINALS = ['zvt', 'opi'];
const TERMINAL_SETTINGS = ['terminalIp', 'terminalPort', 'terminalIncomingPort', 'terminalPassword', 'terminalReceiptColumns', 'scheduledEndOfDayTime'];

const DEFAULT_VALUES: CheckoutDeviceConfig = {
  cigaretteMachineURL: '',
  enableTraceLogging: false,
  featureFlags: [],
  scheduledEndOfDayTime: '',
  terminalPassword: '',
  terminalType: 'feig',
} as any; // TODO the checkout device config is not correctly defined
// this has to change for it to be used properly with the new updates

export interface CheckoutDeviceConfigFormNewProps
  extends CustomFormProps<CheckoutDeviceConfig> {
  additionalButtons?: ReactNode;
  onCancel?: () => void;
}

// TODO remove this component and use the DynaForm variant in the quick drawer
const CheckoutDeviceConfigFormNew = forwardRef<CustomFormRef, CheckoutDeviceConfigFormNewProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
  onCancel,
}, ref) => {
  const t = useResourceTranslator();

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
  } = useEnhancedForm({ defaultValues, errors });

  const selectedTermialProtocol = watch('terminalProtocol');
  useEffect(() => {
    if (selectedTermialProtocol && !FILTER_HARDWARE_TERMINALS.includes(selectedTermialProtocol)) {
      TERMINAL_SETTINGS.forEach((setting) => { setValue(setting, null); });
    }
  }, [selectedTermialProtocol, setValue]);

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="paymentTerminal">
        <SelectOptionsField
          control={control}
          name="terminalType"
          options={TERMINAL_TYPES}
          required
          rules={{ required: true }}
          helperText={t('checkoutDevices.form.helperText.terminalType')}
        />

        <SelectOptionsField
          control={control}
          name="terminalProtocol"
          options={TERMINAL_PROTOCOLS}
          includeEmpty
        />

        {FILTER_HARDWARE_TERMINALS.includes(selectedTermialProtocol) && (
          <>
            <TextField
              control={control}
              name="terminalIp"
              rules={{ pattern: TERMINAL_IP_PATTERN }}
            />

            <NumberField
              control={control}
              name="terminalPort"
            />

            {selectedTermialProtocol === 'opi' && (
              <TextField
                control={control}
                name="terminalIncomingPort"
                inputProps={{
                  inputMode: 'numeric',
                }}
              />
            )}

            {selectedTermialProtocol === 'zvt' && (
              <>
                <TextField
                  control={control}
                  name="terminalPassword"
                  type="password"
                  inputProps={{
                    inputMode: 'numeric',
                    maxLength: TERMINAL_PASSWORD_LENGTH,
                  }}
                  rules={{ pattern: TERMINAL_PASSWORD_PATTERN }}
                  autoComplete="one-time-code"
                />

                <NumberField
                  control={control}
                  name="terminalReceiptColumns"
                />

                <TextField
                  control={control}
                  name="scheduledEndOfDayTime"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </>
        )}

        {selectedTermialProtocol === 'adyen' && (
          <>
            <TextField
              control={control}
              name="terminalAdyenKeyId"
            />
            <TextField
              control={control}
              name="terminalAdyenKeyPassphrase"
            />
            <NumberField
              control={control}
              name="terminalAdyenKeyVersion"
            />
            <TextField
              control={control}
              name="terminalAdyenPoiId"
            />
            <TextField
              control={control}
              name="terminalIp"
              rules={{ pattern: TERMINAL_IP_PATTERN }}
            />
          </>
        )}
      </Fieldset>

      <Fieldset category="frontend">
        <SelectOptionsField
          control={control}
          name="language"
          options={UI_DEFAULT_LANGUAGES}
          includeEmpty
        />

        <SelectOptionsField
          control={control}
          name="screenOrientation"
          options={UI_SCREEN_ORIENTATIONS}
          includeEmpty
        />

        <CheckboxField
          control={control}
          name="darkMode"
        />
      </Fieldset>

      <Fieldset category="additionalData">
        <TextField
          control={control}
          name="cigaretteMachineURL"
          inputProps={{
            inputMode: 'url',
          }}
        />
      </Fieldset>

      <Fieldset category="features">
        <SelectOptionsField
          control={control}
          name="featureFlags"
          options={FEATURE_FLAGS_POS}
          multiple
          helperText={t('helpers.inheritedFeatureFlags')}
        />
      </Fieldset>

      <Fieldset category="devSettings">
        <SelectOptionsField
          control={control}
          name="posReleaseChannel"
          options={UI_RELEASE_CHANNELS}
          includeEmpty
        />
        <CheckboxField
          control={control}
          name="enableTraceLogging"
        />
        <CheckboxField
          control={control}
          name="undergoingMaintenance"
        />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
        additionalButtons={additionalButtons}
        onCancel={onCancel}
      />
    </Form>
  );
});

export default CheckoutDeviceConfigFormNew;
