import React from 'react';
import TableCell from '@mui/material/TableCell';
import Done from '@mui/icons-material/Done';
import Translate from './i18n/Translate';
import ColoredTableRow from './ColoredTableRow';
import LocaleDate from './LocaleDate';
import DownloadAsLink from './DownloadAsLink';

function OrderExportTableRow({ item }) {
  return (
    <ColoredTableRow>
      <TableCell>
        {item.fromDate !== '' && <LocaleDate date={item.fromDate} />}
      </TableCell>
      <TableCell>
        {item.toDate !== '' && <LocaleDate date={item.toDate} />}
      </TableCell>
      <TableCell>
        {item.shop === 'all' && <Translate id="orders.defaultShop" />}
        {item.shop !== 'all' && item.shopLabel}
      </TableCell>
      <TableCell>
        {item.includeAllStates && <Done />}
      </TableCell>
      <TableCell>
        <DownloadAsLink blob={item.blob} name={item.name} type={item.label} />
      </TableCell>
    </ColoredTableRow>
  );
}

export default OrderExportTableRow;
