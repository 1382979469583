import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
// @ts-ignore
import ClosingListContainer from '../ClosingListContainer';
// @ts-ignore
import ClosingViewContainer from '../ClosingViewContainer';
import RouteWrapper from '../../router/RouteWrapper';
import { Access } from '../../access';

export default function ClosingRoutes({ access }: { access?: Access }) {
  const { projectId } = useParams();

  return (
    <Routes>
      {access?.read &&
        <React.Fragment>
          <Route path="/" element={<RouteWrapper><ClosingListContainer /></RouteWrapper>} />
          <Route path="/shops/:shop/sequenceNumbers/:id" element={<RouteWrapper><ClosingViewContainer /></RouteWrapper>} />
        </React.Fragment>
      }
      <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
    </Routes>
  );
}
