import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import LoadableRegion from '../loader/LoadableRegion';
import useTaxRulesApi, { TaxRuleType } from './useTaxRulesApi';
import CreateTaxRuleForm from './CreateTaxRuleForm';

export default function CreateTaxRuleView() {
  const api = useTaxRulesApi();
  const navigate = useProjectNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const buildNewEntry = useCallback(() => ({
    type: (location?.state?.taxRuleType?.id as TaxRuleType),
  }), [location?.state]);

  const availableTaxRules = useMemo(() => (
    Object.values(TaxRuleType).map(rule => ({
      value: rule,
      label: t(`taxRules.value.${rule}`),
    }))
  ), [t]);

  return (
    <>
      <DocumentTitle translationID="taxRules.headline" />
      <LoadableRegion loading={false}>
        <ResourceFormView
          actionName="createTaxRule"
          Form={CreateTaxRuleForm}
          FormProps={{
            availableTaxRules,
          }}
          fetch={buildNewEntry}
          submit={api.createPermanentTaxRule}
          onSubmitted={() => {
            navigate('/taxRules');
          }}
        />
      </LoadableRegion>
    </>
  );
}
