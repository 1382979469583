import React from 'react';
import { Translation } from 'react-i18next';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function sortPaymentMethods(paymentMethods, translate) {
  return (paymentMethods || []).sort((a, b) => {
    const methodA = translate(`paymentMethod.${a}`);
    const methodB = translate(`paymentMethod.${b}`);
    return methodA.localeCompare(methodB);
  });
}

function renderValue(translate) {
  return selectedPaymentMethods => (
    sortPaymentMethods(selectedPaymentMethods, translate).map((paymentMethod, index) => (
      <React.Fragment key={paymentMethod}>
        {index > 0 && ', '}
        {translate(`paymentMethod.${paymentMethod}`)}
      </React.Fragment>
    ))
  );
}

const PaymentMethodFilter = ({ value, paymentMethods, onChange }) => {
  const selectDisabled = !paymentMethods || paymentMethods.length === 0;

  return (
    <FormControl fullWidth>
      <Translation>
        {translate => (
          <>
            <InputLabel
              id="payment-method-filter-label"
              htmlFor="payment-method-filter-input"
            >
              {translate('statistic.paymentMethods')}
            </InputLabel>

            <Select
              labelId="payment-method-filter-label"
              id="payment-method-filter-input"
              value={value}
              label={translate('statistic.paymentMethods')}
              onChange={onChange}
              multiple
              renderValue={renderValue(translate)}
              disabled={selectDisabled}
            >
              {sortPaymentMethods(paymentMethods, translate).map(paymentMethod => (
                <MenuItem key={paymentMethod} value={paymentMethod}>
                  <Checkbox
                    checked={(value || []).includes(paymentMethod)}
                    color="primary"
                  />
                  <ListItemText primary={translate(`paymentMethod.${paymentMethod}`)} />
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Translation>
    </FormControl>
  );
};

export default PaymentMethodFilter;
