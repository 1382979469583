import { AxiosError, AxiosResponse } from 'axios';

// patchResponse maps validation errors of kind `categories[0]image`
// to `categories` as we use a single categories form field in the frontend instead of a list.
const patchResponse =
  (response: AxiosResponse<any> | undefined): AxiosResponse<any> | undefined => {
    if (!response?.data?.error?.validationErrors) {
      return response;
    }

    const clone = { ...response };
    const { validationErrors } = clone.data.error;
    const patchedValidationErrors = validationErrors.map((ve: any) =>
      (ve.field.startsWith('categories')
        ? { ...ve, field: 'categories' }
        : ve));
    clone.data.error.validationErrors = patchedValidationErrors;
    return clone;
  };


const patchErrorToSingleCategoriesField =
  (err: AxiosError<any>): AxiosError<any> =>
    new AxiosError(
      err.message,
      err.code,
      err.config,
      err.request,
      patchResponse(err.response),
    );

export default patchErrorToSingleCategoriesField;
