import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import ListProjectsView from './ListProjectsView';
import CreateProjectView from './CreateProjectView';

function OrganizationProjectRoutes() {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="userManagement">
      <Routes>
        <Route path="/" element={<RouteWrapper><ListProjectsView /></RouteWrapper>} />
        <Route path="/new" element={<RouteWrapper><CreateProjectView /></RouteWrapper>} />
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default OrganizationProjectRoutes;
