import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import RouteWrapper from './RouteWrapper';
import { Access } from '../access';
import ShopListView from '../shop/ShopListView';
import EditShopView from '../shop/EditShopView';
import CreateShopView from '../shop/CreateShopView';
import ViewShopView from '../shop/ViewShopView';

function ShopRoutes({ access }: { access?: Access }) {
  return (
    <Routes>
      {access?.write &&
        <React.Fragment>
          <Route path="/new" element={<RouteWrapper><CreateShopView /></RouteWrapper>} />
          <Route path="/:id/edit" element={<RouteWrapper><EditShopView /></RouteWrapper>} />
        </React.Fragment>
      }
      {access?.read &&
        <React.Fragment>
          <Route path="/" element={<RouteWrapper><ShopListView /></RouteWrapper>} />
          <Route path="/:id" element={<RouteWrapper><ViewShopView /></RouteWrapper>} />
        </React.Fragment>
      }
      <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
    </Routes>
  );
}

export default ShopRoutes;
