import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import LanguageSwitch from './LanguageSwitch';
import StageLabel from './StageLabel';
import { ApplicationState } from '../reducers/initialState';

const styles = (theme: any) => ({
  topBar: {
    background: 'rgba(0,0,0,0.04)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingRight: theme.spacing(4),
    paddingBottom: 0,
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
      height: '50px',
    },
  },
  username: {
    marginLeft: 'auto',
    alignSelf: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  mobileMenuIcon: {
    backgroundColor: theme.palette.primary.main,
    fill: '#fff',
    padding: '13px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapper: {
    maxWidth: '1248px',
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '1264px',
    },
  },
  defaultDrawer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

interface TopBarProps {
  classes: any;
}

function TopBar({ classes }: TopBarProps) {
  const username = useSelector<ApplicationState, string>(state => (state.token?.grants as any)?.sub || '');
  return (
    <div className={classes.topBar}>
      <div className={classes.wrapper}>
        <StageLabel />
        <Typography variant="subtitle1" className={classes.username}>{username}</Typography>
        <LanguageSwitch />
      </div>
    </div>
  );
}

export default withStyles(styles)(TopBar);
