import React, { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useOrganizationApi, { Organization } from './useOrganizationApi';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import EditOrganizationForm from './EditOrganizationForm';

export default function EditOrganizationView() {
  const api = useOrganizationApi();
  const { organizationId } = useParams();

  const [etag, setEtag] = useState<string | undefined>('');

  const fetchOrganization = useCallback(async () => {
    if (!organizationId) return {};
    const { data, etag: newEtag } = await api.getOrganization(organizationId);
    setEtag(newEtag);
    return data;
  }, [api, organizationId]);

  const updateOrganization = useCallback(async (data: FieldValues) => {
    if (!organizationId || !etag) return;
    await api.updateOrganization({
      organizationId,
      organizationData: data as Organization,
      etag,
    });
    const { etag: newEtag } = await api.getOrganization(organizationId);
    setEtag(newEtag);
  }, [api, etag, organizationId]);

  return (
    <>
      <DocumentTitle translationID="headlines.editOrganization" />
      <ResourceFormView
        actionName="editOrganization"
        Form={EditOrganizationForm}
        fetch={fetchOrganization}
        submit={updateOrganization}
      />
    </>
  );
}
