import React from 'react';
import Translate from './i18n/Translate';
import PaperTableRow from './PaperTableRow';
import PaperTable from './PaperTable';

import ProductSaleStop from './ProductSaleStop';

const RESTRICTIONS = {
  min_age_6: 'minimum age 6',
  min_age_12: 'minimum age 12',
  min_age_14: 'minimum age 14',
  min_age_16: 'minimum age 16',
  min_age_18: 'minimum age 18',
  min_age_21: 'minimum age 21',
  fsk: 'FSK',
};

function ProductDetailsControl({
  product,
}) {
  if (!product) return null;

  let { saleRestriction } = product;
  if (saleRestriction && RESTRICTIONS[saleRestriction]) {
    saleRestriction = RESTRICTIONS[saleRestriction];
  }

  return (
    <PaperTable headline={<Translate id="product.controlAttributes" />}>
      <PaperTableRow label={<Translate id="product.controlIndication" />} value={product.controlIndication || '–'} />
      <PaperTableRow
        label={<Translate id="product.forceControl" />}
        value={product.forceControl ? <Translate id="button.yes" /> : <Translate id="button.no" />}
      />
      <PaperTableRow label={<Translate id="product.saleRestriction" />} value={saleRestriction || '–'} />
      <PaperTableRow label={<Translate id="product.saleStop" />} value={<ProductSaleStop saleStop={product.saleStop} />} />
    </PaperTable>
  );
}

export default ProductDetailsControl;
