import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { useLocation, useParams } from 'react-router-dom';
// @ts-ignore
import Translate from '../components/i18n/Translate';
import SidebarMenuItem from '../sidebar/SidebarMenuItem';
// @ts-ignore
import { ReactComponent as DashboardIcon } from '../icons/team_dashboard.svg';
// @ts-ignore
import { ReactComponent as ProjectConfigIcon } from '../icons/manufacturing.svg';
// @ts-ignore
import { ReactComponent as ProjectsIcon } from '../icons/home_storage.svg';
// @ts-ignore
import { ReactComponent as UserManagemenIcon } from '../icons/manage_accounts.svg';
// @ts-ignore
import { ReactComponent as ServiceDeskIcon } from '../icons/contact_support.svg';
// @ts-ignore
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { useOrganizationBaseURL } from '../organizations/useOrganizationNavigate';

const styles = ({
  sidebar: {
    boxShadow: '0 0 16px 0 rgba(0,0,0,0.18), 0 16px 16px 0 rgba(0,0,0,0.24)',
    backgroundColor: '#fff',
    // minHeight: '100vh',
    height: '100%',
    overflowY: 'auto',
    width: '250px',
    padding: 0,
    margin: 0,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
});

interface SidebarMenuProps {
  isMini?: boolean;
  classes: any;
}

const SidebarMenu = ({
  isMini = false,
  classes,
}: SidebarMenuProps) => {
  const { pathname } = useLocation();
  const { organizationId } = useParams();
  const organizationsBaseUrl = useOrganizationBaseURL();

  return (
    <ul className={classes.sidebar}>
      <SidebarMenuItem
        linkTarget={organizationsBaseUrl}
        linkText={<Translate id="dashboard.headline" />}
        icon={<DashboardIcon />}
        currentPath={pathname}
        isMini={isMini}
      />
      <SidebarMenuItem
        linkTarget={`/organizations/${organizationId}/organization-config`}
        linkText={<Translate id="organization.config.headline" />}
        icon={<ProjectConfigIcon />}
        currentPath={pathname}
        isMini={isMini}
      />
      <SidebarMenuItem
        linkTarget={`/organizations/${organizationId}/organization-projects`}
        linkText={<Translate id="organization.project.headline" />}
        icon={<ProjectsIcon />}
        currentPath={pathname}
        isMini={isMini}
      />
      <SidebarMenuItem
        linkTarget={`/organizations/${organizationId}/organization-admins`}
        linkText={<Translate id="organization.adminManagement.headline" />}
        icon={<UserManagemenIcon />}
        currentPath={pathname}
        isMini={isMini}
      />
      <SidebarMenuItem
        linkTarget="https://snabble.atlassian.net/servicedesk/customer/portal/1"
        linkText={<Translate id="ticket.navigation" />}
        icon={<ServiceDeskIcon />}
        isExternal
        isMini={isMini}
      />
      <SidebarMenuItem
        linkTarget="/login?logout=true"
        linkText="Logout"
        icon={<LogoutIcon />}
        isExternal
        isMini={isMini}
      />
    </ul>
  );
};

export default withStyles(styles)(SidebarMenu);
