import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles, { Styles } from '@mui/styles/withStyles';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import Translate from './i18n/Translate';

const DATE_FORMAT = 'YYYY-MM-DD';

type Range = { from?: string, to?: string, };

export interface FromToSelectorProps {
  classes: any;
  onChange: (range: Range) => void;
  range?: Range;
  hasError: boolean;
  emitFilled?: boolean;
}

export interface FromToSelectorState {
  range: Range;
  prevProps: FromToSelectorProps;
}

const styles: Styles<any, any, string> = () => ({
  formInput: {
    boxSizing: 'border-box',
  },
  error: {
    whiteSpace: 'nowrap',
  },
});

function startOfDay(value?: string) {
  if (!value) return undefined;
  return moment(value).startOf('day').toISOString(true);
}

function endOfDay(value?: string) {
  if (!value) return undefined;
  return moment(value).endOf('day').toISOString(true);
}

class FromToSelector extends React.Component<FromToSelectorProps, FromToSelectorState> {
  constructor(props: FromToSelectorProps) {
    super(props);

    this.state = {
      prevProps: props,
      range: {
        from: props.range?.from,
        to: props.range?.to,
      },
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  static getDerivedStateFromProps(props: FromToSelectorProps, currentState: FromToSelectorState) {
    if (isEqual(props, currentState.prevProps)) {
      return null;
    }

    return {
      prevProps: props,
      range: {
        from: props.range?.from,
        to: props.range?.to,
      },
    };
  }

  handleChangeRange(range: Range) {
    this.setState({ range }, () => {
      if (this.props.emitFilled && (!range.from || !range.to)) {
        return;
      }

      if (!range.from && !range.to) {
        return;
      }

      this.props.onChange(range);
    });
  }

  handleChangeFrom(event: React.ChangeEvent<HTMLInputElement>) {
    this.handleChangeRange({
      from: startOfDay(event.target.value),
      to: endOfDay(this.state.range.to),
    });
  }

  handleChangeTo(event: React.ChangeEvent<HTMLInputElement>) {
    this.handleChangeRange({
      from: startOfDay(this.state.range.from),
      to: endOfDay(event.target.value),
    });
  }

  render() {
    const { classes } = this.props;
    const { range } = this.state;

    let fromValue = '';
    if (range.from) {
      fromValue = moment(range.from).format(DATE_FORMAT);
    }

    let toValue = '';
    if (range.to) {
      toValue = moment(range.to).format(DATE_FORMAT);
    }

    return (
      <React.Fragment>
        <TextField
          error={this.props.hasError}
          variant="outlined"
          className={classes.formInput}
          onChange={this.handleChangeFrom}
          id="fromDate"
          label={<Translate id="filter.from" />}
          type="date"
          value={fromValue}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          error={this.props.hasError}
          variant="outlined"
          className={classes.formInput}
          onChange={this.handleChangeTo}
          id="toDate"
          label={<Translate id="filter.to" />}
          type="date"
          value={toValue}
          InputLabelProps={{ shrink: true }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FromToSelector);
