import React, { ReactNode, useEffect, useState } from 'react';

export interface AsyncContentProps<T> {
  fetch: () => Promise<T>;
  render: (content: T | null, isLoading: boolean, error: any | null) => ReactNode;
  renderLoading?: () => ReactNode;
  renderError?: (error: any) => ReactNode;
  refetch?: boolean;
}

export default function AsyncContent<T>({
  fetch,
  render,
  renderLoading,
  renderError,
  refetch = false,
}: AsyncContentProps<T>) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [content, setContent] = useState<T | null>(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const contentresp = await fetch?.();
        setContent(contentresp);
        setError(null);
      } catch (e: any) {
        setContent(null);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContent();
  // NOTE: this component should only fetch data once
  // having no dependencies for this useEffect eliminates the need to wrap the
  // fetch function in a useCallback hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(refetch ? [fetch, render] : [])]);

  if (renderLoading && isLoading) return <>{renderLoading()}</>;

  if (renderError && error) return <>{renderError(error)}</>;

  return (
    <>
      {render(content, isLoading, error)}
    </>
  );
}
