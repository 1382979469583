import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { DiscreteColorLegend } from 'react-vis';
import Translate from './i18n/Translate';
import LocaleDate from './LocaleDate';

const styles = theme => ({
  wrapper: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    [theme.breakpoints.down(700)]: {
      margin: `${theme.spacing(2)} 0`,
      padding: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  headlineWrapper: {
    marginRight: 'auto',
    maxWidth: '60%',
  },
  headlineWrapperXL: {
    maxWidth: '100%',
  },
  additionalItemsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignSelf: 'baseline',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  legend: {
    '& div': {
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  subheadline: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,0.8)',
    zIndex: 10,
  },
});

const StatisticPaper = ({
  classes, headline, children, from, to, total, legendItems, isLoading, additionalHeaderItems,
}) => (
  <Paper className={classes.wrapper}>
    {isLoading && <div className={classes.loading} />}

    <div className={classes.header}>
      <div className={
          additionalHeaderItems || legendItems ?
            classes.headlineWrapper : classes.headlineWrapperXL
        }>
        <Typography variant="h5" component="h2">{headline} {!!total && <span>({total})</span>}</Typography>
        {from && to && (
          <Typography variant="caption" className={classes.subheadline}>
            <LocaleDate date={from} dateOnly /> <Translate id="statistic.to" /> <LocaleDate date={to} dateOnly />
          </Typography>
        )}
      </div>

      <div className={classes.additionalItemsWrapper}>
        {additionalHeaderItems}

        {!!legendItems &&
        <DiscreteColorLegend
          className={classes.legend}
          items={legendItems} />}
      </div>
    </div>

    {children}
  </Paper>
);

export default withStyles(styles)(StatisticPaper);
