import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import { useNavigate } from 'react-router';
import ConfirmButton from '../../button/ConfirmButton';
import { useResourceTranslator } from '../../resource';

export type CancelButtonFormState = Pick<FormState<FieldValues>, 'isDirty'>;

export interface CancelButtonProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  formState: CancelButtonFormState;
}

export default function CancelButton({ formState, onConfirm, onCancel }: CancelButtonProps) {
  const { isDirty } = formState;

  const t = useResourceTranslator();
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
      return;
    }

    if (onCancel) {
      onCancel();
      return;
    }
    navigate(-1);
  };

  const dialogTitle = useMemo(
    () => t('form.confirmation.actions.cancel.title', {
      defaultValue: null,
    }),
    [t],
  );

  const dialogBody = useMemo(
    () => t('form.confirmation.actions.cancel.body', {
      defaultValue: null,
    }),
    [t],
  );

  if (!isDirty) {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={handleConfirm}
      >
        {t('form.actions.cancel')}
      </Button>
    );
  }

  return (
    <ConfirmButton
      actionName="cancel"
      variant="contained"
      color="secondary"
      dialogTitle={dialogTitle}
      dialogBody={dialogBody}
      onConfirm={handleConfirm}
    >
      {t('form.actions.cancel')}
    </ConfirmButton>
  );
}
