import actions from '../actions/constants';

export default (state, action) => {
  switch (action.type) {
    case actions.RECEIVED_APPROVAL_DURATION_STATISTIC:
      return {
        approvalDurationStatistic: action.payload,
        fetchingApprovalDurationStatistic: false,
        range: action.payload.date,
        shopFilter: action.payload.shopID,
      };

    case actions.RECEIVED_APPROVAL_DURATION_STATISTIC_ERROR:
      return {
        approvalDurationStatistic: null,
        fetchingApprovalDurationStatistic: false,
      };

    case actions.REQUEST_APPROVAL_DURATION_STATISTIC:
      return { fetchingApprovalDurationStatistic: true };

    case actions.RESET_APPROVAL_DURATION_STATISTIC:
      return { approvalDurationStatistic: null };

    default:
      return {};
  }
};
