import Moment from 'moment';

import { get, stream } from './request';
import actions from './constants';
import { fetchPricing } from './pricing';

function requestProducts() {
  return {
    type: actions.REQUEST_PRODUCTS,
  };
}

function receivedProducts(json, searchTerm) {
  return {
    type: actions.RECEIVED_PRODUCTS,
    payload: { result: json, searchTerm },
  };
}

function receivedProductsError() {
  return {
    type: actions.RECEIVED_PRODUCTS_ERROR,
  };
}

function fetchProducts(limit, term, abortController) {
  const q = term && term.length ? `&q=${encodeURIComponent(term)}` : '';
  return (dispatch, getState) => {
    dispatch(requestProducts());
    return stream(dispatch, getState, `/{project}/products?limit=${limit}${q}`, 'application/vnd.snabble.product.v2+x-ndjson', abortController, 10000)
      .then(
        json => dispatch(receivedProducts(json, term)),
        () => dispatch(receivedProductsError()),
      );
  };
}

function resetProduct() {
  return {
    type: actions.RESET_PRODUCT,
  };
}

function requestProduct() {
  return {
    type: actions.REQUEST_PRODUCT,
  };
}

function receivedProduct(json) {
  return {
    type: actions.RECEIVED_PRODUCT,
    payload: json,
  };
}

function fetchProduct(sku) {
  return (dispatch, getState) => {
    dispatch(requestProduct());
    return get(dispatch, getState, `/{project}/products/sku/${encodeURIComponent(sku)}`, 'application/vnd.snabble.product.v2+json')
      .then(
        (json) => {
          dispatch(receivedProduct(json));
          const state = getState();
          if (!state.fetchPricing) {
            dispatch(fetchPricing(sku));
          }
        },
        () => dispatch(receivedProduct({})),
      );
  };
}

function receivedProductStatistic(json, date) {
  return {
    type: actions.RECEIVED_PRODUCT_STATISTIC,
    payload: Object.assign(json, { date }),
  };
}

function fetchProductStatistic() {
  return (dispatch, getState) => {
    const dateQuery = Moment(Moment().subtract(1, 'days')).toISOString();
    return get(dispatch, getState, `/{project}/products/statistics?since=${dateQuery}`, 'application/json')
      .then(
        json => dispatch(receivedProductStatistic(json, dateQuery)),
        () => dispatch(receivedProductStatistic({}, dateQuery)),
      );
  };
}

export { fetchProductStatistic, fetchProducts, fetchProduct, resetProduct };
