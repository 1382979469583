import { useContext } from 'react';
import ToastContext from './ToastContext';
import { ToastProps } from './ToastProps';

export interface UseToast {
  show: (props: ToastProps) => void;
  hide: () => void;
}

export default function useToast() {
  return useContext(ToastContext);
}
