import React, { ReactNode } from 'react';
import { Theme } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { To, Link } from 'react-router-dom';

const styles = (theme: Theme) => ({
  headline: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  button: {
    float: 'right' as 'right',
  },
});

export interface HeadlineProps {
  classes: any;
  children: ReactNode;
  withLink?: boolean;
  to?: To;
  linkText?: ReactNode;
}

function Headline({
  classes, children,
  withLink = false,
  to,
  linkText,
}: HeadlineProps) {
  if (withLink && to) {
    // DEPRECATED: Use HeadlineWithButtons instead
    return (
      <div className={classes.headlineWrapper}>
        <Typography variant="h5" component="h1">{children}</Typography>
        <Button component={Link} to={to} variant="contained" className={classes.button} color="primary">
          {linkText}
        </Button>
      </div>
    );
  }

  return (
    <Typography variant="h5" component="h1" className={classes.headline}>
      {children}
    </Typography>
  );
}

export default withStyles(styles)(Headline);
