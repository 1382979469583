import { FieldValues } from 'react-hook-form';
import isNaN from 'lodash/isNaN';
import {
  defineApi,
  isNotFoundError,
  useProjectSpecificApiClient,
} from '../../api';
import CheckoutDevice from '../CheckoutDevice';
import { terminalPasswordToNumber, terminalPasswordToString } from './terminalPassword';
import CheckoutDeviceConfig from '../CheckoutDeviceConfig';
import { commaSeparatedStringToArray } from '../apiStringUtils';
import checkStringIsEmail from '../apiValidationUtils';
import GlobalCheckoutDeviceConfig from '../GlobalCheckoutDeviceConfig';

export enum CheckoutDeviceType {
  SCO = 'sco',
  GATEKEEPER = 'gatekeeper',
  POS = 'pos',
  'SCO-NG' = 'sco-ng',
}

interface GetCheckoutDeviceParams {
  id: string;
}

interface CreateCheckoutDeviceParams {
  data: FieldValues;
}

interface UpdateCheckoutDeviceParams {
  id: string;
  data: FieldValues;
}

interface CheckoutDeviceList {
  devices: CheckoutDevice[];
}

export interface GetCheckoutDeviceConfigParams {
  id: string;
}

export interface UpdateCheckoutDeviceConfigParams {
  id: string;
  data: FieldValues;
}

const matchRealmInCashierLoginUrl = /(?<=\/realms\/)(.+?)(?=\/login)/g;

export interface UpdateGlobalConfigParams {
  data: FieldValues;
}

export interface AppearanceConfigCoordinates {
  shop?: string;
  device?: string;
}

export interface UpdateAppearanceConfig {
  coordinates?: AppearanceConfigCoordinates;
  data: FieldValues;
}

const sanitizeAppearanceConfig = (data: FieldValues) => {
  const repairedObject = data;
  if (repairedObject.cartDeletionTimeoutSeconds) {
    repairedObject.cartDeletionTimeout = repairedObject.cartDeletionTimeoutSeconds ? `PT${repairedObject.cartDeletionTimeoutSeconds}S` : '';
  }
  delete repairedObject.cartDeletionTimeoutSeconds;
  Object.keys(repairedObject).forEach((key) => {
    if (repairedObject[key] === '' || isNaN(repairedObject[key])) delete repairedObject[key];
  });
  const cleanedData: FieldValues = {
    ...repairedObject,
  };
  return cleanedData;
};

const saniztizeGlobalConfig = (data: FieldValues) => {
  const repairedObject = data;

  repairedObject.emailAlertRecipients = commaSeparatedStringToArray(data.emailAlertRecipients)
    .filter(v => checkStringIsEmail(v));

  // NOTE for a better ux the user just has to update the realm and the rest of the url is kept
  // as is.
  repairedObject.cashierLoginURL = data.cashierLoginRealm ? `/realms/${data.cashierLoginRealm}/login/policies` : '';
  repairedObject.cartDeletionTimeout = data.cartDeletionTimeoutSeconds * 1000;
  repairedObject.posSessionTimeout = data.posSessionTimeoutSeconds * 1000;

  delete repairedObject.cashierLoginRealm;
  delete repairedObject.cartDeletionTimeoutSeconds;
  delete repairedObject.posSessionTimeoutSeconds;

  return repairedObject;
};

const useApi = defineApi({
  getCheckoutDevices: async client =>
    client.get<CheckoutDeviceList>('').then(resp => resp.data?.devices),
  getCheckoutDevice: (client, { id }: GetCheckoutDeviceParams) =>
    client.get<CheckoutDevice>(`/id/${id}`).then(resp => resp.data),
  getCheckoutDeviceConfig: async (client, { id }: GetCheckoutDeviceConfigParams) => {
    try {
      const { data } = await client.get(`/id/${id}/configs`);

      let terminalPassword: string | undefined;
      if (data.terminalPassword) {
        terminalPassword = terminalPasswordToString(data.terminalPassword);
      }

      return { ...data, terminalPassword } as CheckoutDeviceConfig;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  getGlobalConfig: async (client) => {
    try {
      const { data } = await client.get<GlobalCheckoutDeviceConfig>('/configs');
      data.cashierLoginRealm = data.cashierLoginURL?.match(matchRealmInCashierLoginUrl)?.[0] ?? '';
      data.cartDeletionTimeoutSeconds = data.cartDeletionTimeout / 1000;
      data.posSessionTimeoutSeconds = data.posSessionTimeout / 1000;
      return data;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return undefined;
      }
      throw e;
    }
  },
  getAppearanceConfig: async (client, coordinates: AppearanceConfigCoordinates | null) => {
    try {
      const { data } = await client.get('/config/content', { params: coordinates });
      const duration = (data?.cartDeletionTimeout as string)?.match(/PT([0-9]+)S/)?.[1];
      if (duration) data.cartDeletionTimeoutSeconds = Number(duration || '0');
      return data;
    } catch (e) {
      if (e instanceof Error && isNotFoundError(e)) {
        return {};
      }
      throw e;
    }
  },
  getUiSchema: async client =>
    client.get<any>('/config/ui-schema', { params: { platform: 'portal' } }).then(resp => resp.data),
  getShopStatus: (client, { id }: GetCheckoutDeviceParams) =>
    client.get(`/shop/${id}/status`).then(resp => resp.data),
  getAvailableDeviceTypes: client =>
    client.get<CheckoutDeviceType[]>('/available-types').then(resp => resp.data),
  createCheckoutDevice: (client, { data }: CreateCheckoutDeviceParams) =>
    client.post<CheckoutDevice>('', data).then(resp => resp.data),
  updateCheckoutDevice: (client, { id, data }: UpdateCheckoutDeviceParams) =>
    client.put(`/id/${id}`, data),
  updateCheckoutDeviceConfig: (client, { id, data }: UpdateCheckoutDeviceConfigParams) => {
    let terminalPassword: number | undefined;
    if (data.terminalPassword) {
      terminalPassword = terminalPasswordToNumber(data.terminalPassword);
    }

    return client.put(`/id/${id}/configs`, { ...data, terminalPassword });
  },
  updateGlobalConfig: (client, { data }: UpdateGlobalConfigParams) =>
    client.put<GlobalCheckoutDeviceConfig>('/configs', saniztizeGlobalConfig(data)),
  updateAppearanceConfig: async (client, { data, coordinates }: UpdateAppearanceConfig) =>
    client.put('/config/content', sanitizeAppearanceConfig(data), { params: coordinates }),
  deleteCheckoutDevice: (client, { id }: GetCheckoutDeviceParams) =>
    client.delete(`/id/${id}`),
  resetCheckoutDeviceSecret: (client, { id }: GetCheckoutDeviceConfigParams) =>
    client.post<CheckoutDevice>(`/id/${id}/reset-secret`).then(resp => resp.data),
});

export default function useCheckoutDeviceApi() {
  const client = useProjectSpecificApiClient({ basePath: '/checkout-devices' });
  return useApi(client);
}
