import TextField from '@mui/material/TextField';
import withStyles, { Styles } from '@mui/styles/withStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import Translate from './i18n/Translate';

type Range = { from?: string, to?: string, };

export interface FromToSelectorProps {
  classes: any;
  onChange: (range: Range) => void;
  range?: Range;
  hasError: boolean;
}

export interface FromToSelectorState {
  range: Range;
  prevProps: FromToSelectorProps;
}

const styles: Styles<any, any, string> = () => ({
  formInput: {
    boxSizing: 'border-box',
  },
  error: {
    whiteSpace: 'nowrap',
  },
});

class FromToSelectorWithDateTime extends React.Component<FromToSelectorProps, FromToSelectorState> {
  constructor(props: FromToSelectorProps) {
    super(props);

    this.state = {
      prevProps: props,
      range: {
        from: props.range?.from,
        to: props.range?.to,
      },
    };

    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  static getDerivedStateFromProps(props: FromToSelectorProps, currentState: FromToSelectorState) {
    if (isEqual(props, currentState.prevProps)) {
      return null;
    }

    return {
      prevProps: props,
      range: {
        from: props.range?.from,
        to: props.range?.to,
      },
    };
  }

  handleChangeRange(range: Range) {
    this.setState({ range }, () => {
      this.props.onChange(range);
    });
  }

  handleChangeFrom(value: moment.Moment | null) {
    let from: string | undefined;
    if (value) {
      from = value.toISOString(true);
    }
    this.handleChangeRange({
      from,
      to: this.state.range.to,
    });
  }

  handleChangeTo(value: moment.Moment | null) {
    this.handleChangeRange({
      to: value?.toISOString(true) ?? undefined,
      from: this.state.range.from,
    });
  }

  render() {
    const { classes } = this.props;
    const { range } = this.state;

    let fromValue = null;
    if (range.from) {
      fromValue = moment(range?.from);
    }
    let toValue = null;
    if (range.to) {
      toValue = moment(range?.to);
    }

    return (
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            renderInput={props =>
              (<TextField
                {...props}
                id="fromDate"
                error={this.props.hasError}
                variant="outlined"
                className={classes.formInput}
                InputLabelProps={{ shrink: true }}
              />)
            }
            label={<Translate id="filter.from" />}
            value={fromValue}
            onChange={this.handleChangeFrom}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            renderInput={props =>
              (<TextField
                {...props}
                id="toDate"
                error={this.props.hasError}
                variant="outlined"
                className={classes.formInput}
                InputLabelProps={{ shrink: true }}
              />)
            }
            label={<Translate id="filter.to" />}
            value={toValue}
            onChange={this.handleChangeTo}
          />
        </LocalizationProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FromToSelectorWithDateTime);
