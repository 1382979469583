import React from 'react';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';
import Translate from './i18n/Translate';
import CodeWithTemplate from './CodeWithTemplate';
import ColoredTableRow from './ColoredTableRow';
import { escapeWhitespace, escapeNonPrintableChars } from '../utils/escapeString';


const styles = {
  cell: {
    width: '25%',
  },
  isPrimary: {
    fontWeight: 'bold',
  },
  primaryHint: {
    color: grey[400],
  },
  templateError: {
    fontStyle: 'italic',
    fontSize: 'small',
  },
};

function ProductAttributeCodes({
  codeObj, classes, codeTemplates,
}) {
  if (!codeObj) return null;

  return (
    <ColoredTableRow>
      <TableCell className={`${classes.cell} ${codeObj.isPrimary ? classes.isPrimary : ''}`}>
        {escapeWhitespace(escapeNonPrintableChars(codeObj.code))}
        {codeObj.isPrimary && (<p className={classes.primaryHint}><Translate id="product.primaryCode" /></p>)}
      </TableCell>
      <TableCell className={classes.cell}>
        {codeObj.template}
        <pre>
          {!!codeTemplates && codeTemplates[codeObj.template]}
        </pre>
      </TableCell>
      <TableCell className={classes.cell}>{codeObj.encodingUnit || '–'}</TableCell>
      <TableCell className={classes.cell}>{codeObj.specifiedQuantity || '–'}</TableCell>
      <TableCell className={classes.cell}>
        {codeTemplates?.[codeObj.template] ? (
          <CodeWithTemplate
            codeTemplates={codeTemplates}
            code={codeObj.code}
            template={codeObj.template}
            key={codeObj.code}
          />
      ) : (
        <span className={classes.templateError}>
          <Translate id="product.codeTemplateError" data={{ template: codeObj.template }} />
        </span>
      )}
      </TableCell>
    </ColoredTableRow>
  );
}

export default withStyles(styles)(ProductAttributeCodes);
