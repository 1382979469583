import React from 'react';
import Translate from './i18n/Translate';
import SimpleStateIndicator from './SimpleStateIndicator';
import SupervisingCheckedItemsList from './SupervisingCheckedItemsList';


function SupervisingFailedCheckIndicator({
  check,
}) {
  if (check.successful) return null;
  if (check.type === 'supervisor_approval' && !check.isRescan) return null;

  if (check.type === 'supervisor_approval') {
    return (
      <SupervisingCheckedItemsList checkedItems={check.checkedItems} />
    );
  }

  return (
    <SimpleStateIndicator
      useErrorColor
      text={<Translate id="supervising.failed" />}
    />
  );
}

export default SupervisingFailedCheckIndicator;
