import {
  defineApi,
  useApiClient,
} from '../api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';

export function sortProjectsAlphabetically(projects: Project[]) {
  return projects.sort((a, b) => (
    a.name
      .toLowerCase()
      .localeCompare(b.name.toLowerCase())
  ));
}

export interface Project {
  id: string;
  name: string;
  displayName: string;
  companyName: string;
  companyAddress: {
    street: string;
    zip: string;
    city: string;
    country: string;
  }
  currency: string;
  locale: string;
  [key: string]: any;
}

interface ProjectListResponse {
  projects: Project[];
  links: any;
}

const useApi = defineApi({
  getProjects: async (client) => {
    const { data, status } = await client.get<ProjectListResponse>('', {
      validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [403]),
    });
    if (status === 403) return { projects: [] };
    return data;
  },
});

export default function useProjectApi() {
  const client = useApiClient({ basePath: '/metadata/project' });
  return useApi(client);
}
