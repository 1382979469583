import { MappedCheckResult, MappedSupervisingResult, SupervisingResult } from './supervisingTypes';

const KNOWN_RESCAN_TYPES = ['partialRescan', 'rescan'];
const isRescan = (method: string) => KNOWN_RESCAN_TYPES.indexOf(method) !== -1;

// eslint-disable-next-line max-len
export default function mapSupervisingResult(supervisingResult: SupervisingResult): MappedSupervisingResult | null {
  if (!supervisingResult) return null;

  const checks: MappedCheckResult[] = [];
  (supervisingResult.checks || []).forEach((check) => {
    checks.push({ ...check, isRescan: isRescan(check.method) });
  });

  return {
    ...supervisingResult,
    checks,
  };
}
