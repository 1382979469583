import React from 'react';
import { Controller } from 'react-hook-form';
import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';

export interface CheckboxFieldProps extends FieldProps<MUICheckboxProps> {
  label?: string;
}

export default function CheckboxField({
  control,
  name,
  rules,
  ...props
}: CheckboxFieldProps) {
  const label = useFormLabel(name, props.label);

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <MUICheckbox
                    checked={value || false}
                    onChange={onChange}
                    {...props}
                  />
                }
                label={label}
              />
            </FormGroup>

            {error && (
              <FormHelperText>
                <ValidationError error={error} />
              </FormHelperText>
            )}
          </FormControl>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
