import React, {
  CSSProperties,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { styled, SxProps } from '@mui/system';
import { addHexCodePrefix, isValidHexCode } from './hexCode';

const StyledColorPreview = styled('div')(() => ({
  backgroundColor: 'var(--color)',
  border: '0.15em solid white',
  borderRadius: '100vw',
  boxShadow: '0 0.1em 0.25em rgb(0 0 0 / 0.25)',
  boxSizing: 'border-box',
  height: '1.5em',
  width: '1.5em',
}));

export interface ColorPreviewProps extends HTMLAttributes<HTMLDivElement> {
  color: string;
  sx?: SxProps;
}

export default function ColorPreview({
  color,
  sx,
  ...props
}: ColorPreviewProps) {
  const [validColor, setValidColor] = useState<string>('#fff');

  useEffect(() => {
    if (!isValidHexCode(color)) return;
    setValidColor(addHexCodePrefix(color));
  }, [color]);

  const style = { '--color': validColor } as CSSProperties;
  return <StyledColorPreview style={style} sx={sx} {...props} />;
}
