import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import Translate from '../components/i18n/Translate';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import useOrganizationApi from '../organizations/useOrganizationApi';
import ListProjectsTable from './ListProjectsTable';

export default function ListProjectsView() {
  const api = useOrganizationApi();
  const { organizationId } = useParams();

  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const handleFetch = useCallback(async () => {
    const projects = await api.getProjects(organizationId);
    return projects;
  }, [api, organizationId]);

  return (
    <>
      <DocumentTitle translationID="organization.project.headline" />

      <FilterWrapper
        headline={<Translate id="organization.project.headline" />}
        topBarChildren={<></>}
        handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
      />

      <ResourceChartView
        Chart={ListProjectsTable}
        fetch={handleFetch}
        ref={resourceChartViewRef}
      />
    </>
  );
}
