import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useShopApi, { Shop, TABS } from './useShopApi';
import { ApplicationState } from '../reducers/initialState';
import useProjectNavigate from '../useProjectNavigate';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import ViewShopBaseForm from './ViewShopBaseForm';
import ViewShopAppForm, { ExtendedShop } from './ViewShopAppForm';
import useShopPosConfigApi from './useShopPosConfigApi';
import ViewShopPosConfigForm from './ViewShopPosConfigForm';
import ViewShopPosDevicesForm from './ViewShopPosDevicesForm';
import useCheckoutDeviceApi from '../sco/api/useCheckoutDeviceApi';

export default function ViewShopView() {
  const api = useShopApi();
  const shopDevicesApi = useShopPosConfigApi();
  const posDevicesApi = useCheckoutDeviceApi();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useProjectNavigate();
  const writePermission =
    useSelector<ApplicationState, boolean>(state => !!(state.navigation as any)?.shops?.write);
  const shops = useSelector<ApplicationState, any>(state => state.shops) as Shop[];
  const shopName = useMemo(() => (shops.find(shop => shop.id === id)?.name), [id, shops]);
  const [unloadPosDevices, setUnloadPosDevices] = useState(false);

  const handleFetchShop = useCallback(async () => api.getShop(id), [api, id]);

  const handleFetchExtendedShop = useCallback(async () => {
    if (!id) return {};
    const data: ExtendedShop = await api.getShop(id);
    data.serviceRelations = await api.getShopServiceRelations({ shopID: parseInt(id, 10) });
    return data;
  }, [api, id]);

  const handleFetchShopDevices = useCallback(async () => {
    if (!id) return null;
    const data = await shopDevicesApi.get({ shopID: id });
    return data;
  }, [id, shopDevicesApi]);

  const handleFetchPosDevices = useCallback(async () => {
    if (!id) return null;
    const data = await posDevicesApi.getShopStatus({ id });
    return data;
  }, [id, posDevicesApi]);

  const [currentTab, setCurrentTab] = useState<TabFilterOption>({
    id: TABS.BASE,
    title: t(`shop.tabs.${TABS.BASE}`),
  });

  const navigateToEditForm = useCallback((tab?: TabFilterOption) => {
    navigate(`/shops/${id}/edit`, {
      state: {
        currentTab: tab || currentTab,
      },
    });
  }, [currentTab, id, navigate]);

  const handleTabChange = useCallback(async (tab: TabFilterOption) => {
    setCurrentTab(tab);
    // HACK the current categories view does not support read-only
    // since BACKEND-2168 will update the editor we skip to the edit form for now
    if (tab.id === TABS.SHOP_CATEGORIES) {
      navigateToEditForm({
        id: TABS.SHOP_CATEGORIES,
        title: t(`shop.tabs.${TABS.SHOP_CATEGORIES}`),
      });
    }
  }, [navigateToEditForm, t]);

  const sharedResourceFormViewProps = {
    headline: shopName,
    headlineButtons: (writePermission && (
      <Button
        variant="contained"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => { navigateToEditForm(); }}
      >
        {t('shop.edit')}
      </Button>
    )),
    additionalContent: (
      <TabFilter
        options={Object.values(TABS).map(tab => ({
          id: tab, title: t(`shop.tabs.${tab}`),
        }))}
        defaultOption={currentTab}
        onChange={handleTabChange}
        sx={{ marginBottom: '32px' }}
      />
    ),
  };

  return (
    <>
      <DocumentTitle translationID="headlines.viewShop" />

      {(!currentTab?.id || currentTab?.id === TABS.BASE) && (
        <ResourceFormView
          actionName="viewShop"
          {...sharedResourceFormViewProps}
          Form={ViewShopBaseForm}
          fetch={handleFetchShop}
          submit={() => { }}
        />
      )}

      {currentTab?.id === TABS.APP && (
        <ResourceFormView
          actionName="viewShop"
          {...sharedResourceFormViewProps}
          Form={ViewShopAppForm}
          fetch={handleFetchExtendedShop}
          submit={() => { }}
        />
      )}

      {currentTab?.id === TABS.SHOP_DEVICES && (
        <ResourceFormView
          actionName="updateConfig"
          {...sharedResourceFormViewProps}
          Form={ViewShopPosConfigForm}
          fetch={handleFetchShopDevices}
          submit={() => { }}
        />
      )}

      {(currentTab?.id === TABS.POS_DEVICES && !unloadPosDevices) && (
        <ResourceFormView
          actionName="updatePosDevices"
          {...sharedResourceFormViewProps}
          Form={ViewShopPosDevicesForm}
          FormProps={{
            writePermission,
            // NOTE this is a hack to force the form to reload the data.
            // Normally in this situation we would use the ResourceChartView
            // which supports external refreshes. But since the rest of the
            // forms must support external submitting we have to stick with
            // the ResourceFormView. Extending the ResourceFormView to support
            // external refreshes would be overkill for this single use case.
            onRefresh: () => {
              setTimeout(() => { setUnloadPosDevices(false); }, 0);
              setUnloadPosDevices(true);
            },
            shopId: id,
          }}
          fetch={handleFetchPosDevices}
          submit={() => { }}
        />
      )}
    </>
  );
}
