import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from '../../../api';
import { isValidUrl } from '../../../utils/urlUtils';
import { prependApiHost } from '../../../urls';

export interface UseBase64DownloaderProps {
  url: string | undefined | null;
}

export default function useBase64Downloader({
  url,
}: UseBase64DownloaderProps) {
  const client = useApiClient({});

  const [dataAvailable, setDataAvailable] = useState(false);
  const [srcData, setSrcData] = useState<string | undefined>(undefined);

  const handleImageChange = useCallback(async () => {
    setDataAvailable(false);

    if (!url) return;

    if (url && isValidUrl(url)) {
      setDataAvailable(true);
      setSrcData(url);
      return;
    }

    const fullURL = prependApiHost(url);
    if (!fullURL) return;

    const { data } = await client.get(fullURL, { responseType: 'blob' });

    const reader = new window.FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => {
      if (reader.result) setSrcData(reader.result as string);
      setDataAvailable(true);
    };
  }, [client, url]);

  useEffect(() => {
    handleImageChange();
  }, [handleImageChange, url]);

  return {
    dataAvailable,
    srcData,
  };
}
