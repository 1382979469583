import React from 'react';
import Translate from './i18n/Translate';
import ProductAttributeCodes from './ProductAttributeCodes';
import PaperTable from './PaperTable';


function ProductDetailsCodes({
  codes, codeTemplates,
}) {
  if (!codes) return null;

  return (
    <PaperTable
      headline="Codes"
      tableHeader={[
        <Translate id="product.code" />,
        <Translate id="product.codeTemplate" />,
        <Translate id="product.encodingUnit" />,
        <Translate id="product.specifiedQuantity" />,
        <Translate id="product.barcode" />,
      ]}
    >
      {(codes || []).map(c => (
        <ProductAttributeCodes codeTemplates={codeTemplates} codeObj={c} key={c.code} />
      ))}
    </PaperTable>
  );
}

export default ProductDetailsCodes;
