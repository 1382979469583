import React, { ReactNode, forwardRef, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../form';
import Categories from './Categories';
import { ProductData } from './useCategoriesApi';
import ArrayScaffold, { ArrayScaffoldRef } from '../form/wrapper/ArrayScaffold';
import CategoriesProductDialogView from './CategoriesProductDialogView';
import useAlert from '../toast/useAlert';
import { CustomFormRef } from '../form/CustomFormProps';
import CategoriesMetaDialog from './CategoriesMetaDialog';
import useDragAndDrop from '../utils/useDragAndDrop';
import { reorderElement } from '../utils/arrayUtils';
import syles from './Draggable.module.scss';
import CategoriesFormProductGrid from './CategoriesFormProductGrid';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';
import CategoriesFormTabs from './CategoriesFormTabs';

const DEFAULT_VALUES: Categories = {
  categories: [],
  singleItems: [],
};

export interface CategoriesFormProps
  extends CustomFormProps<Categories> {
  additionalButtons?: ReactNode;
  onProductLookup: (search: string) => Promise<ProductData>;
}

const CategoriesForm = forwardRef<CustomFormRef, CategoriesFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
  onProductLookup,
}, ref) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    watch,
    setValue,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  const handleReset = useCallback(() => {
    // NOTE reset() seems to not work properly when arrays were empty or non-existent in the
    // original state. Therefore the page is reloaded instead of manually handling all edge-cases.
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    alert.success({ message: t('alert.success.reset') });
  }, [alert, t]);

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  const [tab, setTab] = useState(0);
  const [metaConfig, setMetaConfig] = useState<number | null>(null);
  const [searchProduct, setSearchProduct] = useState(false);
  const [replaceProduct, setReplaceProduct] = useState<string | null>(null);
  const categoriesArrayScaffoldRef = useRef<ArrayScaffoldRef>(null);
  const productArrayScaffoldRef = useRef<ArrayScaffoldRef>(null);
  const categories = watch('categories');

  const handleAddCategory = useCallback(() => {
    const numberOfCategories = (getValues('categories') || []).length;
    categoriesArrayScaffoldRef.current?.add({
      id: `Category ${numberOfCategories + 1}`,
      image: '',
      items: [],
    });
    setTab(numberOfCategories);
    setMetaConfig(numberOfCategories);
  }, [getValues]);

  const handleRemoveCategory = useCallback((index: number) => {
    categoriesArrayScaffoldRef.current?.remove(index);
    setMetaConfig(null);
    setTab(prevTab => (prevTab - 1 < 0 ? 0 : prevTab - 1));
  }, []);

  const useDrop = (name: string, callback?: (endIndex: number) => void) => (
    useCallback((start: HTMLElement, end: HTMLElement | null) => {
      const items = getValues(name);

      // NOTE this block is necessary because the index should always be a number. But storing it in
      // the dataset converts it to a string. TS does not know this. This is not a problem since JS
      // does not care for the most part. But when setting it as the current tab it remains a string
      // So when adding 1 further down in the TSX code it is concatenated instead of added.
      // So instead of e.g. 1 the index is "11" which is most likely out of bounds.
      if (!start?.dataset.index) return;
      let startIndex: number;
      let endIndex: number;
      try {
        startIndex = parseInt(start?.dataset.index, 10);
        const endIndexString = end?.dataset.index;
        if (endIndexString) {
          endIndex = parseInt(endIndexString, 10);
          // if the item is moved back the index must be decreased by 1, this is because the item is
          // removed from the list before being inserted which shifts the indices
          if (endIndex > startIndex) endIndex -= 1;
        } else {
          // if the item is moved to the end of the list it has no nearest sibling so the end index
          // is undefined. So the index is manually set to the last index
          endIndex = items.length - 1;
        }
      } catch {
        return;
      }

      const reorderedItems = reorderElement(
        items,
        startIndex,
        endIndex ?? items.length - 1,
      );
      setValue(name, reorderedItems, { shouldDirty: true });
      callback?.(endIndex);
    }, [callback, name])
  );

  const {
    draggableProps,
    droppableProps,
  } = useDragAndDrop({
    onDrop: useDrop(`categories.${tab}.items`),
    onDraggedOverClassName: syles.draggedOver,
    onDraggingClassName: syles.dragging,
  });

  const {
    draggableProps: draggablePropsTab,
    droppableProps: droppablePropsTab,
  } = useDragAndDrop({
    onDrop: useDrop('categories', (endIndex) => { setTab(endIndex); }),
    onDraggedOverClassName: syles.draggedOver,
    onDraggingClassName: syles.dragging,
  });

  return (
    <>
      {(metaConfig !== null) && (
        <CategoriesMetaDialog
          control={control}
          categoryIndex={metaConfig}
          getValues={getValues}
          setValue={setValue}
          onCategoryRemove={handleRemoveCategory}
          onClose={() => { setMetaConfig(null); }}
        />
      )}

      {searchProduct && (
        <CategoriesProductDialogView
          onSelect={(product: ProductData | undefined) => {
            if (product) {
              if (getValues(`categories.${tab}.items`)?.includes(product.sku)) {
                alert.error({ message: t('categories.alert.categoryProductDuplicate', { product: product.name }) });
                return;
              }
              productArrayScaffoldRef.current?.add(product.sku);
              alert.success({ message: t('categories.alert.categoryProductAdded', { product: product.name }) });
            }
          }}
          onClose={() => { setSearchProduct(false); }}
        />
      )}

      {replaceProduct && (
        <CategoriesProductDialogView
          onSelect={(product: ProductData | undefined) => {
            if (product) {
              if (getValues(`categories.${tab}.items`)?.includes(product.sku)) {
                alert.error({ message: t('categories.alert.categoryProductDuplicate', { product: product.name }) });
                return;
              }
              setValue(replaceProduct, product.sku, { shouldDirty: true });
              alert.success({ message: t('categories.alert.categoryProductAdded', { product: product.name }) });
            }
          }}
          onClose={() => { setReplaceProduct(null); }}
          searchQuery={getValues(replaceProduct)}
          singleSelect
        />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <CategoriesFormTabs
          tab={tab}
          onTabChange={setTab}
          containerProps={droppablePropsTab}
          itemProps={draggablePropsTab}
          onAddCategory={handleAddCategory}
          categories={categories}
          onMetaConfigChange={setMetaConfig}
        />

        <ArrayScaffold
          control={control}
          name="categories"
          defaultValue={{}}
          render={(index: number) => {
            if (tab !== index) return null;
            return (
              <CategoriesFormProductGrid
                containerProps={droppableProps}
                itemProps={draggableProps}
                onAddProduct={() => { setSearchProduct(true); }}
                onProductLookup={onProductLookup}
                onProductReplace={(name: string) => { setReplaceProduct(name); }}
                getValues={getValues}
                arrayScaffoldRef={productArrayScaffoldRef}
                control={control}
                name={`categories.${index}.items`}
              />
            );
          }}
          getKey={(name: string, index: number) => `tab-view-${index}`}
          ref={categoriesArrayScaffoldRef}
        />

        <ConfirmationDialog />
        <DefaultFormActions
          formState={formState}
          getValues={getValues}
          reset={reset}
          onCancel={handleReset}
          additionalButtons={additionalButtons}
        />
      </Form>
    </>
  );
});

export default CategoriesForm;
