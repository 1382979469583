import moment from 'moment-timezone';

const files = {
  csv: {
    label: 'CSV',
    contentType: 'text/csv',
    mimeType: 'text/csv',
    fileEnding: 'csv',
  },
  json: {
    label: 'JSON',
    contentType: 'application/json',
    mimeType: 'application/json',
    fileEnding: 'json',
  },
  dfka: {
    label: 'DFKA Taxonomie',
    contentType: 'application/vnd.snabble.dfka+json',
    mimeType: 'application/json',
    fileEnding: 'json',
  },
  dsfinvk: {
    label: 'DSFinV-K',
    contentType: 'application/vnd.snabble.dsfinvk+zip',
    mimeType: 'application/zip',
    fileEnding: 'zip',
  },
};

const delimiterOptions = [';', ','];

const dateFormat = 'YYYY-MM-DD';

function toISOString(date: Date, endOfDay: boolean) {
  if (date && moment(date).isValid()) {
    const momentDate = endOfDay ? moment(date).endOf('day') : moment(date);
    return momentDate.toISOString(true);
  }
  return '';
}

function toISOStringInTz(date: Date, timezone: string, endOfDay: boolean) {
  if (date && moment.tz(date, timezone).isValid()) {
    const momentDate = moment.tz(date, timezone);
    return (endOfDay ? momentDate.endOf('day') : momentDate).toISOString(true);
  }
  return '';
}

export { files, delimiterOptions, dateFormat, toISOString, toISOStringInTz };
