import { ShopServiceRelation } from '../shop/useShopApi';

export enum ShopServiceLanguage {
  DE = 'de',
  EN = 'en',
}

export interface ShopService {
  id: number;
  iconURL?: string;
  translations: Record<ShopServiceLanguage, string>;
}

export interface ShopServices {
  projectServices: ShopService[];
}

export function getShopServiceFromRelation(
  shopServiceRelation: ShopServiceRelation,
  allServices: ShopService[],
): ShopService {
  const shopService: ShopService = {
    id: shopServiceRelation.serviceID,
    translations: { ...shopServiceRelation.translations },
    iconURL: allServices.find(s => s.id === shopServiceRelation.serviceID)?.iconURL,
  };
  return shopService;
}
