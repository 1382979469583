import React, { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SxProps } from '@mui/system';

export interface TabFilterOption {
  id: string;
  title: string;
  [key: string]: string;
}

interface GenericSearchableSelectFilterProps {
  options: TabFilterOption[];
  defaultOption?: TabFilterOption;
  onChange: (option: TabFilterOption) => void;
  value?: TabFilterOption;
  sx?: SxProps;
}

export default function TabFilter({
  options,
  defaultOption,
  onChange,
  value,
  sx,
}: GenericSearchableSelectFilterProps) {
  const indexOfDefaultOption = useMemo(() => {
    if (!defaultOption) return 0;
    return options.findIndex(option => option.id === defaultOption.id);
  }, [defaultOption, options]);

  const [selectedIndex, setSelectedIndex] = useState<number>(indexOfDefaultOption);

  const indexOfValue = useMemo(() => {
    if (!value) return null;
    return options.findIndex(option => option.id === value.id);
  }, [value, options]);

  const handleChange = useCallback((event: any, index: number) => {
    setSelectedIndex(index);
    onChange(options[index]);
  }, [onChange, options]);

  return (
    <Box sx={{ ...sx, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={indexOfValue ?? selectedIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {options.map(option => (
          <Tab label={option.title} key={`tab-filter-${option.id}`} />
        ))}
      </Tabs>
    </Box>
  );
}
