import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useResourceTranslator } from '../../resource';

export interface Props {
  title: string | undefined,
  children: ReactElement<any, any>;
  [key: string]: any
}

export default function TranslatedTooltip({ title, children, ...props }: Props) {
  const t = useResourceTranslator();

  return (
    <Tooltip title={t(`tooltip.${title}`)} {...props}>
      {children}
    </Tooltip>
  );
}
