import React, { ReactNode } from 'react';
import withStyles from '@mui/styles/withStyles';
import TopBar from './TopBar';
import Sidebar from '../sidebar/Sidebar';

const styles = (theme: any) => ({
  contentWrapper: {
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    position: 'relative',
  },
  main: {
    boxSizing: 'border-box',
    maxWidth: '1280px',
    margin: '0 auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down(700)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  appContainer: {
    display: 'flex',
    maxWidth: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    minHeight: '100%',
  },
  content: {
    width: '100%',
    // NOTE  minWidth: 0 is required to prevent the content from overflowing its container.
    // I do not know why but https://stackoverflow.com/a/41675912 says so and it works.
    minWidth: 0,
    flex: '1 1 auto',
  },
});

interface ContentSkeletonProps {
  children: ReactNode;
  classes: any;
}

function ContentSkeleton({
  children,
  classes,
}: ContentSkeletonProps) {
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.appContainer}>
        <Sidebar />
        <div className={classes.content}>
          <TopBar />
          <main className={classes.main}>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(ContentSkeleton);
