import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@mui/styles/styled';
import SelectOptionsField from '../form/input/SelectOptionsField';
// @ts-ignore
import { ReactComponent as AmericanExpressIcon } from '../images/payment-amex.svg';
// @ts-ignore
import { ReactComponent as ApplePayIcon } from '../images/payment-apple-pay.svg';
// @ts-ignore
import { ReactComponent as DankortIcon } from '../images/payment-dankort.svg';
// @ts-ignore
import { ReactComponent as CardTerminalIcon } from '../images/payment-card-terminal.svg';
// @ts-ignore
import { ReactComponent as DinersIcon } from '../images/payment-dinersclub.svg';
// @ts-ignore
import { ReactComponent as GirocardIcon } from '../images/payment-girocard.svg';
// @ts-ignore
import { ReactComponent as GooglePayIcon } from '../images/payment-google-pay.svg';
// @ts-ignore
import { ReactComponent as MastercardIcon } from '../images/payment-mastercard.svg';
// @ts-ignore
import { ReactComponent as PostfinanceIcon } from '../images/payment-postfinance.svg';
// @ts-ignore
import { ReactComponent as TwintIcon } from '../images/payment-twint.svg';
// @ts-ignore
import { ReactComponent as VisaIcon } from '../images/payment-visa.svg';

const Stack = styled('span')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '8px',
}));

const SvgContainer = styled('span')(() => ({
  height: '24px',
  overflow: 'none',
  maxHeight: '24px',
  '& svg': {
    height: '100%',
    width: 'max-content',
    maxWidth: '48px',
  },
}));

export const AVAILABLE_PAYMENT_TERMINAL_ICONS = [
  'american_express',
  'apple_pay',
  'dankort',
  'debit',
  'diners',
  'girocard',
  'google_pay',
  'master_card',
  'postfinance',
  'twint',
  'visa',
];

const ICONS: Partial<Record<typeof AVAILABLE_PAYMENT_TERMINAL_ICONS[number], any>> = {
  american_express: <AmericanExpressIcon />,
  apple_pay: <ApplePayIcon />,
  dankort: <DankortIcon />,
  debit: <CardTerminalIcon />,
  diners: <DinersIcon />,
  girocard: <GirocardIcon />,
  google_pay: <GooglePayIcon />,
  master_card: <MastercardIcon />,
  postfinance: <PostfinanceIcon />,
  twint: <TwintIcon />,
  visa: <VisaIcon />,
};

interface SelectPaymentTerminalIconFieldProps {
  control: any;
  name: string;
}

export default function SelectPaymentTerminalIconField({
  control,
  name,
}: SelectPaymentTerminalIconFieldProps) {
  const { t } = useTranslation();

  return (
    <SelectOptionsField
      control={control}
      name={name}
      helperText={t('checkoutDevices.form.helperText.paymentTerminalIcons')}
      options={AVAILABLE_PAYMENT_TERMINAL_ICONS}
      labelFrom={(option: any) => ((
        <Stack>
          <SvgContainer>{ICONS[option]}</SvgContainer>
          <span>{t(`form.options.paymentTerminalIcons.${option}`)}</span>
        </Stack>
      ) as any)}
      multiple
    />
  );
}
