import getTimezone from '../../actions/timezoneHelper';
import { toSearchQuery } from '../../urls';

export default function visitorStatisticQueryString(params) {
  return toSearchQuery({
    from: params.from,
    to: params.to,
    timezone: getTimezone(),
    shopID: params.shopID,
  });
}
