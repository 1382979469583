import React from 'react';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  tableCell: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    width: '50%',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: 'inherit',
    [theme.breakpoints.down(700)]: {
      width: '75%',
      border: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
    wordBreak: 'break-all',
  },
  smallCell: {
    paddingRight: theme.spacing(1),
    width: '20%',
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
  xsCell: {
    paddingRight: theme.spacing(1),
    width: '10%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '25%',
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
});

function ProductListTableCell({
  classes, content, small, hideOnMobile, xs,
}) {
  let cellClasses = classes.tableCell;
  if (small) {
    cellClasses = `${cellClasses} ${classes.smallCell}`;
  }
  if (xs) {
    cellClasses = `${cellClasses} ${classes.xsCell}`;
  }
  if (hideOnMobile) {
    cellClasses = `${cellClasses} ${classes.hideOnMobile}`;
  }
  return (
    <TableCell className={cellClasses}>
      {content}
    </TableCell>
  );
}

export default withStyles(styles)(ProductListTableCell);
