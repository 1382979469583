import React, { ReactNode, forwardRef } from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

type Categories = Record<string, string>;

const DEFAULT_VALUES: Categories = {
  categories: '',
  singleItems: '',
};

export interface CategoriesFormProps
  extends CustomFormProps<Categories> {
  additionalButtons?: ReactNode;
}

const CategoriesForm = forwardRef<CustomFormRef, CategoriesFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
}, ref) => {
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="categories">
        <TextField
          control={control}
          name="categories"
          multiline
          rows={20}
        />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
        getValues={getValues}
        reset={reset}
        additionalButtons={additionalButtons}
      />
    </Form>
  );
});

export default CategoriesForm;
