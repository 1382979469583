import React, { useCallback } from 'react';
import { Controller, UseFormSetValue } from 'react-hook-form';
import toPairs from 'lodash/toPairs';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import useFormLabel from './useFormLabel';
import AddressAutocomplete from '../../googlePlacesApi/AddressAutocomplete';
import { Address, AddressField } from '../../googlePlacesApi/useAutocompleteApi';

export interface AddressFieldProps extends FieldProps<any> {
  type: AddressField;
  addressFieldsToAutoUpdate?: Partial<Record<keyof Address, string>>;
  setValue?: UseFormSetValue<Record<string, any>>;
  onAutocompleted?: (address: Address | undefined) => void;
  onManualChange?: () => void;
}

export default function AutocompleteAddressField({
  control,
  name,
  type,
  addressFieldsToAutoUpdate,
  setValue,
  onAutocompleted,
  onManualChange,
  rules,
  optional = false,
  ...props
}: AddressFieldProps) {
  const label = useFormLabel(name, props.label, { removeIndex: true, optionalHint: optional });

  const updateAddressFields = useCallback((newAddress: Address | undefined) => {
    toPairs(addressFieldsToAutoUpdate).forEach(([key, path]) => {
      if (!newAddress?.[key as keyof Address]) return;
      setValue?.(path, newAddress[key as keyof Address]);
    });
    onAutocompleted?.(newAddress);
  }, [addressFieldsToAutoUpdate, onAutocompleted, setValue]);

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <AddressAutocomplete
              type={type}
              onChange={(newAddress) => {
                onChange(newAddress?.[type as keyof Address]);
                updateAddressFields(newAddress);
              }}
              onInputChange={(event) => {
                onChange(event);
                onManualChange?.();
              }}
              value={value}
              label={label as string}
            />
          </>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
