import React from 'react';
import { WrappedFunctionProps } from '../types';
import { ColorField as FormColorField } from '../../form/input';

export default function ColorField({
  key,
  translation,
  language,
  control,
}: WrappedFunctionProps) {
  return (
    <FormColorField
      control={control}
      name={key}
      label={translation?.title?.[language] || key}
      helperText={translation?.description?.[language]}
      withPrefix
    />
  );
}
