import actions from './constants';
import { selectProject, fetchProjects, restrictedProject, unsetProject, fetchPortalMetadata } from './projects';
import { fetchOrders, fetchOrder, fetchOrderStatistic, fetchLastWeekOrderStatistic, resetOrderStatistic, fetchOrdersForExport, fetchOrdersForLineItemExport } from './orders';
import { fetchBasketAverageStatistic, resetBasketAverageStatistic, receivedBasketAverageStatistic } from './basketAverage';
import { fetchProductStatistic, fetchProducts, fetchProduct, resetProduct } from './products';
import { fetchPricing, fetchPricingCategories, updatePricingCategory, editPricingCategoryStatusUpdate, deletePricingCategory, fetchPricingCategory } from './pricing';
import { fetchShops, fetchShop } from './shops';
import { fetchRecurringVisitorsStatistic, resetRecurringVisitorsStatistic, receivedRecurringVisitorsStatistic } from './recurringVisitors';
import { fetchClosings, fetchClosing, fetchClosingSchedules, fetchClosingSchedule, createClosingSchedule, editClosingScheduleStatusUpdate, updateClosingSchedule, deleteClosingSchedule } from '../closings/actions/closings';
import { fetchCheckoutDevices, fetchCheckoutDevicesStatus, fetchCheckoutDevice } from '../sco/actions/checkoutDevices';
import { fetchApprovalDurationStatistic, resetApprovalDurationStatistic } from './approvals';
import { updateRange, updateShopFilter } from '../charts/actions/filter';

function acquiredToken(token) {
  return {
    type: actions.ACQUIRED_TOKEN,
    payload: token,
  };
}

export {
  selectProject, fetchProjects, acquiredToken, fetchPortalMetadata,
  restrictedProject, unsetProject, fetchProductStatistic,
  fetchShops, fetchShop, fetchOrders, fetchOrder, fetchOrderStatistic, fetchProducts,
  fetchProduct, fetchPricing, fetchPricingCategories, updatePricingCategory,
  editPricingCategoryStatusUpdate, deletePricingCategory,
  fetchLastWeekOrderStatistic, resetOrderStatistic,
  fetchBasketAverageStatistic, resetBasketAverageStatistic, receivedBasketAverageStatistic,
  fetchRecurringVisitorsStatistic, resetRecurringVisitorsStatistic, fetchPricingCategory,
  receivedRecurringVisitorsStatistic, fetchClosings, fetchClosing, resetProduct,
  fetchClosingSchedules, fetchClosingSchedule, createClosingSchedule,
  editClosingScheduleStatusUpdate, updateClosingSchedule, deleteClosingSchedule,
  fetchOrdersForExport, fetchOrdersForLineItemExport, fetchCheckoutDevices,
  fetchCheckoutDevicesStatus, fetchCheckoutDevice,
  fetchApprovalDurationStatistic, resetApprovalDurationStatistic, updateRange, updateShopFilter,
};
