import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'qs';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { Order, OrdersResponse } from './orderTypes';
import { prependApiHost } from '../urls';
import { ApplicationState } from '../reducers/initialState';
import { mapOrder } from './orderHelper';
import mapSupervisingResult from './supervisingHelper';
import { SupervisingResult } from './supervisingTypes';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { Filter } from '../charts/chartFilterProps';

enum ApiOrderState {
  Final = 'final',
  PreconditionsNotMet = 'preconditionsNotMet',
  PaymentFailed = 'paymentFailed',
  UserAborted = 'userAborted',
  SystemAborted = 'systemAborted',
}

type UiOrderStateMapping = Record<string, {
  translationID: string;
  apiStates: ApiOrderState[];
  includeAllStates: boolean;
}>;
export const uiOrderStateMapping: UiOrderStateMapping = {
  succeeded: {
    translationID: 'orders.succeededOnly',
    apiStates: [],
    includeAllStates: false,
  },
  succeededNotTransferred: {
    translationID: 'orders.withoutTransferred',
    apiStates: [ApiOrderState.Final],
    includeAllStates: false,
  },
  failed: {
    translationID: 'orders.stateFailed',
    apiStates: [
      ApiOrderState.PreconditionsNotMet,
      ApiOrderState.PaymentFailed,
      ApiOrderState.UserAborted,
      ApiOrderState.SystemAborted,
    ],
    includeAllStates: false,
  },
  all: {
    translationID: 'orders.allStates',
    apiStates: [],
    includeAllStates: true,
  },
};
const getUiOrderStateMapping = (key: string) => uiOrderStateMapping[key] || uiOrderStateMapping.all;

export type OrdersFilter = ({
  from?: string;
  to?: string;
} | {
  date?: string;
}) & {
  q?: string; // order id search query
  page?: number;
  perPage?: number;
  shopID?: string; // can be added multiple times
  'shop.externalID'?: string;
  state?: ApiOrderState[]; // can be added multiple times
  includeAllStates?: boolean;
};

const defaultOrdersFilter: OrdersFilter = { page: 0, perPage: 50 };

export default function useOrderApi() {
  const token = useSelector((state: ApplicationState) => state.token.raw);

  const useApi = useMemo(() => (defineApi({
    getOrders: async (client, filter: Filter): Promise<any> => {
      const params: OrdersFilter = {
        ...defaultOrdersFilter,
        from: filter.range.from,
        to: filter.range.to,
        shopID: filter.genericFilter?.shopID,
        q: filter.genericFilter?.orderID,
        state: getUiOrderStateMapping(filter.genericFilter?.orderStatus)?.apiStates,
        ...(getUiOrderStateMapping(filter.genericFilter?.orderStatus)?.includeAllStates
            && { includeAllStates: true }),
        page: filter.page - 1,
      };
      const { data, status } = await client.get<OrdersResponse>('/orders', {
        params,
        paramsSerializer: sParam => stringify(sParam, { arrayFormat: 'repeat' }),
        validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
      });
      if (status === 404) return { orders: [], page: 0, totalPages: 0 };
      return {
        orders: data.resources,
        page: data.pagination.page,
        totalPages: data.pagination.totalPages,
        totalItems: data.pagination.totalItems,
      };
    },
    getOrder: async (client, orderId: string): Promise<any> => {
      if (!orderId) return undefined;
      const { data } = await client.get<Order>(`/orders/${encodeURIComponent(orderId)}`);
      return mapOrder(data);
    },
    getSupervisingResult: async (client, checkoutProcessId: Pick<Order, 'checkoutProcessID'>): Promise<any> => {
      const { data, status } = await client.get<SupervisingResult>(
        `/supervising/results/checkout-id/${checkoutProcessId}`,
        {
          validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
        },
      );
      if (status === 404) return null;
      return mapSupervisingResult(data);
    },
    getReceipt: async (_, receiptLink: Order['receiptLink']) => {
      if (!receiptLink) return undefined;

      const response = await fetch(
        prependApiHost(receiptLink),
        { headers: { 'Client-Token': token } },
      );

      if (response.status === 200) return response.blob();
      return null;
    },
  })), [token]);

  const client = useProjectSpecificApiClient({ basePath: '/' });
  return useApi(client);
}
