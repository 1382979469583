import React from 'react';
import Translate from '../components/i18n/Translate';
import { formatPrice } from '../components/ProductFormatPrice';
import PaperTableRow from '../components/PaperTableRow';
import PaperTable from '../components/PaperTable';

class ClosingViewShareByMethods extends React.PureComponent {
  render() {
    const { closing } = this.props;
    if (!closing.shareByPaymentMethod || !closing.shareByPaymentMethod.length) {
      return null;
    }

    return (
      <PaperTable
        headline={<Translate id="closings.byMethods" />}
        headerAlignment={['left', 'left']}
        tableHeader={[
          <Translate id="closings.method" />,
          <Translate id="closings.amount" />,
        ]}
      >
        {(closing.shareByPaymentMethod || []).map(share => (
          <PaperTableRow
            key={share.paymentMethod}
            label={<Translate id={`paymentMethod.${share.paymentMethod}`} />}
            value={formatPrice(share.amount, closing.currency)}
          />
        ))}
      </PaperTable>
    );
  }
}

export default ClosingViewShareByMethods;
