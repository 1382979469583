import React from 'react';
import Translate from './i18n/Translate';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import Headline from './Headline';
import NoResult from './NoResult';
import PaperTable from './PaperTable';
import PaperTableRow from './PaperTableRow';
import ContentLink from './ContentLink';
import DocumentTitle from './DocumentTitle';

class PricingCategoryView extends React.Component {
  componentDidMount() {
    this.props.fetchPricingCategories();
    window.scrollTo(0, 0);
  }

  render() {
    const {
      showLoadingIndicator, selectedCategory, id, canEdit,
    } = this.props;

    if (!selectedCategory) {
      return (
        <React.Fragment>
          <SnabbleLinearProgress show={showLoadingIndicator} />
          <DocumentTitle translationID="pricingCategory.itemName" />
          <NoResult
            headline={<Translate id="pricingCategory.itemName" />}
            text={<Translate id="pricingCategory.noCategory" data={{ id }} />}
        />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={showLoadingIndicator} />

        <Headline withLink={canEdit} to="edit" linkText={<Translate id="pricingCategory.edit" />}>
          <Translate id="pricingCategory.itemName" />
        </Headline>

        <PaperTable headline={<Translate id="pricingCategory.itemName" />}>
          <PaperTableRow
            label={<Translate id="pricingCategory.id" />}
            value={selectedCategory.id}
          />
          <PaperTableRow
            label={<Translate id="pricingCategory.name" />}
            value={selectedCategory.name}
          />

          <PaperTableRow
            label={<Translate id="shop.headline" />}
            value={
              (selectedCategory.currentShops || []).map((shop, index) => (
                <React.Fragment key={shop.id}>
                  {index !== 0 && ', '}
                  <ContentLink to={`/${shop.project}/shops/${shop.id}`}>
                    {shop.label}
                  </ContentLink>
                </React.Fragment>
              ))}
          />

        </PaperTable>
      </React.Fragment>
    );
  }
}

export default PricingCategoryView;
