import React, { ReactNode, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { CollapsiblePanel } from '../panel';
import AttributesInput from './AttributesInput';
import AttributesList from './AttributesList';

export interface CollapsibleAttributesPanelProps {
  headline: ReactNode;
  attributes: AttributesInput;
}

export default function CollapsibleAttributesPanel({
  headline,
  attributes,
}: CollapsibleAttributesPanelProps) {
  const [expanded, setExpanded] = useState(false);

  if (isEmpty(attributes)) return null;

  return (
    <CollapsiblePanel
      headline={headline}
      expanded={expanded}
      onToggle={setExpanded}
    >
      <AttributesList attributes={attributes} />
    </CollapsiblePanel>
  );
}
