import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchSorter } from 'match-sorter';
import Translate from './i18n/Translate';
import { ApplicationState } from '../reducers/initialState';
import SearchableSelect from './SearchableSelect';

export const searchableProjectSelectSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

interface ProjectFilterProps extends Record<string, any> {
  onChange: (project: Record<string, any>) => void;
  showLabel?: boolean;
  defaultProjectId?: string;
}

export default function SearchableProjectSelect({
  onChange,
  showLabel = false,
  defaultProjectId,
  ...props
}: ProjectFilterProps) {
  const projects = useSelector<ApplicationState, any[]>(state => state.projects);
  const defaultProject = useMemo(() => {
    if (!defaultProjectId) return undefined;
    return projects?.find(project => (project.id === defaultProjectId));
  }, [defaultProjectId, projects]);
  const label = showLabel ? <Translate id="filter.byProject" /> : undefined;

  return (
    <SearchableSelect
      onChange={(_, selectedProject) => { onChange(selectedProject); }}
      options={projects}
      sorterOptions={searchableProjectSelectSorterOptions}
      label={label}
      defaultValue={defaultProject}
      {...props}
    />
  );
}
