import { styled } from '@mui/system';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { ColorField, NumberField, SelectOptionsField, TextField } from '../form/input';
import FEATURE_FLAGS from './featureFlags';
import GlobalCheckoutDeviceConfig from './GlobalCheckoutDeviceConfig';
import SelectPaymentTerminalIconField, { AVAILABLE_PAYMENT_TERMINAL_ICONS } from './SelectPaymentTerminalIconField';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

const FieldColumnGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

const UI_DEFAULT_LANGUAGES = ['de', 'en'];

const DEFAULT_VALUES: GlobalCheckoutDeviceConfig = {
  cartDeletionTimeout: 60000,
  cartDeletionTimeoutSeconds: 60,
  cashierLoginURL: '',
  customerCardType: '',
  couponsURL: '',
  featureFlags: [],
  landingpageType: 'default',
  ledColorError: '#ff0000',
  ledColorPrimary: '#0377bb',
  ledColorSuccess: '#00ff00',
  emailAlertRecipients: '',
  posSessionTimeout: 60000,
  paymentTerminalIcons: AVAILABLE_PAYMENT_TERMINAL_ICONS,
};

type GlobalDeviceConfigFormProps = CustomFormProps<GlobalCheckoutDeviceConfig>;

const GlobalCheckoutDeviceConfigForm = forwardRef<CustomFormRef, GlobalDeviceConfigFormProps>((
  {
    defaultValues = DEFAULT_VALUES,
    errors = {},
    onSubmit,
  },
  ref,
) => {
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const { t } = useTranslation();

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="appearance">
        <SelectOptionsField
          control={control}
          name="landingpageType"
          options={['default', 'cigarette-picking', 'coffee-house']}
          required
          rules={{ required: true }}
          autoFocus
        />

        <SelectOptionsField
          control={control}
          name="language"
          options={UI_DEFAULT_LANGUAGES}
          includeEmpty
        />

        <FieldColumnGrid>
          <ColorField control={control} name="ledColorPrimary" />
          <ColorField control={control} name="ledColorSuccess" />
          <ColorField control={control} name="ledColorError" />
        </FieldColumnGrid>

        <NumberField
          control={control}
          name="cartDeletionTimeoutSeconds"
          helperText={t('checkoutDevices.form.helperText.cartDeletionTimeout')}
        />

        <SelectPaymentTerminalIconField
          control={control}
          name="paymentTerminalIcons"
        />
      </Fieldset>

      <Fieldset category="userSettings">
        <NumberField
          control={control}
          name="posSessionTimeoutSeconds"
          rules={{
            min: 60,
          }}
          helperText={t('checkoutDevices.form.helperText.posSessionTimeout')}
        />
        <TextField
          control={control}
          name="cashierLoginRealm"
          inputProps={{
            inputMode: 'url',
          }}
          helperText={t('checkoutDevices.form.helperText.cashierLoginRealm')}
          optional
        />
      </Fieldset>

      <Fieldset category="customerCard">
        <SelectOptionsField
          control={control}
          name="customerCardType"
          options={['magnetic-stripe', 'numeric', 'code']}
          includeEmpty
        />
      </Fieldset>

      <Fieldset category="coupons">
        <TextField
          control={control}
          name="couponsURL"
          inputProps={{
            inputMode: 'url',
          }}
        />
      </Fieldset>

      <Fieldset category="feedback">
        <TextField
          control={control}
          name="feedbackURL"
          inputProps={{
            inputMode: 'url',
          }}
        />
      </Fieldset>

      <Fieldset category="features">
        <SelectOptionsField
          control={control}
          name="featureFlags"
          options={FEATURE_FLAGS}
          multiple
        />
      </Fieldset>

      <Fieldset category="alerting">
        <TextField
          control={control}
          name="emailAlertRecipients"
        />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
});

export default GlobalCheckoutDeviceConfigForm;
