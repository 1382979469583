import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import {
  CustomFormProps,
} from '../form';
import { Shop } from './useShopApi';
// @ts-ignore
// @ts-ignore
import ColoredTableRow from '../components/ColoredTableRow';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import PaperTable from '../components/PaperTable';
import { useResourceTranslator } from '../resource';
import { DeviceStatus, buildTimestampString, buildTransactionInfoString } from '../sco/CheckoutDeviceHelper';
import CheckoutDevicesStatusIndicator from '../sco/CheckoutDevicesStatusIndicator';
import useProjectNavigate from '../useProjectNavigate';
import TranslatedTooltip from '../components/i18n/TranslatedTooltip';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from './useForceSubmitDialog';

const DEFAULT_VALUES: Shop | {} = {};

function CheckoutDevicesTableRow({
  device,
  writePermission = false,
}: { device: any, writePermission?: boolean }) {
  const t = useResourceTranslator();
  const navigate = useProjectNavigate();

  const externalId = device?.externalID ? `(${device.externalID})` : '';

  return (
    <ColoredTableRow>
      <TableCell
        align="left"
        onClick={() => { navigate(`/checkout-devices/id/${device.id}`); }}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        <Tooltip title={device.id}>
          {device.id.slice(0, 4)}
        </Tooltip>
      </TableCell>

      <TableCell align="left" style={{ paddingRight: '5px' }}>
        {device ? t(`checkoutDevices.types.${device.kind}`) : ''}
      </TableCell>

      <TableCell align="left" style={{ paddingRight: '5px' }}>
        {`${device?.deviceName || ''} ${externalId}`.trim() || '-'}
      </TableCell>

      <TableCell align="left">
        {device?.systemInfo?.transactionInfo ? (
          <TranslatedTooltip title="transactionDaily">
            <span>
              { buildTransactionInfoString({
                successful: device.systemInfo.transactionInfo.dailySuccessfulTransactionCount,
                failed: device.systemInfo.transactionInfo.dailyFailedTransactionCount,
                overall: device.systemInfo.transactionInfo.dailyOverallTransactionCount,
              }) }
            </span>
          </TranslatedTooltip>
        ) : '-'
        }
      </TableCell>

      <TableCell align="left">
        <CheckoutDevicesStatusIndicator status={device.status === 'UP' ? DeviceStatus.Online : DeviceStatus.Offline} />
      </TableCell>

      <TableCell align="left">
        {device.lastSeenAt ? buildTimestampString(device.lastSeenAt) : '-'}
      </TableCell>

      <TableCell align="right" style={{ paddingRight: '10px' }}>
        <Stack direction="row" >
          <Tooltip title={t('tooltip.viewDevice')} placement="top" arrow>
            <IconButton size="small" onClick={() => navigate(`/checkout-devices/id/${device.id}`)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          {writePermission && (
            <>
              <Tooltip title={t('tooltip.editDevice')} placement="top" arrow>
                <IconButton size="small" onClick={() => navigate(`/checkout-devices/${device.id}/edit`)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('tooltip.deviceConfig')} placement="top" arrow>
                <IconButton size="small" onClick={() => navigate(`/checkout-devices/${device.id}/config`)}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </ColoredTableRow>
  );
}

export interface ViewShopPosDevicesFormProps extends CustomFormProps<Shop | {}> {
  writePermission: boolean;
  onRefresh?: () => void;
  shopId?: string;
}

const ViewShopPosDevicesForm = forwardRef<CustomFormRef, ViewShopPosDevicesFormProps>(({
  defaultValues = DEFAULT_VALUES,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errors = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit,
  writePermission,
  onRefresh,
  shopId,
}, ref) => {
  const navigate = useProjectNavigate();
  const data = defaultValues as any;

  useForceSubmitDialog({
    formState: {
      isDirty: false,
      isSubmitting: false,
    },
    ref,
  });

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        {onRefresh && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onRefresh}
          >
            <RefreshIcon />
          </Button>
        )}
        <Button
          onClick={() => navigate('/checkout-devices/new', { state: { shopId } })}
          variant="contained"
          sx={{ marginLeft: 'auto' }}
        >
          <Translate id="checkoutDevices.actions.add" />
        </Button>
      </Stack>
      <PaperTable
        headline={<Translate id="form.fieldsets.devices" />}
        tableHeader={
          [
            <Translate id="checkoutDevices.id" />,
            <Translate id="checkoutDevices.type" />,
            <Translate id="checkoutDevices.name" />,
            <Translate id="checkoutDevices.transactionDailyShort" />,
            <Translate id="checkoutDevices.statusIndicator" />,
            <Translate id="checkoutDevices.lastSeen" />,
            '',
          ]
        }
      >
        {(data.devices || []).map((device: any) => (
          <CheckoutDevicesTableRow
            key={device.id}
            device={device}
            writePermission={writePermission}
          />
        ))}
      </PaperTable>
    </>
  );
});

export default ViewShopPosDevicesForm;
