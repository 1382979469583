import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
// @ts-ignore
import ClosingSchedulesListContainer from '../ClosingSchedulesListContainer';
// @ts-ignore
import ClosingScheduleEditContainer from '../ClosingScheduleEditContainer';
// @ts-ignore
import ClosingScheduleDetailsContainer from '../ClosingScheduleDetailsContainer';
import RouteWrapper from '../../router/RouteWrapper';
import { Access } from '../../access';

function ClosingScheduleRoutes({ access }: { access?: Access }) {
  return (
    <Routes>
      {access?.read &&
        <React.Fragment>
          <Route path="/" element={<RouteWrapper><ClosingSchedulesListContainer /></RouteWrapper>} />
          <Route path="/id/:id" element={<RouteWrapper><ClosingScheduleDetailsContainer /></RouteWrapper>} />
        </React.Fragment>
      }
      {access?.write &&
        <React.Fragment>
          <Route path="/id/new" element={<RouteWrapper><ClosingScheduleEditContainer /></RouteWrapper>} />
          <Route path="/id/:id/edit" element={<RouteWrapper><ClosingScheduleEditContainer /></RouteWrapper>} />
        </React.Fragment>
      }
      <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
    </Routes>
  );
}

export default ClosingScheduleRoutes;
