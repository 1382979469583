import React from 'react';
import withStyles from '@mui/styles/withStyles';
import SaleStopIcon from '@mui/icons-material/Block';
import Translate from './i18n/Translate';

const styles = () => ({
  saleStopContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  saleStopIcon: {
    color: 'red',
  },
  saleStopText: {
    selfAlign: 'center',
  },
});

function ProductSaleStop({ classes, saleStop }) {
  const text = saleStop ? <Translate id="product.saleStop" /> : '';
  return (
    <span className={classes.saleStopContainer}>
      {saleStop ? <SaleStopIcon className={classes.saleStopIcon} /> : ''}
        &nbsp;
      <span className={classes.saleStopText}>{ text }</span>
    </span>
  );
}

export default withStyles(styles)(ProductSaleStop);
