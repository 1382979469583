import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import PricingCategoryEditViewContainer from '../containers/PricingCategoryEditViewContainer';
// @ts-ignore
import PricingCategoryListContainer from '../containers/PricingCategoryListContainer';
// @ts-ignore
import PricingCategoryViewContainer from '../containers/PricingCategoryViewContainer';
import RouteWrapper from './RouteWrapper';
import { Access } from '../access';

function PricingCategoryRoutes({ access }: { access?: Access }) {
  return (
    <Routes>
      {access?.read &&
        <React.Fragment>
          <Route path="/" element={<RouteWrapper><PricingCategoryListContainer /></RouteWrapper>} />
          <Route path="/id/:id" element={<RouteWrapper><PricingCategoryViewContainer /></RouteWrapper>} />
        </React.Fragment>
      }
      {access?.write &&
        <React.Fragment>
          <Route path="/id/create" element={<RouteWrapper><PricingCategoryEditViewContainer /></RouteWrapper>} />
          <Route path="/id/:id/edit" element={<RouteWrapper><PricingCategoryEditViewContainer /></RouteWrapper>} />
        </React.Fragment>
      }
      <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
    </Routes>
  );
}

export default PricingCategoryRoutes;
