import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CopyAll from '@mui/icons-material/CopyAll';
import Download from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { QRCodeSVG } from 'qrcode.react';
import { useResourceTranslator } from '../resource';
import CheckoutDevice, { CheckoutDeviceType } from './CheckoutDevice';
import { downloadJson, downloadSvgAsPng } from '../utils/downloadFile';
import { ApplicationState } from '../reducers/initialState';
import { whichEnvironment } from '../urls';
import shopLabel from '../shop';

const LEGACY_DEVICES: CheckoutDeviceType[] = [
  CheckoutDeviceType.SCO,
  CheckoutDeviceType.GATEKEEPER,
];

export interface ConfigDialogProps {
  device: CheckoutDevice | undefined;
  onClose?: () => void;
  open?: boolean;
}

export default function ConfigDialog({
  device,
  open = false,
  onClose,
}: ConfigDialogProps) {
  const t = useResourceTranslator();

  const projects = useSelector<ApplicationState, Record<string, any>[]>(state => state.projects);
  const selectedProject =
    useSelector<ApplicationState, string | null>(state => state.selectedProjectId);
  const selectedProjectName = useMemo(() => (
    projects.find(p => p.id === selectedProject)?.name
  ), [projects, selectedProject]);
  const shops = useSelector<ApplicationState, Record<string, any>[]>(state => state.shops);
  const env = whichEnvironment(window.location);

  const useLegacyConfigFormat = LEGACY_DEVICES.includes(device?.type as CheckoutDeviceType);
  const [useBase64Config, setUseBase64Config] = useState(false);

  const config = useMemo(() => {
    if (useLegacyConfigFormat) return device;
    const cleanedDevice = { ...device };
    delete cleanedDevice.links;
    return cleanedDevice;
  }, [device, useLegacyConfigFormat]);
  const visibleConfig = JSON.stringify(config, undefined, 2);
  // NOTE the pos is able to get the config as base64. That base64 string must be prefixed with
  // 'ConfigZ' and must not contain the '=' padding
  const qrCodeConfig = useBase64Config
    ? `ConfigZ;${btoa(JSON.stringify(config)).replace(/=/g, '')}`
    : JSON.stringify(config);

  const generateFilename = useCallback(() => {
    // Device Name - Shop Name ($shopID / $externalShopID) - Project Name (Environment).json
    // Environment for Prod not shown

    const shop = shopLabel(shops.find(s => s.id === device?.shop));
    const envString = env !== 'prod' ? ` (${env})` : '';

    return `${device?.name} - ${shop} - ${selectedProjectName}${envString}`;
  }, [device?.name, device?.shop, env, selectedProjectName, shops]);

  const handleJsonDownload = useCallback(() => {
    if (!visibleConfig) return;
    downloadJson(visibleConfig, `${generateFilename()}.json`);
  }, [generateFilename, visibleConfig]);

  const qrCode = useRef<HTMLDivElement>(null);
  const handleSvgDownload = useCallback(() => {
    // The ref prop is currently not working on QRCodeCanvas.
    // https://github.com/zpao/qrcode.react/issues/140
    // This is why we need a wrapper component that we can reference here.
    const qrCodeCanvas = (qrCode.current?.children[0] as HTMLCanvasElement);
    if (!qrCodeCanvas) return;
    downloadSvgAsPng(qrCodeCanvas, `${generateFilename()}.png`);
  }, [generateFilename]);

  const copyToClipboard = () => navigator.clipboard.writeText(visibleConfig);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('dialog.config.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog.config.body')}</DialogContentText>
        <Stack direction={{ sm: 'row', xs: 'column' }} gap="20px" >
          <Stack sx={{ overflow: 'hidden' }} alignItems="flex-end">
            <Box sx={{ width: '100%' }}>
              <Box component="pre" sx={{ overflow: 'auto', color: 'info.main' }}>
                {visibleConfig}
              </Box>
            </Box>
            <Stack direction="row">
              <IconButton onClick={copyToClipboard} aria-label={t('actions.copyToClipboard')} >
                <CopyAll />
              </IconButton>
              <IconButton onClick={handleJsonDownload} aria-label={t('actions.download')} >
                <Download />
              </IconButton>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <Stack ref={qrCode} style={{ flexGrow: 1, marginInline: 'auto' }} direction="column" justifyContent="center">
              <QRCodeSVG
                value={qrCodeConfig}
                size={512}
                bgColor="#ffffff"
                fgColor="#000000"
                level="L"
                includeMargin
                style={{ width: '256px', height: '256px' }}
              />
              {!useLegacyConfigFormat && (
                <Stack direction="row" alignItems="center">
                  <Switch
                    color="default"
                    checked={useBase64Config}
                    onChange={() => { setUseBase64Config(s => !s); }}
                  />
                  <Typography variant="caption">
                    {t('checkoutDevices.useBase64Config')}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <IconButton
              onClick={handleSvgDownload}
              aria-label={t('actions.download')}
            >
              <Download />
            </IconButton>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          autoFocus
        >
          {t('actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
