import React from 'react';
import Typography from '@mui/material/Typography';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  headline: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
});

function NoResult({
  classes, headline, text,
}) {
  return (
    <div className={classes.wrapper}>
      {!!headline &&
        <Typography variant="h5" component="h1" className={classes.headline}>
          {headline}
        </Typography>
      }

      {!!text &&
        <Typography>
          {text}
        </Typography>
      }
    </div>
  );
}

export default withStyles(styles)(NoResult);
