import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import useResourceContext from './useResourceContext';

export default function useResourceTranslator() {
  const { t } = useTranslation();

  const {
    name: resourceName,
    preventFallbackToGlobals,
  } = useResourceContext();

  return useCallback<TFunction>(
    (key, options) => {
      let defaultValue: string | undefined;
      if (!preventFallbackToGlobals) {
        defaultValue = t(key, options);
      }

      if (!resourceName) {
        return defaultValue;
      }

      return t(
        `${resourceName}.${key}`,
        Object.assign({}, options, { defaultValue }),
      );
    },
    [preventFallbackToGlobals, resourceName, t],
  );
}
