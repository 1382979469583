import Button from '@mui/material/Button';
import React, { useCallback } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import isFunction from 'lodash/isFunction';
import useResourceTranslator from '../../resource/useResourceTranslator';

export interface ArrayWrapperProps<T> {
  control: Control;
  name: string;
  defaultValue: T | (() => T);
  // path is the path to the element including the index
  // e.g. path.to.this.array[4]
  render: (index: number, path: string) => any;
  inlineDeleteButton?: boolean;
  indent?: boolean;
  disabled?: boolean;
  addLabel?: string;
  removeLabel?: string;
}

export default function ArrayWrapper<T>({
  control,
  name,
  defaultValue,
  render,
  inlineDeleteButton = false,
  indent = false,
  disabled = false,
  addLabel,
  removeLabel,
}: ArrayWrapperProps<T>) {
  const t = useResourceTranslator();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const getDefaultValue = useCallback(() => (
    isFunction(defaultValue) ? defaultValue() : defaultValue
  ), [defaultValue]);

  return (
    <Stack
      spacing={2}
      divider={!inlineDeleteButton && <Divider orientation="horizontal" flexItem sx={{ backgroundColor: 'grey' }} />}
      sx={indent ? {
        paddingLeft: '32px',
        borderLeft: '1px solid grey',
      } : {}}
    >
      {fields.map((field: any, index: number) => (
        <Stack
          key={field.id}
          direction={inlineDeleteButton ? 'row' : 'column'}
          spacing={1}
        >
          {render(index, `${name}[${index}]`)}
          <Button
            aria-label="delete"
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => { remove(index); }}
            disabled={disabled}
          >
            {removeLabel || t('button.remove')}
          </Button>
        </Stack>
      ))}
      <Button
        aria-label="add"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => { append(getDefaultValue()); }}
        fullWidth
        disabled={disabled}
      >
        {addLabel || t('button.add')}
      </Button>
    </Stack>
  );
}

