import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import { ShopService, ShopServiceLanguage } from './shopServices';
import useBase64Downloader from '../form/input/imageField/useBase64Downloader';

interface ShopServiceIconPreviewProps {
  service: ShopService
}

export default function ShopServiceIconPreview({
  service,
}: ShopServiceIconPreviewProps) {
  const { i18n } = useTranslation();
  const language = useMemo<ShopServiceLanguage>(() => (
    (i18n.resolvedLanguage || 'de') as ShopServiceLanguage
  ), [i18n.resolvedLanguage]);

  const { dataAvailable, srcData } = useBase64Downloader({ url: service.iconURL });

  if (!service.iconURL) return null;
  if (!dataAvailable) return <Skeleton variant="rectangular" width={32} height={24} />;

  return (<img
    src={srcData}
    alt={service.translations[language]}
    style={{ maxWidth: '64px', maxHeight: '24px' }}
  />);
}
