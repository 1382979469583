import React from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export interface ProgressButtonProps extends ButtonProps {
  loading?: boolean;
}

export default function ProgressButton({
  loading,
  disabled,
  color,
  ...props
}: ProgressButtonProps) {
  const isDisabled = disabled || loading;

  return (
    <Box sx={{ display: 'inline-flex', position: 'relative' }}>
      <Button {...props} color={color} disabled={isDisabled} fullWidth />
      {loading && (
        <CircularProgress
          size="1em"
          color={color}
          sx={{
            left: '50%',
            marginLeft: '-0.5em',
            marginTop: '-0.5em',
            position: 'absolute',
            top: '50%',
          }}
        />
      )}
    </Box>
  );
}
