import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import ColoredTableRow from './ColoredTableRow';

const styles = theme => ({
  keyCell: {
    width: '35%',
  },
  light: {
    color: theme.palette.common.white,
  },
  rowHeader: {
    backgroundColor: '#07b',
  },
});

const PaperTableRow = ({
  label, value, classes, header,
}) => {
  if (header) {
    return (
      <TableRow className={classes.rowHeader}>
        <TableCell className={classes.keyCell}>{label}</TableCell>
        <TableCell className={classes.light}>{value}</TableCell>
      </TableRow>
    );
  }
  return (
    <ColoredTableRow>
      <TableCell className={classes.keyCell}>{label}</TableCell>
      <TableCell>{value}</TableCell>
    </ColoredTableRow>
  );
};

export default withStyles(styles)(PaperTableRow);
