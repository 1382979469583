/* eslint-disable react/no-array-index-key */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { Order } from './SortableTableUtils';

const style = () => ({
  headerRow: {
    backgroundColor: '#d1d1d1',
  },
});

export type ColumnType = 'string' | 'numeric' | 'datetime' | 'boolean' | 'padding';

export type ColumnProp = {
  key: string,
  type?: ColumnType,
  disablePadding: boolean,
  displayName: string,
} | {
  type: 'padding',
  disablePadding: boolean,
};

export type ColumnsProp = ColumnProp[];

export interface SortableTableHeaderProps {
  numSelected: number,
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void,
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
  order: Order,
  orderBy: string,
  rowCount: number,
  columns: ColumnsProp,
  rowCheckbox?: boolean,
  rowButtons?: boolean,
}

export function SortableTableHeader(props: SortableTableHeaderProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    rowCheckbox,
    rowButtons,
  } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const classes = style();

  return (
    <TableHead>
      <TableRow style={classes.headerRow}>
        <TableCell padding="checkbox">
          {rowCheckbox && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          )}
        </TableCell>
        {columns.map((column, index) => (
          (column.type !== 'padding') ? (
            <TableCell
              key={column.key}
              align={column.type === 'numeric' ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.key ? order : false}
              sx={{ textWrap: 'nowrap' }}
            >
              <TableSortLabel
                active={orderBy === column.key}
                direction={orderBy === column.key ? order : 'asc'}
                onClick={createSortHandler(column.key)}
              >
                {column.displayName}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={`padding-${index}`}
              padding={column.disablePadding ? 'none' : 'normal'}
            />
          )
        ))}
        {rowButtons && (
          <TableCell padding="checkbox" />
        )}
      </TableRow>
    </TableHead >
  );
}
