import { connect } from 'react-redux';
import { fetchClosingSchedules } from '../actions';
import ClosingSchedulesList from './ClosingSchedulesList';

const ClosingSchedulesListContainer = connect(
  ({ closings, navigation, selectedProjectId }) => ({
    schedules: closings.schedules,
    isLoading: closings.isLoading,
    count: closings.schedules.length,
    projectId: selectedProjectId,
    canEdit: navigation.closingsSchedules && navigation.closingsSchedules.write,
  }),
  dispatch => ({
    fetchClosingSchedules: () =>
      dispatch(fetchClosingSchedules()),
  }),
)(ClosingSchedulesList);

export default ClosingSchedulesListContainer;
