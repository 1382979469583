import React from 'react';
import Translate from './i18n/Translate';
import ErrorBox from './ErrorBox';

type APIErrorBoxProps = {
  error: APIError | null | undefined;
};

type APIError = {
  type: string,
  message?: string,
};

export default function APIErrorBox({ error } : APIErrorBoxProps) {
  if (!error) return null;
  return (
    <ErrorBox>
      <Translate
        id={`apiErrors.${error.type}`}
        defaultValue={error.message}
      />
    </ErrorBox>
  );
}
