import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import AdditionalAttributes from './AdditionalAttributes';
import { useResourceTranslator } from '../resource';
import AdditionalAttributesDialog from './AdditionalAttributesDialog';
import ContentLink from '../components/ContentLink';

export interface AdditionalAttributesDialogButtonProps {
  attributes: AdditionalAttributes;
}

export default function AdditionalAttributesDialogButton({
  attributes,
}: AdditionalAttributesDialogButtonProps) {
  const t = useResourceTranslator();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (isEmpty(attributes)) return null;

  return (
    <>
      <AdditionalAttributesDialog
        attributes={attributes}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />

      <ContentLink
        component="button"
        Icon={MoreHoriz}
        title={t('actions.viewAdditionalAttributes')}
        onClick={handleClick}
      />
    </>
  );
}
