import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const PanelHeaderBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.main,
  border: 0,
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  width: '100%',
}));

const TextWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export interface PanelHeaderProps {
  children: ReactNode;
  extra?: ReactNode;
  onClick?: () => void;
}

export default function PanelHeader({
  children,
  extra,
  onClick,
}: PanelHeaderProps) {
  return (
    <PanelHeaderBox
      component={onClick ? 'button' : undefined}
      sx={{ cursor: onClick ? 'pointer' : undefined }}
      onClick={onClick}
    >
      <TextWrapper>
        <Typography variant="caption">
          {children}
        </Typography>
      </TextWrapper>
      {extra && (
        <div>
          {extra}
        </div>
      )}
    </PanelHeaderBox>
  );
}
