import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Pagination from '@mui/material/Pagination';

const styles = theme => ({
  paginationWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',

    '& nav': {
      width: 'auto',
    },
  },
});

const SnabblePagination = ({
  classes, page, count, onChange,
}) => (
  <div data-testid="pagination" className={classes.paginationWrapper}>
    <Pagination
      page={page}
      count={count}
      shape="rounded"
      onChange={onChange}
    />
  </div>
);

export default withStyles(styles)(SnabblePagination);
