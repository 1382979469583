import { styled } from '@mui/system';
import React from 'react';
import Barcode from 'react-barcode';
import { isValidEAN13, isValidEAN8 } from '../ean';

const BarcodeContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginRight: theme.spacing(20),
  [theme.breakpoints.down(700)]: {
    width: '100%',
  },
}));

function Code({ code, forceITF }) {
  if (!code || code === '') return null;
  let format = 'CODE128';
  if (forceITF) format = 'ITF';
  if (!forceITF && isValidEAN13(code)) format = 'EAN13';
  if (!forceITF && isValidEAN8(code)) format = 'EAN8';

  return (
    <BarcodeContainer data-testid={format} data-test-value={code}>
      <Barcode value={code} format={format} height={40} width={1} fontSize={14} margin={0} background="transparent" />
    </BarcodeContainer>
  );
}

export default Code;
