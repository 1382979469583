import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import useResourceTranslator from '../resource/useResourceTranslator';
import { Origin, RealmValidationRules, User } from './useUserManagementApi';
import ArrayWrapper from '../form/wrapper/ArrayWrapper';

const DEFAULT_VALUES: User | {} = {};

export interface CreateUserFormProps extends CustomFormProps<User | {}> {
  currentProject: string | undefined;
  availableRealms: any;
  isRealmAdmin: boolean;
  isSnabbleUser: boolean;
  realmValidationRules: RealmValidationRules | undefined;
  updateValidationRules: (realm: string) => void;
}

export default function CreateUserForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  currentProject,
  availableRealms,
  isRealmAdmin,
  isSnabbleUser = false,
  realmValidationRules,
  updateValidationRules,
}: CreateUserFormProps) {
  const t = useResourceTranslator();

  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const selectedRealm = watch('realm');
  useEffect(() => {
    updateValidationRules(selectedRealm);
  }, [selectedRealm, updateValidationRules]);

  const newRoleEntry = { project: currentProject, role: undefined };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="user">
        {isRealmAdmin && (
          <SelectOptionsField
            control={control}
            name="realm"
            options={availableRealms}
            required
            rules={{ required: true }}
          />
        )}
        <TextField
          control={control}
          name="sub"
          required
          rules={{
            required: true,
            ...realmValidationRules?.subValidationRules,
          }}
          helperText={realmValidationRules?.subValidationRules?.hint}
        />
        <TextField
          control={control}
          name="name"
          optional
        />
        {isSnabbleUser && (
          // Creating oauth users is currently only possible for Snabble users
          <SelectOptionsField
            control={control}
            name="origin"
            options={Object.values(Origin)}
            labelFrom={(origin: Origin) => (origin)}
            label={t('form.labels.origin')}
            required
            rules={{ required: true }}
            helperText={t('form.labels.originHint')}
          />
        )}
        {realmValidationRules?.passwordChoiceRule.email.enabled && (
        <TextField
          control={control}
          name="email"
          required={realmValidationRules?.passwordChoiceRule.email.required}
          rules={{ required: realmValidationRules?.passwordChoiceRule.email.required }}
        />
        )}
        {realmValidationRules?.passwordChoiceRule.password.enabled && (
        <TextField
          control={control}
          name="password"
          type="password"
          required={realmValidationRules?.passwordChoiceRule.password.required}
          rules={{
            ...realmValidationRules?.passwordValidationRules,
            required: realmValidationRules?.passwordChoiceRule.password.required,
          }}
          label={t('form.labels.initialPassword')}
          helperText={realmValidationRules?.passwordValidationRules?.hint}
        />
        )}
      </Fieldset>

      <Fieldset category="roles">
        <ArrayWrapper
          control={control}
          name="roles"
          defaultValue={newRoleEntry}
          inlineDeleteButton
          render={(_, path) => (
            <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
              <Box sx={{ width: '50%' }}>
                <SelectOptionsField
                  control={control}
                  name={`${path}.role`}
                  options={realmValidationRules?.allowedRoles || []}
                  label={t('form.labels.role')}
                  required
                  rules={{ required: true }}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <SelectOptionsField
                  control={control}
                  name={`${path}.project`}
                  options={realmValidationRules?.allowedProjects || []}
                  labelFrom="name"
                  valueFrom="id"
                  label={t('form.labels.project')}
                  required
                  rules={{ required: true }}
                />
              </Box>
            </Stack>
          )}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
