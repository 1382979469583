import { connect } from 'react-redux';
import { fetchClosing } from '../actions';
import ClosingView from './ClosingView';

const ClosingViewContainer = connect(
  ({
    closings, token,
  }) => ({
    closing: closings.details,
    isLoading: closings.isLoading,
    log: closings.log,
    token: token && token.raw,
  }),
  dispatch => ({
    fetchClosing: (shopID, sequenceNumber) =>
      dispatch(fetchClosing(shopID, sequenceNumber)),
  }),
)(ClosingView);

export default ClosingViewContainer;
