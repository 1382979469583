import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import HiddenInput from './HiddenInput';

function getFirstImage(files: FileList | null): File | null {
  if (!files) return null;
  return (
    Array
      .from(files)
      .filter(file => (
        file.type.startsWith('image/')
      ))?.[0] || null
  );
}

interface UploadInputProps {
  onFileChange: (file: File | null) => void;
}

export default function UploadHandler({ onFileChange }: UploadInputProps) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button
        component="div"
        sx={{
          height: '200px',
          borderRadius: '5px',
          background: '#f2f2f2',
          border: '1px dashed #ccc',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const { files } = e.dataTransfer;
          onFileChange(getFirstImage(files));
        }}
        onClick={() => fileInputRef.current?.click()}
      >
        <UploadFileIcon fontSize="large" />
        {t('form.imageField.uploadHint')}
      </Button>
      <HiddenInput
        type="file"
        accept="image/*"
        onChange={(event) => {
          onFileChange(getFirstImage(event.target.files));
        }}
        ref={fileInputRef}
      />
    </>
  );
}
