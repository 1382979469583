const nonPrintableChars : Record<string, string> = {
  '\t': '␉',
  '\v': '␋',
  '\n': '␊',
  '\r': '␍',
};


function escapeNonPrintableChars(value?: any) {
  if (!value) return value;
  return String(value).replace(/[^ -~]+/g, (s) => {
    if (Object.prototype.hasOwnProperty.call(nonPrintableChars, s)) {
      return nonPrintableChars[s];
    }
    return '';
  });
}

function escapeWhitespace(value?: any) {
  if (!value) return value;
  return String(value).replace(/\s+/g, () => '␠');
}

export { escapeNonPrintableChars, escapeWhitespace };
