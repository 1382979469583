import React from 'react';
import TableCell from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import Translate from './i18n/Translate';
import { formatPrice } from './ProductFormatPrice';
import LineItemTableRow from './LineItemTableRow';
import LineItemTotalRow from './LineItemTotalRow';
import ColoredTableRow from './ColoredTableRow';
import PaperTable from './PaperTable';
import { AdditionalAttributesDialogButton } from '../additional-attributes';


function OrderViewLineItems({
  lineItems, currency, project, referringItems, price, cartDiscountInfo,
}) {
  const { t } = useTranslation();

  if (!lineItems || !currency) return null;

  return (
    <PaperTable
      headline={<Translate id="orders.items" />}
      tableHeader={[
        <Translate id="product.sku" />,
        <Translate id="product.name" />,
        <Translate id="orders.itemType.tableHeader" />,
        <Translate id="orders.amount" />,
        <Translate id="orders.weight" />,
        <Translate id="orders.listPrice" />,
        <Translate id="orders.price" />,
        <Translate id="orders.totalPrice" />,
        '',
      ]}>
      {(lineItems || []).map((item, index) => (
        <React.Fragment key={`${item.id}`} >
          <LineItemTableRow
            item={item}
            currency={currency}
            productLink={`/${project}/products/${item.sku}`}
            isColored={(index % 2) === 0}
            alwaysCollapse={referringItems[item.id]}
          />
          {(referringItems[item.id] || []).map(referringItem => (
            <LineItemTableRow
              key={`${referringItem.id}`}
              item={referringItem}
              currency={currency}
              productLink={`/${project}/products/${referringItem.sku}`}
              isColored={(index % 2) === 0}
              alwaysCollapse
              isReferringItem
            />
          ))}

        </React.Fragment>
      ))}

      {(cartDiscountInfo || []).map((discount) => {
        const additionalData = {
          Name: discount.name,
          Code: discount.code,
          Message: discount.message,
          ...(discount.discountPercentage &&
            { DiscountPercentage: discount.discountPercentage }),
          ...(discount.discountValue &&
            { DiscountValue: formatPrice(discount.discountValue, currency) }),
        };

        return (
          <ColoredTableRow
            key={`${discount.id}`}
            isColored={false}>
            <TableCell />
            <TableCell colSpan={5}><strong>{discount.name || t('orders.cartDiscount') }</strong></TableCell>
            <TableCell>
              {discount.discountPercentage && `${discount.discountPercentage}%`}
              {discount.discountAmount && formatPrice(discount.discountAmount, currency)}
            </TableCell>
            <TableCell>{formatPrice(discount.sum, currency)}</TableCell>
            <TableCell size="small" align="right">
              <AdditionalAttributesDialogButton attributes={additionalData} />
            </TableCell>

          </ColoredTableRow>
        );
      })}

      {!!price && !!price.subTotal &&
        <LineItemTotalRow
          label={<Translate id="orders.subTotal" />}
          value={formatPrice(price.subTotal, currency)}
          sx={{ border: 'none' }}
        />
      }
      <LineItemTotalRow label={<Translate id="orders.totalNet" />} value={!!price && formatPrice(price.netPrice, currency)} sx={{ border: 'none' }} />
      <LineItemTotalRow label={<Translate id="orders.total" />} value={!!price && formatPrice(price.price, currency)} />
    </PaperTable>
  );
}

export default OrderViewLineItems;
