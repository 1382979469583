import { get } from './request';
import actions from './constants';


function receivedShops(json) {
  return {
    type: actions.RECEIVED_SHOPS,
    payload: json ? json.shops : [],
  };
}

function requestShops() {
  return {
    type: actions.REQUEST_SHOPS,
  };
}

function fetchShops() {
  return (dispatch, getState) => {
    dispatch(requestShops());
    return get(dispatch, getState, '/{project}/shops', 'application/json')
      .then(
        json => dispatch(receivedShops(json)),
        () => dispatch(receivedShops({})),
      );
  };
}

function receivedShop(json) {
  return {
    type: actions.RECEIVED_SHOP,
    payload: json || {},
  };
}

function requestShop() {
  return {
    type: actions.REQUEST_SHOP,
  };
}

function fetchShop(id) {
  return (dispatch, getState) => {
    dispatch(requestShop());
    return get(dispatch, getState, `/{project}/shops/${id}`, 'application/json')
      .then(
        json => dispatch(receivedShop(json)),
        () => dispatch(receivedShop({})),
      );
  };
}

export { fetchShops, fetchShop, receivedShops };
