import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import NotFound from './NotFound';
import OrganizationsDashboardPlaceholder from '../organizationsLayout/OrganizationsDashboardPlaceholder';
import OrganizationAdminManagementRoutes from '../organizationAdminManagement/OrganizationAdminManagementRoutes';
import OrganizationProjectRoutes from '../organizationProjects/OrganizationProjectRoutes';
import OrganizationConfigRoutes from '../organizations/OrganizationConfigRoutes';

export default function OrganizationRoutesForAuthorizedUsers() {
  return (
    <Routes>
      <Route path="/" element={<OrganizationsDashboardPlaceholder />} />
      <Route path="/organization-config/*" element={<OrganizationConfigRoutes />} />
      <Route path="/organization-projects/*" element={<OrganizationProjectRoutes />} />
      <Route path="/organization-admins/*" element={<OrganizationAdminManagementRoutes />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/*" element={<Navigate to="404" replace />} />
    </Routes>
  );
}
