import React from 'react';
import MenuItem from '@mui/material/MenuItem';

import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectField, TextField } from '../form/input';
import { ResourceTranslate } from '../resource';


const DEFAULT_VALUES = {
  pan: '',
  reason: '',
  type: '',
};

export default function BlockUserForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CustomFormProps) {
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="user">
        <SelectField
          control={control}
          name="credentialsType"
          required
          rules={{ required: true }}
        >
          <MenuItem value="phoneNumber">
            <ResourceTranslate id="shopAccess.credentialsTypes.phoneNumber" />
          </MenuItem>
          <MenuItem value="card">
            <ResourceTranslate id="shopAccess.credentialsTypes.card" />
          </MenuItem>
          <MenuItem value="appUserID">
            <ResourceTranslate id="shopAccess.credentialsTypes.appUserID" />
          </MenuItem>
        </SelectField>

        <TextField
          control={control}
          name="credentialsValue"
          required
          rules={{ required: true }}
        />
        <TextField
          control={control}
          name="reason"
          required
          rules={{ required: true }}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
