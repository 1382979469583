import {
  ElementDescription,
  UISchemaElement,
  UISchemaElementTranslations,
} from './types';

/**
  This function converts a nested UISchema into a flat array of ElementDescription objects.

  e.g.:
  {
    "myField": {
      "component": "TextField",
      "scopes": ["project"],
      "translation": {
        "title": {
          "de": "Logo",
          "en": "Logo"
        },
      }
    }
  }
  becomes:
  [
    {
      key: "myField",
      component: "TextField",
      scopes: ["project"],
      translation: {
        title: {
          de: "Logo",
          en: "Logo"
        }
      }
    }
  ]
*/
export function flattenUISchema(schema: Record<string, UISchemaElement>): ElementDescription[] {
  if (!schema) return [];
  return Object
    .entries(schema as Record<string, Record<string, any>>)
    .map(([key, data]) => ({ key, ...data })) as ElementDescription[];
}

interface ValidateTranslationProps {
  translation: UISchemaElementTranslations;
  key: ElementDescription['key'];
}

/**
  This function ensures that the most relevant translations are set.

  The german and english titles must be set, other wise they will be filled with the key.
  Same goes for descriptions as soon as at least one description is set.
*/
export function validateTranslation({
  translation,
  key,
}: ValidateTranslationProps): UISchemaElementTranslations {
  const validatedTranslation: UISchemaElementTranslations = {
    title: {
      de: translation?.title?.de || key,
      en: translation?.title?.en || key,
    },
  };
  if (translation?.description) {
    validatedTranslation.description = {
      de: translation?.description?.de || key,
      en: translation?.description?.en || key,
    };
  }
  if (translation?.enumNames) {
    validatedTranslation.enumNames = {
      ...translation?.enumNames,
    };
  }
  return validatedTranslation;
}
