import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  container: {
    height: theme.spacing(2),
    position: 'absolute',
    left: 0,
    top: '70px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      top: '50px',
    },
  },
});

const SnabbleLinearProgress = ({ show, classes }) => (
  <div className={classes.container}>
    {show && <LinearProgress />}
  </div>
);

export default withStyles(styles)(SnabbleLinearProgress);
