export function reorderElement<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function ensureArray<T>(input: T | T[] | undefined): T[] {
  if (!input) return [];
  if (Array.isArray(input)) return input;
  return [input];
}

export function removeDuplicates<T>(array: Array<T>): Array<T> {
  return [...new Set(array)];
}
