// eslint-disable-next-line import/prefer-default-export
export function isValidUrl(url: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(url as any);
  } catch (e) {
    return false;
  }
  return true;
}
