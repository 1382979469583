import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
// @ts-ignore
import PaperTable from '../components/PaperTable';
import EditShopServiceDialogView from './EditShopServiceDialogView';
import { ShopService, ShopServiceLanguage, ShopServices } from './shopServices';
import ShopServiceIconPreview from './ShopServiceIconPreview';

export interface EditAvailableShopServicesTableProps {
  values: ShopServices;
  onUpdate: () => Promise<void>;
  onDeleteService: (id: number) => Promise<void>;
}

export default function EditAvailableShopServicesTable({
  values,
  onUpdate,
  onDeleteService,
}: EditAvailableShopServicesTableProps) {
  const { t, i18n } = useTranslation();
  const language = useMemo<ShopServiceLanguage>(() => (
    (i18n.resolvedLanguage || 'de') as ShopServiceLanguage
  ), [i18n.resolvedLanguage]);
  const { projectServices: services } = (values as ShopServices);
  const [serviceToDelete, setServiceToDelete] = useState<ShopService | null>(null);
  const [serviceToUpdate, setServiceToUpdate] = useState<ShopService | null>(null);
  const [createNewService, setCreateNewService] = useState(false);

  const handleDeleteService = useCallback(async () => {
    if (serviceToDelete === null) return;
    await onDeleteService(serviceToDelete.id);
    setServiceToDelete(null);
    await onUpdate();
  }, [onDeleteService, onUpdate, serviceToDelete]);

  const handleEditDialogClose = useCallback(async () => {
    setServiceToUpdate(null);
    setCreateNewService(false);
    await onUpdate();
  }, [onUpdate]);

  return (
    <>
      <Dialog
        open={serviceToDelete !== null}
        onClose={() => { setServiceToDelete(null); }}
        maxWidth="xs"
      >
        <DialogTitle>
          {t('shopServices.deleteService.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('shopServices.deleteService.confirmationBody', { service: serviceToDelete?.translations[language] })}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setServiceToDelete(null); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button
            onClick={handleDeleteService}
            color="primary"
            variant="contained"
          >
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <EditShopServiceDialogView
        service={serviceToUpdate}
        newService={createNewService}
        onClose={handleEditDialogClose}
      />

      <PaperTable
        headline={t('shopServices.general')}
        tableHeader={[
          '',
          t('shopServices.deTranslation'),
          t('shopServices.enTranslation'),
          '',
        ]}
      >
        {services?.map(service => (
          <TableRow key={service.id}>
            <TableCell sx={{ width: '64px' }} size="small" >
              <Stack alignItems="center" justifyContent="center">
                <ShopServiceIconPreview service={service} />
              </Stack>
            </TableCell>
            <TableCell>{service.translations.de}</TableCell>
            <TableCell>{service.translations.en}</TableCell>
            <TableCell padding="checkbox" align="right">
              <Stack direction="row">
                <Tooltip title={t('shopServices.editTooltip')} placement="top" arrow>
                  <IconButton size="small" onClick={() => { setServiceToUpdate(service); }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('shopServices.deleteTooltip')} placement="top" arrow>
                  <IconButton size="small" onClick={() => { setServiceToDelete(service); }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell colSpan={4}>
            <Button
              aria-label="add"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => { setCreateNewService(true); }}
              fullWidth
            >
              {t('button.add')}
            </Button>
          </TableCell>
        </TableRow>
      </PaperTable>
    </>
  );
}
