import React from 'react';
import { WrappedFunctionProps } from '../types';
import FormCheckboxField from '../../form/input/CheckboxField';

export default function CheckboxField({
  key,
  translation,
  language,
  control,
}: WrappedFunctionProps) {
  return (
    <FormCheckboxField
      control={control}
      name={key}
      label={translation?.title?.[language] || key}
    />
  );
}
