import React from 'react';
import Translate from '../components/i18n/Translate';
import PaperTable from '../components/PaperTable';
import TaxTableRow from '../components/TaxTableRow';


class ClosingViewTaxShares extends React.PureComponent {
  render() {
    const { closing } = this.props;
    if (!closing.taxShares || !closing.taxShares.length) {
      return null;
    }

    return (
      <PaperTable
        headline={<Translate id="closings.taxes" />}
        headerAlignment={['left', 'right', 'right', 'right']}
        tableHeader={[
          <Translate id="taxes.rate" />,
          <Translate id="taxes.net" />,
          <Translate id="taxes.tax" />,
          <Translate id="taxes.total" />,
        ]}
      >
        {(closing.taxShares || []).map(taxGroup => (
          <TaxTableRow
            key={taxGroup.rate}
            taxShares={taxGroup}
            currency={closing.currency} />
        ))}
      </PaperTable>

    );
  }
}

export default ClosingViewTaxShares;
