import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from '../components/DocumentTitle';
import useProjectConfigApi from './useProjectConfigApi';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import HeadlineWithButtons from '../components/HeadlineWithButtons';
import EditAvailableShopServicesTable from './EditAvailableShopServicesTable';

export default function EditAvailableShopServicesView() {
  const { t } = useTranslation();
  const api = useProjectConfigApi();
  const chartRef = useRef<ResourceChartViewRef>(null);

  const handleUpdate = useCallback(async () => chartRef.current?.updateData(), []);

  const handleDeleteService = useCallback(async (id: number) => {
    await api.deleteShopService(id);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="headlines.editAvailableShopServices" />

      <HeadlineWithButtons>{t('headlines.editAvailableShopServices')}</HeadlineWithButtons>

      <ResourceChartView
        Chart={EditAvailableShopServicesTable}
        ChartProps={{
          onDeleteService: handleDeleteService,
          onUpdate: handleUpdate,
        }}
        fetch={() => api.getAvailableShopServices(null)}
        ref={chartRef}
      />
    </>
  );
}
