import React, { useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import usePlacesApi, { MarkerPosition } from './useAutocompleteApi';

export interface MapProps {
  markers?: MarkerPosition[];
  autoFitBounds?: boolean;
  zoomLevel?: number;
  center?: {
    lat: number,
    lng: number,
  };
  helperText?: string;
  onClick?: (event: any) => void;
  [key: string]: any;
}

export default function Map({
  markers,
  autoFitBounds = false,
  zoomLevel,
  center,
  helperText,
  onClick,
  ...props
}: MapProps) {
  const {
    createMap,
    waitForLibraryLoaded,
  } = usePlacesApi();

  const mapTarget = useRef<HTMLDivElement | null>(null);
  const map = useRef<any>(null);

  useEffect(() => {
    const loadMap = async () => {
      if (!mapTarget.current) return;
      await waitForLibraryLoaded();
      map.current = await createMap({
        targetElement: mapTarget.current,
        zoomLevel,
        center,
      });
      if (map.current) {
        map.current.setClickableIcons(false);
        map.current.setMarkers(markers, autoFitBounds);
        if (onClick) map.current.addListener('click', onClick);
      }
    };
    loadMap();
  // NOTE do not trigger effect on markers, center, zoomLevel because these are handled without
  // creating a new map
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMap, waitForLibraryLoaded]);

  useEffect(() => {
    if (!map.current) return;
    if (center) map.current.setCenter(center);
    if (zoomLevel)map.current.setZoom(zoomLevel);
  }, [center, zoomLevel]);

  useEffect(() => {
    if (!map.current) return;
    map.current.setMarkers(markers);
  }, [markers]);

  return (
    <>
      <Paper elevation={4} {...props}>
        <div
          ref={mapTarget}
          style={{
            width: '100%',
            aspectRatio: '16/9',
          }}
        />
      </Paper>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
