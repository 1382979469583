import React from 'react';
import { VerticalBarSeries } from 'react-vis';
import StatisticPlot from './StatisticPlot';

class StatisticBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      compareHint: null,
      showHint: false,
      totalHint: null,
    };
    this.setHint = this.setHint.bind(this);
  }
  setHint(value) {
    let compareValue = null;
    if (this.props.compareValues) {
      compareValue = this.props.compareValues[value.x - 1];
    }

    if (this.props.withTotal && compareValue !== null) {
      this.setState({ compareHint: compareValue, totalHint: compareValue.totalLabel, hint: value });
    } else {
      this.setState({ compareHint: compareValue, hint: value });
    }
  }
  toggleHint(isVisible) {
    this.setState({ showHint: isVisible });
  }
  render() {
    const {
      values, color, maxValue, translationLabelYKey, translationComparedKey, translationHintKey,
      translationData, compareValues, compareColor, withRegression, stackY, translationLabelXKey,
      xLabelAsDate,
    } = this.props;
    const barColor = color || '#07b';
    const barCompareColor = compareColor || '#ffc400';

    return (
      <StatisticPlot
        values={values}
        maxValue={maxValue}
        translationLabelYKey={translationLabelYKey}
        translationComparedKey={translationComparedKey}
        translationHintKey={translationHintKey}
        translationData={translationData}
        translationLabelXKey={translationLabelXKey}
        xLabelAsDate={xLabelAsDate}
        hint={this.state.hint}
        compareHint={this.state.compareHint}
        showHint={this.state.showHint}
        withRegression={withRegression}
        stackY={stackY}
        totalHint={this.state.totalHint}
      >
        <VerticalBarSeries
          data={values}
          color={barColor}
          onNearestX={this.setHint}
          onSeriesMouseOver={() => this.toggleHint(true)}
          onSeriesMouseOut={() => this.toggleHint(false)}
        />
        {!!compareValues &&
          <VerticalBarSeries
            data={compareValues}
            color={barCompareColor}
            onSeriesMouseOver={() => this.toggleHint(true)}
            onSeriesMouseOut={() => this.toggleHint(false)}
          />
        }
      </StatisticPlot>
    );
  }
}

export default StatisticBar;
