import React from 'react';
import Translate from './i18n/Translate';
import PricingCategoryTableRow from './PricingCategoryTableRow';
import Headline from './Headline';
import PaperTable from './PaperTable';
import SnabbleLinearProgress from './SnabbleLinearProgress';
import DocumentTitle from './DocumentTitle';

class PricingCategoryList extends React.Component {
  componentDidMount() {
    this.props.fetchPricingCategories();
  }
  render() {
    const { pricingCategories, projectId, canEdit } = this.props;

    return (
      <React.Fragment>
        <SnabbleLinearProgress show={this.props.fetchingPricingCategories} />
        <DocumentTitle translationID="pricingCategory.headline" />

        <Headline to={`/${projectId}/pricing/categories/id/create`} linkText={<Translate id="pricingCategory.create" />} withLink={canEdit}>
          <Translate id="pricingCategory.headline" />
        </Headline>

        {pricingCategories &&
          <PaperTable tableHeader={[
            <Translate id="pricingCategory.id" />,
            <Translate id="pricingCategory.name" />,
            <Translate id="shop.itemName" />,
            '',
          ]}>
            {(pricingCategories || []).map(category =>
              (
                <PricingCategoryTableRow key={category.id} category={category} canEdit={canEdit} />
              ))}
          </PaperTable>
        }
      </React.Fragment>
    );
  }
}

export default PricingCategoryList;
