import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '../form/input/TextField';
import { useImageUrl } from '../assets/useAssetApi';
import AsyncContent from '../components/AsyncContent';
import CategoriesIconDialogView from './CategoriesIconDialogView';

export interface CategoriesMetaDialogProps {
  control: any;
  categoryIndex: number;
  getValues: any;
  setValue: any;
  onCategoryRemove: (index: number) => void;
  onClose: () => void;
}

export default function CategoriesMetaDialog({
  control,
  categoryIndex,
  getValues,
  setValue,
  onCategoryRemove,
  onClose,
} : CategoriesMetaDialogProps) {
  const { t } = useTranslation();
  const getAbsoluteIconUrl = useImageUrl();

  const [iconSearch, setIconSearch] = useState(false);

  return (
    <>
      {iconSearch && (
        <CategoriesIconDialogView
          onSelect={(assetPath: string | undefined) => {
            setValue(`categories.${categoryIndex}.image`, assetPath || '', { shouldDirty: true });
            setIconSearch(false);
          }}
          onClose={() => { setIconSearch(false); }}
        />
      )}

      <Dialog
        open
        maxWidth="md"
        fullWidth
        onClose={() => { onClose(); }}
      >
        <DialogTitle>{t('categories.metaDialog.title')}</DialogTitle>
        <DialogContent dividers sx={{ overflow: 'hidden' }}>
          <Table>
            <TableBody>
              <TableRow sx={{ '& > *': { border: 'unset' } }}>
                <TableCell width="100%">
                  <TextField
                    control={control}
                    name={`categories.${categoryIndex}.id`}
                    label={t('categories.formLabel.id')}
                    required
                    rules={{ required: true }}
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ '& > *': { border: 'unset' } }}>
                <TableCell width="100%">
                  <Stack direction="row" alignItems="flex-start" gap="8px">
                    <AsyncContent
                      fetch={() => getAbsoluteIconUrl(getValues(`categories.${categoryIndex}.image`))}
                      render={(absoluteIconUrl: string | null, isLoading: boolean) => (
                        (!isLoading && absoluteIconUrl) && (
                          <img
                            style={{ height: '40px' }}
                            src={absoluteIconUrl || ''}
                            alt={getValues(`categories.${categoryIndex}.id`)}
                          />
                        ))}
                      refetch
                    />
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        control={control}
                        name={`categories.${categoryIndex}.image`}
                        label={t('categories.formLabel.image')}
                        size="small"
                        helperText={t('categories.formLabel.imageHint')}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      startIcon={<SearchIcon />}
                      onClick={() => { setIconSearch(true); }}
                      sx={{ height: '40px' }}
                    >
                      {t('categories.formLabel.addIcon')}
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '& > *': { border: 'unset' } }}>
                <TableCell width="100%">
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => { onCategoryRemove(categoryIndex); }}
                    sx={{ height: '40px' }}
                  >
                    {t('categories.arrayLabel.removeCategory')}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { onClose(); }}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
