import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { matchSorter } from 'match-sorter';
import { SortableTableHeader, ColumnsProp, ColumnType } from './SortableTableHeader';
import { SortableTableToolbar } from './SortableTableToolbar';
import { Order, getComparator } from './SortableTableUtils';
import Tooltip from '../components/i18n/TranslatedTooltip';
// @ts-ignore
import SimpleStateIndicator from '../components/SimpleStateIndicator';

const style = (theme: Theme) => ({
  oddRow: {
    backgroundColor: theme.palette.action.hover,
  },
  rowButtons: {
    display: 'flex',
    flexDirecton: 'row',
    alignItems: 'center',
    justifyContent: 'space between',
  },
});

export interface SortableTableDataPoint {
  [key: string]: any,
}

export interface SortableTableRowButtonsProps {
  // NOTE: both props are used
  // eslint-disable-next-line react/no-unused-prop-types
  icon: React.ReactNode,
  // eslint-disable-next-line react/no-unused-prop-types
  callback?: (row: SortableTableDataPoint) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  tooltip?: string
}

export function formatCell(value: string, type: ColumnType | undefined) {
  if (type === 'datetime') {
    const parsedDatetime = new Date(Date.parse(value));
    return parsedDatetime.toLocaleString();
  }
  if (type === 'boolean') {
    return value ? <SimpleStateIndicator useGreen /> : <SimpleStateIndicator useErrorColor />;
  }

  return value;
}

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export interface SortableTableProps {
  data: SortableTableDataPoint[],
  columnsMetadata: ColumnsProp,
  CustomRowContent?: (rowData: SortableTableDataPoint, index: number) => any,
  title: string,
  filterPlaceholder?: string,
  filterOverwrite?: string,
  onFilterChange?: (newFilter: string) => void,
  rowCheckbox?: boolean,
  rowButtons?: SortableTableRowButtonsProps[],
  labelRowsPerPage?: string,
  verticalToolbarPadding?: number,
  rowsPerPageOptions?: number[],
  rowsPerPageDefault?: number,
}

export function SortableTable({
  data,
  columnsMetadata,
  CustomRowContent,
  title,
  filterPlaceholder,
  filterOverwrite,
  onFilterChange,
  rowCheckbox,
  rowButtons,
  labelRowsPerPage,
  verticalToolbarPadding,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  rowsPerPageDefault,
}: SortableTableProps) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault
    || rowsPerPageOptions?.[0]
    || 25);
  const [filteredRows, setFilteredRows] = useState<SortableTableDataPoint[]>();

  const theme = useTheme();
  const classes = style(theme);

  const availableColumns = useMemo(
    () => columnsMetadata.filter(column => column.type !== 'padding').map(column => column.key),
    [columnsMetadata],
  );

  useEffect(() => {
    if (!filterOverwrite) {
      setFilteredRows(data);
      return;
    }

    setFilteredRows(matchSorter(data, filterOverwrite ?? '', { keys: availableColumns }));
  }, [availableColumns, data, filterOverwrite]);

  const handleFilterChange = useCallback((newFilter: string) => {
    onFilterChange?.(newFilter);

    if (!newFilter) {
      setFilteredRows(data);
      return;
    }

    setFilteredRows(matchSorter(data, newFilter, { keys: availableColumns }));
  }, [data, availableColumns, onFilterChange]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows?.map((n: SortableTableDataPoint) => n.id) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(() => {
    if ((filteredRows?.length || 0) < rowsPerPage) return 0;
    return Math.max(0, ((1 + page) * rowsPerPage) - (filteredRows?.length || 0));
  }, [page, filteredRows, rowsPerPage]);

  const rowsOnCurrentPage = useMemo(() => {
    if (!filteredRows) return undefined;

    const firstRowIndex = page * rowsPerPage;
    const lastRowIndex = firstRowIndex + rowsPerPage;

    return filteredRows.sort(getComparator(order, orderBy))
      .slice(firstRowIndex, lastRowIndex);
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  return (
    <>
      <SortableTableToolbar
        numSelected={selected.length}
        title={title}
        filterPlaceholder={filterPlaceholder}
        filterOverwrite={filterOverwrite}
        onFilterChange={handleFilterChange}
        verticalPadding={verticalToolbarPadding}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <SortableTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows?.length || 0}
            columns={columnsMetadata}
            rowCheckbox={rowCheckbox}
            rowButtons={!!rowButtons}
          />
          <TableBody>
            {rowsOnCurrentPage && rowsOnCurrentPage.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const rowKey = `sortableTableRow${index}`;

              return (
                <TableRow
                  style={(index % 2 === 0) ? {} : classes.oddRow}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rowKey}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    {rowCheckbox && (
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    )}
                  </TableCell>
                  {!CustomRowContent && columnsMetadata.map(column => (
                    <TableCell
                      align={column.type === 'numeric' ? 'right' : 'left'}
                      key={`data-${row.id}-${column.key}`}
                    >
                      {formatCell(row[column.key], column.type)}
                    </TableCell>
                  ))}
                  {CustomRowContent && <CustomRowContent rowData={row} index={index} />}
                  {!!rowButtons && (
                    <TableCell padding="checkbox" sx={{ paddingRight: '8px' }}>
                      <div style={classes.rowButtons}>
                        {rowButtons.map((
                          { icon, callback, tooltip }: SortableTableRowButtonsProps,
                          rowButtonIndex,
                        ) => {
                          const buttonKey = `rowButton-${rowButtonIndex}`;
                          return (
                            <Tooltip title={tooltip} placement="top" arrow key={buttonKey}>
                              <IconButton size="small" onClick={() => callback && callback(row)}>
                                {icon}
                              </IconButton>
                            </Tooltip>
                          );
                        })}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredRows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={labelRowsPerPage}
      />
    </>
  );
}
