import { useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useApiClient,
} from '../api';
import { removeUndefinedObjectEntries } from '../utils/api/repairFormArrays';
import useResourceTranslator from '../resource/useResourceTranslator';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { PasswordChoiceRule, ValidationPolicy, ValidationRules } from './useValidationPolicyParser';

export class UserExistingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserExistingError';
  }
}

export enum Origin {
  ACCOUNT = 'account',
  GOOGLE = 'google',
}

export enum ProjectRole {
  PROJECT_ADMIN = 'projectAdmin',
  PROJECT_READER = 'projectReader',
  PROJECT_GUEST = 'projectGuest',
  CASHIER = 'cashier',
  SUPERVISOR = 'supervisor',
}

export enum RealmRole {
  REALM_ADMIN = 'realmAdmin',
}

export enum OrganizationRole {
  ORGANIZATION_ADMIN = 'organizationAdmin',
}

enum ValidationErrorCategory {
  FORBIDDEN = 'forbidden',
  ONEOF = 'oneof',
  REQUIRED = 'required',
  EMAIL = 'email',
  GT = 'gt',
}

export enum PasswordChoicePolicy {
  RESET_VIA_EMAIL = 'resetViaEmail',
  SET_BY_CREATOR = 'setByCreator',
  ANY = 'any',
}

export interface ProjectPermission {
  project: string;
  role: ProjectRole;
}

export interface RealmPermission {
  realm: string;
  role: RealmRole;
}

export interface OrganizationPermission {
  organization: string;
  role: OrganizationRole;
}

export type Permission = ProjectPermission | RealmPermission | OrganizationPermission;

interface Link {
  self: {
    href: string;
  }
}

export interface User {
  name?: string | null;
  email?: string | null;
  sub: string;
  id?: string;
  origin?: Origin;
  realm?: String;
  roles: Permission[];
  links?: Link;
}

interface Error {
  error: {
    message: string;
    type: string;
  }
}

interface ValidationError {
  field: string;
  category: ValidationErrorCategory;
  restrictions: {
    possibleValues: string[];
  }
}

export interface ProjectFilter {
  realm?: string | undefined;
  project?: string | undefined;
  role?: ProjectRole | RealmRole | undefined;
}

export interface GetUsersReponse {
  users: User[];
  links: Link;
}

export type UserID = string | undefined;

export interface GetUserProps {
  userID: UserID;
  filter: ProjectFilter;
}

export interface UpdateUserProps {
  userID: UserID;
  user: FieldValues;
  filter: ProjectFilter;
}

export interface CreateUserProps {
  user: FieldValues;
  filter: ProjectFilter;
}

export interface Realm {
  id: string;
  name: string;
  subValidationPolicy: ValidationPolicy;
  passwordValidationPolicy: ValidationPolicy;
  allowedRoles: (ProjectRole | RealmRole)[];
  allowedProjects: string[];
  passwordChoicePolicy: PasswordChoicePolicy,
  links: {
    self: {
      href: string;
    }
  }
}

export interface RealmValidationRules {
  subValidationRules: ValidationRules;
  passwordValidationRules: ValidationRules;
  allowedRoles: (ProjectRole | RealmRole)[];
  allowedProjects: Record<string, any>[];
  passwordChoiceRule: PasswordChoiceRule;
}

export type DeleteUserProps = GetUserProps;

export interface ModifyRealmAdminsProps extends GetUserProps { }

export function buildBaseUrl(realmID: string | undefined) {
  if (!realmID || realmID === 'default') return '/users/administration';
  return `/realms/${realmID}/users/administration`;
}

export interface OverwriteUserPasswordProps extends GetUserProps {
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface ChangePasswordProps {
  username: string;
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export default function useUserManagementApi() {
  // NOTE to use the translator hook for the error messages the functions had to move into the
  // useUserManagementApi hook itself
  const t = useResourceTranslator();

  const sanitizeData = useCallback((data: FieldValues) => {
    const repairedObject = data as User;

    if (repairedObject.roles.length <= 0) throw new Error(t('userManagement.error.noRole'));
    if (repairedObject.roles?.some((r: Permission) => (
      !r.role || !(
        (r as ProjectPermission).project
        || (r as RealmPermission).realm
        || (r as OrganizationPermission).organization
      )
    ))) {
      throw new Error(t('userManagement.error.emptyRole'));
    }

    if (!repairedObject.origin) repairedObject.origin = Origin.ACCOUNT;

    let cleanedData: FieldValues = {
      ...repairedObject,
    };

    cleanedData = removeUndefinedObjectEntries(cleanedData);

    return cleanedData;
  }, [t]);

  const useApi = useMemo(() => (defineApi({
    getUsers: async (client, filter: ProjectFilter) => {
      const { data } = await client.get<GetUsersReponse>(buildBaseUrl(filter?.realm));
      let { users } = data;

      if (filter?.project) {
        users = users?.filter(user => (
          !filter?.project || user.roles?.find(role => (
            (role as ProjectPermission).project === filter.project
          ))
        ));
      }

      if (filter?.role) {
        users = users?.filter(user => (
          !filter?.role || user.roles?.find(role => (
            role.role === filter.role
          ))
        ));
      }

      return users;
    },
    getUser: async (client, { userID, filter }: GetUserProps) => {
      if (!userID) return null;
      const { data } = await client.get<User>(`${buildBaseUrl(filter?.realm)}/id/${userID}`);
      return data;
    },
    updateUser: async (client, { userID, user, filter }: UpdateUserProps) => {
      if (!userID) return null;
      const body = sanitizeData(user);
      try {
        const { data }: { data: User | Error | ValidationError } =
          await client({
            url: `${buildBaseUrl(filter?.realm)}/id/${userID}`,
            method: 'PUT',
            data: body,
          });
        return data;
      } catch (e: any) {
        if (e.response?.status === 409) throw new Error(t('userManagement.error.userExisting'));
        throw e;
      }
    },
    createUser: async (client, { user, filter }: CreateUserProps) => {
      const body = sanitizeData(user);
      try {
        const { data }: { data: User | Error | ValidationError } =
          await client({
            url: buildBaseUrl(filter?.realm),
            method: 'POST',
            data: body,
          });
        return data;
      } catch (e: any) {
        if (e.response?.status === 409) throw new UserExistingError(t('userManagement.error.userExisting'));
        throw e;
      }
    },
    deleteUser: async (client, { userID, filter }: DeleteUserProps) => {
      const { status } = await client({
        url: `${buildBaseUrl(filter?.realm)}/id/${userID}`,
        method: 'DELETE',
        // NOTE 403 is normally catched by the global axios response interceptor that loggs out the
        // user. Here 403 should be handled differently.
        validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [403]),
      });

      // NOTE a 403 means that this user might have additional roles invisible to the current user
      if (status === 403) throw new Error(t('userManagement.error.cannotDeleteUser'));
    },
    getRealms: async (client) => {
      const { data: realms } = await client.get<Realm[]>('/users/administration/realms');

      // NOTE manually move the default realm to the beginning of the list.
      // This is just for aesthetics of the realm selector tab bar.
      const indexOfDefaultRealm = realms.findIndex(realm => (realm.id === 'default'));
      const defaultRealm = realms.splice(indexOfDefaultRealm, 1)?.[0];
      if (defaultRealm) realms.unshift(defaultRealm);

      if (realms.length < 1) return {};

      return {
        defaultRealm: realms[0],
        realms,
        isRealmAdmin: realms.length > 1,
      };
    },
    resetUserPassword: async (client, { userID, filter }: DeleteUserProps) => {
      await client({
        url: `${buildBaseUrl(filter?.realm)}/id/${userID}/reset-password`,
        method: 'POST',
      });
    },
    overwriteUserPassword: async (client, {
      newPassword,
      newPasswordConfirmation,
      userID,
      filter,
    }: OverwriteUserPasswordProps) => {
      if (newPassword !== newPasswordConfirmation) throw new Error('Passwords do not match');

      await client({
        url: `${buildBaseUrl(filter?.realm)}/id/${userID}/reset-password`,
        method: 'POST',
        data: { newPassword },
      });
    },
    changePassword: async (client, {
      username,
      password,
      newPassword,
      newPasswordConfirmation,
    }: ChangePasswordProps) => {
      if (newPassword !== newPasswordConfirmation) throw new Error('Passwords do not match');

      await client({
        url: '/realms/default/accounts/password/change',
        method: 'POST',
        auth: { username, password },
        data: { newPassword },
      });
    },
    getRealmAdmins: async (client, filter: ProjectFilter) => {
      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      const { data } = await client.get<GetUsersReponse>(buildBaseUrl(undefined));
      let { users } = data;

      users = users?.filter(user => (user.roles?.find(role => (
        role.role === RealmRole.REALM_ADMIN
        && (
          role.realm === filter?.realm
          || role.realm === '*'
        )
      ))));

      return users;
    },
    promoteUserToRealmAdmin: async (client, {
      userID,
      filter,
    }: ModifyRealmAdminsProps) => {
      if (!userID) return;
      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      const { data } = await client.get<User>(`${buildBaseUrl(undefined)}/id/${userID}`);
      data.roles.push({ role: RealmRole.REALM_ADMIN, realm: filter?.realm } as any);

      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      await client({
        url: `${buildBaseUrl(undefined)}/id/${userID}`,
        method: 'PUT',
        data,
      });
    },
    demoteUserFromRealmAdmin: async (client, { userID, filter }: GetUserProps) => {
      if (!userID) return;
      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      const { data } = await client.get<User>(`${buildBaseUrl(undefined)}/id/${userID}`);
      data.roles = data.roles.filter(role => !(
        role.role === RealmRole.REALM_ADMIN
        && (role as any).realm === filter?.realm
      ));

      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      await client({
        url: `${buildBaseUrl(undefined)}/id/${userID}`,
        method: 'PUT',
        data,
      });
    },
  })), [sanitizeData, t]);

  const client = useApiClient({ basePath: '' });
  return useApi(client);
}
