import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import { Access } from '../access';
import AppAppearanceView from './AppAppearanceView';

function AppAppearanceRoutes({ access }: { access?: Access }) {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="appUser">
      <Routes>
        {(access?.read && access?.write) &&
          <>
            <Route path="/" element={<RouteWrapper><AppAppearanceView /></RouteWrapper>} />
          </>
        }
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default AppAppearanceRoutes;
