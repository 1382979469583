import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useProjectNavigate from '../useProjectNavigate';
// @ts-ignore
import ColoredTableRow from '../components/ColoredTableRow';
// @ts-ignore
import PaperTable from '../components/PaperTable';
import { buildLabelFromArray } from '../utils/stringUtils';
import { formatCell } from '../SortableTable';

export interface JournalTableProps {
  values: any;
  writePermission: boolean;
}

export default function AppUsersTable({
  values,
  writePermission,
}: JournalTableProps) {
  const { t } = useTranslation();
  const navigate = useProjectNavigate();

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <ColoredTableRow>
      <TableCell align="left">
        {rowData.id.slice(0, 8)}
      </TableCell>
      <TableCell align="left">
        {buildLabelFromArray({
          input: [rowData.details?.firstName, rowData.details?.lastName],
          separator: ' ',
        }) || '-/-'}
      </TableCell>
      <TableCell align="left">
        {rowData.phoneNumber || '-/-'}
      </TableCell>
      <TableCell align="left">
        {rowData.details?.email || '-/-'}
      </TableCell>
      <TableCell align="left">
        {formatCell(rowData.createdAt, 'datetime')}
      </TableCell>
      <TableCell padding="checkbox" align="right">
        <div style={{ display: 'flex', flexGrow: 1 }} >
          <IconButton size="small" onClick={() => { navigate(`/app-user-admin/user/${rowData.id}`); }}>
            <VisibilityIcon />
          </IconButton>
          {writePermission && (
            <IconButton size="small" onClick={() => { navigate(`/app-user-admin/user/${rowData.id}/edit`); }}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </TableCell>
    </ColoredTableRow>
  ), [navigate, writePermission]);

  const headCells = useMemo<string[]>(() => [
    t('appUser.id'),
    t('appUser.fullName'),
    t('appUser.phoneNumber'),
    t('appUser.email'),
    t('appUser.userCreatedAt'),
    '',
  ], [t]);

  const table = (
    (values?.list?.length > 0) ?
      (
        <PaperTable tableHeader={headCells}>
          {(values.list || []).map((rowData: any) => (
            <TableRowEntries rowData={rowData} key={rowData?.id} />
          ))}
        </PaperTable>
      )
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="appUser.list.empty" /></Typography>
        </div>
      )
  );

  return (
    <Paper elevation={4} >
      {table}
    </Paper>
  );
}
