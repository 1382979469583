import React from 'react';
import Translate from './i18n/Translate';
import ProductOutOfStock from './ProductOutOfStock';
import PaperTableRow from './PaperTableRow';
import PaperTable from './PaperTable';

function ProductDetailsGeneral({
  product,
}) {
  if (!product) return null;

  const image = product.imageUrl ? <img src={product.imageUrl} alt={product.name} width="200" /> : '–';
  const pluSet = product.pluSet ? product.pluSet.join(', ') : '–';

  return (
    <PaperTable headline={<Translate id="product.generalInformation" />}>
      <PaperTableRow label={<Translate id="product.sku" />} value={product.sku} />
      <PaperTableRow label={<Translate id="product.name" />} value={product.name || '–'} />
      <PaperTableRow label={<Translate id="product.subtitle" />} value={product.subtitle || '–'} />
      <PaperTableRow label={<Translate id="product.description" />} value={product.description || '–'} />
      <PaperTableRow label={<Translate id="product.type" />} value={product.productType} />
      <PaperTableRow label={<Translate id="product.customerWeighing" />} value={product.weighByCustomer ? <Translate id="button.yes" /> : <Translate id="button.no" />} />
      <PaperTableRow label={<Translate id="product.referenceUnit" />} value={product.referenceUnit || '–'} />
      <PaperTableRow label={<Translate id="product.encodingUnit" />} value={product.encodingUnit || '–'} />
      <PaperTableRow label={<Translate id="product.plu" />} value={pluSet} />
      <PaperTableRow
        label={<Translate id="product.stock" />}
        value={<ProductOutOfStock outOfStock={product.outOfStock} />} />
      <PaperTableRow
        label={<Translate id="product.image" />}
        value={image} />
      <PaperTableRow label={<Translate id="product.taxCategory" />} value={product.taxCategory || '–'} />
      <PaperTableRow label={<Translate id="product.isDiscountable" />} value={product.isDiscountable ? <Translate id="button.yes" /> : <Translate id="button.no" />} />
      {!!product.notForSale && <PaperTableRow label={<Translate id="product.notForSale" />} value={<Translate id="button.yes" />} />}
      {!!product.scanMessage && <PaperTableRow label={<Translate id="product.scanMessage" />} value={product.scanMessage} />}
      {product.priceEntryRequired && <PaperTableRow label={<Translate id="product.priceEntryRequired" />} value={<Translate id="button.yes" />} />}
    </PaperTable>
  );
}

export default ProductDetailsGeneral;
