import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';
import FormTableRow from './FormTableRow';

const styles = theme => ({
  deleteBtn: {
    marginRight: theme.spacing(1),
  },
  inputStyle: {
    width: '100%',
    maxWidth: '150px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: grey[400],
    borderRadius: 3,
    '& input': {
      padding: theme.spacing(1.5),
    },
  },
  withBtns: {
    marginRight: theme.spacing(2),
  },
});

class FormTableRowTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(prevProps) {
    return (
      this.props.value !== prevProps.value ||
      this.props.text !== prevProps.text ||
      this.props.required !== prevProps.required ||
      this.props.propertyName !== prevProps.propertyName ||
      this.props.errorText !== prevProps.errorText ||
      this.props.hasError !== prevProps.hasError
    );
  }

  handleChange(event) {
    this.props.handleChange(event.target.value);
  }

  render() {
    const {
      text, required, propertyName, errorText, hasError, classes, value,
    } = this.props;

    return (
      <FormTableRow
        text={text}
        required={required}
        propertyName={propertyName}
        errorText={errorText}
        hasError={hasError}
      >
        <TextField
          type="time"
          name="opens"
          value={value}
          InputProps={{ disableunderline: 'true', className: classes.inputStyle }}
          onChange={this.handleChange}
        />
      </FormTableRow>
    );
  }
}

export default withStyles(styles)(FormTableRowTimePicker);
