import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import RouteWrapper from './RouteWrapper';
import { Access } from '../access';
import { ResourceProvider } from '../resource';
import ListTaxRuleView from '../products/ListTaxRuleView';
import CreateTaxRuleView from '../products/CreateTaxRuleView';
import EditTaxRuleView from '../products/EditTaxRuleView';

function TaxRulesRoutes({ access }: { access?: Access }) {
  return (
    <ResourceProvider name="taxRules">
      <Routes>
        {access?.read &&
          <React.Fragment>
            <Route path="/" element={<RouteWrapper><ListTaxRuleView /></RouteWrapper>} />
          </React.Fragment>
        }
        {access?.write &&
          <React.Fragment>
            <Route path="/new" element={<RouteWrapper><CreateTaxRuleView /></RouteWrapper>} />
            <Route path="/edit/:type/:value" element={<RouteWrapper><EditTaxRuleView /></RouteWrapper>} />
          </React.Fragment>
        }
        <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default TaxRulesRoutes;
