import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import useCheckoutDeviceApi, { CheckoutDeviceType } from './api/useCheckoutDeviceApi';
import useProjectNavigate from '../useProjectNavigate';
import ResourceFormView from '../scaffold/ResourceFormView';
import CheckoutDeviceConfigForm from './CheckoutDeviceConfigForm';
import CheckoutDeviceConfigFormNew from './CheckoutDeviceConfigFormNew';
import { useResourceTranslator } from '../resource';
import { UISchemaScope, DynaForm } from '../dynaForm';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import { CustomFormRef } from '../form/CustomFormProps';

const NewCheckoutDeviceType: Partial<CheckoutDeviceType>[] = [
  CheckoutDeviceType.POS,
  CheckoutDeviceType['SCO-NG'],
];

enum TABS {
  CONFIG = 'deviceConfig',
  APPEARANCE = 'appearance',
}

export default function UpdateCheckoutDeviceConfigView() {
  const api = useCheckoutDeviceApi();

  const navigate = useProjectNavigate();
  const { id } = useParams();
  const t = useResourceTranslator();

  const [deviceName, setDeviceName] = useState<string | undefined>(undefined);
  const [deviceType, setDeviceType] = useState<CheckoutDeviceType | undefined>(undefined);
  const [deviceShop, setDeviceShop] = useState<string | undefined>(undefined);
  const [uiSchema, setUiSchema] = useState<any>(null);

  const handleFetch = useCallback(async () => {
    if (!id) return null;

    const apiData = await api.getCheckoutDevice({ id });
    setDeviceName(apiData.name);
    setDeviceType(apiData.type as CheckoutDeviceType);
    setDeviceShop(apiData.shop);
    setUiSchema(await api.getUiSchema(null));

    const configApiData = await api.getCheckoutDeviceConfig({ id });
    return configApiData;
  }, [api, id]);

  const headline = useMemo(() => {
    if (!deviceName) return undefined;
    return `${t('headlines.updateConfig')} (${deviceName})`;
  }, [deviceName, t]);

  const navigateToIndex = () => {
    navigate('/checkout-devices');
  };

  const [currentTab, setCurrentTab] = useState<TabFilterOption>({
    id: TABS.CONFIG,
    title: t(`checkoutDevices.tabs.${TABS.CONFIG}`),
  });

  const currentForm = useRef<CustomFormRef | null>(null);
  const handleTabChange = useCallback(async (tab: TabFilterOption) => {
    if (await currentForm.current?.forceSubmit()) setCurrentTab(tab);
  }, []);

  const sharedResourceFormViewProps = {
    additionalContent: (
      <TabFilter
        options={Object.values(TABS).map(tab => ({
          id: tab, title: t(`checkoutDevices.tabs.${tab}`),
        }))}
        defaultOption={currentTab}
        onChange={handleTabChange}
        value={currentTab}
        sx={{ marginBottom: '32px' }}
      />
    ),
  };

  if (!id) return null;

  return (
    <>
      {(!deviceType || NewCheckoutDeviceType.includes(deviceType)) && (
        (!currentTab?.id || currentTab?.id === TABS.CONFIG) ? (
          <ResourceFormView
            actionName="updateConfig"
            headline={headline}
            {...sharedResourceFormViewProps}
            Form={CheckoutDeviceConfigFormNew}
            FormProps={{
              ref: currentForm,
            }}
            fetch={handleFetch}
            submit={data => api.updateCheckoutDeviceConfig({ id, data })}
            onSubmitted={navigateToIndex}
          />
        ) : (
          <ResourceFormView
            actionName="updateConfig"
            headline={headline}
            {...sharedResourceFormViewProps}
            Form={DynaForm}
            FormProps={{
              uiSchema,
              currentScope: UISchemaScope.DEVICE,
              ref: currentForm,
            }}
            fetch={() => (api.getAppearanceConfig({
              shop: deviceShop,
              device: id,
            }))}
            submit={data => api.updateAppearanceConfig({
              data,
              coordinates: {
                shop: deviceShop,
                device: id,
              },
            })}
            onSubmitted={navigateToIndex}
          />
        )
      )}

      {(deviceType && !NewCheckoutDeviceType.includes(deviceType)) && (
        <ResourceFormView
          actionName="updateConfig"
          headline={headline}
          Form={CheckoutDeviceConfigForm}
          fetch={handleFetch}
          submit={data => api.updateCheckoutDeviceConfig({ id, data })}
          onSubmitted={navigateToIndex}
        />
      )}
    </>
  );
}
