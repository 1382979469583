import regression from 'regression';

const calculateRegression = (formattedData) => {
  const regressionData = formattedData.map(data => [parseFloat(data.x), data.y]);
  if (!regressionData) return { regressionData: [] };

  const linearRegression = regression.linear(regressionData);
  return {
    regressionData: linearRegression.points.map(point => ({
      x: point[0],
      y: point[1],
    })),
  };
};
export { calculateRegression as default };
