import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
// @ts-ignore
import SnabbleLinearProgress from '../../components/SnabbleLinearProgress';
import DocumentTitle from '../../components/DocumentTitle';
// @ts-ignore
import PaperTable from '../../components/PaperTable';
import ContentLink from '../../components/ContentLink';
// @ts-ignore
import ColoredTableRow from '../../components/ColoredTableRow';
import { fetchReleases } from '../api/releases';
import { useResourceTranslator } from '../../resource';
import Translate from '../../components/i18n/Translate';
import Headline from '../../components/Headline';
import { useAlert } from '../../toast';
import { App } from '../entity/state';
import getRolloutInformation, { Channel, RolloutInformation } from '../api/rolloutInformation';

const CHANNEL_COLORS = {
  stable: 'success',
  beta: 'warning',
  latest: 'error',
} as const;

export default function ReleasesOverview() {
  const { projectId } = useParams();

  const { i18n } = useTranslation();
  const t = useResourceTranslator();
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState<App[] | undefined>(undefined);
  const [rolloutInformation, setRolloutInformation] =
    useState<RolloutInformation | undefined>(undefined);
  const [showOnlyStable, setShowOnlyStable] = useState(true);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);

      try {
        setApps(await fetchReleases('pos_releases', i18n.language));
        setRolloutInformation(await getRolloutInformation());
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        const message = e instanceof Error ? e.message : 'Unknown error.';
        alert.error({ message });
      }
    };
    load();
  // NOTE do not add the alert to the deps array. When ever the api call fails
  // (api limit, wrong resonse, etc.) the alert will be shown.
  // This in turn will cause the component to rerender and the api call will be
  // triggered again. This will result in an endless loop.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const isStable = (release: App['releases'][0]) => rolloutInformation?.available.stable.includes(release.version);

  const ChannelChip = (release: App['releases'][0], channel: Channel) => {
    const releaseAvailableInChannel = rolloutInformation?.available[channel]
      .includes(release.version);
    if (!releaseAvailableInChannel) return null;

    const releaseIsCurrent = rolloutInformation?.current[channel] === release.version;

    return (
      <Chip
        label={t(`releases.channels.${channel}`)}
        color={CHANNEL_COLORS[channel]}
        disabled={!releaseIsCurrent}
        size="small"
      />
    );
  };

  const body = !apps ? null : apps.map(app => (
    <PaperTable
      key={app.id}
      headline={`${app.name}`}
      tableHeader={[
        t('releases.version'),
        t('releases.date'),
        t('releases.channel'),
        '',
      ]}
      headlineChildren={(
        <Stack direction="row" alignItems="center">
          <Switch
            color="default"
            checked={showOnlyStable}
            onChange={() => { setShowOnlyStable(s => !s); }}
          />
          <Typography variant="caption">
            {t('releases.showOnlyStable')}
          </Typography>
        </Stack>
      )}
    >
      {app.releases.map(release => (
        (isStable(release) || !showOnlyStable) && (
          <ColoredTableRow key={release.id}>
            <TableCell align="left" style={{ paddingRight: '10px' }}>
              {release.version}
            </TableCell>
            <TableCell align="left" style={{ paddingRight: '10px' }}>
              {release.date}
            </TableCell>
            <TableCell align="right" style={{ paddingRight: '10px' }}>
              <Stack direction="row" spacing={1}>
                {ChannelChip(release, Channel.STABLE)}
                {ChannelChip(release, Channel.BETA)}
                {ChannelChip(release, Channel.LATEST)}
              </Stack>
            </TableCell>
            <TableCell align="right" style={{ paddingRight: '10px' }}>
              <ContentLink
                to={`/${projectId}/releases/${app.repository}/${release.id}`}
                view
              />
            </TableCell>
          </ColoredTableRow>
        )
      ))}
    </PaperTable >
  ));

  return (
    <>
      <SnabbleLinearProgress show={isLoading} />
      <DocumentTitle translationID="releases.headline" />
      <Headline>
        <Translate id="releases.headline" />
      </Headline>
      {body}
    </>
  );
}
