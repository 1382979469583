import React from 'react';
import isString from 'lodash/isString';
import { withTranslation } from 'react-i18next';
import PricingCategoryForm from './PricingCategoryForm';
import FormWrapper from './FormWrapper';

class PricingCategoryEditView extends React.Component {
  constructor(props) {
    super(props);
    const { project } = props;
    this.state = {
      initialLoad: props.isNew,
      id: '',
      project,
      shopIds: [],
      name: '',
      idMissing: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchPricingCategory();
  }

  componentDidUpdate(prevProps) {
    const { showLoadingIndicator } = this.props;

    if (this.state.initialLoad) return;

    if (!showLoadingIndicator && prevProps.showLoadingIndicator) {
      this.updateCategory();
    }
  }

  componentWillUnmount() {
    this.props.updateStatus('');
  }

  onSubmit() {
    const {
      shopIds, id, name, project,
    } = this.state;

    if (id === '') {
      this.props.updateStatus('errors');
      this.setState({ idMissing: true });
      return;
    }

    this.props.updatePricingCategory({
      id,
      name,
      project,
      priority: this.props.priority,
      shops: shopIds.map(shopId => ({ id: shopId })),
    });
  }

  handleChange(name, value) {
    this.setState({ [`${name}`]: isString(value) ? value.trim() : value });
  }

  updateCategory() {
    const { selectedCategory } = this.props;

    this.setState({
      id: selectedCategory ? selectedCategory.id : '',
      shopIds: selectedCategory ? selectedCategory.shops.map(shop => shop.id) : [],
      name: selectedCategory ? selectedCategory.name : '',
      initialLoad: true,
    });
  }

  render() {
    const {
      shops, showLoadingIndicator,
      selectedCategory, deletePricingCategory,
      updateStatus, editStatus, categoryError, isNew,
      navigate,
    } = this.props;
    const {
      initialLoad, id, name, shopIds, idMissing,
    } = this.state;

    return (
      <FormWrapper
        isNew={isNew}
        newUrl={`${this.props.listURL}/id/${id}/edit`}
        backUrl={this.props.listURL}
        notFound={!!this.props.id && !selectedCategory && initialLoad}
        notFoundText={this.props.t('pricingCategory.noCategory', { id: this.props.id })}
        isLoading={showLoadingIndicator && !initialLoad}
        title="pricingCategory.headline"
        onSubmit={this.onSubmit}
        resetEditStatus={() => updateStatus('')}
        editStatus={editStatus}
        itemName={this.props.t('pricingCategory.headline')}
        serverError={categoryError || ''}
        isDeleteable={!isNew}
        onDelete={() => deletePricingCategory()}
        navigate={navigate}
      >
        {initialLoad &&
          <PricingCategoryForm
            shops={shops}
            id={id}
            name={name}
            shopIds={shopIds}
            handleChange={this.handleChange}
            idIsDisabled={!isNew}
            idMissing={idMissing}
          />
        }
      </FormWrapper>
    );
  }
}

export default withTranslation()(PricingCategoryEditView);
