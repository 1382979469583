import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FieldValues } from 'react-hook-form';
import ResourceFormView from '../scaffold/ResourceFormView';
import useUserManagementApi, { ProjectFilter, RealmValidationRules, UserID } from './useUserManagementApi';
import PasswordOverwriteDialogForm from './PasswordOverwriteDialogForm';

export interface PasswordOverwriteDialogViewProps {
  userID: UserID;
  filter: ProjectFilter;
  realmValidationRules: RealmValidationRules | undefined;
  onClose: () => void;
}

export default function PasswordOverwriteDialogView({
  userID,
  filter,
  realmValidationRules,
  onClose,
} : PasswordOverwriteDialogViewProps) {
  const api = useUserManagementApi();

  const handlePasswordOverwrite = useCallback(async (passwordInput: FieldValues) => {
    const { newPassword, newPasswordConfirmation } = passwordInput;
    await api.overwriteUserPassword({
      newPassword, newPasswordConfirmation, userID, filter,
    });
  }, [api, filter, userID]);

  return (
    <Dialog open maxWidth="sm" onClose={onClose}>
      <DialogContent dividers>
        <ResourceFormView
          actionName="overwritePassword"
          Form={PasswordOverwriteDialogForm}
          FormProps={{
            realmValidationRules,
            onCancel: onClose,
          }}
          fetch={() => ({})}
          submit={handlePasswordOverwrite}
          onSubmitted={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
