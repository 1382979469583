import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import camelCase from 'lodash/camelCase';
import { PanelHeader } from '../panel';

const styles = theme => ({
  tableHint: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'block',
  },
  paperMargin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  tableScrollContainer: {
    overflowX: 'auto',
  },
});

function PaperTableHead({
  tableHeader, isFirst, classes, alignment,
}) {
  if (!tableHeader) {
    return null;
  }
  return (
    <TableHead>
      <TableRow className={isFirst ? classes.tableHeaderRow : ''}>
        {(tableHeader || []).map((head, index) => (
          <TableCell
            align={alignment[index] || 'left'}
            className={isFirst ? classes.tableHeader : ''}
            key={head.props ? camelCase(head.props.id) : head}>
            {head}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PaperTableHint({ classes, hint }) {
  if (!hint) {
    return '';
  }
  return (
    <div>
      <Typography variant="caption" className={classes.tableHint}>{hint}</Typography>
    </div>
  );
}

const PaperTable = ({
  classes, children, headline, headlineChildren, hint, tableHeader, headerAlignment,
}) => (
  <Paper elevation={4} className={classes.paperMargin}>
    <PanelHeader extra={headlineChildren}>{headline}</PanelHeader>

    <Box className={classes.tableScrollContainer}>
      <Table>
        <PaperTableHead
          tableHeader={tableHeader}
          isFirst={!headline}
          classes={classes}
          alignment={headerAlignment || []} />
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </Box>

    <PaperTableHint classes={classes} hint={hint} />
  </Paper>
);

export default withStyles(styles)(PaperTable);
