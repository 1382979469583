import React from 'react';
import { useParams } from 'react-router';

export default function OrganizationsDashboardPlaceholder() {
  const { organizationId } = useParams();
  return (
    <div>
      <h1>Organization</h1>
      <p>Organization ID: {organizationId}</p>
    </div>
  );
}
