import React from 'react';
import { WrappedFunctionProps } from '../types';
import FormSelectPaymentTerminalIconField from '../../sco/SelectPaymentTerminalIconField';

export default function SelectPaymentTerminalIconField({
  key,
  control,
}: WrappedFunctionProps) {
  return (
    <FormSelectPaymentTerminalIconField
      control={control}
      name={key}
    />
  );
}
