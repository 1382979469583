import React from 'react';
import { useTranslation } from 'react-i18next';
import { FailureCause, Order, State as OrderState, PaymentState } from './orderTypes';
import AdvancedStateIndicator, { State } from '../components/AdvancedStateIndicator';
import TextWithSubText from '../components/TextWithSubText';

const ORDER_STATE_MAPPING: Record<OrderState, State> = {
  [OrderState.Final]: State.Success,
  [OrderState.Transferred]: State.Success,
  [OrderState.Success]: State.Success,
  [OrderState.UserAborted]: State.Warning,
  [OrderState.PreconditionsNotMet]: State.Error,
  [OrderState.SystemAborted]: State.Error,
  [OrderState.PaymentFailed]: State.Error,
};

const PAYMENT_STATE_MAPPING: Record<PaymentState, State> = {
  [PaymentState.Successful]: State.Success,
  [PaymentState.Transferred]: State.Success,
  [PaymentState.Pending]: State.Warning,
  [PaymentState.Unset]: State.Error,
  [PaymentState.Failed]: State.Error,
};

const FAILURE_CAUSE_MAPPING: Record<FailureCause, State> = {
  [FailureCause.UserAborted]: State.Warning,
  [FailureCause.AgeVerificationFailed]: State.Warning,
  [FailureCause.AgeVerificationNotSupportedByCard]: State.Warning,
  [FailureCause.TerminalAbort]: State.Error,
  [FailureCause.TerminalFailed]: State.Error,
  [FailureCause.TerminalErrored]: State.Error,
};

export interface OrderStateIndicatorProps {
  paymentState: Order['paymentState'];
  state: Order['state'];
  paymentResult: Order['paymentResult'];
}

export default function OrderStateIndicator({
  paymentState,
  state,
  paymentResult,
}: OrderStateIndicatorProps) {
  const { t } = useTranslation();
  const stateT = (inputState: State | OrderState | PaymentState | FailureCause) => (
    inputState ? t(`orders.state.${inputState}`, { defaultValue: inputState }) : ''
  );

  const failureCause = paymentResult?.failureCause as FailureCause;

  const computedOrderState = ORDER_STATE_MAPPING[state] || State.Warning;
  const computedPaymentState = PAYMENT_STATE_MAPPING[paymentState] || State.Warning;
  const computedFailureCause = FAILURE_CAUSE_MAPPING[failureCause] || State.Warning;

  let computedIndicatorState: State = State.Warning;
  let computedIndicatorLabel: string = '';
  let computedIndicatorSubLabel: string = '';

  // NOTE this is just wrapped in a function so it stays somewhat readable
  const setIndicatorValues = () => {
    // all states are successful and there is are no failures:
    // happy path
    if (
      computedOrderState === State.Success
      && computedPaymentState === State.Success
      && !failureCause
    ) {
      computedIndicatorState = State.Success;
      computedIndicatorLabel = t('orders.state.successful');
      computedIndicatorSubLabel = '';
      return;
    }

    // the overall state is successful but there are no payment details:
    // probably a successful transaction with a mocked payment (testing / staging)
    // or a transaction without a payment (e.g. everything payed with deposit vouchers)
    if (
      computedOrderState === State.Success
      && (paymentState === PaymentState.Unset || paymentState === undefined)
      && !failureCause
    ) {
      computedIndicatorState = State.Success;
      computedIndicatorLabel = t('orders.state.successful');
      computedIndicatorSubLabel = '';
      return;
    }

    // the paymentState is still pending
    if (
      paymentState === PaymentState.Pending
      && state !== OrderState.SystemAborted
    ) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = t('orders.state.pending');
      computedIndicatorSubLabel = '';
      return;
    }

    // the user aborted the payment at the terminal
    if (
      state === OrderState.UserAborted
      || failureCause === FailureCause.UserAborted
    ) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = t('orders.state.userAborted');
      computedIndicatorSubLabel = '';
      return;
    }

    // something went wrong but failureCause or state has an excuse
    if (
      computedOrderState === State.Warning
      || computedFailureCause === State.Warning
    ) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = stateT(state);
      computedIndicatorSubLabel = stateT(failureCause);
      return;
    }

    // something went wrong, no excuses
    computedIndicatorState = State.Error;
    computedIndicatorLabel = stateT(state);
    computedIndicatorSubLabel = stateT(failureCause);
  };
  setIndicatorValues();

  return (
    <AdvancedStateIndicator
      state={computedIndicatorState}
      label={(
        <TextWithSubText
          text={computedIndicatorLabel}
          subText={computedIndicatorSubLabel}
        />
      )}
      variant="outlined"
      sx={{ height: 'auto' }}
    />
  );
}
