import React from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import Translate from '../components/i18n/Translate';
import SnabbleLinearProgress from '../components/SnabbleLinearProgress';
import PaperTable from '../components/PaperTable';
import NoResult from '../components/NoResult';
import DocumentTitle from '../components/DocumentTitle';
import Headline from '../components/Headline';
import ColoredTableRow from '../components/ColoredTableRow';
import ContentLink from '../components/ContentLink';

class ClosingSchedulesList extends React.Component {
  componentDidMount() {
    this.props.fetchClosingSchedules();
  }

  render() {
    return (
      <React.Fragment>
        <SnabbleLinearProgress show={this.props.isLoading} />
        <DocumentTitle translationID="scheduledClosings.navigation" />

        <Headline withLink={this.props.canEdit} to={`/${this.props.projectId}/closings/schedules/id/new`} linkText={<Translate id="scheduledClosings.add" />}>
          <Translate id="scheduledClosings.headline" data={{ count: this.props.count }} />
        </Headline>

        {this.props.count === 0 && <NoResult text={<Translate id="scheduledClosings.notFound" />} />}

        {this.props.count > 0 &&
          <PaperTable
            tableHeader={
            [
              <Translate id="scheduledClosings.id" />,
              <Translate id="scheduledClosings.time" />,
              <Translate id="shop.itemName" />,
              '',
            ]
          }>
            {this.props.schedules.map(schedule => (
              <ColoredTableRow key={schedule.id}>
                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  <ContentLink to={schedule.links.self.href}>
                    {schedule.id}
                  </ContentLink>
                </TableCell>

                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  {moment(`${schedule.hour}:${schedule.minute}`, 'HH:mm').format('HH:mm')} {schedule.location}
                </TableCell>

                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  <ContentLink to={`/${schedule.project}/shops/${schedule.shop}`}>
                    {schedule.shopInformation && schedule.shopInformation.label}
                  </ContentLink>
                </TableCell>

                <TableCell align="right" style={{ paddingRight: '10px' }}>
                  <ContentLink to={schedule.links.self.href} view />
                  {this.props.canEdit &&
                    <ContentLink to={`${schedule.links.self.href}/edit`} edit />
                  }
                </TableCell>
              </ColoredTableRow>
            ))}
          </PaperTable>
        }
      </React.Fragment>
    );
  }
}

export default ClosingSchedulesList;
