import React from 'react';
import { WrappedFunctionProps } from '../types';
import { Fieldset as FormFieldset } from '../../form';

export default function Fieldset({
  key,
  translation,
  language,
  children,
}: WrappedFunctionProps) {
  if (!(children.length > 0)) return null;
  return (
    <FormFieldset category={key} legend={translation?.title?.[language] || key}>
      {children}
    </FormFieldset>
  );
}
