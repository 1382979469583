import React from 'react';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(5),
  },
});

const StatisticToolbar = ({ classes, children }) => (
  <div className={classes.container}>{children}</div>
);

export default withStyles(styles)(StatisticToolbar);
