const actions = {
  'chartFilter/updateRange': 'chartFilter/updateRange',
  'chartFilter/updateShop': 'chartFilter/updateShop',
  'chartFilter/updateWithCustomerCard': 'chartFilter/updateWithCustomerCard',
  'chartFilter/updatePaymentMethods': 'chartFilter/updatePaymentMethods',
  'chartFilter/updateGenericFilter': 'chartFilter/updateGenericFilter',

  REQUEST_PROJECTS: 'REQUEST_PROJECTS',
  RECEIVED_PROJECTS: 'RECEIVED_PROJECTS',
  SELECTED_PROJECT: 'SELECTED_PROJECT',
  UNSET_PROJECT: 'UNSET_PROJECT',
  RESTRICTED_PROJECT: 'RESTRICTED_PROJECT',
  SWITCH_PROJECT: 'SWITCH_PROJECT',

  REQUEST_PORTAL_METADATA: 'REQUEST_PORTAL_METADATA',
  RECEIVED_PORTAL_METADATA: 'RECEIVED_PORTAL_METADATA',

  REQUEST_INITIAL_SHOPS: 'REQUEST_INITIAL_SHOPS',
  REQUEST_SHOPS: 'REQUEST_SHOPS',
  RECEIVED_SHOPS: 'RECEIVED_SHOPS',

  RESET_SHOP: 'RESET_SHOP',
  RECEIVED_SHOP: 'RECEIVED_SHOP',
  REQUEST_SHOP: 'REQUEST_SHOP',
  EDIT_SHOP_STATUS_UPDATE: 'EDIT_SHOP_STATUS_UPDATE',

  REQUEST_ORDERS: 'REQUEST_ORDERS',
  RECEIVED_ORDERS: 'RECEIVED_ORDERS',
  REQUEST_ORDER: 'REQUEST_ORDER',
  RECEIVED_ORDER: 'RECEIVED_ORDER',
  RECEIVED_ORDER_EXPORT: 'RECEIVED_ORDER_EXPORT',
  RECEIVED_ORDER_EXPORT_ERROR: 'RECEIVED_ORDER_EXPORT_ERROR',
  RECEIVED_LINE_ITEMS_FOR_EXPORT: 'RECEIVED_LINE_ITEMS_FOR_EXPORT',
  REQUEST_ORDER_EXPORT: 'REQUEST_ORDER_EXPORT',
  REQUEST_LINE_ITEMS_FOR_EXPORT: 'REQUEST_LINE_ITEMS_FOR_EXPORT',

  REQUEST_PRODUCTS: 'REQUEST_PRODUCTS',
  RECEIVED_PRODUCTS: 'RECEIVED_PRODUCTS',
  RECEIVED_PRODUCTS_ERROR: 'RECEIVED_PRODUCTS_ERROR',
  REQUEST_PRODUCT: 'REQUEST_PRODUCT',
  RECEIVED_PRODUCT: 'RECEIVED_PRODUCT',
  RESET_PRODUCT: 'RESET_PRODUCT',

  REQUEST_PRICING: 'REQUEST_PRICING',
  RECEIVED_PRICING: 'RECEIVED_PRICING',

  REQUEST_PRICING_CATEGORIES: 'REQUEST_PRICING_CATEGORIES',
  RECEIVED_PRICING_CATEGORIES: 'RECEIVED_PRICING_CATEGORIES',
  EDIT_PRICING_CATEGORY_STATUS_UPDATE: 'EDIT_PRICING_CATEGORY_STATUS_UPDATE',

  REQUEST_PRICING_CATEGORY: 'REQUEST_PRICING_CATEGORY',
  RECEIVED_PRICING_CATEGORY: 'RECEIVED_PRICING_CATEGORY',

  PRICING_CATEGORY_STATUS_RESET: 'PRICING_CATEGORY_STATUS_RESET',

  UPDATE_PRICING_CATEGORY_FAILED: 'UPDATE_PRICING_CATEGORY_FAILED',
  UPDATE_PRICING_CATEGORY_SUCCESS: 'UPDATE_PRICING_CATEGORY_SUCCESS',

  DELETE_PRICING_CATEGORY_FAILED: 'DELETE_PRICING_CATEGORY_FAILED',
  DELETE_PRICING_CATEGORY_SUCCESS: 'DELETE_PRICING_CATEGORY_SUCCESS',

  RECEIVED_PRODUCT_STATISTIC: 'RECEIVED_PRODUCT_STATISTIC',
  REQUEST_ORDER_STATISTIC: 'REQUEST_ORDER_STATISTIC',
  RECEIVED_ORDER_STATISTIC_ERROR: 'RECEIVED_ORDER_STATISTIC_ERROR',
  RECEIVED_ORDER_STATISTIC: 'RECEIVED_ORDER_STATISTIC',
  RESET_ORDER_STATISTIC: 'RESET_ORDER_STATISTIC',
  REQUEST_BASKET_AVERAGE_STATISTIC: 'REQUEST_BASKET_AVERAGE_STATISTIC',
  RECEIVED_BASKET_AVERAGE_STATISTIC: 'RECEIVED_BASKET_AVERAGE_STATISTIC',
  RECEIVED_BASKET_AVERAGE_STATISTIC_ERROR: 'RECEIVED_BASKET_AVERAGE_STATISTIC_ERROR',
  RESET_BASKET_AVERAGE_STATISTIC: 'RESET_BASKET_AVERAGE_STATISTIC',
  REQUEST_RECURRING_VISITORS_STATISTIC: 'REQUEST_RECURRING_VISITORS_STATISTIC',
  RECEIVED_RECURRING_VISITORS_STATISTIC: 'RECEIVED_RECURRING_VISITORS_STATISTIC',
  RECEIVED_RECURRING_VISITORS_STATISTIC_ERROR: 'RECEIVED_RECURRING_VISITORS_STATISTIC_ERROR',
  RESET_RECURRING_VISITORS_STATISTIC: 'RESET_RECURRING_VISITORS_STATISTIC',
  RECEIVED_LAST_WEEK_ORDER_STATISTIC: 'RECEIVED_LAST_WEEK_ORDER_STATISTIC',

  REQUEST_APPROVAL_DURATION_STATISTIC: 'REQUEST_APPROVAL_DURATION_STATISTIC',
  RECEIVED_APPROVAL_DURATION_STATISTIC: 'RECEIVED_APPROVAL_DURATION_STATISTIC',
  RECEIVED_APPROVAL_DURATION_STATISTIC_ERROR: 'RECEIVED_APPROVAL_DURATION_STATISTIC_ERROR',
  RESET_APPROVAL_DURATION_STATISTIC: 'RESET_APPROVAL_DURATION_STATISTIC',

  ACQUIRED_TOKEN: 'ACQUIRED_TOKEN',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',

  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',

  REQUEST_CLOSINGS: 'REQUEST_CLOSINGS',
  RECEIVED_CLOSINGS: 'RECEIVED_CLOSINGS',
  REQUEST_CLOSING: 'REQUEST_CLOSING',
  RECEIVED_CLOSING: 'RECEIVED_CLOSING',
  REQUEST_CLOSING_SCHEDULES: 'REQUEST_CLOSING_SCHEDULES',
  RECEIVED_CLOSING_SCHEDULES: 'RECEIVED_CLOSING_SCHEDULES',
  REQUEST_CLOSING_SCHEDULE: 'REQUEST_CLOSING_SCHEDULE',
  RECEIVED_CLOSING_SCHEDULE: 'RECEIVED_CLOSING_SCHEDULE',
  CREATE_CLOSING_SCHEDULE: 'CREATE_CLOSING_SCHEDULE',

  EDIT_CLOSING_SCHEDULE_STATUS_UPDATE: 'EDIT_CLOSING_SCHEDULE_STATUS_UPDATE',

  REQUEST_CHECKOUT_DEVICES: 'REQUEST_CHECKOUT_DEVICES',
  RECEIVED_CHECKOUT_DEVICES: 'RECEIVED_CHECKOUT_DEVICES',
  REQUEST_CHECKOUT_DEVICE: 'REQUEST_CHECKOUT_DEVICE',
  RECEIVED_CHECKOUT_DEVICE: 'RECEIVED_CHECKOUT_DEVICE',
  REQUEST_CHECKOUT_DEVICES_STATUS: 'REQUEST_CHECKOUT_DEVICES_STATUS',
  RECEIVED_CHECKOUT_DEVICES_STATUS: 'RECEIVED_CHECKOUT_DEVICES_STATUS',
};


export default actions;
