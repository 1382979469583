import { Shop } from './shop/useShopApi';
import { buildLabelFromArray } from './utils/stringUtils';

export interface MinimalShop {
  id?: string;
  externalId?: string;
  externalID?: string;
  name?: string;
}

type ShopLabelData = Pick<Shop, 'id' | 'externalId' | 'name'>;

export function buildShopLabel(shop?: ShopLabelData) {
  if (!shop) return '';

  let idString = buildLabelFromArray({ input: [shop.id, shop.externalId], separator: ' / ' });
  if (shop.name) idString = ` (${idString})`;
  const nameString = shop.name ?? '';

  return `${nameString}${idString}`;
}

export default function shopLabel(shop?: MinimalShop) {
  if (!shop) {
    return undefined;
  }

  const externalId = shop.externalId ?? shop.externalID;
  if (!shop.name && !shop.id && !externalId) {
    return '';
  }

  if (shop.name && shop.id && externalId) {
    return `${shop.name} (${shop.id} / ${externalId})`;
  }
  if (shop.name && shop.id) {
    return `${shop.name} (${shop.id})`;
  }
  if (shop.name && externalId) {
    return `${shop.name} (${externalId})`;
  }
  if (shop.name) {
    return shop.name;
  }
  return shop.id;
}
