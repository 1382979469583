import React from 'react';
import Translate from './i18n/Translate';
import PaperTableRow from './PaperTableRow';
import PaperTable from './PaperTable';
import ContentLink from './ContentLink';


function OrderViewShop({ shop, projectID, id }) {
  if (!shop) return null;
  const url = `/${projectID}/shops/${id}`;
  return (
    <PaperTable headline={<Translate id="shop.itemName" />}>
      <PaperTableRow label={<Translate id="shop.externalId" />} value={<ContentLink to={url}>{shop.externalID}</ContentLink>} />
      <PaperTableRow label={<Translate id="shop.name" />} value={<ContentLink to={url}>{shop.name}</ContentLink>} />
    </PaperTable>
  );
}

export default OrderViewShop;
