import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React from 'react';
// @ts-ignore
import CircularSnabbleLogo from '../images/circular-logo-small.svg';

const Background = styled('div')(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
}));

const MainContainer = styled('div')(() => ({
  position: 'absolute',
  margin: 'auto',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 270,
  height: 270,
}));

const Container = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block',
}));

const SnabbleLogo = styled('img')(() => ({
  position: 'absolute',
  top: 35,
  left: 35,
}));


const LoadingCircle = ({ show }: { show: boolean }) => {
  if (!show) {
    return null;
  }
  return (
    <Background>
      <MainContainer>
        <Container>
          <CircularProgress size={270} thickness={3} />
          <SnabbleLogo src={CircularSnabbleLogo} alt="snabble.io" />
        </Container>
      </MainContainer>
    </Background>
  );
};

export default LoadingCircle;
