import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import FormTableRow from './FormTableRow';
import { isValidStatsTimeRange } from '../ranges';

const styles = theme => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputStyle: {
    width: `calc(25% - ${theme.spacing(1)})`,
    maxWidth: '200px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& input': {
      padding: theme.spacing(1.5),
    },
  },
});

const cleanValue = (value) => {
  let cleaned = value;
  if (value && value.length > 10) {
    const x = value.length - 10;
    cleaned = value.substring(x);
  }
  return cleaned;
};

class FormTableRowDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasInvalidRange: !isValidStatsTimeRange({ from: props.from, to: props.to }),
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  shouldComponentUpdate(prevProps) {
    return (
      this.props.hasError !== prevProps.hasError ||
      this.props.from !== prevProps.from ||
      this.props.to !== prevProps.to ||
      this.props.errorText !== prevProps.errorText ||
      this.props.required !== prevProps.required ||
      this.props.propertyName !== prevProps.propertyName ||
      this.props.text !== prevProps.text ||
      this.props.children !== prevProps.children
    );
  }

  handleChangeFrom(event) {
    const value = cleanValue(event.target.value);
    if (!value) return;

    const validRange = isValidStatsTimeRange({ from: value, to: this.props.to });
    this.props.fromCallback(value, validRange);
    this.setState({ hasInvalidRange: !validRange });
  }

  handleChangeTo(event) {
    const value = cleanValue(event.target.value);
    if (!value) return;

    const validRange = isValidStatsTimeRange({ from: this.props.from, to: value });
    this.props.toCallback(value, validRange);
    this.setState({ hasInvalidRange: !validRange });
  }

  render() {
    const {
      text, from, to, propertyName, classes, required, errorText, hasError,
    } = this.props;

    const invalidRangeError = this.state.hasInvalidRange ? 'form.invalidRange' : null;

    return (
      <FormTableRow
        text={text}
        required={required}
        propertyName={propertyName}
        errorText={errorText || invalidRangeError}
        hasError={hasError || this.state.hasInvalidRange}>
        <div className={classes.inputWrapper}>
          <TextField
            onChange={this.handleChangeFrom}
            id="fromDate"
            type="date"
            value={from}
            variant="outlined"
            label={<Translate id="filter.from" />}
            className={classes.inputStyle}
            InputLabelProps={{
              readOnly: true,
              shrink: true,
            }}
          />

          <TextField
            classes={{ root: classes.root }}
            onChange={this.handleChangeTo}
            id="toDate"
            type="date"
            value={to}
            variant="outlined"
            label={<Translate id="filter.to" />}
            className={classes.inputStyle}
            InputLabelProps={{
              readOnly: true,
              shrink: true,
            }}
          />
        </div>
      </FormTableRow>
    );
  }
}


export default withStyles(styles)(FormTableRowDateRange);
