import getTimezone from '../../actions/timezoneHelper';
import { toSearchQuery } from '../../urls';

export default function orderStatisticQueryString(params) {
  return toSearchQuery({
    from: params.from,
    to: params.to,
    timezone: getTimezone(),
    withCustomerCard: params.withCustomerCard,
    shopID: params.shopID,
    paymentMethod: params.paymentMethods,
    'filter-sessions': true,
  });
}
