import React, { BaseSyntheticEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import Translate from '../components/i18n/Translate';
import { Shop } from '../shop/useShopApi';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import SearchableShopSelect from '../components/SearchableShopSelect';
import useChartFilter from '../charts/useChartFilter';
// @ts-ignore
import FilterSelect from '../components/FilterSelect';
import { ensureArray } from '../utils/arrayUtils';
import useOrderApi, { uiOrderStateMapping } from './useOrderApi';
import OrderListTable from './OrderListTable';
import FromToSelectorWithDateTime from '../components/FromToSelectorWithDateTime';
// @ts-ignore
import QuickRangeFilter from '../components/QuickRangeFilter';
import { predefinedRanges } from '../ranges';
// @ts-ignore
import FilterTextInput from '../components/FilterTextInput';
import { Range } from '../charts/chartFilterProps';
// @ts-ignore
import FilterError from '../components/FilterError';
import OrderQuickEditDrawer from './OrderQuickEditDrawer';

export default function OrderListView(props: any) {
  const { t } = useTranslation();
  const api = useOrderApi();
  const resourceTableRef = useRef<ResourceChartViewRef | null>(null);

  const [pageCount, setPageCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fetchPage, setFetchPage] = useState(false);
  const [itemCount, setItemCount] = useState<number | undefined>();

  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const handleChangePage = useCallback((_: BaseSyntheticEvent, page: number) => {
    setFetchPage(true);
    setCurrentPage(page);
  }, []);

  const { filter, updateFilter } = useChartFilter({
    urlQuery: props.location.search,
    autoUpdateUrl: true,
    genericFilterInUrl: ['shopID', 'orderID', 'orderStatus'],
    defaultValues: {
      range: predefinedRanges.last14Days,
    },
  });

  const handleUpdateIdFilter = useCallback((newFilter: string) => {
    updateFilter({
      genericFilter: {
        ...filter.genericFilter,
        orderID: newFilter,
      },
    });
  }, [filter.genericFilter, updateFilter]);

  const handleFetch = useCallback(async (fetchFilter) => {
    const { orders, totalPages, totalItems } = await api.getOrders({
      ...fetchFilter,
      page: fetchPage ? currentPage : 1,
    });
    setCurrentPage(fetchPage ? currentPage : 1);
    setFetchPage(false);
    setPageCount(totalPages);
    setItemCount(totalItems);
    return orders;
  }, [api, currentPage, fetchPage]);

  return (
    <>
      <DocumentTitle translationID="orders.headline" />

      <FilterWrapper
        headline={
          <>
            <Translate id="orders.headline" />
            {itemCount && ` (${itemCount})`}
          </>
        }
        handleRefresh={() => { resourceTableRef.current?.updateData(); }}
        error={
          <FilterError id={filter.error} data={filter.errorData} />
          }
      >
        <FromToSelectorWithDateTime
          range={filter.range}
          onChange={(event: Range) => updateFilter({ range: event })}
          hasError={!!filter.error}
        />
        <QuickRangeFilter
          range={filter.range}
          onChange={(event: Range) => updateFilter({ range: event })}
        />
        <FilterTextInput
          label={<Translate id="orders.prefixSearch" />}
          value={filter.genericFilter?.orderID || ''}
          onChange={(event: BaseSyntheticEvent) => {
            handleUpdateIdFilter(event.target.value);
          }}
        />
        <SearchableShopSelect
          defaultShopId={ensureArray(filter.genericFilter?.shopID) || []}
          onChange={(newShops) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                shopID: (newShops as Shop[])?.map(s => s.id) || [],
              },
            });
          }}
          showLabel
          multiple
        />
        <FilterSelect
          value={filter.genericFilter?.orderStatus || 'all'}
          onChange={({ target }: PointerEvent) => {
            updateFilter({
              genericFilter: {
                ...filter.genericFilter,
                orderStatus: (target as any)?.value,
              },
            });
          }}
          label={t('orders.state.label')}
        >
          {Object.keys(uiOrderStateMapping).map(key => (
            <MenuItem key={key} value={key}>
              {t(uiOrderStateMapping[key].translationID)}
            </MenuItem>
          ))}
        </FilterSelect>
      </FilterWrapper>

      <ResourceChartView
        Chart={OrderListTable}
        ChartProps={{
          onOrderQuickEdit: setSelectedOrderId,
        }}
        fetch={handleFetch}
        filter={filter}
        ref={resourceTableRef}
      />

      {!!pageCount && (
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
        />
      )}

      <OrderQuickEditDrawer
        selectedOrderId={selectedOrderId}
        onClose={() => setSelectedOrderId(null)}
      />

    </>
  );
}
