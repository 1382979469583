import { get } from './request';
import actions from './constants';
import visitorStatisticQueryString from '../utils/api/visitorStatisticQueryString';

function requestRecurringVisitorsStatistic() {
  return {
    type: actions.REQUEST_RECURRING_VISITORS_STATISTIC,
  };
}

function resetRecurringVisitorsStatistic() {
  return {
    type: actions.RESET_RECURRING_VISITORS_STATISTIC,
  };
}

function receivedRecurringVisitorsStatistic(json, date, shopID) {
  return {
    type: actions.RECEIVED_RECURRING_VISITORS_STATISTIC,
    payload: json ? Object.assign(json, { date }, shopID) : null,
  };
}

function receivedRecurringVisitorsStatisticError() {
  return {
    type: actions.RECEIVED_RECURRING_VISITORS_STATISTIC_ERROR,
  };
}

function fetchRecurringVisitorsStatistic(from, to, shopID) {
  return (dispatch, getState) => {
    dispatch(requestRecurringVisitorsStatistic());

    const queryString = visitorStatisticQueryString({ from, to, shopID });

    return get(dispatch, getState, `/{project}/orders/statistics/visitors${queryString}`, 'application/json')
      .then(
        (json) => {
          if (!json) {
            dispatch(receivedRecurringVisitorsStatisticError());
          } else {
            dispatch(receivedRecurringVisitorsStatistic(
              json,
              { from, to }, shopID,
            ));
          }
        },
        () => dispatch(receivedRecurringVisitorsStatisticError()),
      );
  };
}

export {
  fetchRecurringVisitorsStatistic, resetRecurringVisitorsStatistic,
  receivedRecurringVisitorsStatistic,
};
