import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import FormTableRow from './FormTableRow';

const styles = () => ({
  label: {
    fontSize: '0.875rem',
  },
});

class FormTableRowRadio extends React.PureComponent {
  render() {
    const {
      options, currentValue, handleChange, classes, text,
    } = this.props;
    return (
      <FormTableRow text={text}>
        {(options || []).map(option => (
          <FormControlLabel
            key={option.value}
            className={classes.checkbox}
            label={option.text}
            classes={{ label: classes.label }}
            control={<Radio
              color="primary"
              value={option.value}
              checked={option.value === currentValue}
              onChange={() => handleChange(option.value)}
                />}
              />
          ))}
      </FormTableRow>
    );
  }
}

export default withStyles(styles)(FormTableRowRadio);
