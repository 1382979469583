import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

export function repairObject(incomingObject: any) {
  if (isArray(incomingObject)) {
    let currentObject = structuredClone(incomingObject);
    currentObject = currentObject.filter((i:any) => i !== undefined);
    currentObject = currentObject.map(o => (repairObject(o)));
    return currentObject;
  } if (isPlainObject(incomingObject)) {
    const currentObject = structuredClone(incomingObject);
    Object.keys(currentObject).forEach((key) => {
      currentObject[key] = repairObject(currentObject[key]);
    });
    return currentObject;
  }
  return incomingObject;
}

export function removeUndefinedObjectEntries(objectParam: object) {
  const currentObject = Object.assign(objectParam);
  Object.keys(currentObject).forEach((i) => {
    if (currentObject[i] === undefined) delete currentObject[i];
    if (typeof currentObject[i] === 'object' && currentObject[i]) currentObject[i] = removeUndefinedObjectEntries(currentObject[i]);
  });
  return currentObject;
}
