import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import toPairs from 'lodash/toPairs';
import { Order } from './orderTypes';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import { flattenObject } from '../utils/objectUtils';
import DownloadAsLink from '../components/DownloadAsLink';

interface OrderPaymentResultProps {
  paymentResult?: Order['paymentResult'];
}

export default function OrderPaymentResult({
  paymentResult,
}: OrderPaymentResultProps) {
  const { t } = useTranslation();

  const renderTableRowList = useCallback((translatedKey: string, value: any[]) => (
    <PaperTableRow
      label={translatedKey}
      value={(
        <ul>
          {value.map(item => (
            <li>{JSON.stringify(item, null, 2)}</li>
          ))}
        </ul>
          )}
    />
  ), []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderDownloadLink = useCallback((name: string, receipt: string) => (
    (name && receipt) && <DownloadAsLink
      blob={new Blob([receipt], { type: 'text/plain;charset=utf-8' })}
      name={name}
      type="txt"
    />
  ), []);

  const renderTableRow = useCallback(([key, value]: [string, any]) => {
    // NOTE payment results change frequently. So a good fallback is needed for the translation.
    // For this the key itself is used but the dots are replaced with arrows.
    const tranlatedKey = t(`paymentResult.${key}`, {
      defaultValue: key.replace(/\./g, ' → '),
    });

    if (Array.isArray(value)) return renderTableRowList(tranlatedKey, value);
    if (key === 'customerReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`customer-receipt-${paymentResult?.receiptNumber}`, value)}
        />
      );
    }
    if (key === 'merchantReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`merchant-receipt-${paymentResult?.receiptNumber}`, value)}
        />
      );
    }

    const formattedValue = String(value || '-/-');

    return <PaperTableRow label={tranlatedKey} value={formattedValue} />;
  }, [paymentResult?.receiptNumber, renderDownloadLink, renderTableRowList, t]);

  if (!paymentResult) return null;

  return (
    <>
      <PaperTable headline={t('paymentResult.headline')}>
        {
          toPairs(flattenObject(paymentResult)).map(renderTableRow)
        }
      </PaperTable>
    </>
  );
}
