import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Download from '@mui/icons-material/Download';
import TableCell from '@mui/material/TableCell';
import { PhoneAndroid, QrCode } from '@mui/icons-material';
// @ts-ignore
import DocumentTitle from '../../components/DocumentTitle';
// @ts-ignore
import PaperTable from '../../components/PaperTable';
// @ts-ignore
import ColoredTableRow from '../../components/ColoredTableRow';
import { useResourceTranslator } from '../../resource';
import Translate from '../../components/i18n/Translate';
import Headline from '../../components/Headline';
import QRCodeDownloadDialog from './QrCodeDialog';

const DOWNLOAD_LINK = 'https://docs.snabble.io/supervisor-android/release/supervisor.apk';

export default function SupervisorDetailView() {
  const t = useResourceTranslator();
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);

  const body = (
    <>
      <PaperTable
        headline={t('releases.information')}
      >
        <ColoredTableRow>
          <TableCell align="left" >
            {t('releases.name')}
          </TableCell>

          <TableCell align="left" >
            Supervisor
          </TableCell>
        </ColoredTableRow>
      </PaperTable >
      <PaperTable
        headline={t('releases.downloads')}
        tableHeader={[
          t('releases.type'),
          t('releases.name'),
          '',
        ]}
      >
        <ColoredTableRow>
          <TableCell align="left" style={{ paddingRight: '10px' }}>
            <PhoneAndroid />
          </TableCell>
          <TableCell align="left" style={{ paddingRight: '10px' }}>
            supervisor.apk
          </TableCell>
          <TableCell align="right" style={{ paddingRight: '10px' }}>
            <IconButton aria-label={`${t('releases.download')} supervisor`} onClick={() => { setShowQrCodeDialog(true); }}>
              <QrCode />
            </IconButton>
            <a href={DOWNLOAD_LINK}>
              <IconButton aria-label={`${t('releases.download')} supervisor apk`}>
                <Download />
              </IconButton>
            </a>
          </TableCell>
        </ColoredTableRow>
      </PaperTable >
    </>
  );

  return (
    <>
      <DocumentTitle translationID="releases.headline" />
      <Headline>
        <Translate id="releases.headline" />
      </Headline>
      {showQrCodeDialog &&
        <QRCodeDownloadDialog
          downloadLink={DOWNLOAD_LINK}
          onClose={() => { setShowQrCodeDialog(false); }}
          open
        />
      }
      {body}
    </>
  );
}
