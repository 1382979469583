/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MUITextField, { TextFieldProps as MUITextFieldProps, TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import NoImageIcon from '@mui/icons-material/HideImage';
import OpenPreviewIcon from '@mui/icons-material/OpenInFull';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useController } from 'react-hook-form';
import { FieldProps } from '../FieldProps';
import FieldContainer from '../FieldContainer';
import useFormLabel from '../useFormLabel';
import ValidationError from '../ValidationError';
import BorderWrapper from './BorderWrapper';
import ImagePreviewContainer from './ImagePreviewContainer';
import UploadDialog from './UploadDialog';
import useBase64Downloader from './useBase64Downloader';
import useFrontendResourcesApi from '../../../frontendResources/useFrontendResourcesApi';

export interface ImageFieldProps extends FieldProps<MUITextFieldProps> {
  optional?: boolean;
  // the return value of onUpload is entered into the TextField
  onUpload?: (file: File) => Promise<void | string> | void | string;
  label?: string;
  allowManualEdit?: boolean;
  textFieldProps?: TextFieldProps;
}

export default function ImageField({
  control,
  name,
  rules,
  type,
  optional = false,
  onUpload,
  label,
  // TODO if we want to allow typing in external URLs we should debounce the
  // fetch function and add proper error handling. But for now only uploding
  // images is needed.
  allowManualEdit = false,
  textFieldProps,
}: ImageFieldProps) {
  const { t } = useTranslation();
  const resourceApi = useFrontendResourcesApi();

  const textFieldLabel = useFormLabel(
    name,
    label,
    { removeIndex: true, optionalHint: optional },
  );

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const {
    dataAvailable: previewAvailable,
    srcData,
  } = useBase64Downloader({ url: value });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleOpenPreview = () => previewAvailable && setPreviewOpen(true);

  const handleUpload = useCallback(async (file: File) => {
    let newValue;
    if (onUpload) {
      newValue = await onUpload?.(file);
    } else {
      newValue = await resourceApi.uploadImage({ file });
    }
    if (newValue) onChange(newValue);
    setUploadDialogOpen(false);
  }, [onChange, onUpload, resourceApi]);

  const handleDelete = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <>
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
        maxWidth="sm"
        sx={{ cursor: 'pointer' }}
      >
        <img
          src={srcData}
          alt="preview of set url"
          onClick={() => setPreviewOpen(false)}
        />
      </Dialog>

      <UploadDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onUpload={handleUpload}
      />

      <FieldContainer>
        <BorderWrapper direction="row" gap="4px">
          <ImagePreviewContainer onClick={handleOpenPreview}>
            <img
              src={srcData}
              alt="preview of set url"
              style={{ opacity: previewAvailable ? 1 : 0 }}
            />
            {previewAvailable && (
              <IconButton aria-label="delete">
                <OpenPreviewIcon />
              </IconButton>
            )}
            {!previewAvailable && (
              <NoImageIcon className="placeholder" />
            )}
          </ImagePreviewContainer>
          <Stack
            sx={{ flexGrow: 1 }}
            alignItems="flex-end"
            gap={0.5}
          >
            <MUITextField
              type={type}
              label={textFieldLabel}
              variant="outlined"
              value={value || ''}
              onChange={onChange}
              error={!!error}
              helperText={error && <ValidationError error={error} />}
              fullWidth
              disabled={!allowManualEdit}
              {...textFieldProps}
            />
            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                color="error"
                disabled={!value}
                startIcon={<DeleteIcon />}
                sx={{ flexGrow: 0 }}
                onClick={handleDelete}
              >
                {t('form.imageField.removeImageButton')}
              </Button>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ flexGrow: 0 }}
                onClick={() => setUploadDialogOpen(true)}
              >
                {t('form.imageField.uploadButton')}
              </Button>
            </Stack>
          </Stack>
        </BorderWrapper>
      </FieldContainer >
    </>
  );
}
