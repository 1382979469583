import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';

const useApi = defineApi({
  uploadImage: async (client, { file }) => {
    const formData = new FormData();
    formData.append('file', file);
    const { headers } = await client.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return (headers?.location || '') as string;
  },
});

export default function useFrontendResourcesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/frontend-resources' });
  return useApi(client);
}
