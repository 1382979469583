import React from 'react';
import Translate from './i18n/Translate';
import PaperTableRow from './PaperTableRow';
import PaperTable from './PaperTable';
import SupervisingCheckRow from './SupervisingCheckRow';
import SimpleStateIndicator from './SimpleStateIndicator';


function OrderViewSupervising({ result }) {
  if (!result) return null;
  return (
    <PaperTable headline={<Translate id="supervising.headline" />}>
      <PaperTableRow
        label={<Translate id="supervising.result" />}
        value={<SimpleStateIndicator useErrorColor useGreen={result.successful} text={<Translate id={result.successful ? 'supervising.success' : 'supervising.failed'} />} />}
    />
      <PaperTableRow label={<Translate id="supervising.supervisorID" />} value={result.supervisorID} />
      {(result.checks || []).map(check => (
        <SupervisingCheckRow check={check} key={check.type} />
      ))}
    </PaperTable>
  );
}

export default OrderViewSupervising;
