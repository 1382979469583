import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import debounce from 'lodash/debounce';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
import CategoriesProductDialogTable from './CategoriesProductDialogTable';
import { ProductData, useCategoryProductApi } from './useCategoriesApi';

export interface CategoriesProductDialogViewProps {
  onSelect: (product: ProductData | undefined) => void;
  onClose: () => void;
  searchQuery?: string;
  singleSelect?: boolean;
}

export default function CategoriesProductDialogView({
  onSelect,
  onClose,
  searchQuery = '',
  singleSelect = false,
}: CategoriesProductDialogViewProps) {
  const api = useCategoryProductApi();
  const { t } = useTranslation();

  const [filterInput, setFilterInput] = useState<string>(searchQuery);
  const [filter, setFilter] = useState<any>({ search: searchQuery });

  const filterRef = useRef<any>(null);
  useEffect(() => {
    setTimeout(() => { filterRef.current?.focus(); }, 0);
  }, []);

  const handleFetch = useCallback(async () => {
    const products = await api.getProductSearch(filter);
    return products || [];
  }, [api, filter]);

  const handleFilterChange = useCallback((event) => {
    setFilter({ search: event.target.value });
  }, []);

  const handleFilterChangeDebounced = useMemo(() => (
    debounce(handleFilterChange, 500)
  ), [handleFilterChange]);

  const handleFilterInputChange = useCallback((event) => {
    setFilterInput(event.target.value);
    handleFilterChangeDebounced(event);
  }, [handleFilterChangeDebounced]);

  const handleFilterClear = useCallback(() => {
    setFilterInput('');
    setFilter({});
    filterRef.current?.focus();
  }, []);

  const handleSelect = useCallback((product: ProductData) => {
    onSelect(product);
    if (singleSelect) onClose();
    filterRef.current?.focus();
    filterRef.current?.select();
  }, [onClose, onSelect, singleSelect]);

  return (
    <>
      <Dialog
        open
        sx={{ '& .MuiDialog-paper': { height: '50vh' } }}
        maxWidth="lg"
        fullWidth
        onClose={() => { onClose(); }}
      >
        <DialogContent dividers sx={{ overflow: 'hidden' }}>
          <TextField
            label={t('categories.searchProduct')}
            onChange={handleFilterInputChange}
            value={filterInput}
            fullWidth
            variant="standard"
            autoFocus
            inputRef={filterRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon onClick={handleFilterClear} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }}
          />
          <ResourceChartView
            Chart={CategoriesProductDialogTable}
            ChartProps={{
              onSelect: handleSelect,
            }}
            fetch={handleFetch}
            filter={filter}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { onClose(); }}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
