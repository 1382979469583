import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Access } from '../access';
// @ts-ignore
import StatisticApprovalsContainer from '../containers/StatisticApprovalsContainer';
// @ts-ignore
import StatisticBasketAverageContainer from '../containers/StatisticBasketAverageContainer';
// @ts-ignore
import StatisticTotalRevenueContainer from '../containers/StatisticTotalRevenueContainer';
// @ts-ignore
import StatisticRecurringVisitorsContainer from '../containers/StatisticRecurringVisitorsContainer';
import RouteWrapper from './RouteWrapper';
import FeedbackView from '../feedback/FeedbackView';

function StatisticRoutes({ access }: { access?: Access }) {
  const { projectId } = useParams();
  return (
    <Routes>
      {access?.read &&
        <React.Fragment>
          <Route path="/approvals" element={<RouteWrapper><StatisticApprovalsContainer /></RouteWrapper>} />
          <Route path="/basket-average" element={<RouteWrapper><StatisticBasketAverageContainer /></RouteWrapper>} />
          <Route path="/recurring-visitors" element={<RouteWrapper><StatisticRecurringVisitorsContainer /></RouteWrapper>} />
          <Route path="/revenue" element={<RouteWrapper><StatisticTotalRevenueContainer /></RouteWrapper>} />
          { /* do not remove old url yet, in case someone bookmarked it */}
          <Route path="/orders" element={<RouteWrapper><StatisticTotalRevenueContainer /></RouteWrapper >} />
          <Route path="/feedback" element={<RouteWrapper><FeedbackView /></RouteWrapper>} />
        </React.Fragment>
      }
      <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
    </Routes>
  );
}

export default StatisticRoutes;
