import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useResourceTranslator } from '../resource';
import AdditionalAttributes from './AdditionalAttributes';
import useUniqueId from '../useUniqueId';
import { AttributesList } from '../attributes';

export interface AdditionalAttributesDialogProps {
  attributes: AdditionalAttributes;
  open: boolean;
  onClose: () => void;
}

export default function AdditionalAttributesDialog({
  attributes,
  open,
  onClose,
}: AdditionalAttributesDialogProps) {
  const t = useResourceTranslator();
  const titleId = useUniqueId();
  const contentId = useUniqueId();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="sm"
      aria-labelledby={titleId}
      aria-describedby={contentId}
    >
      <DialogTitle id={titleId}>
        {t('fields.additionalAttributes')}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }} id={contentId}>
        <AttributesList attributes={attributes} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('actions.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
