export interface Claim {
  c: string;
  r: string;
}

export default function checkUserIsProjectAdmin(
  userClaims: Claim[],
  projectID: string | undefined,
) {
  if (!userClaims || userClaims.length < 1) return false;
  if (!projectID) return true;
  return userClaims.some(claim => (
    claim.r === 'projectAdmin' && (claim.c === '*' || claim.c === `/${projectID}`)
  ));
}
