import React, { ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useUniqueId from '../useUniqueId';

export interface LoadableRegionProps {
  children: ReactNode;
  loading?: boolean;
}

export default function LoadableRegion({
  children,
  loading = false,
}: LoadableRegionProps) {
  const progressId = useUniqueId('progress_');

  return (
    <div>
      {loading && <CircularProgress id={progressId} size="4rem" />}

      <div
        aria-busy={loading}
        aria-describedby={loading ? progressId : undefined}
      >
        {!loading && children}
      </div>
    </div>
  );
}
