import React, { useState } from 'react';
// @ts-ignore
import { DiscreteColorLegend } from 'react-vis';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import StatisticMultipleBar from '../charts/components/StatisticMultipleBar';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import { Range } from '../charts/chartFilterProps';
// @ts-ignore
import StatisticSwitch from '../components/StatisticSwitch';
import { StatisticMultipleBarProps } from '../charts';

export interface RatingsChartProps {
  values: {
    chartData: StatisticMultipleBarProps,
    countMax: number,
    countSum: number,
  },
  range?: Range,
  ratingsMetadata?: {
    keys: string[],
    legendText: string[],
    legendColors: string[],
  }
  stackedOnInit?: boolean,
  onValueClick?: () => void,
}

const style = (theme: Theme) => ({
  legend: {
    paddingRight: theme.spacing(4),
  },
});

export default function RatingsChart({
  values,
  range,
  ratingsMetadata,
  stackedOnInit,
  onValueClick,
}: RatingsChartProps) {
  const [stacked, setStacked] = useState(stackedOnInit);

  const theme = useTheme();
  const classes = style(theme);

  const { chartData, countMax, countSum } = values;

  const chart = (
    (countSum > 0) ?
      (<StatisticMultipleBar
        xLabelAsDate
        values={chartData}
        maxValue={countMax + 1}
        translationLabelYKey="feedbackStatistic.ratings.diagramHeadlineNet"
        stackY={stacked}
        onValueClick={onValueClick}
        colors={ratingsMetadata?.legendColors}
      />)
      :
      <Typography><Translate id="feedbackStatistic.ratings.empty" /></Typography>
  );

  return (
    <StatisticPaper
      headline={
        <Translate id="feedbackStatistic.ratings.headline" />
            }
      from={range?.from}
      to={range?.to}
      total={countSum}
      additionalHeaderItems={
        <>
          <DiscreteColorLegend
            style={classes.legend}
            colors={ratingsMetadata?.legendColors}
            items={ratingsMetadata?.legendText}
            orientation="horizontal"
          />
          <StatisticSwitch
            label={
              <Translate
                id="feedbackStatistic.ratings.stackChart"
                data={{ currency: '' }}
              />
          }
            checked={stacked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setStacked(event.target.checked);
            }}
          />
        </>
      }
    >
      {chart}
    </StatisticPaper>
  );
}
