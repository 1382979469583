import { get } from './request';
import actions from './constants';
import orderStatisticQueryString from '../utils/api/orderStatisticQueryString';

function requestBasketAverageStatistic() {
  return {
    type: actions.REQUEST_BASKET_AVERAGE_STATISTIC,
  };
}

function resetBasketAverageStatistic() {
  return {
    type: actions.RESET_BASKET_AVERAGE_STATISTIC,
  };
}

function receivedBasketAverageStatisticError() {
  return {
    type: actions.RECEIVED_BASKET_AVERAGE_STATISTIC_ERROR,
  };
}

function receivedBasketAverageStatistic(json, date, shopID, withCustomerCard, paymentMethods) {
  const filter = {
    date,
    shopID,
    withCustomerCard,
    paymentMethods,
  };

  return {
    type: actions.RECEIVED_BASKET_AVERAGE_STATISTIC,
    payload: json ? Object.assign(json, filter) : null,
  };
}

function fetchBasketAverageStatistic(from, to, withCustomerCard, shopID, paymentMethods) {
  return (dispatch, getState) => {
    dispatch(requestBasketAverageStatistic());

    const queryString = orderStatisticQueryString({
      from,
      to,
      withCustomerCard,
      shopID,
      paymentMethods,
    });

    return get(dispatch, getState, `/{project}/orders/statistics/basketAverage${queryString}`, 'application/json')
      .then(
        (json) => {
          if (!json) {
            dispatch(receivedBasketAverageStatisticError());
          } else {
            dispatch(receivedBasketAverageStatistic(
              json,
              { from, to },
              shopID,
              withCustomerCard,
              paymentMethods,
            ));
          }
        },
        () => dispatch(receivedBasketAverageStatisticError()),
      );
  };
}

export {
  fetchBasketAverageStatistic, resetBasketAverageStatistic, receivedBasketAverageStatistic,
};
