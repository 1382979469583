import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import isEqual from 'lodash/isEqual';
import Translate from './i18n/Translate';
import { predefinedRanges } from '../ranges';

function findValue(ranges, range) {
  if (!range) return '';

  return ranges
    .find(i => i.from === range?.from && i.to === range?.to)
    ?.value || '';
}

function findRange(ranges, value) {
  if (!value) return null;

  const range = ranges.find(i => i.value === value);
  if (!range) return null;

  return { from: range.from, to: range.to };
}

const DEFAULT_RANGES = [
  predefinedRanges.currentWeek,
  predefinedRanges.last14Days,
  predefinedRanges.currentMonth,
  predefinedRanges.lastMonth,
];

class QuickRangeFilter extends Component {
  constructor(props) {
    super(props);

    const ranges = props.ranges || DEFAULT_RANGES;

    this.state = {
      range: props.range,
      ranges,
      value: findValue(ranges, props.range),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(props, currentState) {
    if (
      isEqual(props.range, currentState.range) &&
      isEqual(props.ranges, currentState.ranges)
    ) {
      return null;
    }

    const ranges = props.ranges || DEFAULT_RANGES;

    return {
      range: props.range,
      ranges,
      value: findValue(ranges, props.range),
    };
  }

  handleChange(event) {
    const { value } = event.target;
    if (!value) return;

    this.setState({ value }, () => {
      const range = findRange(this.state.ranges, value);
      this.props.onChange(range);
    });
  }

  render() {
    const label = <Translate id="filter.timeRange" />;

    return (
      <FormControl fullWidth>
        <InputLabel
          id="quick-range-filter-label"
          htmlFor="quick-range-filter-input"
        >
          {label}
        </InputLabel>

        <Select
          labelId="quick-range-filter-label"
          id="quick-range-filter-input"
          value={this.state.value}
          label={label}
          onChange={this.handleChange}
        >
          {this.state.ranges.map(range => (
            <MenuItem key={range.value} value={range.value}>
              <Translate id={`range.${range.value}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default QuickRangeFilter;
