import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  CustomFormProps,
  Form,
  FormActions,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import { RealmValidationRules, User } from './useUserManagementApi';
import { CancelButton, SubmitButton } from '../form/button';

const DEFAULT_VALUES: User | {} = {};

export interface CreateUserFormProps extends CustomFormProps<User | {}> {
  realmValidationRules: RealmValidationRules | undefined;
  onCancel: () => void;
}

export default function PasswordOverwriteDialogForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  onCancel,
  realmValidationRules,
}: CreateUserFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        {t('userManagement.overwritePassword.dialogBody')}
      </Typography>
      <TextField
        control={control}
        name="newPassword"
        type="password"
        required
        helperText={realmValidationRules?.passwordValidationRules?.hint}
        rules={{
          ...realmValidationRules?.passwordValidationRules,
          required: true,
          validate: value =>
            value === watch('newPasswordConfirmation') || 'Passwords do not match',
        }}
      />
      <TextField
        control={control}
        name="newPasswordConfirmation"
        type="password"
        required
        rules={{
          ...realmValidationRules?.passwordValidationRules,
          required: true,
          validate: value =>
            value === watch('newPassword') || 'Passwords do not match',
        }}
      />

      <FormActions allowReverse>
        <SubmitButton formState={formState} />
        <CancelButton formState={formState} onConfirm={onCancel} />
      </FormActions>
    </Form>
  );
}
