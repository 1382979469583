import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import withStyles from '@mui/styles/withStyles';
import { green } from '@mui/material/colors';
import Translate from './i18n/Translate';

const styles = theme => ({
  snackbarSuccess: {
    backgroundColor: green[500],
    fontWeight: 'bold',
  },
  snackbarError: {
    backgroundColor: theme.palette.error[500],
    fontWeight: 'bold',
  },
});

class FormStatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusType: '',
      message: '',
    };
    this.chooseMessage = this.chooseMessage.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  updateContent() {
    const { status } = this.props;
    const success = ['deleted', 'saved'].indexOf(status) !== -1;
    this.setState({
      statusType: success ? 'success' : 'error',
      message: this.chooseMessage(),
    });
  }

  chooseMessage() {
    const { itemName, status } = this.props;
    switch (status) {
      case 'deleted':
        return <Translate id="form.deletingSuccess" data={{ itemName }} />;
      case 'deletingFailed':
        return <Translate id="form.deletingFailed" data={{ itemName }} />;
      case 'noChanges':
        return <Translate id="form.noChanges" data={{ itemName }} />;
      case 'saved':
        return <Translate id="form.updateSuccess" data={{ itemName }} />;
      case 'savingFailed':
        return <Translate id="form.updateFailed" data={{ itemName }} />;
      case 'errors':
        return <Translate id="form.genericErrors" />;
      default:
        return '';
    }
  }

  render() {
    const { classes } = this.props;
    const { statusType, message } = this.state;
    const statusClass = statusType === 'success' ? classes.snackbarSuccess : classes.snackbarError;
    return (
      <Snackbar
        data-testid={statusType}
        open={this.props.status !== ''}
        ContentProps={{ className: statusClass }}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={this.props.reset}
        TransitionProps={{
          onEnter: this.updateContent,
          onExited: this.updateContent,
        }}
        message={message}
    />);
  }
}

FormStatusBar.defaultProps = {
  status: '',
  itemName: null,
  reset: () => { },
};

FormStatusBar.propTypes = {
  status: PropTypes.string,
  reset: PropTypes.func,
  itemName: PropTypes.string,
};

export default withStyles(styles)(FormStatusBar);
