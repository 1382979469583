import { get } from './request';
import actions from './constants';
import approvalStatisticQueryString from '../utils/api/approvalStatisticQueryString';

function requestApprovalDurationStatistic() {
  return {
    type: actions.REQUEST_APPROVAL_DURATION_STATISTIC,
  };
}

function resetApprovalDurationStatistic() {
  return {
    type: actions.RESET_APPROVAL_DURATION_STATISTIC,
  };
}

function receivedApprovalDurationStatisticError() {
  return {
    type: actions.RECEIVED_APPROVAL_DURATION_STATISTIC_ERROR,
  };
}

function receivedApprovalDurationStatistic(data, date, shopID) {
  const filter = { date, shopID };

  return {
    type: actions.RECEIVED_APPROVAL_DURATION_STATISTIC,
    payload: Object.assign({}, data, filter),
  };
}

function fetchApprovalDurationStatistic(from, to, shopID) {
  return async (dispatch, getState) => {
    dispatch(requestApprovalDurationStatistic());

    const queryString = approvalStatisticQueryString({
      from,
      to,
      shopID,
    });

    try {
      const data = await get(
        dispatch,
        getState,
        `/{project}/orders/statistics/averageApprovalDurations${queryString}`,
        'application/json',
      );

      if (!data) {
        dispatch(receivedApprovalDurationStatisticError());
      } else {
        dispatch(receivedApprovalDurationStatistic(data, { from, to }, shopID));
      }
    } catch (e) {
      dispatch(receivedApprovalDurationStatisticError());
    }
  };
}

export {
  fetchApprovalDurationStatistic,
  resetApprovalDurationStatistic,
  receivedApprovalDurationStatistic,
};
