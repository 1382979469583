import React from 'react';
import { LineMarkSeries, AreaSeries } from 'react-vis';
import StatisticPlot from './StatisticPlot';

class StatisticArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      compareHint: null,
      showHint: false,
      totalHint: null,
    };
    this.setHint = this.setHint.bind(this);
  }
  setHint(value) {
    let compareValue = null;
    if (this.props.compareValues) {
      compareValue = this.props.compareValues[value.x - 1];
    }

    if (this.props.withTotal && compareValue !== null) {
      this.setState({ compareHint: compareValue, totalHint: compareValue.totalLabel, hint: value });
    } else {
      this.setState({ compareHint: compareValue, hint: value });
    }
  }
  toggleHint(isVisible) {
    this.setState({ showHint: isVisible });
  }
  render() {
    const {
      values, color, maxValue, translationLabelYKey, translationComparedKey, translationHintKey,
      translationData, compareValues, compareColor, withRegression, xLabelAsDate,
    } = this.props;
    const areaColor = color || '#07b';
    const lineMarkColor = compareColor || '#ffc400';

    return (
      <StatisticPlot
        values={values}
        maxValue={maxValue}
        translationLabelYKey={translationLabelYKey}
        translationComparedKey={translationComparedKey}
        translationHintKey={translationHintKey}
        translationData={translationData}
        xLabelAsDate={xLabelAsDate}
        hint={this.state.hint}
        compareHint={this.state.compareHint}
        showHint={this.state.showHint}
        withRegression={withRegression}
        totalHint={this.state.totalHint}
      >
        <AreaSeries
          data={values}
          color={areaColor}
          onNearestX={this.setHint}
          onSeriesMouseOver={() => this.toggleHint(true)}
          onSeriesMouseOut={() => this.toggleHint(false)}
        />
        {!!compareValues &&
          <LineMarkSeries
            data={compareValues}
            color={lineMarkColor}
            onSeriesMouseOver={() => this.toggleHint(true)}
            onSeriesMouseOut={() => this.toggleHint(false)}
            strokeStyle="dashed"
            markStyle={{ strokeWidth: '2', fill: '#ffffff' }}
            fill="#ffffff"
          />
        }
      </StatisticPlot>
    );
  }
}

export default StatisticArea;
