import axios from 'axios';

const UNAUTHORIZED_STATUS = 401;
const FORBIDDEN_STATUS = 403;

export default function isAuthorizationError(error: Error): boolean {
  if (!axios.isAxiosError(error) || !error.response) return false;

  return [UNAUTHORIZED_STATUS, FORBIDDEN_STATUS].includes(error.response.status);
}
