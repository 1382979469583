import React from 'react';
import { useResourceContext } from '../../resource';
import WithAccess, { WithAccessProps } from '../WithAccess';

export type WithResourceAccessProps = Omit<WithAccessProps, 'scope'>;

export default function WithResourceAccess(props: WithResourceAccessProps) {
  const { name: scope } = useResourceContext();

  return (
    <WithAccess scope={scope} {...props} />
  );
}
