import React, { useState } from 'react';
import { ButtonProps } from '@mui/material/Button';
import ConfigDialog from './ConfigDialog';
import CheckoutDevice from './CheckoutDevice';
import ConfirmButton from '../button/ConfirmButton';
import { useAlert } from '../toast';

export interface ResetSecretButtonProps extends ButtonProps {
  reset: () => Promise<CheckoutDevice>;
}

export default function ResetSecretButton({
  reset,
  ...props
}: ResetSecretButtonProps) {
  const [device, setDevice] = useState<CheckoutDevice>();
  const [disabled, setDisabled] = useState(false);
  const alert = useAlert();

  const handleConfirm = async () => {
    setDisabled(true);

    try {
      setDevice(await reset());
      alert.success({ actionName: 'resetSecret' });
    } catch (e) {
      if (e instanceof Error) {
        alert.error({ message: e.message });
      }
      throw e;
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <ConfigDialog
        open={!!device}
        device={device}
        onClose={() => { setDevice(undefined); }}
      />
      <ConfirmButton
        actionName="resetSecret"
        variant="contained"
        color="error"
        onConfirm={handleConfirm}
        disabled={disabled}
        {...props}
      />
    </>
  );
}
