import { styled } from '@mui/system';

const CodeBlock = styled('pre')(() => ({
  overflow: 'auto',
  maxHeight: '30rem',
  margin: 0,
}));


export default CodeBlock;
