import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { useImageUrl } from '../assets/useAssetApi';
import AsyncContent from '../components/AsyncContent';

const NonTransformingButton = styled(Button)({
  textTransform: 'none',
});

enum TabStates {
  ERROR_EMPTY = 'error_empty',
  ERROR_DUPLICATE = 'error_duplicate',
  SELECTED = 'selected',
  DEFAULT = 'default',
}

const tabColors = {
  [TabStates.ERROR_EMPTY]: 'red',
  [TabStates.ERROR_DUPLICATE]: 'red',
  [TabStates.SELECTED]: 'theme.primary',
  [TabStates.DEFAULT]: 'grey',
};

interface CategoriesFormTabsProps {
  tab: number;
  onTabChange: (index: number) => void;
  containerProps: Record<string, any>;
  itemProps: Record<string, any>;
  onAddCategory: () => void;
  categories: any[];
  onMetaConfigChange: (index: number) => void;
}

export default function CategoriesFormTabs({
  tab,
  onTabChange,
  containerProps,
  itemProps,
  onAddCategory,
  categories,
  onMetaConfigChange,
}: CategoriesFormTabsProps) {
  const { t } = useTranslation();
  const getAbsoluteIconUrl = useImageUrl();

  const tabStates = categories?.map((category, index) => {
    if (!category.items || category.items.length === 0) return TabStates.ERROR_EMPTY;
    if (categories.findIndex(element => (element.id === category.id)) !== index) {
      return TabStates.ERROR_DUPLICATE;
    }
    if (index === tab) return TabStates.SELECTED;
    return TabStates.DEFAULT;
  });

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} {...containerProps}>
      <Tabs
        value={tab + 1}
        onChange={(_, index) => { onTabChange(index); }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Stack direction="row" spacing={0} alignItems="center" sx={{ marginRight: '16px' }}>
          <IconButton onClick={onAddCategory}>
            <AddIcon color="primary" />
          </IconButton>
        </Stack>
        {(categories || []).map((category: any, index: number) => (
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            sx={{ marginRight: '16px' }}
            // eslint-disable-next-line react/no-array-index-key
            key={`tab-${index}`}
            {...itemProps}
            data-index={index}
          >
            <AsyncContent
              fetch={() => getAbsoluteIconUrl(category.image)}
              render={(absoluteIconUrl: string | null, isLoading: boolean) => (
                (!isLoading && absoluteIconUrl) && (
                  <img style={{ height: '1em' }} src={absoluteIconUrl || ''} alt={category.name} />
                ))}
              refetch
            />
            <Tooltip
              title={tabStates[index].startsWith('error') && t(`categories.error.${tabStates[index]}`)}
              placement="top"
              arrow
            >
              <NonTransformingButton
                onClick={() => onTabChange(index)}
                sx={{ color: tabColors[tabStates[index]] }}
              >
                {category.id}
              </NonTransformingButton>
            </Tooltip>
            <IconButton onClick={() => { onTabChange(index); onMetaConfigChange(index); }}>
              <EditIcon />
            </IconButton>
          </Stack>
        ))}
      </Tabs>
    </Box>
  );
}
