import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import { formatPrice } from './ProductFormatPrice';

const styles = {
  cell: {
    width: '25%',
  },
  row: {
    '&:nth-of-type(odd)': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
};

function TaxTableRow({ taxShares, currency, classes }) {
  if (!taxShares) return null;

  return ((
    <TableRow className={classes.row}>
      <TableCell className={classes.cell}>{taxShares.rate}</TableCell>
      <TableCell className={classes.cell} align="right">
        {formatPrice(taxShares.net, currency)}
      </TableCell>
      <TableCell className={classes.cell} align="right">
        {formatPrice(taxShares.tax, currency)}
      </TableCell>
      <TableCell className={classes.cell} align="right">
        {formatPrice(taxShares.total, currency)}
      </TableCell>
    </TableRow>
  ));
}

export default withStyles(styles)(TaxTableRow);
