import { connect } from 'react-redux';
import { fetchClosings } from '../actions';
import ClosingList from './ClosingList';

const ClosingListContainer = connect(
  ({
    closings,
  }) => ({
    closings: closings.list,
    isLoading: closings.isLoading,
    totalPages: closings.pagination.totalPages || 1,
    totalItems: closings.pagination.totalItems || 0,
  }),
  dispatch => ({
    fetchClosings: (shopID, page) =>
      dispatch(fetchClosings(shopID, page)),
  }),
)(ClosingList);

export default ClosingListContainer;
