// NOTE Download from within JS only works if the function was called as a
// response to a user interaction (e.g. onclick)

const createImgElementWithData = async (url: string): Promise<HTMLImageElement> => {
  const img = document.createElement('img');
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

export function downloadJson(json: string, filename: string) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:application/json;charset=utf-8,${encodeURIComponent(json)}`,
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function downloadFile(data: string, filename: string, mimeType: string) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${mimeType};charset=utf-8,${encodeURIComponent(data)}`,
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function downloadCanvasAsPng(canvas: HTMLCanvasElement, filename: string) {
  const content = canvas.toDataURL('image/png');
  const element = document.createElement('a');
  element.setAttribute('href', content);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// mostly stolen from here https://stackoverflow.com/a/74026755
export async function downloadSvgAsPng(svg: HTMLElement, filename: string) {
  const xml = (new XMLSerializer()).serializeToString(svg);
  const svgXml = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(xml)}`;
  const imgElement = await createImgElementWithData(svgXml);

  const canvas = document.createElement('canvas');
  canvas.width = svg.clientWidth;
  canvas.height = svg.clientHeight;
  canvas.style.display = 'none';
  canvas?.getContext('2d')?.drawImage(imgElement, 0, 0, svg.clientWidth, svg.clientHeight);

  downloadCanvasAsPng(canvas, filename);
}
