import React, { ReactNode, forwardRef, useEffect } from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import CheckoutDevice from './CheckoutDevice';
import { CheckoutDeviceType } from './api/useCheckoutDeviceApi';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';
import { Shop } from '../shop/useShopApi';

const DEFAULT_VALUES: CheckoutDevice = {
  name: '',
  type: '',
  shop: '',
  externalID: '',
  description: '',
} as any;

export interface CheckoutDeviceFormProps extends CustomFormProps<CheckoutDevice> {
  additionalButtons?: (disable: boolean) => ReactNode;
  availableDeviceTypes: CheckoutDeviceType[];
  availableShops: Shop[];
  preSelectedShopId?: string;
  onCancel?: () => void;
}

const CheckoutDeviceForm = forwardRef<CustomFormRef, CheckoutDeviceFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  additionalButtons,
  availableShops,
  availableDeviceTypes,
  preSelectedShopId,
  onCancel,
}, ref) => {
  const {
    handleSubmit,
    control,
    formState,
    setValue,
  } = useEnhancedForm({ defaultValues, errors });

  useEffect(() => {
    if (preSelectedShopId) setValue('shop', preSelectedShopId);
  }, [preSelectedShopId, setValue]);

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="device">
        <SelectOptionsField
          control={control}
          name="shop"
          options={availableShops || []}
          valueFrom="id"
          labelFrom={shop => `${shop.name} (${shop.id})`}
          required
          rules={{ required: true }}
          autoFocus
        />

        <TextField
          control={control}
          name="name"
          required
          rules={{ required: true }}
        />

        <SelectOptionsField
          control={control}
          name="type"
          options={availableDeviceTypes || []}
          required
          rules={{ required: true }}
        />

        <TextField control={control} name="externalID" />

        <TextField control={control} name="description" multiline rows={5} />
      </Fieldset>

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
        additionalButtons={additionalButtons?.(formState.isDirty)}
        onCancel={onCancel}
      />
    </Form>
  );
});

export default CheckoutDeviceForm;
