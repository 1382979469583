import React from 'react';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import useResourceTranslator from '../resource/useResourceTranslator';
import { Shop } from './useShopApi';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import Map from '../googlePlacesApi/Map';

const DEFAULT_VALUES: Shop | {} = {};

export interface EditShopFormProps extends CustomFormProps<Shop | {}> {}

export default function ViewShopForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: EditShopFormProps) {
  const t = useResourceTranslator();
  const data = defaultValues as Shop;
  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PaperTable headline={t('shop.general')}>
          <PaperTableRow
            label={t('shop.name')}
            value={data.name}
          />
          <PaperTableRow
            label={t('shop.externalId')}
            value={data.externalId}
          />
          <PaperTableRow
            label={t('shop.id')}
            value={data.id}
          />
        </PaperTable>

        <PaperTable headline={t('shop.contact')}>
          <PaperTableRow
            label={t('shop.mail')}
            value={data.email}
          />
          <PaperTableRow
            label={t('shop.phone')}
            value={data.phone}
          />
        </PaperTable>

        <PaperTable headline={t('shop.address')}>
          <PaperTableRow
            label={t('shop.street')}
            value={data.street}
          />
          <PaperTableRow
            label={t('shop.zip')}
            value={data.zip}
          />
          <PaperTableRow
            label={t('shop.city')}
            value={data.city}
          />
          <PaperTableRow
            label={t('shop.state')}
            value={data.state}
          />
          <PaperTableRow
            value={data.countryCode}
            label={t('shop.countryCode')}
          />
          <PaperTableRow
            label={`${t('shop.country')} ${t('form.labels.optionalHint')}`}
            value={data.country}
          />
        </PaperTable>

        <PaperTable headline={t('shop.address')}>
          <PaperTableRow
            value={data.lat}
            label={t('shop.latitude')}
          />
          <PaperTableRow
            label={t('shop.longitude')}
            value={data.lon}
          />
          <tr>
            <td style={{ width: '100%', padding: '16px' }} colSpan={2}>
              <Map
                markers={[{ lat: data.lat, lng: data.lon }]}
                center={{ lat: data.lat, lng: data.lon }}
                zoomLevel={19}
              />
            </td>
          </tr>
        </PaperTable>
      </Form>
    </>
  );
}
