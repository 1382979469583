import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { SortableTable, ColumnsProp } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useResourceTranslator from '../resource/useResourceTranslator';
import useProjectNavigate from '../useProjectNavigate';
import { buildLabelFromArray } from '../utils/stringUtils';

function buildAddressLabel(rowData: any) {
  const {
    street, zip, city, country,
  } = rowData;
  const cityString = buildLabelFromArray({ input: [zip, city], separator: ' ' });
  return buildLabelFromArray({ input: [street, cityString, country], separator: ', ' });
}

export interface ShopListTableProps {
  values: any;
  writePermission: boolean;
}

export default function ShopListTable({
  values,
  writePermission = false,
}: ShopListTableProps) {
  const t = useResourceTranslator();
  const navigate = useProjectNavigate();

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'id',
      disablePadding: false,
      displayName: t('shop.id'),
    },
    {
      key: 'externalId',
      disablePadding: false,
      displayName: t('shop.externalId'),
    },
    {
      key: 'name',
      disablePadding: false,
      displayName: t('shop.name'),
    },
    {
      key: 'searchableAddress',
      disablePadding: false,
      displayName: t('shop.address'),
    },
    {
      type: 'padding',
      disablePadding: false,
    },
  ], [t]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => (
    <>
      <TableCell align="left" >
        {rowData.id}
      </TableCell>
      <TableCell align="left">
        {rowData.externalId}
      </TableCell>
      <TableCell>
        {rowData.name}
      </TableCell>
      <TableCell>
        {buildAddressLabel(rowData)}
      </TableCell>
      <TableCell padding="checkbox" align="right" sx={{ paddingRight: '8px' }}>
        <div style={{ display: 'flex', flexGrow: 1 }} >
          <IconButton size="small" onClick={() => navigate(`/shops/${rowData.id}`)}>
            <VisibilityIcon />
          </IconButton>
          {writePermission && (
            <IconButton size="small" onClick={() => navigate(`/shops/${rowData.id}/edit`)}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </TableCell>
    </>
  ), [navigate, writePermission]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        title=""
        filterPlaceholder={t('shop.shopList.filterPlaceholder')}
        labelRowsPerPage={t('shop.shopList.labelRowsPerPage')}
        CustomRowContent={TableRowEntries}
        verticalToolbarPadding={4}
      />)
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="shop.shopList.empty" /></Typography>
        </div>
      )
  );

  return (
    <Paper elevation={4}>
      {table}
    </Paper>
  );
}
