import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// AL: Nasty work around as our app is old-fashioned, and we need this information in our components
function RouteWrapper({ children }: { children: React.ReactElement<any> }) {
  const location = useLocation() || {};
  const navigate = useNavigate() || {};
  const { id, shop } = useParams();

  return React.cloneElement(children, {
    location, navigate, id, shop,
  });
}

export default RouteWrapper;
