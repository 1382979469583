import { connect } from 'react-redux';
import OrderExport from '../components/OrderExport';
import { fetchOrdersForExport } from '../actions';

const OrderExportContainer = connect(
  ({
    ordersExport, ordersExportError, fetchingOrdersExport, shops,
  }) => ({
    ordersExport,
    error: ordersExportError,
    fetchingOrdersExport,
    shops,
  }),
  dispatch => ({
    fetchOrdersForExport: (fileType, state, fromDate, toDate, timezone, shop, delimiter) =>
      dispatch(fetchOrdersForExport(fileType, state, fromDate, toDate, timezone, shop, delimiter)),
  }),
)(OrderExport);

export default OrderExportContainer;
