import React from 'react';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import MoneyOutlined from '@mui/icons-material/MoneyOutlined';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import { grey } from '@mui/material/colors';
import Translate from './i18n/Translate';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  box: {
    width: `calc(50% - ${theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    height: '100%',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      width: `calc(50% - ${theme.spacing(1)})`,
    },
    [theme.breakpoints.down(500)]: {
      width: '100%',
    },
  },
  headline: {
    width: '100%',
    color: grey[600],
  },
  icon: {
    backgroundColor: '#07b',
  },
});

const Box = withStyles(styles)(({
  classes, headline, value, icon,
}) => (
  <Paper className={classes.box}>
    <Typography variant="body2" component="h2" className={classes.headline}>{headline}</Typography>
    <div>
      <Typography variant="h5" component="p">{value}</Typography>
    </div>
    <Avatar className={classes.icon}>{icon}</Avatar>
  </Paper>
));

function OrdersOfTheDay({
  classes,
  ordersFinal,
  totalFinal,
  ordersTransferred,
  totalTransferred,
}) {
  return (
    <div className={classes.wrapper}>
      <Box
        headline={<Translate id="orderStatistic.todaysOrdersFinal" />}
        value={ordersFinal}
        icon={<ShoppingCartOutlined />}
      />

      <Box
        headline={<Translate id="orderStatistic.todaysOrdersTransferred" />}
        value={ordersTransferred}
        icon={<ShoppingCartOutlined />}
      />

      <Box
        headline={<Translate id="orderStatistic.todaysRevenueFinal" />}
        value={totalFinal}
        icon={<MoneyOutlined />}
      />

      <Box
        headline={<Translate id="orderStatistic.todaysRevenueTransferred" />}
        value={totalTransferred}
        icon={<MoneyOutlined />}
      />
    </div>
  );
}

export default withStyles(styles)(OrdersOfTheDay);
