import React from 'react';
import { CollapsibleAttributesPanel } from '../attributes';
import { useResourceTranslator } from '../resource';

export interface FiscalAttributesPanelProps {
  attributes: Record<string, any>;
}

export default function FiscalAttributesPanel({
  attributes,
}: FiscalAttributesPanelProps) {
  const t = useResourceTranslator();

  return (
    <CollapsibleAttributesPanel
      headline={t('fields.fiscalReference')}
      attributes={attributes}
    />
  );
}
