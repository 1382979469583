import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import FormTableRow from './FormTableRow';

const styles = theme => ({
  inputStyle: {
    maxWidth: '400px',
    marginLeft: theme.spacing(1),
    '& select': {
      padding: theme.spacing(1.5),
      paddingRight: 0,
      width: `calc(100% - ${theme.spacing(1.5)})`,
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
});

class FormTableRowSelect extends React.Component {
  shouldComponentUpdate(prevProps) {
    return (
      this.props.hasError !== prevProps.hasError ||
      this.props.property !== prevProps.property ||
      this.props.errorText !== prevProps.errorText ||
      this.props.required !== prevProps.required ||
      this.props.propertyName !== prevProps.propertyName ||
      this.props.text !== prevProps.text ||
      this.props.children !== prevProps.children
    );
  }
  render() {
    const {
      text, property, propertyName, handleChange, classes,
      children, required, errorText, hasError, errorDetails,
    } = this.props;

    return (
      <FormTableRow
        text={text}
        required={required}
        propertyName={propertyName}
        errorText={errorText}
        errorDetails={errorDetails}
        hasError={hasError}>
        <TextField
          select
          name={propertyName}
          value={property}
          onChange={handleChange}
          variant="outlined"
          className={classes.inputStyle}
          SelectProps={{
            native: true,
          }}>
          <option value="" hidden disabled />
          {children}
        </TextField>
      </FormTableRow>
    );
  }
}


export default withStyles(styles)(FormTableRowSelect);
