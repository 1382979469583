import React from 'react';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';

export interface TextFieldProps extends FieldProps<MUITextFieldProps> {
  optional?: boolean;
}

export default function TextField({
  control,
  name,
  rules,
  type,
  optional = false,
  ...props
}: TextFieldProps) {
  const label = useFormLabel(name, props.label, { removeIndex: true, optionalHint: optional });

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MUITextField
            type={type}
            label={label}
            variant="outlined"
            value={value || ''}
            onChange={onChange}
            error={!!error}
            helperText={error && <ValidationError error={error} />}
            fullWidth
            {...props}
          />
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
