import { connect } from 'react-redux';
import StatisticBasketAverageView from '../components/StatisticBasketAverageView';
import { fetchBasketAverageStatistic, resetBasketAverageStatistic } from '../actions';

const StatisticBasketAverageContainer = connect(
  ({
    basketAverageStatistic,
    fetchingBasketAverageStatistic,
    range,
    shopFilter,
    withCustomerCardFilter,
    paymentMethodsFilter,
  }) => ({
    showLoadingIndicator: fetchingBasketAverageStatistic,
    basketAverageStatistic,
    defaultRange: range,
    defaultShop: shopFilter,
    defaultWithCustomerCard: withCustomerCardFilter,
    defaultPaymentMethods: paymentMethodsFilter,
    paymentMethods: [],
  }),
  dispatch => ({
    fetchBasketAverageStatistic: (from, to, withCustomerCard, shopID, paymentMethods) =>
      dispatch(fetchBasketAverageStatistic(from, to, withCustomerCard, shopID, paymentMethods)),
    resetBasketAverageStatistic: () => dispatch(resetBasketAverageStatistic()),
  }),
)(StatisticBasketAverageView);

export default StatisticBasketAverageContainer;
