import React from 'react';
import { VerticalBarSeries } from 'react-vis';
import StatisticPlot from '../../components/StatisticPlot';

class StatisticMultipleBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleValueClick = this.handleValueClick.bind(this);
  }

  handleValueClick(clickedValue) {
    const parsedChartValue = {
      xLabel: clickedValue.xLabel,
      x: clickedValue.x,
      y: clickedValue.y - (clickedValue.y0 || 0),
    };

    if (this.props.onValueClick) {
      this.props.onValueClick(parsedChartValue);
    }
  }

  render() {
    const {
      values, maxValue, translationLabelYKey, translationComparedKey, translationHintKey,
      translationData, withRegression, stackY, translationLabelXKey,
      xLabelAsDate, colors,
    } = this.props;

    const barColors = colors || [
      '#ff0707',
      '#ffc107',
      '#17d214',
    ];

    return (
      <StatisticPlot
        values={values[0]}
        maxValue={maxValue}
        translationLabelYKey={translationLabelYKey}
        translationComparedKey={translationComparedKey}
        translationHintKey={translationHintKey}
        translationData={translationData}
        translationLabelXKey={translationLabelXKey}
        xLabelAsDate={xLabelAsDate}
        withRegression={withRegression}
        stackY={stackY}
      >
        {values.map((value, index) => (<VerticalBarSeries
          // eslint-disable-next-line react/no-array-index-key
          key={`VerticalBar + ${index}`}
          data={value}
          color={barColors[index]}
          onValueClick={this.handleValueClick}
          />))}
      </StatisticPlot>
    );
  }
}

export default StatisticMultipleBar;
