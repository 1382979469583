import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import { TaxRuleType, TaxRuleWithType } from './useTaxRulesApi';

interface CreateTaxRuleFormProps extends CustomFormProps {
  availableTaxRules: { value: TaxRuleType, label: string }[];
}

const DEFAULT_VALUES: TaxRuleWithType | {} = {};

export default function CreateTaxRuleForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  availableTaxRules,
}: CreateTaxRuleFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const ruleType = watch('type');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="permanentTaxRule">
        <SelectOptionsField
          control={control}
          name="type"
          options={availableTaxRules}
          required
          rules={{ required: true }}
          label={t('taxRules.label.type')}
        />
        <TextField
          control={control}
          name="value"
          required
          rules={{ required: true }}
          label={t(`taxRules.label.${ruleType}`)}
        />
        <TextField
          control={control}
          name="description"
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
