import React from 'react';
import TableCell from '@mui/material/TableCell';
import Translate from './i18n/Translate';
import ColoredTableRow from './ColoredTableRow';

function FulfillmentTableRow({
  fulfillment,
}) {
  if (!fulfillment) return null;

  return (
    <ColoredTableRow>
      <TableCell><Translate id={`fulfillments.${fulfillment.type}`} /></TableCell>
      <TableCell><Translate id={`fulfillments.${fulfillment.state}`} /></TableCell>
      <TableCell>{(fulfillment.items || []).map(item => (
        <div key={item.id}>{item.sku}</div>
      ))}
      </TableCell>
    </ColoredTableRow>
  );
}

export default FulfillmentTableRow;
