import React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import MenuItem from '@mui/material/MenuItem';
import SelectField, { SelectFieldProps } from './SelectField';
import { useResourceTranslator } from '../../resource';
import { ExtractFieldsOfType } from '../../types/ExtractFieldsOfType';
import { removeArrayIndexFromPath } from './useFormLabel';

export type SelectOptionValue = string | number;

export type SelectOption = SelectOptionValue | Record<string, any>;

type SelectOptionExtractor<
  T extends SelectOption = SelectOption,
  TReturnType = unknown,
> =
  ExtractFieldsOfType<T, TReturnType> |
  ((option: T) => TReturnType);

export type SelectOptionLabelExtractor<
  TOption extends SelectOption = SelectOption,
> = SelectOptionExtractor<TOption, string>;

export type SelectOptionValueExtractor<
  TOption extends SelectOption = SelectOption,
> = SelectOptionExtractor<TOption, SelectOptionValue>;

export interface SelectOptionsFieldProps<
  TOption extends SelectOption = SelectOption,
> extends SelectFieldProps {
  options: TOption[];
  preferredOptions?: TOption[];
  labelFrom?: SelectOptionLabelExtractor<TOption>;
  valueFrom?: SelectOptionValueExtractor<TOption>;
  dividerLabel?: string;
  includeEmpty?: boolean;
  emptyLabel?: string;
}

export default function SelectOptionsField<
  TOption extends SelectOption = SelectOption,
>({
  name,
  options,
  preferredOptions,
  labelFrom = 'label' as SelectOptionLabelExtractor<TOption>,
  valueFrom = 'value' as SelectOptionValueExtractor<TOption>,
  dividerLabel = '',
  includeEmpty,
  emptyLabel,
  ...props
}: SelectOptionsFieldProps<TOption>) {
  const t = useResourceTranslator();

  function extract<TReturnType>(
    extractor: SelectOptionExtractor<TOption, TReturnType>,
    option: TOption,
  ): TReturnType {
    if (isFunction(extractor)) {
      return extractor(option);
    }
    return get(option, extractor);
  }

  const getOptionValue = (option: TOption) => {
    if (isPlainObject(option)) {
      return extract(valueFrom, option);
    }
    return option as SelectOptionValue;
  };

  const getOptionLabel = (option: TOption) => {
    const label = extract(labelFrom, option);
    if (label) return label;

    const value = getOptionValue(option);
    return t(`form.options.${removeArrayIndexFromPath(name)}.${value}`);
  };

  return (
    <SelectField name={name} {...props}>
      {includeEmpty && (
        <MenuItem key="" value="">
          {emptyLabel ?? t(`form.options.${name}._empty`, {
            defaultValue: t('form.emptyOption'),
          })}
        </MenuItem>
      )}

      {preferredOptions && preferredOptions.map((option) => {
        const value = getOptionValue(option);
        return (
          <MenuItem key={value} value={value}>
            {getOptionLabel(option)}
          </MenuItem>
        );
      })}

      <ListSubheader disableSticky color="primary" >{dividerLabel}</ListSubheader>

      {options.map((option) => {
        const value = getOptionValue(option);
        return (
          <MenuItem key={value} value={value}>
            {getOptionLabel(option)}
          </MenuItem>
        );
      })}
    </SelectField>
  );
}
