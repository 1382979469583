import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
// @ts-ignore
import FilterWrapper from '../components/FilterWrapper';
import Translate from '../components/i18n/Translate';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import ListAdminsTable from './ListAdminsTable';
import useOrganizationAdminManagementApi from './useOrganizationAdminManagementApi';

export default function ListAdminsView() {
  const api = useOrganizationAdminManagementApi();
  const { organizationId } = useParams();

  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const handleFetch = useCallback(async () => {
    if (!organizationId) return [];
    const users = await api.getAdmins(organizationId);
    return users;
  }, [api, organizationId]);

  const demoteAdmin = useCallback(async (userID: string) => {
    if (!organizationId || !userID) return;
    await api.removeAdmin({ userID, organizationId });
    resourceChartViewRef.current?.updateData();
  }, [api, organizationId]);

  return (
    <>
      <DocumentTitle translationID="organization.adminManagement.headline" />

      <FilterWrapper
        headline={<Translate id="organization.adminManagement.headline" />}
        topBarChildren={<></>}
        handleRefresh={() => { resourceChartViewRef.current?.updateData(); }}
      />

      <ResourceChartView
        Chart={ListAdminsTable}
        ChartProps={{
          demoteAdmin,
        }}
        fetch={handleFetch}
        ref={resourceChartViewRef}
      />
    </>
  );
}
