import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useCheckoutDeviceApi, { CheckoutDeviceType } from './api/useCheckoutDeviceApi';
import CheckoutDeviceForm from './CheckoutDeviceForm';
import useProjectNavigate from '../useProjectNavigate';
import ResourceFormView from '../scaffold/ResourceFormView';
import DeleteResourceView from '../scaffold/DeleteResourceView';
import ResetSecretButton from './ResetSecretButton';
import { useResourceTranslator } from '../resource';
import TranslatedTooltip from '../components/i18n/TranslatedTooltip';
import useShopApi, { Shop } from '../shop/useShopApi';

export default function UpdateCheckoutDeviceView() {
  const api = useCheckoutDeviceApi();
  const shopApi = useShopApi();
  const navigate = useProjectNavigate();
  const { id } = useParams();
  const t = useResourceTranslator();

  const [isFetched, setIsFetched] = useState(false);
  const [deviceName, setDeviceName] = useState<string | undefined>(undefined);
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState<CheckoutDeviceType[]>([]);
  const [availableShops, setAvailableShops] = useState<Shop[]>([]);

  const handleFetch = useCallback(async () => {
    if (!id) return null;
    const apiData = await api.getCheckoutDevice({ id });
    setDeviceName(apiData.name);
    const availableTypes = await api.getAvailableDeviceTypes(null);
    setAvailableDeviceTypes(availableTypes);
    setAvailableShops(await shopApi.getShops(null));
    return apiData;
  }, [api, id, shopApi]);

  const headline = useMemo(() => {
    if (!deviceName) return undefined;
    return `${t('headlines.update')} (${deviceName})`;
  }, [deviceName, t]);

  const navigateToIndex = useCallback(() => {
    navigate('/checkout-devices');
  }, [navigate]);

  const additionalButtons = useCallback((disabled: boolean) => (
    (isFetched && id) ? (
      <>
        <DeleteResourceView
          remove={() => api.deleteCheckoutDevice({ id })}
          onRemoved={navigateToIndex}
        />
        {disabled ? (
          <TranslatedTooltip title="mustSubmitFirst">
            <div>
              <ResetSecretButton reset={() => api.resetCheckoutDeviceSecret({ id })} disabled />
            </div>
          </TranslatedTooltip>
        ) : (
          <ResetSecretButton reset={() => api.resetCheckoutDeviceSecret({ id })} />
        )}
      </>
    ) : null
  ), [api, id, isFetched, navigateToIndex]);

  if (!id) return null;

  return (
    <ResourceFormView
      actionName="update"
      headline={headline}
      Form={CheckoutDeviceForm}
      FormProps={{
        additionalButtons,
        availableDeviceTypes,
        availableShops,
      }}
      fetch={handleFetch}
      onFetched={() => { setIsFetched(true); }}
      submit={data => api.updateCheckoutDevice({ id, data })}
      onSubmitted={() => { }}
    />
  );
}
