import React from 'react';
import CodeBlock from './CodeBlock';

export interface JSONCodeBlockProps {
  data: any;
}

export default function JSONCodeBlock({ data }: JSONCodeBlockProps) {
  return (
    <CodeBlock>
      {JSON.stringify(data, null, 2)}
    </CodeBlock>
  );
}
