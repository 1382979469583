import React from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import { ChangePasswordProps } from './useUserManagementApi';

export interface ChangePasswordFormProps extends CustomFormProps<ChangePasswordProps | {}> {
  username: string;
}

export default function ChangePasswordForm({
  username,
  onSubmit,
}: ChangePasswordFormProps) {
  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({
    defaultValues: {
      username,
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
    } as ChangePasswordProps,
  });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset category="changePassword">
          <TextField
            control={control}
            name="password"
            required
            rules={{ required: true }}
            type="password"
          />
          <TextField
            control={control}
            name="newPassword"
            required
            rules={{
              required: true,
              validate: value =>
                value === watch('newPasswordConfirmation') || 'Passwords do not match',
            }}
            type="password"
          />
          <TextField
            control={control}
            name="newPasswordConfirmation"
            required
            rules={{
              required: true,
              validate: value =>
                value === watch('newPassword') || 'Passwords do not match',
            }}
            type="password"
          />
        </Fieldset>

        <DefaultFormActions
          formState={formState}
        />
      </Form>
    </>
  );
}
