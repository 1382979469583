import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { QRCodeCanvas } from 'qrcode.react';
import { useResourceTranslator } from '../../resource';

export interface QrCodeDownloadDialogProps {
  downloadLink: string;
  onClose?: () => void;
  open?: boolean;
}

export default function QRCodeDownloadDialog({
  downloadLink,
  open = false,
  onClose,
}: QrCodeDownloadDialogProps) {
  const t = useResourceTranslator();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>{t('releases.qrCodeDownloadTitle')}</DialogTitle>
      <DialogContent>
        <QRCodeCanvas
          value={downloadLink}
          size={160}
          bgColor="#ffffff"
          fgColor="#000000"
          level="L"
          includeMargin={false}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          autoFocus
        >
          {t('actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
