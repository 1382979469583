/* eslint react/jsx-filename-extension: "off" */
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider } from '@mui/material/styles';
import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-vis/dist/style.css';
import { acquiredToken, fetchProjects } from './actions';
import './index.css';
import portal from './reducers';
import Router from './router/Router';
import theme from './theme';
import { ToastProvider } from './toast';
import { getToken } from './TokenUtils';
import './translations/i18n';
import sentryReduxEnhancer from './sentry-redux';

// Leave Sentry on top so it is able to intercept all errors
import './sentry';

const store = configureStore({ reducer: portal }, sentryReduxEnhancer);

const token = getToken();
if (token.raw === '' && !window.location.href.match(/\/login/)) {
  window.location = `/login?backTo=${encodeURIComponent(window.location.href)}`;
} else {
  store.dispatch(acquiredToken(token));
  store.dispatch(fetchProjects());
  ReactDOM.render(
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <Router />
          </ToastProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>,
    document.getElementById('root'),
  );
}
