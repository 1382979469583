import React from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';

const styles = theme => ({
  error: {
    color: theme.palette.error[500],
  },
});

function StatisticError({ classes }) {
  return (
    <div className={classes.error}>
      <Typography>
        <Translate id="statistic.error" />
      </Typography>
    </div>
  );
}

export default withStyles(styles)(StatisticError);
