import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosInstance } from 'axios';
import useApiClientFactory from '../context/useApiClientFactory';
// @ts-ignore
import { prependApiHost } from '../../urls';
import { ApplicationState } from '../../reducers/initialState';
import { authorizationError } from '../../actions/request';
import logoutInterceptor from '../logoutInterceptor';

export interface UseApiClientOptions {
  basePath?: string;
  configure?: (client: AxiosInstance) => void;
}

export default function useApiClient(
  options: UseApiClientOptions = {},
  createAbortController: () => AbortController = () => new AbortController(),
) {
  const clientFactory = useApiClientFactory();

  const abortControllerRef = useRef(createAbortController());

  const authToken = useSelector<ApplicationState, string>(state => state.token.raw);
  const dispatch = useDispatch();

  const client = useMemo(
    () => {
      const c = clientFactory();
      c.defaults.baseURL = prependApiHost(options.basePath ?? '');
      c.defaults.signal = abortControllerRef.current.signal;

      if (authToken) {
        c.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        c.interceptors.response.use(
          undefined,
          logoutInterceptor(() => {
            dispatch(authorizationError());
          }),
          { synchronous: true },
        );
      }

      return c;
    },
    [authToken, clientFactory, dispatch, options.basePath],
  );

  // Must run only on unmount, not rerender as it then may unintentionally
  // cancel running requests.
  useEffect(() => () => {
    abortControllerRef.current.abort();
  }, []);

  return client;
}
