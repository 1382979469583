import React, { useState } from 'react';
import DeleteButton from '../button/DeleteButton';
import { useAlert } from '../toast';
import isUnhandledError from './isUnhandledError';

export interface DeleteResourceViewProps<TDeleteResult> {
  actionName?: string;
  // this is called remove because delete is a reserved term
  remove: () => TDeleteResult | Promise<TDeleteResult>;
  onRemoved?: () => void | Promise<void>;
  onRemoveFailed?: (error: Error) => void | Promise<void>;
}

export default function DeleteResourceView<TDeleteResult = unknown>({
  actionName = 'delete',
  remove,
  onRemoved,
  onRemoveFailed,
}: DeleteResourceViewProps<TDeleteResult>) {
  const [isDeleting, setIsDeleting] = useState(false);
  const alert = useAlert();

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await remove();
      setIsDeleting(false);
      await onRemoved?.();
      alert.success({ actionName });
    } catch (e) {
      if (!(e instanceof Error) || isUnhandledError(e)) return;
      setIsDeleting(false);
      await onRemoveFailed?.(e);
      alert.error({ message: e.message });
    }
  };

  return (
    <DeleteButton
      loading={isDeleting}
      onConfirm={handleDelete}
    />
  );
}
