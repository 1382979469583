import React from 'react';
import ConfirmButton, { ConfirmButtonProps } from './ConfirmButton';

export type DeleteButtonProps = Omit<
ConfirmButtonProps,
'variant' | 'color' | 'dialogTitle' | 'dialogBody'
>;

export default function DeleteButton({
  children,
  ...props
}: DeleteButtonProps) {
  return (
    <ConfirmButton
      {...props}
      actionName="delete"
      variant="contained"
      color="error"
    />
  );
}
