import React from 'react';
import Translate from '../components/i18n/Translate';
import PaperTableRow from '../components/PaperTableRow';
import PaperTable from '../components/PaperTable';
import ContentLink from '../components/ContentLink';


class ClosingViewOrders extends React.PureComponent {
  render() {
    const { closing } = this.props;
    if (!closing.orders || !closing.orders.length) {
      return null;
    }

    return (
      <PaperTable
        headline={<Translate id="closings.orders" data={{ count: closing.orders.length }} />}
        tableHeader={[
          <Translate id="closings.orderSequenceNumber" />,
          <Translate id="closings.orderID" />,
        ]}
      >
        {(closing.orders || []).map(order => (
          <PaperTableRow
            key={order.sequenceNumber}
            label={
              <ContentLink to={order.links.self.href}>
                {order.sequenceNumber}
              </ContentLink>}
            value={
              <ContentLink to={order.links.self.href}>
                {order.id}
              </ContentLink>
            } />
        ))}
      </PaperTable>
    );
  }
}

export default ClosingViewOrders;
