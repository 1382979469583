/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { CustomFormProps } from '../form';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import Translate from '../components/i18n/Translate';
import CheckoutDevicesStatusIndicator from './CheckoutDevicesStatusIndicator';
// @ts-ignore
import LocaleDate from '../components/LocaleDate';
import { Status } from './CheckoutDevice';
import { DeviceStatus, buildOSVersionString, buildTerminalTypesList, buildTerminalVersionsList, buildTimestampString, buildTransactionInfoString } from './CheckoutDeviceHelper';
import { buildShopLabel } from '../shop';
import useProjectNavigate from '../useProjectNavigate';
import TextWithSubText from '../components/TextWithSubText';
import UnorderedList from '../components/UnorderedList';

interface CheckoutDeviceQuickEditViewProps extends CustomFormProps<any | {}> { }

export default function CheckoutDeviceQuickEditView({
  defaultValues = {},
}: CheckoutDeviceQuickEditViewProps) {
  const navigate = useProjectNavigate();
  const checkoutDevice = defaultValues;

  return (
    <>
      <PaperTable headline={<Translate id="checkoutDevices.headlineSingle" />}>
        <PaperTableRow
          label={<Translate id="checkoutDevices.id" />}
          value={checkoutDevice.id}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.type" />}
          value={<Translate id={`checkoutDevices.types.${checkoutDevice.type}`} defaultValue={checkoutDevice.type} />}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.externalID" />}
          value={checkoutDevice.externalID || '-/-'}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.name" />}
          value={checkoutDevice.name}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.description" />}
          value={checkoutDevice.description || '-/-'}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.shop" />}
          value={
            <div
              onClick={() => { navigate(`/shops/${checkoutDevice.shop.id}`); }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {buildShopLabel(checkoutDevice.shop)}
            </div>
          }
        />
      </PaperTable>

      <PaperTable headline={<Translate id="checkoutDevices.statusIndicator" />}>
        <PaperTableRow
          label={<Translate id="checkoutDevices.statusIndicator" />}
          value={
            <CheckoutDevicesStatusIndicator
              status={(checkoutDevice.status === Status.UP || checkoutDevice.status === 'online')
                ? DeviceStatus.Online
                : DeviceStatus.Offline
              }
            />
          }
        />
        {checkoutDevice.uiStatus !== DeviceStatus.NA &&
          <PaperTableRow
            label={<Translate id="checkoutDevices.software" />}
            value={
              <CheckoutDevicesStatusIndicator status={checkoutDevice.uiStatus} />
            }
          />
        }
        {checkoutDevice.serviceStatus !== DeviceStatus.NA &&
          <PaperTableRow
            label={<Translate id="checkoutDevices.service" />}
            value={
              <CheckoutDevicesStatusIndicator status={checkoutDevice.serviceStatus} />
            }
          />
        }
        {checkoutDevice.posStatus !== DeviceStatus.NA &&
          <PaperTableRow
            label={<Translate id="checkoutDevices.pos" />}
            value={
              <CheckoutDevicesStatusIndicator status={checkoutDevice.posStatus} />
            }
          />
        }
        {checkoutDevice.terminalStatus !== DeviceStatus.NA &&
          <PaperTableRow
            label={<Translate id="checkoutDevices.terminal" />}
            value={
              <CheckoutDevicesStatusIndicator status={checkoutDevice.terminalStatus} />
            }
          />
        }
        {checkoutDevice.printerStatus !== DeviceStatus.NA &&
          <PaperTableRow
            label={<Translate id="checkoutDevices.printer" />}
            value={<CheckoutDevicesStatusIndicator status={checkoutDevice.printerStatus} />}
          />
        }
        <PaperTableRow
          label={<Translate id="checkoutDevices.errors" />}
          value={(
            <UnorderedList>
              {checkoutDevice.messages?.map((m: any) => (m.message))}
            </UnorderedList>
          )}
        />
        <PaperTableRow
          label={<Translate id="checkoutDevices.lastSeen" />}
          value={checkoutDevice?.lastSeenAt ? buildTimestampString(checkoutDevice?.lastSeenAt) : '-'}
        />
      </PaperTable>

      {checkoutDevice?.systemInfo?.transactionInfo && (
        <PaperTable headline={<Translate id="checkoutDevices.transactionInfo" />}>
          <PaperTableRow
            label={
              <TextWithSubText
                text={<Translate id="checkoutDevices.transactionDailyShort" />}
                subText={<Translate id="tooltip.transactionDaily" />}
              />
            }
            value={buildTransactionInfoString({
              successful: checkoutDevice.systemInfo
                .transactionInfo.dailySuccessfulTransactionCount,
              failed: checkoutDevice.systemInfo.transactionInfo.dailyFailedTransactionCount,
              overall: checkoutDevice.systemInfo.transactionInfo.dailyOverallTransactionCount,
            })}
          />
          <PaperTableRow
            label={
              <TextWithSubText
                text={<Translate id="checkoutDevices.transactionInfo" />}
                subText={<Translate id="tooltip.transactionDaily" />}
              />
            }
            value={buildTransactionInfoString({
              successful: checkoutDevice.systemInfo.transactionInfo.successfulTransactionCount,
              failed: checkoutDevice.systemInfo.transactionInfo.failedTransactionCount,
              overall: checkoutDevice.systemInfo.transactionInfo.overallTransactionCount,
            })}
          />
        </PaperTable>
      )}

      {checkoutDevice?.systemInfo && (
        <PaperTable headline={<Translate id="checkoutDevices.systemInfo" />}>
          {!!checkoutDevice.systemInfo.os &&
            <>
              <PaperTableRow
                label={<Translate id="checkoutDevices.osName" />}
                value={buildOSVersionString(checkoutDevice.systemInfo.os)}
              />
              <PaperTableRow
                label={<Translate id="checkoutDevices.osBuild" />}
                value={checkoutDevice.systemInfo.os.build}
              />
            </>
          }
          {checkoutDevice.systemInfo.host &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.bootTime" />}
              value={<LocaleDate date={checkoutDevice.systemInfo.host.bootTime} />}
            />
          }
          {checkoutDevice.systemInfo.process &&
            <>
              <PaperTableRow
                label={<Translate id="checkoutDevices.processVersion" />}
                value={checkoutDevice.systemInfo.process.version}
              />
              <PaperTableRow
                label={<Translate id="checkoutDevices.processStart" />}
                value={<LocaleDate date={checkoutDevice.systemInfo.process.startTime} />}
              />
            </>
          }
          {checkoutDevice.systemInfo.supportedTerminalTypes &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.supportedTerminalTypes" />}
              value={buildTerminalTypesList(checkoutDevice.systemInfo.supportedTerminalTypes)}
            />
          }
          {checkoutDevice.systemInfo.selectedTerminalType &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.selectedTerminalType" />}
              value={checkoutDevice.systemInfo.selectedTerminalType}
            />
          }
          {checkoutDevice.systemInfo.installedTerminalVersions &&
            <PaperTableRow
              label={<Translate id="checkoutDevices.installedTerminalVersions" />}
              value={buildTerminalVersionsList(checkoutDevice.systemInfo.installedTerminalVersions)}
            />
          }
        </PaperTable>
      )}
    </>
  );
}
