import { FieldValues } from 'react-hook-form';
import { defineApi, useProjectSpecificApiClient } from '../api';
import { Currency } from './Currencies';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { ShopService, ShopServices } from './shopServices';

export enum RoundingMode {
  UP = 'up',
  DOWN = 'down',
  COMMERCIAL = 'commercial',
}

export interface ProjectConfig {
  'id': string,
  'name': string,
  'displayName': string,
  'companyName': string,
  'companyAddress': {
    'street': string,
    'zip': string,
    'city': string,
    'country': string,
    'phone': string,
    'email': string,
  },
  'texts': Record<string, string>,
  'taxNumber': string;
  'taxID': string;
  'currency': Currency;
  'decimalDigits': number;
  'roundingMode': RoundingMode;
  'displayNetPrice': boolean;
}

export interface UpdateProjectConfigParams {
  projectConfig: FieldValues,
  etag: string,
}

export interface UpdateAppCustomizationConfigParams {
  data: FieldValues,
}

const useApi = defineApi({
  getProjectConfig: async (client) => {
    const { data, headers } = await client.get<ProjectConfig>('/retailer/project');
    return { projectConfig: data, responseEtag: headers.etag };
  },
  getAppCustomizationConfig: async (client) => {
    const { data, status } = await client.get(
      '/app-customization/config/content',
      {
        validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
      },
    );
    if (status === 404) return {};
    return data;
  },
  getAppCustomizationSchema: async client =>
    client.get('/app-customization/config/schema').then(resp => resp.data),
  getAppCustomizationUISchema: async client =>
    client.get('/app-customization/config/ui-schema').then(resp => resp.data),
  getAvailableShopServices: async client =>
    client.get<ShopServices>('/retailer/shop-services').then(resp => resp.data),
  createShopService: async (client, newShopService: ShopService) =>
    client.post('/retailer/shop-services', newShopService),
  updateProjectConfig: async (client, { projectConfig, etag }: UpdateProjectConfigParams) => {
    if (!etag) throw new Error('Missing etag');
    await client({
      url: '/retailer/project',
      method: 'PUT',
      data: projectConfig,
      headers: { 'If-Match': etag },
    });
  },
  updateAppCustomizationConfig: async (client, { data }: UpdateAppCustomizationConfigParams) =>
    client.put('/app-customization/config/content', data),
  updateShopService: async (client, shopService: ShopService) =>
    client.put(`/retailer/shop-services/${shopService.id}`, shopService),
  deleteShopService: async (client, id: ShopService['id']) =>
    client.delete(`/retailer/shop-services/${id}`),
});

export default function useProjectConfigApi() {
  const client = useProjectSpecificApiClient({ basePath: '' });
  return useApi(client);
}
