import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { StyledComponentProps } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
// @ts-ignore
import { ReactComponent as Logo } from '../images/icon.svg';
import { ApplicationState } from '../reducers/initialState';
import SearchableProjectNavigator from './SearchableProjectNavigator';

const styles = (theme: any) => ({
  container: {
    backgroundColor: '#07b',
    borderBottom: '4px solid #ffc400',
    paddingTop: 23,
    paddingBottom: 23,
    '&:hover': {
      backgroundColor: '#07b',
    },
  },
  projectName: {
    marginLeft: theme.spacing(2),
    paddingLeft: '32px',
  },
});

interface SidebarProjectSelectorProps extends StyledComponentProps {
  isMini?: boolean;
}

const SidebarProjectSelector = ({
  isMini = false,
  classes,
}: SidebarProjectSelectorProps) => {
  const { projectId } = useParams();
  const projects = useSelector<ApplicationState, any>(state => state.projects);
  const project = projects.find((pr: any) => (pr.id === projectId)) || { name: 'Snabble' };

  const handleNavigateToProjectDialog = useCallback(() => {
    const url = `${document.location.origin}/`;
    document.location.assign(url);
  }, []);

  if (isMini) {
    return (
      <ListItem className={classes?.container}>
        <IconButton
          onClick={handleNavigateToProjectDialog}
          style={{ padding: 0 }}
        >
          <Logo />
        </IconButton>
      </ListItem>
    );
  }

  return (
    <ListItem className={classes?.container}>
      <Logo
        style={{
          position: 'absolute',
          left: '24px',
        }}
      />
      {projects.length <= 1 ? (
        <Typography variant="button" color="secondary" className={classes?.projectName}>
          {project.name}
        </Typography>
      ) : (
        <SearchableProjectNavigator />
      )
      }
    </ListItem>
  );
};

export default withStyles(styles)(SidebarProjectSelector);
