import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  net: {
    marginTop: '-7px',
    marginRight: 0,
    [theme.breakpoints.up(700)]: {
      marginRight: theme.spacing(2),
    },
    '& span': {
      fontSize: '12px',
    },
  },
});

const StatisticSwitch = ({
  classes, label, onChange, checked,
}) => (
  <FormControlLabel
    className={classes.net}
    control={<Switch value="net" color="primary" size="small" onChange={onChange} />}
    label={label}
    checked={checked}
  />
);

export default withStyles(styles)(StatisticSwitch);
