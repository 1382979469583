import React from 'react';
import Translate from './i18n/Translate';
import { prependApiHost } from '../urls';
import DownloadButtonContainer from '../containers/DownloadButtonContainer';

class StatisticDownloadButton extends React.Component {
  constructor(props) {
    super(props);

    this.fetchStatistic = this.fetchStatistic.bind(this);
  }

  fetchStatistic(token, project) {
    const { range } = this.props.params;

    const group = this.props.group || 'orders';

    const params = {
      ...this.props.params,
      from: range?.from,
      to: range?.to,
    };

    const queryString = this.props.queryStringBuilder(params);
    const url = prependApiHost(`/${project}/${group}/statistics/${this.props.type}${queryString}`);

    return fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-store, no-cache',
      },
    });
  }

  render() {
    return (
      <DownloadButtonContainer
        disabled={this.props.disabled}
        fetch={this.fetchStatistic}
        filename={`snabble-statistics-${this.props.type}.csv`}
      >
        <Translate id="statistic.download" />
      </DownloadButtonContainer>
    );
  }
}

export default StatisticDownloadButton;
