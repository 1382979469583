import React from 'react';
import Moment from 'moment';
import debounce from 'lodash/debounce';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { Translation } from 'react-i18next';
import Translate from './i18n/Translate';
import DocumentTitle from './DocumentTitle';
import FormTableRowSelect from './FormTableRowSelect';
import { isValidStatsTimeRange } from '../ranges';
import Headline from './Headline';
import PaperTable from './PaperTable';
import FormTableRowDateRange from './FormTableRowDateRange';
import OrderExportTableRow from './OrderExportTableRow';
import { delimiterOptions, dateFormat } from '../exportOptions';

const styles = theme => ({
  button: {
    float: 'right',
    marginBottom: theme.spacing(2),
  },
});

class LineItemExport extends React.Component {
  constructor(props) {
    super(props);

    const today = Moment().format(dateFormat);
    const guessedTimezone = Moment.tz.guess();

    this.state = {
      fromDate: today,
      toDate: today,
      currentShopID: 'all',
      disableButton: false,
      delimiter: ';',
      guessedTimezone,
      timezone: guessedTimezone,
    };

    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.handleShopChange = this.handleShopChange.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleDelimiterChange = this.handleDelimiterChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);

    this.fetchOrders = debounce(
      (fromDate, toDate, timezone, currentShopID, delimiter) => {
        this.props.fetchOrdersForLineItemExport(
          {
            label: 'CSV',
            contentType: 'text/vnd.snabble.lineitems+csv',
            mimeType: 'text/csv',
            fileEnding: 'csv',
          },
          fromDate,
          toDate,
          timezone,
          currentShopID,
          delimiter,
        );
      },
      500,
    );
  }

  handleFromDateChange(value) {
    this.setState({
      fromDate: value,
      disableButton: !isValidStatsTimeRange({ from: value, to: this.state.toDate }),
    });
  }

  handleToDateChange(value) {
    this.setState({
      toDate: value,
      disableButton: !isValidStatsTimeRange({ from: this.state.fromDate, to: value }),
    });
  }

  handleShopChange(event) {
    this.setState({ currentShopID: event.target.value });
  }

  handleDelimiterChange(event) {
    this.setState({ delimiter: event.target.value });
  }

  handleTimezoneChange(event) {
    this.setState({ timezone: event.target.value });
  }

  handleFetch() {
    const {
      fromDate, toDate, timezone, currentShopID, delimiter,
    } = this.state;
    this.fetchOrders(fromDate, toDate, timezone, currentShopID, delimiter);
  }

  render() {
    const {
      classes, shops,
    } = this.props;
    const {
      currentShopID, fromDate, toDate, timezone, guessedTimezone, disableButton,
    } = this.state;

    const additionalTzOption = (translate) => {
      if (guessedTimezone !== 'Europe/Berlin' && guessedTimezone !== 'UTC') {
        return (
          <option value={guessedTimezone} key={guessedTimezone}>
            {translate('orders.additionalTimezone', guessedTimezone)}
          </option>
        );
      }
      return null;
    };

    return (
      <Translation>
        { translate => (
          <React.Fragment>
            <DocumentTitle translationID="orders.lineItemExport" />
            <Headline>{translate('orders.lineItemExport')}</Headline>

            <PaperTable headline={translate('orders.export')}>
              <FormTableRowDateRange
                text={translate('orders.range')}
                from={fromDate}
                to={toDate}
                fromCallback={this.handleFromDateChange}
                toCallback={this.handleToDateChange}
              />

              <FormTableRowSelect
                property={timezone}
                propertyName="timezone"
                text={translate('orders.timezone')}
                handleChange={this.handleTimezoneChange}
              >
                {additionalTzOption(translate)}
                <option value="Europe/Berlin" key="EuropeBerlin">
                  {translate('orders.berlinTimezone')}
                </option>
                <option value="UTC" key="UTC">
                  {translate('orders.utcTimezone')}
                </option>
              </FormTableRowSelect>

              <FormTableRowSelect property={currentShopID} text={translate('orders.shop')} handleChange={this.handleShopChange} propertyName="shopID">
                <option value="all" key="all">{translate('orders.defaultShop')}</option>
                {(shops || [])
                  .map(value => (
                    <option value={value.id} key={value.id}>{value.label}</option>
                  ))}
              </FormTableRowSelect>

              <FormTableRowSelect text={translate('orders.delimiter')} property={this.state.delimiter} handleChange={this.handleDelimiterChange} propertyName="delimiter">
                {delimiterOptions
                    .map(value => (<option value={value} key={value}>{value}</option>))}
              </FormTableRowSelect>
            </PaperTable>

            <Button variant="contained" color="primary" disabled={disableButton || this.props.fetchingLineItemExport} onClick={this.handleFetch} className={classes.button}>{translate('orders.requestExport')}</Button>
            {!!this.props.lineItemExport.length &&
              <PaperTable
                headline={translate('orders.exportFiles')}
                headerAlignment={['left', 'left', 'left']}
                tableHeader={[
                  <Translate id="orders.range" />,
                  '',
                  <Translate id="orders.shop" />,
                  ' ',
                  '   ',
                ]}
              >
                {(this.props.lineItemExport).map(e => (
                  <OrderExportTableRow item={e} key={e.name} />
                ))}
              </PaperTable>
            }
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(LineItemExport);
