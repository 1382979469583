import React, { useCallback, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { SortableTable, ColumnsProp } from '../SortableTable';
// @ts-ignore
import Translate from '../components/i18n/Translate';
// @ts-ignore
import useResourceTranslator from '../resource/useResourceTranslator';
// @ts-ignore
import StatisticPaper from '../components/StatisticPaper';
import { TaxRuleType } from './useTaxRulesApi';

export interface BlockListTableProps {
  values: any;
  onEntryRemove: (user: any) => void;
  onEntryAdd: () => void;
  onEntryEdit: (value: string) => void;
  idType: TaxRuleType;
}

export default function ListTaxRulesTable({
  values,
  onEntryRemove,
  onEntryAdd,
  onEntryEdit,
  idType,
}: BlockListTableProps) {
  const t = useResourceTranslator();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState<string | undefined>();

  const removeEntry = useCallback((row: any) => {
    setEntryToDelete(row);
    setDialogOpen(true);
  }, []);

  const confirmRemoveEntry = useCallback(() => {
    setDialogOpen(false);
    if (!entryToDelete) return;
    onEntryRemove(entryToDelete);
    setEntryToDelete(undefined);
  }, [entryToDelete, onEntryRemove]);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'value',
      disablePadding: false,
      displayName: t(`taxRules.value.${idType}`),
    },
    {
      key: 'description',
      disablePadding: false,
      displayName: t('taxRules.description'),
    },
  ], [idType, t]);

  const table = (
    (values?.length > 0) ?
      (<SortableTable
        data={values || []}
        columnsMetadata={headCells}
        title=""
        filterPlaceholder={t('taxRules.filterPlaceholder')}
        labelRowsPerPage={t('taxRules.labelRowsPerPage')}
        verticalToolbarPadding={4}
        rowButtons={[
          { icon: <EditIcon />, tooltip: 'editEntry', callback: (row) => { onEntryEdit(row.value); } },
          { icon: <DeleteIcon />, tooltip: 'removeEntry', callback: (row) => { removeEntry(row.value); } },
        ]}
      />)
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="taxRules.empty" /></Typography>
        </div>
      )
  );

  return (
    <>
      <Dialog open={dialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('taxRules.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('taxRules.confirmationBody', { product: entryToDelete })}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmRemoveEntry} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <StatisticPaper
        headline={
          <Translate id="taxRules.permanent.headline" />
            }
        total={values?.length}
        additionalHeaderItems={
          <Button variant="outlined" endIcon={<AddIcon />} onClick={onEntryAdd}>
            {t('taxRules.addRule')}
          </Button>
      }
      >
        {table}
      </StatisticPaper>
    </>
  );
}
