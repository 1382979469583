import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@mui/system/styled';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import { TemplateProject } from '../organizations/useOrganizationApi';
import { Project } from '../projects/useProjectApi';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const DEFAULT_VALUES: TemplateProject | {} = {};

export interface CreateProjectFormProps extends CustomFormProps<TemplateProject | {}> {
  templateProjectIds: string[];
  projects: Project[];
}

export default function CreateProjectForm({
  defaultValues = DEFAULT_VALUES,
  templateProjectIds,
  projects,
  errors = {},
  onSubmit,
}: CreateProjectFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
  } = useEnhancedForm({ defaultValues, errors });

  const templateProjectId = watch('templateProjectId');
  const name = watch('name');
  useEffect(() => {
    // automatically generate the idPrefix from the name of the project
    const idPrefix = (name || '')
      .replaceAll(' ', '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .toLowerCase()
      .substring(0, 25);
    setValue('idPrefix', idPrefix);
  }, [name, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="templateProject">
        {templateProjectIds?.length > 0 ? (
          <Wrapper>
            <SelectOptionsField
              control={control}
              name="templateProjectId"
              label={t('form.labels.project')}
              options={templateProjectIds || []}
              labelFrom={(id: string) => (
                projects.find(p => p.id === id)?.name || id
              )}
              required
              rules={{
                required: true,
              }}
              disabled={!(templateProjectIds?.length > 1)}
            />
            <div>
              {t('organization.project.helperText.templateProject')}
            </div>
          </Wrapper>
        ) : (
          t('organization.project.noTemplateProjects')
        )}

        {!!templateProjectId && (
          <>
            <TextField
              control={control}
              name="idPrefix"
              disabled
            />
            <TextField
              control={control}
              name="name"
            />
          </>
        )}
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
