import React, { useMemo } from 'react';
import isArray from 'lodash/isArray';
import { useSelector } from 'react-redux';
import { matchSorter } from 'match-sorter';
import Translate from './i18n/Translate';
import { ApplicationState } from '../reducers/initialState';
import SearchableSelect from './SearchableSelect';
import { Shop } from '../shop/useShopApi';

const sorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
    { threshold: matchSorter.rankings.ACRONYM, key: 'externalId' },
  ],
};

interface ShopFilterProps {
  onChange: (shop: Shop | Shop[] | null) => void;
  defaultShopId?: string | string[] | null;
  showLabel?: boolean;
  multiple?: boolean;
}

export default function SearchableShopSelect({
  onChange,
  defaultShopId, // TODO allow for shopID arrays (do this if SSelect supports other keys)
  showLabel = true,
  multiple = false,
}: ShopFilterProps) {
  const shops = useSelector<ApplicationState, Shop[]>(state => state.shops);

  const defaultShop = useMemo(() => {
    if (!defaultShopId) return undefined;

    if (isArray(defaultShopId)) {
      return shops?.filter(s => (defaultShopId.includes(s.id)));
    }
    return shops?.find(s => (s.id === defaultShopId));
  }, [defaultShopId, shops]);

  const label = showLabel ? <Translate id="filter.byShop" /> : undefined;

  return (
    <SearchableSelect
      onChange={(_, selectedShop) => { onChange(selectedShop); }}
      options={shops}
      sorterOptions={sorterOptions}
      label={label}
      defaultValue={defaultShop}
      multiple={multiple}
      labelFrom="name"
    />
  );
}
