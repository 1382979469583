import { connect } from 'react-redux';
import ProductList from '../components/ProductList';
import { fetchProducts } from '../actions';

const ProductListContainer = connect(
  ({
    projectCurrency, products, fetchingProducts, codeTemplates, searchTerm, fetchingProductsError,
  }) => ({
    projectCurrency,
    products,
    fetchingProducts,
    codeTemplates,
    searchTerm,
    fetchingProductsError,
  }),
  dispatch => ({
    fetchProducts: (limit, term, abortController) =>
      dispatch(fetchProducts(limit, term, abortController)),
  }),
)(ProductList);

export default ProductListContainer;
