import { connect } from 'react-redux';
import StatisticRecurringVisitorsView from '../components/StatisticRecurringVisitorsView';
import { fetchRecurringVisitorsStatistic, resetRecurringVisitorsStatistic } from '../actions';

const StatisticRecurringVisitorsContainer = connect(
  ({
    recurringVisitorsStatistic, fetchingRecurringVisitorsStatistic, range, shopFilter,
  }) => ({
    showLoadingIndicator: fetchingRecurringVisitorsStatistic,
    recurringVisitorsStatistic,
    defaultRange: range,
    defaultShop: shopFilter,
  }),
  dispatch => ({
    fetchRecurringVisitorsStatistic: (from, to, shopID) =>
      dispatch(fetchRecurringVisitorsStatistic(from, to, shopID)),
    resetRecurringVisitorsStatistic: () => dispatch(resetRecurringVisitorsStatistic()),
  }),
)(StatisticRecurringVisitorsView);

export default StatisticRecurringVisitorsContainer;
