import React from 'react';
import { AreaSeries } from 'react-vis';
import StatisticPlot from './StatisticPlot';

class StatisticStackedArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      compareHint: null,
      showHint: false,
      totalHint: null,
    };
    this.setHint = this.setHint.bind(this);
  }
  setHint(value) {
    let compareValue = null;
    if (this.props.compareValues) {
      compareValue = this.props.compareValues[value.x - 1];
    }

    if (this.props.withTotal && compareValue !== null) {
      this.setState({ compareHint: compareValue, totalHint: compareValue.totalLabel, hint: value });
    } else {
      this.setState({ compareHint: compareValue, hint: value });
    }
  }
  toggleHint(isVisible) {
    this.setState({ showHint: isVisible });
  }
  render() {
    const {
      values, color, maxValue, translationLabelYKey, translationComparedKey, translationHintKey,
      translationData, compareValues, compareColor, withRegression, xLabelAsDate,
    } = this.props;
    const areaColor = color || '#07b';
    const areaCompareColor = compareColor || '#ffc400';

    return (
      <StatisticPlot
        values={values}
        maxValue={maxValue}
        translationLabelYKey={translationLabelYKey}
        translationComparedKey={translationComparedKey}
        translationHintKey={translationHintKey}
        translationData={translationData}
        xLabelAsDate={xLabelAsDate}
        hint={this.state.hint}
        compareHint={this.state.compareHint}
        showHint={this.state.showHint}
        withRegression={withRegression}
        totalHint={this.state.totalHint}
      >
        { /* compared area stacks the other values that means it is always
        as high as the other one or higher, so it has to appear first to be behind
        the other area */ }
        {!!compareValues &&
          <AreaSeries
            data={compareValues}
            color={areaCompareColor}
            onSeriesMouseOver={() => this.toggleHint(true)}
            onSeriesMouseOut={() => this.toggleHint(false)}
          />
        }
        <AreaSeries
          data={values}
          color={areaColor}
          onNearestX={this.setHint}
          onSeriesMouseOver={() => this.toggleHint(true)}
          onSeriesMouseOut={() => this.toggleHint(false)}
        />
      </StatisticPlot>
    );
  }
}

export default StatisticStackedArea;
