import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SidebarMenu from './SidebarMenu';

export const drawerWidth = 250;
export const miniDrawerWidth = 56; // 16px spacing + 24px icon + 16px spacing

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create(
    'width',
    {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    },
  ),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: miniDrawerWidth,
  transition: theme.transitions.create(
    'width',
    {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    },
  ),
  overflowX: 'hidden',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ToggleButton = styled(IconButton)(() => ({
  borderRadius: '50%',
  height: '24px',
  width: '24px',

  backgroundColor: '#ffc4007f',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#ffc400ff',
  },

  position: 'fixed',
  zIndex: 1000,
  transform: 'translate(-50%, -50%)',
  top: '24px',
  left: 0,
}));

export default function Sidebar() {
  // NOTE  an animation is triggered between this state and the state loaded
  // inside the useEffect. Transitioning from the smaller to the larger drawer
  // looks better
  const [miniSidebarTarget, setMiniSidebarTarget] = useState(true);
  const [miniSidebarCurrent, setMiniSidebarCurrent] = useState(true);

  const toggleMiniSidebar = useCallback(() => {
    setMiniSidebarTarget(!miniSidebarTarget);
    localStorage.setItem('preferMiniSidebar', (!miniSidebarTarget).toString());
  }, [miniSidebarTarget]);

  // handle screen size changes
  const theme = useTheme();
  const screenIsSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  // NOTE this effect is run on the initial load and when the screen size changes
  // and automatically shows the mini sidebar on small screens
  useEffect(() => {
    // load user preference from local storage
    // do not use mini drawer by default since it is less verbose
    const miniUserPreference = localStorage.getItem('preferMiniSidebar') === 'true' ?? false;
    // always show the mini sidebar on small screens
    setMiniSidebarTarget(miniUserPreference || screenIsSmallerThanMd);
  }, [screenIsSmallerThanMd]);
  //

  // dynamically position the toggle button
  const toggleButtonAnchor = useRef<HTMLElement | null>(null);
  const [toggleButtonPosition, setToggleButtonPosition] = useState({ top: 0, left: 0 });

  const updateToggleButtonPosition = useCallback(() => {
    // always pin the toggle button to the lower right corner of the sidebar
    // header (project quick switch)
    const toggleButtonAnchorPosition = toggleButtonAnchor.current?.getBoundingClientRect();
    setToggleButtonPosition({
      top: toggleButtonAnchorPosition?.bottom ?? 24,
      left: toggleButtonAnchorPosition?.right ?? 0,
    });
  }, []);

  const handleTransitionEnd = useCallback(({ propertyName }) => {
    if (propertyName !== 'width') return;
    setMiniSidebarCurrent(miniSidebarTarget);
    updateToggleButtonPosition();
  }, [miniSidebarTarget, updateToggleButtonPosition]);

  useEffect(() => { updateToggleButtonPosition(); }, [updateToggleButtonPosition]);

  const { organizationId } = useParams();

  return (
    <Box>
      {(miniSidebarTarget === miniSidebarCurrent) && (
        <ToggleButton
          onClick={toggleMiniSidebar}
          sx={toggleButtonPosition}
          aria-label="toggle sidebar"
        >
          {miniSidebarTarget ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </ToggleButton>
      )}

      <Drawer
        variant="permanent"
        open={!miniSidebarTarget}
        onTransitionEnd={handleTransitionEnd}
        PaperProps={{ sx: { zIndex: 900 } }}
        data-testid="sidebar"
      >
        <Box ref={toggleButtonAnchor}>
          <div
            style={{
              height: '64px',
              backgroundColor: '#07b',
              borderBottom: '4px solid #ffc400',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            {organizationId}
          </div>
        </Box>
        <SidebarMenu isMini={miniSidebarTarget} />
      </Drawer>
    </Box>
  );
}
