import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useResourceTranslator } from '../../resource';

const BASE_FIELD_NAME = 'base';

export interface ValidationErrorProps {
  error: FieldError;
}

export default function ValidationError({ error }: ValidationErrorProps) {
  const t = useResourceTranslator();

  const messages = useMemo(() => {
    const fieldName = error.ref?.name || BASE_FIELD_NAME;

    const translateError = (type: string) => t(
      `form.errors.fields.${fieldName}.${type}`,
      {
        defaultValue: t(
          `form.errors.types.${type}`,
          { defaultValue: error.message || undefined },
        ),
      },
    );

    if (error.types) {
      return Object
        .keys(error.types)
        .map(errorType => translateError(errorType));
    }

    return [translateError(error.type)];
  }, [t, error]);

  if (!messages) return null;

  return (<>{messages.join(', ')}</>);
}
