import React from 'react';
import TableCell from '@mui/material/TableCell';
import Translate from './i18n/Translate';
import ColoredTableRow from './ColoredTableRow';
import PaperTableRow from './PaperTableRow';
import SupervisingSucceededCheckIndicator from './SupervisingSucceededCheckIndicator';
import SupervisingFailedCheckIndicator from './SupervisingFailedCheckIndicator';


function SupervisingCheckRow({
  check,
}) {
  if (!check) return null;
  switch (check.type) {
    case 'min_age':
      return (
        <PaperTableRow
          label={<Translate id="supervising.minAge" />}
          value={
            <React.Fragment>
              <SupervisingFailedCheckIndicator check={check} />
              <SupervisingSucceededCheckIndicator check={check} />
            </React.Fragment>
          }
        />
      );

    case 'verify_debit_card':
      return (
        <PaperTableRow
          label={<Translate id="supervising.verifyDebitCard" />}
          value={
            <React.Fragment>
              <SupervisingFailedCheckIndicator check={check} />
              <SupervisingSucceededCheckIndicator check={check} />
            </React.Fragment>
          }
        />
      );

    case 'supervisor_approval':
      if (check.method === 'partialRescan' || check.method === 'rescan') {
        return (
          <React.Fragment>
            <ColoredTableRow>
              <TableCell><Translate id="supervising.rescan" /></TableCell>
              <TableCell>
                <SupervisingFailedCheckIndicator check={check} />
                <SupervisingSucceededCheckIndicator check={check} />
              </TableCell>
            </ColoredTableRow>
          </React.Fragment>
        );
      }
      return null;

    default:
      return null;
  }
}

export default SupervisingCheckRow;
