import { AnyAction } from '@reduxjs/toolkit';
import actions from '../actions/constants';

export default (state: unknown, action: AnyAction) => {
  switch (action.type) {
    case actions.ACQUIRED_TOKEN:
      if (!action?.payload?.raw) {
        return { authorized: false };
      }
      return { token: action.payload, authorized: true };

    case actions.AUTHORIZATION_ERROR:
      return { authorized: false };
    default:
      return {};
  }
};
