import React, { ReactNode } from 'react';
import { styled, Theme } from '@mui/system';

export interface FormActionsProps {
  children: ReactNode;
  allowReverse?: boolean;
}

interface ContainerProps {
  allowReverse: boolean;
  theme?: Theme;
}

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'allowReverse',
})(({ theme, allowReverse }: ContainerProps) => {
  if (!theme) return null;

  return {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: allowReverse ? 'row-reverse' : 'row',
    },
  };
});

export default function FormActions({
  allowReverse = false,
  children,
}: FormActionsProps) {
  return (
    <Container allowReverse={allowReverse}>
      {children}
    </Container>
  );
}
