import React from 'react';
import Translate from './i18n/Translate';
import PaperTable from './PaperTable';
import ProductAttributePrice from './ProductAttributePrice';


function ProductDetailsPrices({
  product, projectCurrency, canSeePricings,
}) {
  if (!product) return null;

  return (
    <PaperTable
      headline={<Translate id="product.pricingInformation" />}
      tableHeader={[
        <Translate id="product.pricingCategory" />,
        <Translate id="product.listPrice" />,
        <Translate id="product.discountedPrice" />,
        <Translate id="product.customerCardPrice" />,
        <Translate id="product.basePrice" />,
      ]}>
      {(product.prices || []).map(price => (
        <ProductAttributePrice
          key={price.category}
          price={price}
          currency={projectCurrency}
          project={product.project}
          referenceUnit={product.referenceUnit}
          canSeePricings={canSeePricings}
        />
      ))}
    </PaperTable>
  );
}

export default (ProductDetailsPrices);
