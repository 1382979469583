interface BuildLabelFromArrayProps {
  input: Array<string | undefined>;
  separator?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function buildLabelFromArray({
  input,
  separator = ', ',
}: BuildLabelFromArrayProps): string {
  return input?.filter(Boolean).join(separator) || '';
}
