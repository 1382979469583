import React, { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useOrganizationAdminManagementApi from './useOrganizationAdminManagementApi';
import CreateAdminForm from './CreateAdminForm';
import { User } from '../userManagement/useUserManagementApi';
import useOrganizationNavigate from '../organizations/useOrganizationNavigate';

export default function CreateAdminView() {
  const api = useOrganizationAdminManagementApi();
  const navigate = useOrganizationNavigate();
  const { organizationId } = useParams();

  const handleCreateUser = useCallback(async (user: FieldValues) => {
    if (!organizationId) return;
    await api.createAdmin({ user: user as User, organizationId });
  }, [api, organizationId]);

  return (
    <>
      <DocumentTitle translationID="organization.adminManagement.headline" />
      <ResourceFormView
        actionName="createOrganizationAdmin"
        Form={CreateAdminForm}
        fetch={() => ({})}
        submit={handleCreateUser}
        onSubmitted={() => {
          navigate('/organization-admins');
        }}
      />
    </>
  );
}
