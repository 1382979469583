import React from 'react';
import TableCell from '@mui/material/TableCell';
import ColoredTableRow from './ColoredTableRow';

function LineItemTotalRow({
  label, value, ...props
}) {
  return (
    <ColoredTableRow isColored={false}>
      <TableCell colSpan={6} {...props} />
      <TableCell><strong>{label}</strong></TableCell>
      <TableCell>{value}</TableCell>
      <TableCell />
    </ColoredTableRow>
  );
}

export default LineItemTotalRow;
