import React, { ComponentType, ElementType, MouseEventHandler, ReactNode, useMemo } from 'react';
import withStyles from '@mui/styles/withStyles';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Link from '@mui/material/Link';
import { Link as RouterLink, useParams } from 'react-router-dom';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import { useResourceTranslator } from '../resource';

const styles = (theme: any) => ({
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fontSize: '20px',
    marginRight: '5px',
  },
  linkWithIcon: {
    color: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
});

export interface ProjectContentLinkProps {
  children?: ReactNode;
  classes?: any;
  component?: ElementType<any>;
  edit?: boolean;
  Icon?: ComponentType<{ className?: string }>;
  onClick?: MouseEventHandler;
  title?: string;
  to?: string;
  view?: boolean;
}

function ProjectContentLink({
  children,
  classes,
  component = RouterLink,
  edit,
  Icon,
  onClick,
  title,
  to,
  view,
}: ProjectContentLinkProps) {
  const t = useResourceTranslator();
  const { projectId } = useParams();

  const IconComponent = useMemo(() => {
    if (Icon) return Icon;
    // TODO: Remove the old view and edit variants
    if (view) return RemoveRedEyeOutlined;
    if (edit) return EditOutlined;
    return undefined;
  }, [Icon, view, edit]);

  const className = IconComponent ? classes.linkWithIcon : classes.link;

  const linkTitle = useMemo(() => {
    if (title) return title;
    if (view) return t('actions.view');
    if (edit) return t('actions.edit');
    return undefined;
  }, [view, edit, t, title]);

  const projectTo = useMemo(() => `/${projectId}${to}`, [projectId, to]);

  return (
    <Link
      component={component}
      to={projectTo}
      href={to}
      className={className}
      title={linkTitle}
      onClick={onClick}
    >
      {IconComponent && <IconComponent className={classes.icon} />}{children}
    </Link>
  );
}

// TODO: Migrate to styled API
export default withStyles(styles)(ProjectContentLink);
