import { connect } from 'react-redux';
import {
  fetchClosingSchedule, createClosingSchedule,
  updateClosingSchedule, editClosingScheduleStatusUpdate,
  deleteClosingSchedule,
} from '../actions';
import ClosingScheduleEdit from './ClosingScheduleEdit';

const ClosingScheduleEditContainer = connect(
  ({ closings, shops, selectedProjectId }) => ({
    schedule: closings.schedule,
    isLoading: closings.isLoading,
    count: closings.schedules.length,
    editStatus: closings.editStatus,
    error: closings.serverError,
    projectId: selectedProjectId,
    shops,
  }),
  dispatch => ({
    updateStatus: status => dispatch(editClosingScheduleStatusUpdate(status)),
    fetchClosingSchedule: id =>
      dispatch(fetchClosingSchedule(id)),
    createClosingSchedule: schedule => dispatch(createClosingSchedule(schedule)),
    updateClosingSchedule: (link, schedule) => dispatch(updateClosingSchedule(link, schedule)),
    deleteClosingSchedule: link => dispatch(deleteClosingSchedule(link)),
  }),
)(ClosingScheduleEdit);

export default ClosingScheduleEditContainer;
