import React from 'react';
import Translate from './i18n/Translate';
import ContentLink from './ContentLink';
import PaperTable from './PaperTable';
import PaperTableRow from './PaperTableRow';


function ProductDetailsGeneral({
  product,
}) {
  if (!product) return null;

  if (!product.depositProduct && !product.bundledProduct) {
    return null;
  }

  const depositProductLink = `/${product.project}/products/${product.depositProduct}`;
  const bundledProductLink = `/${product.project}/products/${product.bundledProduct}`;

  return (
    <PaperTable headline={<Translate id="product.linkedProducts" />}>
      {!!product.depositProduct &&
        <PaperTableRow
          label={<Translate id="product.depositProduct" />}
          value={<ContentLink to={depositProductLink}>{product.depositProduct}</ContentLink>}
        />
      }
      {!!product.bundledProduct &&
        <PaperTableRow
          label={<Translate id="product.bundledProduct" />}
          value={<ContentLink to={bundledProductLink}>{product.bundledProduct}</ContentLink>}
        />
      }
    </PaperTable>
  );
}

export default ProductDetailsGeneral;
