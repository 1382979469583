import React from 'react';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { formatPrice } from '../components/ProductFormatPrice';
import LocaleDate from '../components/LocaleDate';
import ColoredTableRow from '../components/ColoredTableRow';
import ContentLink from '../components/ContentLink';
import SimpleStateIndicator from '../components/SimpleStateIndicator';

const styles = theme => ({
  large: {
    minWidth: theme.spacing(15),
    paddingRight: theme.spacing(1),
  },
});

class ClosingListItem extends React.PureComponent {
  render() {
    const { closing, classes } = this.props;

    if (!closing) {
      return null;
    }

    return (
      <ColoredTableRow>
        <TableCell align="left">
          <SimpleStateIndicator useGreen={closing.state === 'finalized'} />
        </TableCell>
        <TableCell align="left" style={{ paddingRight: '10px' }}>
          <ContentLink to={closing.links.self.href}>
            {closing.sequenceNumber}
          </ContentLink>
        </TableCell>
        <TableCell align="left" className={classes.large}>
          <ContentLink to={`/${closing.project}/shops/${closing.shopID}`}>
            {closing.shop.label}
          </ContentLink>
        </TableCell>
        <TableCell align="left">
          <LocaleDate date={closing.date} relative />
        </TableCell>
        <TableCell align="center">
          {closing.state === 'finalized' ? closing.orders.length : '…'}
        </TableCell>
        <TableCell align="right">
          {formatPrice(closing.totalPrice, closing.currency)}
        </TableCell>
        <TableCell align="right">
          <ContentLink to={closing.links.self.href} view />
        </TableCell>
      </ColoredTableRow>
    );
  }
}

export default withStyles(styles)(ClosingListItem);
