import React from 'react';
import Translate from './i18n/Translate';
import PaperTableRow from './PaperTableRow';
import PaperTable from './PaperTable';

function OrderViewDevice({ order }) {
  if (!order) return null;

  if (order.checkoutDeviceID && order.appUserID) {
    return (
      <PaperTable headline={<Translate id="orders.device" />}>
        <PaperTableRow label={<Translate id="orders.type" />} value={<React.Fragment><Translate id="orders.typeApp" /> + <Translate id="orders.typeSco" /></React.Fragment>} />
        <PaperTableRow label={<Translate id="orders.scoExternal" />} value={!!order.checkoutDevice && order.checkoutDevice.externalID} />
        <PaperTableRow label={<Translate id="orders.scoID" />} value={order.checkoutDeviceID} />
        <PaperTableRow label={<Translate id="orders.sco" />} value={!!order.checkoutDevice && order.checkoutDevice.name} />
        <PaperTableRow label={<Translate id="orders.appUserID" />} value={order.appUserID} />
        <PaperTableRow label={<Translate id="orders.clientID" />} value={order.clientID} />
        <PaperTableRow label={<Translate id="orders.sessionID" />} value={order.session} />
      </PaperTable>
    );
  }

  if (order.checkoutDeviceID) {
    return (
      <PaperTable headline={<Translate id="orders.device" />}>
        {order.checkoutDevice && order.checkoutDevice.type === 'sco' && <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typeSco" />} />}
        {order.checkoutDevice && order.checkoutDevice.type === 'pos' && <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typePos" />} />}
        <PaperTableRow label={<Translate id="orders.scoExternal" />} value={!!order.checkoutDevice && order.checkoutDevice.externalID} />
        <PaperTableRow label={<Translate id="orders.scoID" />} value={order.checkoutDeviceID} />
        <PaperTableRow label={<Translate id="orders.sco" />} value={!!order.checkoutDevice && order.checkoutDevice.name} />
        {order.cashier?.subject && <PaperTableRow label={<Translate id="orders.cashier" />} value={order.cashier?.subject} />}
      </PaperTable>
    );
  }

  return (
    <PaperTable headline={<Translate id="orders.device" />}>
      <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typeApp" />} />
      <PaperTableRow label={<Translate id="orders.appUserID" />} value={order.appUserID} />
      <PaperTableRow label={<Translate id="orders.clientID" />} value={order.clientID} />
      <PaperTableRow label={<Translate id="orders.sessionID" />} value={order.session} />
    </PaperTable>
  );
}

export default OrderViewDevice;
