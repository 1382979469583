import React, { ReactNode, useCallback } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { matchSorter, MatchSorterOptions } from 'match-sorter';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

interface SearchableChipSelectProps<T> extends Record<string, any> {
  options: T[];
  onAdd: (value: T) => void | Promise<void>;
  onRemove: (value: T) => void | Promise<void>;
  sorterOptions: MatchSorterOptions;
  label?: string | undefined;
  labelFrom?: keyof T | ((option: T | undefined) => ReactNode);
  valueFrom?: keyof T;
  textFieldProps?: TextFieldProps;
  optional?: boolean;
  value: T[]
}

export default function SearchableChipSelect<T extends Record<string, any>>({
  options,
  onAdd,
  onRemove,
  sorterOptions,
  labelFrom = 'title',
  valueFrom = 'id',
  textFieldProps,
  optional,
  value,
  ...props
}: SearchableChipSelectProps<T>) {
  const filterOptions = (opts: T[], { inputValue }: { inputValue: string }) =>
    matchSorter(opts, inputValue, sorterOptions) as T[];

  const getLabel = (option: T | undefined) => (isFunction(labelFrom) ? labelFrom(option) : (option?.[labelFrom] as string || ''));

  const handleChange = useCallback(async (_event, _value, reason, details) => {
    switch (reason) {
      case 'selectOption':
        await onAdd(details?.option?.[valueFrom]);
        break;
      case 'removeOption':
        await onRemove(details?.option?.[valueFrom]);
        break;
      case 'clear':
        value?.forEach(async (v) => {
          await onRemove(v[valueFrom]);
        });
        break;
      default:
        break;
    }
  }, [onAdd, onRemove, value, valueFrom]);

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={(option) => {
        if (isObject(option)) {
          // the Automcomplete component does not officially support ReactNodes as option labels
          return getLabel(option) as any;
        }
        return getLabel(options.find(o => o[valueFrom] === option)) as any;
      }}
      renderOption={(rProps, option) => (
        <li {...rProps} key={option[valueFrom] as string}>
          {getLabel(option)}
        </li>
      )}
      renderInput={params => <TextField {...params} variant="outlined" label={props.label} />}
      isOptionEqualToValue={(option, oValue) => option?.[valueFrom] === oValue}
      onChange={handleChange}
      multiple
      filterSelectedOptions
      label={props.label}
      value={value}
      {...props}
    />
  );
}
