import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import DocumentTitle from '../components/DocumentTitle';
import Translate from '../components/i18n/Translate';

const Content = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(8),
}));

const HeadLine = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const NotFound = () => (
  <Content>
    <DocumentTitle translationID="notFound.headline" />
    <HeadLine variant="h4">
      <Translate id="notFound.headline" />
    </HeadLine>
    <Typography><Translate id="notFound.text" /></Typography>
  </Content>
);

export default NotFound;
