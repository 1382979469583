import actions from '../actions/constants';
import { prepareOpening } from './helper';
import shopLabel from '../shop';

function mapShop(shop) {
  if (!shop) return null;
  const mapped = shop;
  mapped.label = shopLabel(shop);
  mapped.opening = prepareOpening(shop.openingHoursSpecification);
  return mapped;
}

function mapShops(shops) {
  if (!shops || !shops.length) return [];
  const mapped = shops;
  mapped.forEach(shop => mapShop(shop));
  return mapped;
}

export default (state, action) => {
  switch (action.type) {
    case actions.REQUEST_INITIAL_SHOPS:
      return { fetchingInitialShops: true, fetchingShops: true };

    case actions.REQUEST_SHOPS:
      return { fetchingShops: true };

    case actions.RECEIVED_SHOPS:
      return Object.assign({}, state, {
        shops: mapShops(action.payload),
        fetchingShops: false,
        fetchingInitialShops: false,
      });

    case actions.REQUEST_SHOP:
      return { fetchingShop: true, shop: {} };

    case actions.RECEIVED_SHOP:
      return {
        shop: mapShop(action.payload),
        fetchingShop: false,
        shopError: null,
      };

    case actions.EDIT_SHOP_STATUS_UPDATE:
      return { shopEditStatus: action.payload.newStatus, shopError: action.payload.error };

    default:
      return {};
  }
};
