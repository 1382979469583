import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// @ts-ignore
import { whichEnvironment } from './urls';

const DSN = 'https://b7dfb22897c642269b4f2c310f92be00@o391311.ingest.sentry.io/4504038271549440';

function isHostingEnvironment(location: Location) {
  if (!location) return false;

  return location.host.startsWith('retailer');
}

function initSentry() {
  if (!isHostingEnvironment(document.location)) return;

  Sentry.init({
    dsn: DSN,
    release: process.env.RELEASE,
    environment: whichEnvironment(document.location),
    debug: process.env.NODE_ENV === 'development',

    integrations: [new BrowserTracing()],
    normalizeDepth: 7,

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
    // monitoring. We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });
}

initSentry();
