import axios from 'axios';

export type GithubAsset = {
  id: number
  name: string
  browser_download_url: string
  size: number
  content_type: string
};

export type GithubRelease = {
  id: number
  name: string
  tag_name: string
  body: string
  published_at: string
  assets: GithubAsset[]
};

const API_TIMEOUT = 10000;

export const fetchGithubReleases = async (repository: string): Promise<GithubRelease[]> => {
  const { data } = await axios.get(
    `https://api.github.com/repos/snabble/${repository}/releases`,
    { timeout: API_TIMEOUT },
  );
  return data;
};

export const fetchGithubRelease = async (
  repository: string,
  id: string,
): Promise<GithubRelease> => {
  const { data } = await axios.get(
    `https://api.github.com/repos/snabble/${repository}/releases/${id}`,
    { timeout: API_TIMEOUT },
  );
  return data;
};
