import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { SelectOptionsField, TextField } from '../form/input';
import useResourceTranslator from '../resource/useResourceTranslator';
import { Shop } from './useShopApi';
import COUNTRY_CODES, { CountryCode, PREFERRED_COUNTRY_CODES } from '../googlePlacesApi/Countries';
import AutocompleteAddressField from '../form/input/AddressField';
import { AddressField } from '../googlePlacesApi/useAutocompleteApi';
import MapField from '../form/input/MapField';
import AnchoredDialog from '../components/AnchoredDialog';
import useAlert from '../toast/useAlert';

const DEFAULT_VALUES: Shop | {} = {};

export interface EditShopFormProps extends CustomFormProps<Shop | {}> {
  onRequestCoordinates?: (address: string) => Promise<
  { lat: number | undefined, lng: number | undefined }
  >;
  hideShopId?: boolean;
}

export default function CreateShopForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  onRequestCoordinates,
  hideShopId = false,
}: EditShopFormProps) {
  const t = useResourceTranslator();
  const alert = useAlert();

  const [showOutdatedCoordinatesHint, setShowOutdatedCoordinatesHint] = useState(false);
  const enableOutdatedCoordinatesHint = useCallback(() => {
    if (!showOutdatedCoordinatesHint) {
      alert.warning({
        message: t('shop.outdatedCoordinatesToast'),
        autoHideDuration: null,
        onClose: () => {},
      });
    }
    setShowOutdatedCoordinatesHint(true);
  }, [alert, showOutdatedCoordinatesHint, t]);
  const disableOutdatedCoordinatesHint = useCallback(() => {
    setShowOutdatedCoordinatesHint(false);
    alert.hide();
  }, [alert]);

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
  } = useEnhancedForm({ defaultValues, errors });

  const [, i18n] = useTranslation();
  const language = useMemo(() => i18n.resolvedLanguage || 'de', [i18n.resolvedLanguage]);

  const selectedCountryCode = watch('countryCode');
  // HACK: prevent country to be overwritten on initial load
  const [countryCodeInited, setCountryCodeInited] = useState(false);
  useEffect(() => {
    if (!countryCodeInited) {
      setCountryCodeInited(true);
      return;
    }
    const countryCode: CountryCode = selectedCountryCode;
    setValue('country', COUNTRY_CODES[countryCode]?.[language === 'en' ? 'englishName' : 'germanName'] || '');
  // NOTE disabled because the language overwrite text should not change if the user changed the
  // language. Only if a new language code is selected should the new language entry be set in
  // the users selected language.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode, setValue]);

  const selectedStreet = watch('street');
  const selectedZip = watch('zip');
  const selectedCity = watch('city');
  const selectedState = watch('state');
  const autofillCoordinates = useCallback(async () => {
    if (onRequestCoordinates) {
      const address = `${selectedStreet} ${selectedZip} ${selectedCity} ${selectedState}`;
      const coordinates = await onRequestCoordinates(address);
      if (coordinates) {
        const { lat, lng } = coordinates;
        setValue('lat', lat, { shouldDirty: true });
        setValue('lon', lng, { shouldDirty: true });
      }
    }
    disableOutdatedCoordinatesHint();
  }, [
    disableOutdatedCoordinatesHint,
    onRequestCoordinates,
    selectedCity,
    selectedState,
    selectedStreet,
    selectedZip,
    setValue,
  ]);

  const currentLat = watch('lat');
  const currentLon = watch('lon');
  const autofillEmptyCoordinates = useCallback(() => {
    if (!currentLat && !currentLon) {
      autofillCoordinates();
    } else {
      enableOutdatedCoordinatesHint();
    }
  }, [autofillCoordinates, currentLat, currentLon, enableOutdatedCoordinatesHint]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          control={control}
          name="name"
          label={t('shop.name')}
          required
          rules={{ required: true }}
        />
        <TextField
          control={control}
          name="externalId"
          label={t('shop.externalId')}
          required
          rules={{ required: true }}
        />
        {!hideShopId && (
        <TextField
          control={control}
          name="id"
          label={t('shop.id')}
          disabled
        />
        )}

        <Fieldset category="contact" legend={t('shop.contact')}>
          <TextField
            control={control}
            name="email"
            label={t('shop.mail')}
            rules={{
              pattern: /^(\S+)@(\S+)\.(\S+)$/,
            }}
          />
          <TextField
            control={control}
            name="phone"
            label={t('shop.phone')}
          />
        </Fieldset>

        <Fieldset category="address" legend={t('shop.address')}>
          <AutocompleteAddressField
            control={control}
            name="street"
            label={t('shop.street')}
            type={AddressField.STREET}
            addressFieldsToAutoUpdate={{
              [AddressField.ZIP]: 'zip',
              [AddressField.CITY]: 'city',
              [AddressField.STATE]: 'state',
              country_code: 'countryCode',
            }}
            setValue={setValue}
            onAutocompleted={autofillEmptyCoordinates}
            onManualChange={autofillEmptyCoordinates}
          />
          <AutocompleteAddressField
            control={control}
            name="zip"
            label={t('shop.zip')}
            type={AddressField.ZIP}
            addressFieldsToAutoUpdate={{
              [AddressField.CITY]: 'city',
              [AddressField.STATE]: 'state',
              country_code: 'countryCode',
            }}
            setValue={setValue}
            onAutocompleted={autofillEmptyCoordinates}
            onManualChange={autofillEmptyCoordinates}
          />
          <AutocompleteAddressField
            control={control}
            name="city"
            label={t('shop.city')}
            type={AddressField.CITY}
            addressFieldsToAutoUpdate={{
              [AddressField.STATE]: 'state',
              country_code: 'countryCode',
            }}
            setValue={setValue}
            onAutocompleted={autofillEmptyCoordinates}
            onManualChange={autofillEmptyCoordinates}
          />
          <AutocompleteAddressField
            control={control}
            name="state"
            label={t('shop.state')}
            type={AddressField.STATE}
            addressFieldsToAutoUpdate={{
              country_code: 'countryCode',
            }}
            setValue={setValue}
            onAutocompleted={autofillEmptyCoordinates}
            onManualChange={autofillEmptyCoordinates}
          />
          <SelectOptionsField
            control={control}
            name="countryCode"
            options={Object.keys(COUNTRY_CODES)}
            preferredOptions={PREFERRED_COUNTRY_CODES}
            labelFrom={(code: string) => (COUNTRY_CODES as Record<string, any>)[code]?.[language === 'en' ? 'englishName' : 'germanName']}
            label={t('shop.countryCode')}
            dividerLabel={t('countrySelect.otherCountries')}
            includeEmpty
          />
          <TextField
            control={control}
            name="country"
            label={`${t('shop.country')} ${t('form.labels.optionalHint')}`}
            helperText={t('shop.helperText.country')}
          />
        </Fieldset>

        <Fieldset category="coordinates" legend={t('shop.coordinates')}>
          <TextField
            control={control}
            name="lat"
            label={t('shop.latitude')}
            helperText={t('shop.helperText.latitude')}
            type="number"
            rules={{
              min: -90,
              max: 90,
            }}
          />
          <TextField
            control={control}
            name="lon"
            label={t('shop.longitude')}
            helperText={t('shop.helperText.longitude')}
            type="number"
            rules={{
              min: -180,
              max: 180,
            }}
          />
          <Button
            variant="contained"
            onClick={autofillCoordinates}
          >
            {t('shop.autofillCoordinates')}
          </Button>
          <AnchoredDialog
            Dialog={(
              <>
                <DialogContent>
                  <DialogContentText>
                    {t('shop.outdatedCoordinatesHint')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={autofillCoordinates}>
                    {t('button.yes')}
                  </Button>
                  <Button onClick={disableOutdatedCoordinatesHint}>
                    {t('button.no')}
                  </Button>
                </DialogActions>
              </>
              )}
            dialogOpen={showOutdatedCoordinatesHint}
          >
            <MapField
              control={control}
              name="map"
              latName="lat"
              lngName="lon"
              rules={{
                min: -180,
                max: 180,
              }}
            />
          </AnchoredDialog>
        </Fieldset>

        <DefaultFormActions
          formState={formState}
        />
      </Form>
    </>
  );
}

