import moment from 'moment';
import toLower from 'lodash/toLower';
import forIn from 'lodash/forIn';
import { formatPrice, formatDigits } from '../components/ProductFormatPrice';
import shopLabel from '../shop';

function sortByDate(items, order) {
  if (!items.length) return [];

  if (order === 'asc') {
    items.sort((a, b) => {
      if (a.date > b.date) {
        return 1;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 0;
    });
  } else {
    items.sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });
  }

  return items;
}

function mapFulfillmentItems(fulfillment, lineItems) {
  const mapped = [];
  (fulfillment.refersTo || []).forEach((refering) => {
    const item = lineItems.find(i => i.id === refering);
    if (item) {
      mapped.push(item);
    }
  });
  return mapped;
}

function mapFulfillments(order) {
  if (!order || !order.fulfillments) return [];
  const fulfillments = [...order.fulfillments];
  const { lineItems } = order;

  fulfillments.forEach((fulfillment, fulfillmentIndex) => {
    fulfillments[fulfillmentIndex].items = mapFulfillmentItems(fulfillment, lineItems);
  });
  return fulfillments;
}

function isTerminalAborted(order) {
  if (!order) return false;

  // Aborted through the app
  if (order.state === 'userAborted') return true;

  // Aborted through the terminal
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'terminalAbort'
  ) return true;

  // Aborted through softpos
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'userAborted'
  ) return true;
  return false;
}

function ageVerificationError(order) {
  if (!order || order.state !== 'paymentFailed') return false;
  return (
    order.paymentResult?.failureCause === 'ageVerificationNotSupportedByCard' ||
    order.paymentResult?.failureCause === 'ageVerificationFailed'
  );
}

function translationKeyOrderState(order) {
  if (!order) return '';
  if (isTerminalAborted(order)) {
    return 'orders.state.userAborted';
  }
  if (ageVerificationError(order)) {
    return `orders.state.${order.paymentResult.failureCause}`;
  }
  return `orders.state.${order.state}`;
}


function filterCartDiscounts(items) {
  if (!items || !items.length) return { items: [], cartDiscountInfo: [] };

  // find and extract any manual cart discounts
  const manualCartDiscountIDs = items
    .filter(item => (item.type === 'manualDiscount' && !item.refersTo && item.discountID))
    .map(item => item.discountID);
  // find portions for manual cart discounts
  const manualCartDiscountPortions = items
    .filter(item => item.type === 'discount' && manualCartDiscountIDs.includes(item.discountID));

  // construct cartDiscountInfo
  const cartDiscountInfo = [];

  // collect all manualCartDiscountItems
  manualCartDiscountIDs.forEach((manualCartDiscountID) => {
    if (!manualCartDiscountID) return;

    const portions = manualCartDiscountPortions
      .filter(item => item.discountID === manualCartDiscountID);
    const trigger = items
      .find(item => item.discountID === manualCartDiscountID && item.type === 'manualDiscount');
    const sum = portions
      .reduce((accumulator, portion) => accumulator + portion.totalPrice, 0);

    const mappedDiscount = {
      id: manualCartDiscountID,
      trigger,
      name: trigger.name,
      code: trigger.discountReasonCode,
      message: trigger.discountReasonMessage,
      discountPercentage: trigger.discountPercentage ?? undefined,
      discountValue: trigger.price ?? 0,
      portions,
      sum,
    };
    cartDiscountInfo.push(mappedDiscount);
  });


  const withoutCartManualDiscounts = items
    // remove all discount items that refer to a manual cart discount or are of type manualDiscount
    .filter(item => !manualCartDiscountIDs.includes(item.discountID) && item.type !== 'manualDiscount');

  return { items: withoutCartManualDiscounts, cartDiscountInfo };
}


function sortLineItems(items) {
  const referringItems = {};
  const filteredItems = [];
  let sum = 0;
  (items || []).forEach((item) => {
    if (item.refersTo) {
      if (referringItems[item.refersTo]) {
        referringItems[item.refersTo].push(item);
      } else {
        referringItems[item.refersTo] = [item];
      }
    } else {
      sum += item.amount;
      filteredItems.push(item);
    }
  });
  return { items: filteredItems, referringItems, sum };
}

function mapOrder(order) {
  if (!order) return {};
  const { items: filteredLineItems, cartDiscountInfo } = filterCartDiscounts(order.lineItems);
  const { items, referringItems, sum } = sortLineItems(filteredLineItems);

  const fulfillments = mapFulfillments(order);

  let hasError = false;
  if (order.state === 'final' && fulfillments && fulfillments.length) {
    hasError = !!fulfillments.find((fulfillment => fulfillment.state !== 'processed'));
  }

  return Object.assign({}, order, {
    itemCount: sum,
    items,
    referringItems,
    cartDiscountInfo,
    shortID: order.id ? order.id.substring(0, 8) : order.id,
    shortAppUserID: order.appUserID ? order.appUserID.substring(0, 8) : order.appUserID,
    shortCheckoutDeviceID:
      order.checkoutDeviceID ? order.checkoutDeviceID.substring(0, 8) : order.checkoutDeviceID,
    fulfillments,
    shop: Object.assign({}, order.shop, { label: shopLabel(order.shop) || '' }),
    stateTranslation: translationKeyOrderState(order),
    isTerminalAborted: isTerminalAborted(order),
    hasError,
  });
}

function mapOrders(orders) {
  if (!orders || !orders.resources || !orders.resources.length) return { list: [], pagination: {} };
  const sorted = sortByDate(orders.resources);
  const mapped = [];

  (sorted || []).forEach((o) => {
    const order = mapOrder(o);

    mapped.push(order);
  });
  return { list: mapped, pagination: orders.pagination };
}

function prepareOrderStatisticFor(statistic, availablePaymentMethods, currency) {
  const orderStatistic = {
    orders: [],
    total: [],
    totalNet: [],
    // set minimum to 10, to avoid negative value
    maxOrders: 10,
    maxTotal: 10,
    count: statistic.count,
    totalSum: formatPrice(statistic.total, currency),
    totalNetSum: formatPrice(statistic.net, currency),
    currency,
    availablePaymentMethods: availablePaymentMethods || [],
  };

  const items = sortByDate(statistic.items, 'asc');

  items.forEach((order, index) => {
    const earnings = order.total ? formatDigits(order.total, currency) : 0;
    const { count } = order;
    const x = index + 1;
    orderStatistic.orders.push({
      x,
      xLabel: order.date,
      y: count,
      label: count,
    });
    orderStatistic.total.push({
      x,
      xLabel: order.date,
      y: earnings,
      label: order.total ? formatPrice(order.total, currency) : formatPrice(0, currency),
    });
    orderStatistic.totalNet.push({
      x,
      xLabel: order.date,
      y: order.net ? formatDigits(order.net, currency) : 0,
      label: order.net ? formatPrice(order.net, currency) : formatPrice(0, currency),
    });
    if (orderStatistic.maxTotal < earnings) {
      orderStatistic.maxTotal = earnings;
    }
    if (orderStatistic.maxOrders < count) {
      orderStatistic.maxOrders = count;
    }
  });
  return orderStatistic;
}

function prepareOrderStatistic(statistic, currency) {
  if (!statistic || !statistic.final || !statistic.transferred) return null;

  return {
    final: prepareOrderStatisticFor(
      statistic.final,
      statistic.availablePaymentMethods,
      currency,
    ),
    transferred: prepareOrderStatisticFor(
      statistic.transferred,
      statistic.availablePaymentMethods,
      currency,
    ),

    // filter values from orders#receivedOrderStatistic
    date: statistic.date,
    shopID: statistic.shopID,
    withCustomerCard: statistic.withCustomerCard,
    paymentMethods: statistic.paymentMethods,

    availablePaymentMethods: statistic.availablePaymentMethods || [],
  };
}

function basketAverstatisticsFor(statistic, availablePaymentMethods, currency) {
  const basketAverageStatistic = {
    total: [],
    totalNet: [],
    count: statistic.count,
    // set minimum to 10, to avoid negative value
    maxTotal: 10,
    totalSum: formatPrice(statistic.total, currency),
    totalNetSum: formatPrice(statistic.net, currency),
    currency,
    availablePaymentMethods: availablePaymentMethods || [],
  };

  const items = sortByDate(statistic.items, 'asc');
  items.forEach((order, index) => {
    const x = index + 1;
    const earnings = order.total ? formatDigits(order.total, currency) : 0;
    basketAverageStatistic.total.push({
      x,
      xLabel: order.date,
      y: earnings,
      label: order.total ? formatPrice(order.total, currency) : formatPrice(0, currency),
    });
    basketAverageStatistic.totalNet.push({
      x,
      xLabel: order.date,
      y: order.net ? formatDigits(order.net, currency) : 0,
      label: order.net ? formatPrice(order.net, currency) : formatPrice(0, currency),
    });
    if (basketAverageStatistic.maxTotal < earnings) {
      basketAverageStatistic.maxTotal = earnings;
    }
  });

  return basketAverageStatistic;
}

function prepareBasketAverageStatistic(statistic, currency) {
  if (!statistic || !statistic.final || !statistic.transferred) return null;

  return {
    final: basketAverstatisticsFor(
      statistic.final,
      statistic.availablePaymentMethods,
      currency,
    ),
    transferred: basketAverstatisticsFor(
      statistic.transferred,
      statistic.availablePaymentMethods,
      currency,
    ),

    // filter values from basketAverage#receivedBasketAverageStatistic
    date: statistic.date,
    shopID: statistic.shopID,
    withCustomerCard: statistic.withCustomerCard,
    paymentMethods: statistic.paymentMethods,

    availablePaymentMethods: statistic.availablePaymentMethods || [],
  };
}

function prepareWeekOnWeekOrderStatisticForType(currency, date, statistic) {
  if (!statistic || !statistic.items || statistic.items.length < 13) return null;

  const items = sortByDate(statistic.items, 'asc');
  const orderStatistic = {
    thisWeek: {
      orders: [],
      total: [],
    },
    lastWeek: {
      orders: [],
      total: [],
    },
    today: {
      orders: items[13].count || 0,
      total: formatPrice(items[13].total, currency),
    },
    date: {
      from: moment(moment(date.to).subtract(6, 'days').startOf('day')).toISOString(),
      to: moment(date.to).toISOString(),
    },
    maxOrders: 10,
    maxTotal: 10,
    count: 0,
    totalSum: 0,
    currency,
  };

  items.forEach((order, index) => {
    const earnings = order.total ? formatDigits(order.total, currency) : 0;
    const { count } = order;
    const x = index + 1;
    const o = {
      x,
      y: count,
      xLabel: order.date,
      label: count,
    };
    const t = {
      x,
      y: earnings,
      xLabel: order.date,
      label: order.total ? formatPrice(order.total, currency) : formatPrice(0, currency),
    };
    if (index < 7) {
      orderStatistic.lastWeek.orders.push(o);
      orderStatistic.lastWeek.total.push(t);
    } else {
      o.x -= 7;
      t.x -= 7;
      orderStatistic.thisWeek.orders.push(o);
      orderStatistic.thisWeek.total.push(t);
      orderStatistic.count += count;
      orderStatistic.totalSum += order.total;
    }
    if (count > orderStatistic.maxOrders) { orderStatistic.maxOrders = count; }
    if (earnings > orderStatistic.maxTotal) { orderStatistic.maxTotal = earnings; }
  });
  orderStatistic.totalSum = formatPrice(orderStatistic.totalSum, currency);
  return orderStatistic;
}


function prepareWeekOnWeekOrderStatistic(statistic, currency) {
  if (!statistic || !statistic.final || !statistic.transferred) return null;

  return {
    final: prepareWeekOnWeekOrderStatisticForType(
      currency,
      statistic.date,
      statistic.final,
    ),
    transferred: prepareWeekOnWeekOrderStatisticForType(
      currency,
      statistic.date,
      statistic.transferred,
    ),
  };
}


function getPortion(total, part) {
  if (total === 0 || part === 0) return 0;
  const x = 1 / total;
  return x * part * 100;
}

function prepareRecurringVisitorsStatistic(statistic) {
  if (!statistic) return null;
  const visitorsStatistic = {
    total: statistic.total,
    maxValue: 10,
    newVisitors: [],
    recurringVisitors: [],
    recurringVisitorsPortion: [],
    date: statistic.date,
  };
  const items = sortByDate(statistic.items, 'asc');
  items.forEach((item, index) => {
    const newVisitors = item.total - item.revisits;
    const recurringPortion = getPortion(item.total, item.revisits);
    const x = index + 1;
    visitorsStatistic.recurringVisitorsPortion.push({
      x,
      xLabel: item.date,
      y: recurringPortion,
      label: `${Math.round(recurringPortion)}%`,
    });
    visitorsStatistic.newVisitors.push({
      x,
      xLabel: item.date,
      y: item.total,
      label: newVisitors,
      totalLabel: item.total,
    });
    visitorsStatistic.recurringVisitors.push({
      x,
      xLabel: item.date,
      y: item.revisits,
      label: item.revisits,
    });
    if (visitorsStatistic.maxValue < item.total) {
      visitorsStatistic.maxValue = item.total;
    }
  });
  return visitorsStatistic;
}

const prepareOpening = (opening) => {
  const daysOfWeek = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  if (!opening) return daysOfWeek;

  opening.forEach((o) => {
    const day = toLower(o.dayOfWeek);
    const current = daysOfWeek[day];
    current.push(Object.assign({}, o, { id: `${day}-${current.length}` }));
  });

  // add empty line, if there are no openings by now
  forIn(daysOfWeek, (item, day) => {
    if (item.length === 0) {
      item.push({ id: `${day}-0`, dayOfWeek: day });
    }
  });
  return daysOfWeek;
};

export {
  sortByDate,
  mapOrders,
  mapOrder,
  prepareBasketAverageStatistic,
  prepareOrderStatistic,
  prepareWeekOnWeekOrderStatistic,
  prepareRecurringVisitorsStatistic,
  prepareOpening,
  filterCartDiscounts,
};
