import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import ProductListContainer from '../containers/ProductListContainer';
// @ts-ignore
import ProductViewContainer from '../containers/ProductViewContainer';
import RouteWrapper from './RouteWrapper';
import { Access } from '../access';
import { ResourceProvider } from '../resource';

function ProductRoutes({ access }: { access?: Access }) {
  return (
    <ResourceProvider name="products">
      <Routes>
        {access?.read &&
        <React.Fragment>
          <Route path="/" element={<RouteWrapper><ProductListContainer /></RouteWrapper>} />
          <Route path="/:id" element={<RouteWrapper><ProductViewContainer /></RouteWrapper>} />
        </React.Fragment>
      }
        <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default ProductRoutes;
