import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Translate from './i18n/Translate';

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.state = {
      buttonDisabled: false,
      showError: false,
    };
  }

  handleSnackbarClose() {
    this.setState({ showError: false });
  }

  downloadFile(blob) {
    const url = URL.createObjectURL(blob);

    const tempLink = document.createElement('a');
    tempLink.setAttribute('href', url);
    tempLink.setAttribute('download', this.props.filename || '');
    tempLink.click();
    tempLink.remove();

    URL.revokeObjectURL(url);
  }

  async handleButtonClick() {
    this.setState({ buttonDisabled: true });

    try {
      const response = await this.props.fetch();

      if (response.status !== 200) {
        this.setState({ showError: true });
        return;
      }

      const blob = await response.blob();
      this.downloadFile(blob);
    } finally {
      this.setState({ buttonDisabled: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudDownload />}
          disabled={this.props.disabled || this.state.buttonDisabled}
          onClick={this.handleButtonClick}
        >
          {this.props.children || (
            <Translate id="downloadButton.download" />
          )}
        </Button>

        <Snackbar
          open={this.state.showError}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <Translate id="downloadButton.downloadFailed">
              Download failed
            </Translate>
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default DownloadButton;
