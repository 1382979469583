import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { amber } from '@mui/material/colors';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    isolation: 'isolate',
    lineHeight: 'normal',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  caption: {
    fontSize: '0.75rem',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    position: 'relative',
    zIndex: 2,
  },
  bar: {
    backgroundColor: amber[500],
    inset: 0,
    position: 'absolute',
    zIndex: 1,
  },
});

function HorizontalDiagramBar({
  value, max, unit, classes,
}) {
  let percentage;
  if (max === 0) {
    percentage = 100;
  } else {
    percentage = (value / max) * 100;
  }

  return (
    <div className={classes.container}>
      <div className={classes.caption}>
        {value}{unit && ` ${unit}`}
      </div>
      <div data-testid="percentage-bar" className={classes.bar} style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default withStyles(styles)(HorizontalDiagramBar);
