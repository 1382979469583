import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { Range } from '../charts/chartFilterProps';

function formatDateForRequest(dateString: string): string {
  const date = new Date(dateString);
  return date.toISOString();
}

export enum IDType {
  Card = 'card',
  AppUser = 'appUser',
}

interface GetJournalProps {
  shopIDs: string[];
  range: Range;
  idFilter: string;
  typeFilter: IDType | undefined;
  accessGrantedFilter: boolean | undefined;
  currentPage: number | undefined;
}

type TCredentialsType = 'appUserID' | 'card' | 'phoneNumber';


interface BlockUserProps {
  credentialsType: TCredentialsType;
  credentialsValue: string;
  reason: string;
}

export interface UnblockUserProps {
  type: TCredentialsType;
  value: string;
}

interface JournalEntry {
  ID: number;
  accessGranted: boolean;
  attemptTime: string;
  credentialsType: TCredentialsType;
  credentialsValue: string;
  denial_reason: string;
  projectID: string;
  shopID: string;
  phoneNumber?: string;
}

export interface BlockListEntry {
  ID: number;
  createdAt: string;
  createdBy: string;
  projectID: string;
  reason: string;
  credentialsType: string;
  credentialsValue: string;
}

interface ApiResponseWithData<T> {
  error: string;
  list: Array<T>;
  pagination: {
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
  };
}

interface ApiResponse {
  error: string;
}

function checkForError(data: ApiResponseWithData<any> | ApiResponse) {
  const { error } = data;
  if (error) throw new Error(error);
}

function convertBlockListToCsv(data: Array<BlockListEntry>): string {
  const header: Array<keyof BlockListEntry> = [
    'credentialsType',
    'credentialsValue',
    'reason',
    'createdAt',
    'createdBy',
  ];

  if (!data) return header.join(',');
  const body = data
    .map(entry => (
      header.map(column => (entry[column] ?? '')).join(',')
    ))
    .join('\n');

  return `${header.join(',')}\n${body}`;
}

const useApi = defineApi({
  getJournal: async (client, {
    shopIDs, range, idFilter, typeFilter, accessGrantedFilter, currentPage,
  }: GetJournalProps) => {
    if (!range.from || !range.to) return null;

    const from = formatDateForRequest(range.from);
    const to = formatDateForRequest(range.to);

    const shopQuery = (shopIDs && shopIDs.length > 0) ? shopIDs.map(id => `&shop-id=${id}`).join('') : '';
    const idQuery = idFilter ? `&search=${encodeURIComponent(idFilter)}` : '';
    const typeQuery = typeFilter ? `&credential-type=${typeFilter}` : '';
    const accessGrantedQuery = accessGrantedFilter !== undefined ? `&access-granted=${accessGrantedFilter}` : '';
    const pageQuery = currentPage ? `&page=${currentPage}` : '';
    const url = `/journal?from=${from}&to=${to}${shopQuery}${idQuery}${typeQuery}${pageQuery}${accessGrantedQuery}`;

    const { data } = await client.get<ApiResponseWithData<JournalEntry>>(url);
    checkForError(data);

    return { journal: data, page: data.pagination.page, totalPages: data.pagination.totalPages };
  },
  getBlocklist: async (client) => {
    const { data } = await client.get<ApiResponseWithData<BlockListEntry>>('/block-list');
    checkForError(data);

    return data;
  },
  getBlocklistCsv: async (client) => {
    const { data } = await client.get<ApiResponseWithData<BlockListEntry>>('/block-list');
    checkForError(data);

    return convertBlockListToCsv(data.list);
  },
  blockUser: async (client, {
    credentialsType,
    credentialsValue,
    reason,
  }: BlockUserProps) => {
    if (!credentialsValue || !reason || !credentialsType) return null;

    const url = '/block-list';
    const body = { credentialsType, credentialsValue, reason };

    const { data } = await client.post<ApiResponse>(url, body);
    checkForError(data);

    return data;
  },
  unblockUser: async (client, { type, value }: UnblockUserProps) => {
    if (!type || !value) return null;

    const url = `/block-list/${type}/${value}`;

    const { data } = await client.delete<ApiResponse>(url);
    checkForError(data);

    return data;
  },
});

export default function useShopAccessApi() {
  const client = useProjectSpecificApiClient({ basePath: '/access' });
  return useApi(client);
}
