import actions from '../actions/constants';

const mapCategory = (category, { shops }) => {
  const mappedCategory = category;
  if (category && category.shops && shops && shops.length) {
    const mappedShops = [];
    shops.forEach((shop) => {
      const c = category.shops.find(categoryShop => categoryShop.id === shop.id);
      if (c) {
        mappedShops.push(shop);
      }
    });
    mappedCategory.shops = mappedShops;
  }
  return mappedCategory;
};

const mapCategories = (categories, { shops }) => {
  const mappedCategories = [];
  (categories || []).forEach(category => mappedCategories.push(mapCategory(category, { shops })));
  return mappedCategories;
};

export default (state, action) => {
  switch (action.type) {
    case actions.REQUEST_PRICING:
      return { fetchingPricing: true };

    case actions.RECEIVED_PRICING:
      if (action.payload && action.payload.sku === state.product.sku) {
        return Object.assign(
          {}, state,
          {
            fetchingPricing: false,
            product: Object.assign({}, state.product, { prices: action.payload.prices }),
          },
        );
      }
      return { fetchingPricing: false, pricing: action.payload };

    case actions.REQUEST_PRICING_CATEGORIES:
      return { fetchingPricingCategories: true };

    case actions.RECEIVED_PRICING_CATEGORIES:
      return {
        fetchingPricingCategories: false,
        pricingCategories: mapCategories(action.payload.categories, state),
      };

    case actions.REQUEST_PRICING_CATEGORY:
      return {
        fetchingPricingCategory: true,
        pricingCategory: null,
      };

    case actions.RECEIVED_PRICING_CATEGORY:
      return {
        fetchingPricingCategory: false,
        pricingCategory: mapCategory(action.payload, state),
      };

    case actions.UPDATE_PRICING_CATEGORY_FAILED:
      return {
        pricingCategoryUpdateSuccessful: false,
        pricingCategoryUpdateError: action.payload,
      };

    case actions.UPDATE_PRICING_CATEGORY_SUCCESS:
      return { pricingCategoryUpdateSuccessful: true };

    case actions.DELETE_PRICING_CATEGORY_FAILED:
      return {
        pricingCategoryDeleteSuccessful: false,
      };

    case actions.DELETE_PRICING_CATEGORY_SUCCESS:
      return { pricingCategoryDeleteSuccessful: true };

    case actions.PRICING_CATEGORY_STATUS_RESET:
      return {
        pricingCategoryDeleteSuccessful: null,
        pricingCategoryUpdateSuccessful: null,
        pricingCategoryUpdateError: null,
      };

    case actions.EDIT_PRICING_CATEGORY_STATUS_UPDATE:
      return {
        pricingCategoryEditStatus:
          action.payload.newStatus,
        pricingCategoryUpdateError: action.payload.error,
      };

    default:
      return {};
  }
};
