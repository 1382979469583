import { get } from './request';
import actions from './constants';
import { receivedShops } from './shops';

// This additional initial shops actions are needed to prevent views using
// fetchShops on mount to rerender infinitely. They are exclusively called when
// a project is selected.

export function requestInitialShops() {
  return {
    type: actions.REQUEST_INITIAL_SHOPS,
  };
}

export function fetchInitialShops() {
  return (dispatch, getState) => {
    dispatch(requestInitialShops());
    return get(dispatch, getState, '/{project}/shops', 'application/json')
      .then(
        json => dispatch(receivedShops(json)),
        () => dispatch(receivedShops({})),
      );
  };
}
