import InStockIcon from '@mui/icons-material/Done';
import { styled } from '@mui/system';
import React from 'react';
import Translate from './i18n/Translate';

type ProductOutOfStockProps = {
  outOfStock: boolean;
};

const StockContainer = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const textAndIconForOutOfStock = (isOutOfStock: boolean) => {
  if (isOutOfStock) {
    return { icon: null, text: '–' };
  }
  return {
    icon: <InStockIcon style={{ color: '#4caf50' }} />,
    text: <Translate id="product.inStock" />,
  };
};

function ProductOutOfStock({ outOfStock }: ProductOutOfStockProps) {
  const { icon, text } = textAndIconForOutOfStock(outOfStock);
  return (
    <StockContainer>
      {icon}&nbsp;<span style={{ alignSelf: 'center' }}>{text}</span>
    </StockContainer>
  );
}

export default ProductOutOfStock;
