import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Translate from './i18n/Translate';
import FilterWrapper from './FilterWrapper';
import FilterSelect from './FilterSelect';
import FilterTextInput from './FilterTextInput';

class ProductListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.initialInputValue || '',
      selectState: props.initialSelectState || 'false',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  requestWithTimeout() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.handleSearchUpdate(this.state.inputValue);
    }, 500);
  }

  handleInputChange(event) {
    this.setState(
      { inputValue: event.target.value },
      this.requestWithTimeout,
    );
  }

  handleSelectChange(event) {
    if (!event) return;
    this.setState({ selectState: event.target.value === 'true' }, () => {
      this.props.onSelectChange(this.state.selectState);
    });
  }

  render() {
    const { inputValue, selectState } = this.state;

    return (
      <FilterWrapper
        headline={<Translate id="product.headline" />}
        handleRefresh={this.props.fetchData}
        >
        <FilterTextInput
          label={<Translate id="product.searchLabel" />}
          value={inputValue}
          onChange={this.handleInputChange}
        />

        <Translation>
          {translate => (
            <FilterSelect
              value={selectState}
              onChange={this.handleSelectChange}
              label={translate('product.showBarcodes')}
            >
              <MenuItem value="false">{translate('button.no')}</MenuItem>
              <MenuItem value="true">{translate('button.yes')}</MenuItem>
            </FilterSelect>
          )}
        </Translation>
      </FilterWrapper>
    );
  }
}


export default ProductListFilter;
