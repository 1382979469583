import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { useResourceTranslator } from '../../resource';
import isUnhandledError from '../../scaffold/isUnhandledError';
import LoadableRegion from '../../loader/LoadableRegion';
// @ts-ignore
import NoResult from '../../components/NoResult';
// @ts-ignore
import Translate from '../../components/i18n/Translate';

export interface ResourceChartViewProps {
  Chart: React.ComponentType<any>;
  ChartProps?: Object;
  fetch: (filter: any) => any;
  onFetched?: (result: any) => void | Promise<void>;
  onFetchFailed?: (error: Error) => void | Promise<void>;
  dataIsEmpty?: (values: any) => boolean;
  filter?: Record<string, any>;
}

export interface ResourceChartViewRef {
  updateData: () => void;
}

const ResourceChartView = forwardRef<ResourceChartViewRef, ResourceChartViewProps>(({
  Chart,
  ChartProps,
  fetch,
  onFetched,
  onFetchFailed,
  dataIsEmpty,
  filter,
}, ref) => {
  const t = useResourceTranslator();

  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const [criticalError, setCriticalError] = useState('');
  const [hasFetchError, setHasFetchError] = useState(false);

  // Keeps track whether fetch is already running for the component instance.
  // Prevents fetching multiple times when rerendering while still fetching.
  const fetchingRef = useRef(false);

  const handleFetch = useCallback(async () => {
    fetchingRef.current = true;
    setIsFetching(true);
    setCriticalError('');
    try {
      setData(await fetch(filter));
      setIsFetching(false);
    } catch (e) {
      if (!(e instanceof Error) || isUnhandledError(e)) return;
      setCriticalError(e.message);
      setIsFetching(false);
      setHasFetchError(true);
      await onFetchFailed?.(e);
    } finally {
      fetchingRef.current = false;
    }
  }, [fetch, filter, onFetchFailed]);

  useImperativeHandle(ref, () => ({
    // expose the handleFetch function so the parent component can instruct the
    // chart to fetch new data
    updateData() {
      handleFetch();
    },
  }));

  useEffect(() => {
    if (!fetch || fetchingRef.current || filter?.hasError) return;

    handleFetch();
  }, [filter, fetch, handleFetch]);

  useEffect(() => {
    if (!data) return;
    onFetched?.(data);
  }, [data, onFetched]);

  const chartProps = useMemo(
    () => ({
      ...ChartProps,
      values: data,
      onRefresh: handleFetch,
    }),
    [ChartProps, data, handleFetch],
  );

  return (
    <div>
      <Stack spacing={4}>
        {criticalError && (
          <Alert severity="error">
            <AlertTitle>{t('alert.criticalError')}</AlertTitle>
            {criticalError}
          </Alert>
        )}

        <LoadableRegion loading={isFetching}>
          {
            !hasFetchError && data && (
              dataIsEmpty && dataIsEmpty(data) ?
                <NoResult text={<Translate id="statistic.empty" />} />
                :
                <Chart {...chartProps} />
            )
          }
        </LoadableRegion>
      </Stack>
    </div>
  );
});

export default ResourceChartView;
