import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/system';
import React from 'react';
import Translate from '../i18n/Translate';

const ToggleButtonGroupWithMargin = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

class OrderStateFilterButtonGroup extends React.PureComponent {
  render() {
    const {
      onChange,
      value,
      withMargin,
    } = this.props;

    const Component = withMargin ? ToggleButtonGroupWithMargin : ToggleButtonGroup;
    return ((
      <Component
        onChange={onChange}
        value={value}
        size="small"
        color="primary"
        exclusive
      >
        <ToggleButton data-testid="final" value="final"><Translate id="orderStateFilter.final" /></ToggleButton>
        <ToggleButton data-testid="transferred" value="transferred"><Translate id="orderStateFilter.transferred" /></ToggleButton>
      </Component>
    ));
  }
}

export default OrderStateFilterButtonGroup;
