import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Attribute from './Attribute';
import AttributeValue from './AttributeValue';
import AttributesInput from './AttributesInput';

export interface AttributesListProps {
  attributes: AttributesInput;
}

function inputToAttributes(input: AttributesInput) {
  if (Array.isArray(input)) {
    return input as Attribute[];
  }

  return Object
    .entries(input)
    .map<Attribute>(([key, value]) => ({ key, value }));
}

export default function AttributesList({
  attributes,
}: AttributesListProps) {
  const attrs = inputToAttributes(attributes);

  if (attrs.length === 0) return null;

  return (
    <List sx={{ width: '100%' }}>
      {attrs.map((({ key, value }, index) => (
        <Fragment key={key}>
          {index > 0 && <Divider component="li" />}
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={key}
              secondary={<AttributeValue value={value} />}
            />
          </ListItem>
        </Fragment>
      )))}
    </List>
  );
}
