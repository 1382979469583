import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';

export enum TaxRuleType {
  SKU = 'sku',
  PREFIX = 'prefix',
}

export interface TaxRule {
  value: string;
  description: string;
}

export interface TaxRuleWithType extends TaxRule {
  type: TaxRuleType;
}

export type TaxRuleSet = Record<TaxRuleType, TaxRule[] | null>;

export interface GetPermamentTaxRulesData {
  data: TaxRule[] | null;
  etag: string;
}

export interface GetPermamentTaxRulesSetData {
  data: TaxRuleSet | null;
  etag: string;
}

export interface DeletePermanentTaxRuleProps {
  type: TaxRuleType;
  value: string;
}

export interface EditPermanentTaxRuleProps {
  rules: TaxRuleSet;
  etag: string;
}

export function castStringToTaxRuleType(type?: string): TaxRuleType | undefined {
  if (!Object.values(TaxRuleType).includes(type as any)) return undefined;
  return type as TaxRuleType;
}

const useApi = defineApi({
  getPermanentTaxRules: async (
    client,
    ruleType: TaxRuleType,
  ): Promise<GetPermamentTaxRulesData> => {
    const { data, headers } = await client.get<TaxRuleSet>('/permanent-tax-rules');
    return { data: data?.[ruleType], etag: headers.etag };
  },
  getPermanentTaxRulesSet: async (client): Promise<GetPermamentTaxRulesSetData> => {
    const { data, headers } = await client.get<TaxRuleSet>('/permanent-tax-rules');
    return { data, etag: headers.etag };
  },
  createPermanentTaxRule: async (client, rule: FieldValues) => {
    await client.post('/permanent-tax-rules', rule);
  },
  deletePermamentTaxRule: async (client, { type, value }: DeletePermanentTaxRuleProps) => {
    if (!type || !value) throw new Error('No tax rule selected');
    const query = `?${type}=${value}`;
    await client.delete(`/permanent-tax-rules${query}`);
  },
  updatePermanentTaxRules: async (client, { rules, etag }: EditPermanentTaxRuleProps) => {
    await client({
      url: '/permanent-tax-rules',
      method: 'PUT',
      data: rules,
      headers: {
        'If-Match': `"${etag}"`,
      },
    });
  },
});

export default function useTaxRulesApi() {
  const client = useProjectSpecificApiClient({ basePath: '/vpos/config' });
  return useApi(client);
}
