import React, { ReactNode } from 'react';
import { Theme } from '@mui/system';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) => ({
  headline: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
});

export interface HeadlineWithButtonsProps {
  classes: any;
  buttons?: ReactNode;
  children: ReactNode;
}

function HeadlineWithButtons({
  classes,
  buttons,
  children,
}: HeadlineWithButtonsProps) {
  return (
    <div className={classes.headlineWrapper}>
      <Typography variant="h5" component="h1">{children}</Typography>
      {buttons}
    </div>
  );
}

export default withStyles(styles)(HeadlineWithButtons);
