import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';

const styles = theme => ({
  helperText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

function FormTableRow({
  text, required, children, propertyName, errorText, classes, hasError, errorDetails,
}) {
  const error = errorText || 'form.requiredMissing';
  return (
    <TableRow>
      <TableCell width="25%">{text}&nbsp;{required ? '*' : ''}</TableCell>
      <TableCell width="75%">
        <FormControl error={hasError} fullWidth aria-describedby={`${propertyName}-error-text`}>
          {children}
          {hasError ?
            <FormHelperText id={`${propertyName}-error-text`} className={classes.helperText}>
              <Translate id={error} data={errorDetails} />
            </FormHelperText> : <span />
          }
        </FormControl>
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(FormTableRow);
