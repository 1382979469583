const removeProject = (value? : string) => {
  if (!value || !value.length) return value;
  const position = value.indexOf('/');
  if (position !== -1) {
    return value.substring(0, position).toLowerCase();
  }
  return value.toLowerCase();
};

export default removeProject;

export const splitProject = (value? : string) => {
  if (!value || !value.length) return [undefined, undefined];
  const [format, project] = value.split('/');
  if (!project) return [format, project];
  return [format.toLowerCase(), project];
};
