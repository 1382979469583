import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(1),
  },
  label: {
    width: '30%',
  },
  helperText: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%',
  },
  selectElement: {
    maxWidth: '400px',
    width: '100%',
  },
});

function FormTableRowMultiSelect({
  classes,
  errorText,
  hasError,
  label,
  onChange,
  optionLabel = 'label',
  optionValue = 'value',
  options,
  propertyName,
  required,
  values,
}) {
  const { t } = useTranslation();
  const error = errorText || 'form.requiredMissing';

  const handleChange = (event) => {
    onChange?.(event);
  };

  const extractValue = option => option[optionValue] || option.id;
  const extractLabel = (option) => {
    if (!option) {
      return null;
    }
    return option[optionLabel] || option.name || option.id;
  };

  const findOptionByValue = value => options.find(option => extractValue(option) === value);

  return (
    <TableRow>
      <TableCell className={classes.label}>{label}&nbsp;{required ? '*' : ''}</TableCell>
      <TableCell>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={hasError}
          required={required}
        >
          <Select
            value={values || []}
            onChange={handleChange}
            multiple
            variant="outlined"
            className={classes.selectElement}
            renderValue={selected => selected.map(findOptionByValue).map(extractLabel).join(', ')}
          >
            {(options || []).map((option) => {
              const value = extractValue(option);

              return (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={values?.includes(value)} />
                  <ListItemText>
                    {extractLabel(option)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Select>

          {hasError && (
            <FormHelperText
              id={`${propertyName}-error-text`}
              className={classes.helperText}
            >
              {t(error)}
            </FormHelperText>
          )}
        </FormControl>
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(FormTableRowMultiSelect);
