import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';

// This wrapper is here to sync the borders used to indicate the inputs state
// (default, hovered, focused). This way the image preview will look the same
// as the text input.
const BorderWrapper = styled(Stack)(({ theme }) => ({
  ':has(:is(input, img, button):hover) :is(img, .placeholder)': {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  ':has(:is(input, img):focus) :is(img, .placeholder)': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  // here an outline is overlayed over the border since MUI has a lot of logic
  // for their border effects which stay untouched this way
  ':has(img:hover) input:not(:focus)': {
    outline: `1px solid ${theme.palette.text.primary}`,
    outlineOffset: '-1px',
    borderRadius: '4px',
  },
}));

export default BorderWrapper;
