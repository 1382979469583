import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Translate from './i18n/Translate';
import FormTableRow from './FormTableRow';
import ButtonAdd from './ButtonAdd';

const styles = theme => ({
  deleteBtn: {
    marginRight: theme.spacing(1),
  },
  inputStyle: {
    width: '100%',
    maxWidth: '400px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& input': {
      padding: theme.spacing(1.5),
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  withBtns: {
    marginRight: theme.spacing(2),
  },
});

class FormTableRowText extends React.Component {
  shouldComponentUpdate(prevProps) {
    return (
      this.props.text !== prevProps.text ||
      this.props.required !== prevProps.required ||
      this.props.property !== prevProps.property ||
      this.props.propertyName !== prevProps.propertyName ||
      this.props.errorText !== prevProps.errorText ||
      this.props.hasError !== prevProps.hasError ||
      this.props.disabled !== prevProps.disabled ||
      this.props.showDeleteBtn !== prevProps.showDeleteBtn ||
      this.props.showAddBtn !== prevProps.showAddBtn
    );
  }
  render() {
    const {
      text, required, property, propertyName, errorText, handleChange, classes, hasError, disabled,
      showDeleteBtn, showAddBtn, handleDelete, handleAdd, type, errorDetails,
    } = this.props;

    if (handleAdd && handleDelete) {
      return (
        <FormTableRow
          text={text}
          required={required}
          propertyName={propertyName}
          errorText={errorText}
          errorDetails={errorDetails}
          hasError={hasError}>
          <div>
            <TextField
              variant="outlined"
              disabled={disabled}
              name={propertyName}
              value={property}
              onChange={handleChange}
              placeholder={text}
              type={type}
              className={`${classes.inputStyle} ${classes.withBtns}`}
            />
            {showDeleteBtn &&
              <Button onClick={handleDelete} color="primary" variant="contained" className={classes.deleteBtn}>
                <Translate id="button.delete" data={{ itemName: '' }} />
              </Button>
            }
            {showAddBtn &&
              <ButtonAdd onClick={handleAdd} />
            }
          </div>
        </FormTableRow>
      );
    }

    return (
      <FormTableRow
        text={text}
        required={required}
        propertyName={propertyName}
        errorText={errorText}
        errorDetails={errorDetails}
        hasError={hasError}>
        <TextField
          variant="outlined"
          disabled={disabled}
          name={propertyName}
          value={property}
          onChange={handleChange}
          type={type}
          placeholder={text}
          className={classes.inputStyle}
        />
      </FormTableRow>
    );
  }
}

export default withStyles(styles)(FormTableRowText);
