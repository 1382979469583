import React from 'react';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import { OrganizationAdmin } from './useOrganizationAdminManagementApi';

const DEFAULT_VALUES: OrganizationAdmin | {} = {};

export interface CreateAdminFormProps extends CustomFormProps<OrganizationAdmin | {}> {
}

export default function CreateAdminForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CreateAdminFormProps) {
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="user">
        <TextField
          control={control}
          name="sub"
          required
          rules={{
            required: true,
          }}
        />
        <TextField
          control={control}
          name="name"
          optional
        />
        <TextField
          control={control}
          name="email"
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
