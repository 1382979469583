import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import useAppUserApi from './appUserApi';
import EditAppUserForm from './EditAppUserForm';

export default function EditAppUserView() {
  const api = useAppUserApi();
  const navigate = useProjectNavigate();
  const { appUserId } = useParams();

  const handleFetch = useCallback(() => api.getAppUser(appUserId), [api, appUserId]);
  const handleErase = useCallback(async () => {
    await api.eraseAppUser(appUserId);
    navigate('/app-user-admin');
  }, [api, appUserId, navigate]);

  return (
    <>
      <DocumentTitle translationID="shopAccess.headline" />
      <ResourceFormView
        actionName="editAppUser"
        Form={EditAppUserForm}
        FormProps={{
          onErase: handleErase,
        }}
        fetch={handleFetch}
        submit={data => api.updateAppUser({ appUserId, appUserDetails: data?.details as any })}
        onSubmitted={() => {
          navigate('/app-user-admin');
        }}
      />
    </>
  );
}
