import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useUserManagementApi, { ProjectFilter, RealmRole, User } from './useUserManagementApi';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import RealmAdminDialogTable from './RealmAdminDialogTable';
import useAlert from '../toast/useAlert';

export interface RealmAdminDialogViewProps {
  realmName?: string;
  filter: ProjectFilter;
  onClose: () => void;
}

export default function RealmAdminDialogView({
  realmName,
  filter,
  onClose,
} : RealmAdminDialogViewProps) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const api = useUserManagementApi();
  const alert = useAlert();

  const [users, setUsers] = useState<User[]>();
  const [userIdToDemote, setUserIdToDemote] = useState<string | undefined>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const chartViewRef = useRef<ResourceChartViewRef>(null);

  const handleFetch = useCallback(async () => {
    setUsers(await api.getUsers({
      ...filter,
      // NOTE no matter what realm is currently being edited, realmAdmins are always located in
      // the default realm
      realm: undefined,
      project: undefined,
    }));

    return api.getRealmAdmins({ ...filter });
  }, [api, filter]);

  const handlePromote = useCallback(async (userID: string | undefined) => {
    if (!userID || !projectId) return;
    try {
      await api.promoteUserToRealmAdmin({
        userID,
        filter: { ...filter },
      });
    } catch (e) {
      alert.error({ message: t('alert.error.promoteRealmAdmin') });
      return;
    }
    alert.success({ message: t('alert.success.promoteRealmAdmin') });
    chartViewRef.current?.updateData();
  }, [alert, api, filter, projectId, t]);

  const handleDemote = useCallback(async () => {
    setShowConfirmationDialog(false);
    if (!userIdToDemote) return;
    await api.demoteUserFromRealmAdmin({ userID: userIdToDemote, filter: { ...filter } });
    alert.success({ message: t('alert.success.demoteRealmAdmin') });
    chartViewRef.current?.updateData();
  }, [alert, api, filter, t, userIdToDemote]);

  const realmAdminFilter = useMemo(() => (
    { ...filter, role: RealmRole.REALM_ADMIN }
  ), [filter]);

  return (
    <>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => { setShowConfirmationDialog(false); }}
        maxWidth="xs"
      >
        <DialogTitle>
          {t('userManagement.realmAdmins.demoteUser.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('userManagement.realmAdmins.demoteUser.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setShowConfirmationDialog(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={handleDemote} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open
        sx={{ '& .MuiDialog-paper': { height: '50vh' } }}
        maxWidth="lg"
        fullWidth
        onClose={onClose}
      >
        <DialogContent dividers sx={{ overflow: 'hidden' }}>
          <ResourceChartView
            Chart={RealmAdminDialogTable}
            ChartProps={{
              realmName,
              users,
              onPromoteUser: handlePromote,
              onDemoteUser: (userId: string) => {
                setUserIdToDemote(userId);
                setShowConfirmationDialog(true);
              },
            }}
            fetch={handleFetch}
            filter={realmAdminFilter}
            ref={chartViewRef}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={onClose}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
