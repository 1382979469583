import { connect } from 'react-redux';
import PricingCategoryEditView from '../components/PricingCategoryEditView';
import { fetchPricingCategory, updatePricingCategory, deletePricingCategory, editPricingCategoryStatusUpdate } from '../actions';

const parseError = (error) => {
  if (error) {
    if (error && error.message && error.message.indexOf('Shops') !== -1) {
      return error.message.replace('Client Error: ', '');
    }
  }
  return null;
};

const PricingCategoryEditViewContainer = connect(
  ({
    shops,
    pricingCategory,
    fetchingPricingCategory,
    pricingCategoryUpdateError,
    pricingCategoryEditStatus,
    fetchShops,
    selectedProjectId,
  }, { id }) => ({
    shops,
    showLoadingIndicator: fetchingPricingCategory || fetchShops,
    selectedCategory: pricingCategory,
    categoryError: parseError(pricingCategoryUpdateError),
    editStatus: pricingCategoryEditStatus,
    project: selectedProjectId,
    priority: pricingCategory ? pricingCategory.priority : 1,
    listURL: `/${selectedProjectId}/pricing/categories`,
    isNew: !id,
  }),
  (dispatch, { id }) => ({
    fetchPricingCategory: () => dispatch(fetchPricingCategory(id)),
    updatePricingCategory: category => dispatch(updatePricingCategory(category)),
    deletePricingCategory: () => dispatch(deletePricingCategory(id)),
    updateStatus: status => dispatch(editPricingCategoryStatusUpdate(status)),
  }),
)(PricingCategoryEditView);

export default PricingCategoryEditViewContainer;
