import { connect } from 'react-redux';
import PricingCategoryList from '../components/PricingCategoryList';
import { fetchPricingCategories } from '../actions';

const PricingCategoryListContainer = connect(
  ({
    shops, pricingCategories, fetchingPricingCategories, navigation,
    selectedProjectId,
  }) => ({
    shops,
    pricingCategories,
    fetchingPricingCategories,
    projectId: selectedProjectId,
    canEdit: navigation.pricingCategories && navigation.pricingCategories.write,
  }),
  dispatch => ({
    fetchPricingCategories: () => dispatch(fetchPricingCategories()),
  }),
)(PricingCategoryList);

export default PricingCategoryListContainer;
