import { connect } from 'react-redux';
import App from '../components/App';
import { selectProject } from '../actions';

const mapStateToProps = ({
  fetchingProjects,
  fetchingNavigation,
  fetchingInitialShops,
  selectedProjectId,
  restrictedProject,
}, { projectId }) => ({
  projectId,
  validProjectSelected: !restrictedProject && selectedProjectId,
  fetchingData: fetchingNavigation || fetchingProjects || fetchingInitialShops,
});

const mapDispatchToProps = dispatch => ({
  selectProject: (project) => {
    dispatch(selectProject(project));
  },
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
