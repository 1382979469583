import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Archive, PhoneAndroid, QrCode } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ReactMarkdown from 'react-markdown';
import Download from '@mui/icons-material/Download';
import TableCell from '@mui/material/TableCell';
// @ts-ignore
import SnabbleLinearProgress from '../../components/SnabbleLinearProgress';
import DocumentTitle from '../../components/DocumentTitle';
// @ts-ignore
import PaperTable from '../../components/PaperTable';
// @ts-ignore
import ColoredTableRow from '../../components/ColoredTableRow';
import { fetchRelease } from '../api/releases';
import { Release } from '../entity/state';
import { useResourceTranslator } from '../../resource';
import Translate from '../../components/i18n/Translate';
import Headline from '../../components/Headline';
import { useAlert } from '../../toast';
import QRCodeDownloadDialog from './QrCodeDialog';

type ReleaseDetailViewParams = {
  repository: string;
  id: string;
};

export default function ReleaseDetailView() {
  const { i18n } = useTranslation();
  const t = useResourceTranslator();
  const { repository, id } = useParams<ReleaseDetailViewParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [release, setRelease] = useState<Release | undefined>(undefined);
  const [qrCodeDialogLink, setQrCodeDialogLink] = useState<string>('');
  const openDownloadDialog = (link: string) => {
    setQrCodeDialogLink(() => link);
  };
  const closeDownloadDialog = () => {
    setQrCodeDialogLink(() => '');
  };
  const alert = useAlert();

  useEffect(() => {
    if (!repository) {
      setIsLoading(false);
      alert.error({ message: 'repository parameter is missing' });
      return;
    }

    if (!id) {
      setIsLoading(false);
      alert.error({ message: 'id parameter is missing' });
      return;
    }

    const load = async () => {
      setIsLoading(true);

      try {
        setRelease(await fetchRelease(repository, id, i18n.language));
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        const message = e instanceof Error ? e.message : 'Unknown error.';
        alert.error({ message });
      }
    };
    load();
  }, [repository, id, i18n.language, alert]);

  let body = null;

  if (release != null) {
    body = (
      <>
        <PaperTable
          headline={t('releases.information')}
        >
          <ColoredTableRow>
            <TableCell align="left" >
              {t('releases.name')}
            </TableCell>

            <TableCell align="left" >
              {release.name}
            </TableCell>
          </ColoredTableRow>
          <ColoredTableRow>
            <TableCell align="left" >
              {t('releases.version')}
            </TableCell>

            <TableCell align="left" >
              {release.version}
            </TableCell>
          </ColoredTableRow>

          <ColoredTableRow>
            <TableCell align="left" style={{ paddingRight: '10px' }}>
              {t('releases.date')}
            </TableCell>
            <TableCell align="left" style={{ paddingRight: '10px' }}>
              {release.date}
            </TableCell>
          </ColoredTableRow>
        </PaperTable >
        <PaperTable
          headline={t('releases.downloads')}
          tableHeader={[
            t('releases.type'),
            t('releases.name'),
            t('releases.size'),
            '',
          ]}
        >
          {
            release.assets.map(asset => (
              <ColoredTableRow key={asset.id}>
                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  {asset.type === 'application/vnd.android.package-archive' ? <PhoneAndroid /> : <Archive />}
                </TableCell>
                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  {asset.name}
                </TableCell>
                <TableCell align="left" style={{ paddingRight: '10px' }}>
                  {asset.size}
                </TableCell>
                <TableCell align="right" style={{ paddingRight: '10px' }}>
                  <IconButton aria-label={`${t('releases.download')} ${asset.name}`} onClick={() => openDownloadDialog(asset.downloadLink)}>
                    <QrCode />
                  </IconButton>
                  <a href={asset.downloadLink}>
                    <IconButton aria-label={`${t('releases.download')} ${asset.name}`}>
                      <Download />
                    </IconButton>
                  </a>
                </TableCell>
              </ColoredTableRow>
            ))
          }
        </PaperTable >
        <PaperTable
          headline={t('releases.changelog')}
        >
          <ColoredTableRow>
            <TableCell align="left" style={{ paddingRight: '10px' }}>
              <ReactMarkdown>{release.changelog}</ReactMarkdown>
            </TableCell>
          </ColoredTableRow>
        </PaperTable >
      </>
    );
  }

  const qrCodeDialogOpen = qrCodeDialogLink !== '';

  return (
    <>
      <SnabbleLinearProgress show={isLoading} />
      <DocumentTitle translationID="releases.headline" />
      <Headline>
        <Translate id="releases.headline" />
      </Headline>
      {qrCodeDialogOpen &&
        <QRCodeDownloadDialog
          downloadLink={qrCodeDialogLink}
          onClose={closeDownloadDialog}
          open
        />
      }
      {body}
    </>
  );
}
