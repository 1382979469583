import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
// @ts-ignore
import OrderViewGeneral from '../components/OrderViewGeneral';
// @ts-ignore
import OrderViewDevice from '../components/OrderViewDevice';
// @ts-ignore
import OrderViewLineItems from '../components/OrderViewLineItems';
// @ts-ignore
import OrderViewShop from '../components/OrderViewShop';
// @ts-ignore
import OrderViewFulfillments from '../components/OrderViewFulfillments';
import OrderViewMeta from '../components/OrderViewMeta';
// @ts-ignore
import OrderViewSupervising from '../components/OrderViewSupervising';
import FiscalAttributesPanel from '../components/FiscalAttributesPanel';
import { ApplicationState } from '../reducers/initialState';
import useOrderApi from './useOrderApi';
import Headline from '../components/Headline';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import { formatPrice } from '../components/ProductFormatPrice';
import { MappedOrder } from './orderHelper';
import { MappedSupervisingResult } from './supervisingTypes';
import OrderPaymentResult from './OrderPaymentResult';

interface OrderViewContentProps {
  values: MappedOrder;
  projectCurrency: string;
  receipt: any;
  supervising: MappedSupervisingResult | null;
}

export function OrderViewContent({
  values,
  projectCurrency,
  receipt,
  supervising,
}: OrderViewContentProps) {
  const order = values;
  return (
    <>
      <OrderViewGeneral order={order} receipt={receipt} />

      {order.items?.length > 0 && (
        <OrderViewLineItems
          lineItems={order.items}
          referringItems={order.referringItems || []}
          cartDiscountInfo={order.cartDiscountInfo || []}
          currency={projectCurrency}
          price={order.price}
          project={order.project}
        />
      )}

      <OrderViewSupervising result={supervising} />

      <OrderViewDevice order={order} />

      <OrderViewShop
        shop={order.shop}
        projectID={order.project}
        id={order.shopID}
      />

      <OrderPaymentResult paymentResult={order.paymentResult} />

      <OrderViewFulfillments order={order} />

      <OrderViewMeta order={order} />

      {order.fiscalReference && <FiscalAttributesPanel attributes={order.fiscalReference} />}
    </>
  );
}

export default function OrderView() {
  const projectCurrency = useSelector((state: ApplicationState) => state.projectCurrency);
  const { id } = useParams();
  const orderApi = useOrderApi();

  const [order, setOrder] = useState<MappedOrder | null>(null);
  const [supervising, setSupervising] = useState<MappedSupervisingResult | null>(null);
  const [receipt, setReceipt] = useState<any>(null);

  const fetch = useCallback(async () => {
    if (!id) return {};

    const orderResult = await orderApi.getOrder(id);
    setOrder(orderResult);

    const supervisingResult = await orderApi.getSupervisingResult(orderResult.checkoutProcessID);
    setSupervising(supervisingResult);

    const receiptBlob = await orderApi.getReceipt(orderResult.receiptLink);
    setReceipt(receiptBlob);

    return orderResult;
  }, [id, orderApi]);

  return (
    <>
      <DocumentTitle translationID="orders.detailsHeadline" data={{ orderID: order?.shortID, total: formatPrice(order?.price.price, projectCurrency) }} />
      <Headline>
        <Translate id="orders.detailsHeadline" data={{ orderID: order?.shortID, total: formatPrice(order?.price.price, projectCurrency) }} />
      </Headline>
      <ResourceChartView
        Chart={OrderViewContent}
        ChartProps={{
          projectCurrency,
          receipt,
          supervising,
        }}
        fetch={fetch}
      />
    </>
  );
}
