import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import RouteWrapper from '../router/RouteWrapper';
import { ResourceProvider } from '../resource';
import ListAdminsView from './ListAdminsView';
import CreateAdminView from './CreateAdminView';

function OrganizationAdminManagementRoutes() {
  const params = useParams();
  const { projectId } = params;

  return (
    <ResourceProvider name="userManagement">
      <Routes>
        <Route path="/" element={<RouteWrapper><ListAdminsView /></RouteWrapper>} />
        <Route path="/new" element={<RouteWrapper><CreateAdminView /></RouteWrapper>} />
        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default OrganizationAdminManagementRoutes;
