import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { To, useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useShopApi, { Shop, TABS } from './useShopApi';
import useAutocompleteApi, { mapAddressDetailsToAddress } from '../googlePlacesApi/useAutocompleteApi';
import TabFilter, { TabFilterOption } from '../components/TabFilter';
import EditShopBaseForm from './EditShopBaseForm';
import EditShopAppForm from './EditShopAppForm';
import { CustomFormRef } from '../form/CustomFormProps';
import useShopPosConfigApi from './useShopPosConfigApi';
import EditShopPosConfigForm from './EditShopPosConfigForm';
import useShopCategoriesApi, { useLegacyShopCategoriesApi } from '../productsMenu/useShopCategoriesApi';
import useCategoriesApi, { useCategoryProductApi } from '../productsMenu/useCategoriesApi';
import { projectUsesOldCategoriesEditor } from '../utils/features';
import CategoriesFormLegacy from '../productsMenu/CategoriesFormLegacy';
import CategoriesForm from '../productsMenu/CategoriesForm';
import useCheckoutDeviceApi from '../sco/api/useCheckoutDeviceApi';
import ViewShopPosDevicesForm from './ViewShopPosDevicesForm';
import { ApplicationState } from '../reducers/initialState';
import ContentLink from '../components/ContentLink';
import ConfirmButton from '../button/ConfirmButton';

export default function EditShopView() {
  const api = useShopApi();
  const shopDevicesApi = useShopPosConfigApi();
  const shopCategoriesapi = useShopCategoriesApi();
  const categoriesApi = useCategoriesApi();
  const legacyShopCategoriesApi = useLegacyShopCategoriesApi();
  const categoryProductApi = useCategoryProductApi();
  const { resolveAddress } = useAutocompleteApi();
  const posDevicesApi = useCheckoutDeviceApi();
  const { projectId, id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const writePermission =
    useSelector<ApplicationState, boolean>(state => !!(state.navigation as any)?.shops?.write);
  const shops = useSelector<ApplicationState, any>(state => state.shops) as Shop[];
  const shopName = useMemo(() => (shops.find(shop => shop.id === id)?.name), [id, shops]);
  const [unloadPosDevices, setUnloadPosDevices] = useState(false);
  const navigate = useNavigate();

  const handleFetchShop = useCallback(async () => api.getShop(id), [api, id]);

  const handleSubmitShop = useCallback(async (data) => {
    const dataWithProject = { ...data, project: projectId };
    return api.updateShop({ shop: dataWithProject, shopId: id });
  }, [api, id, projectId]);

  const handleResolveCoordinates = useCallback(async (addressString: string) => {
    const address = mapAddressDetailsToAddress((await resolveAddress(addressString))[0]);
    if (!address) return { lat: undefined, lng: undefined };
    return { lat: address.lat, lng: address.lng };
  }, [resolveAddress]);

  const handleFetchShopDevices = useCallback(async () => {
    if (!id) return null;
    const data = await shopDevicesApi.get({ shopID: id });
    return data;
  }, [id, shopDevicesApi]);

  const handleSubmitShopDevices = useCallback((data) => {
    if (!id) return;
    shopDevicesApi.update({ shopID: id, data });
  }, [id, shopDevicesApi]);

  const handleProductLookup = useCallback(async (sku: string) => {
    const products = await categoryProductApi.getProduct({ sku });
    return products;
  }, [categoryProductApi]);

  const handleFetchPosDevices = useCallback(async () => {
    if (!id) return null;
    const data = await posDevicesApi.getShopStatus({ id });
    return data;
  }, [id, posDevicesApi]);

  const [currentTab, setCurrentTab] = useState<TabFilterOption>((
    location.state?.currentTab
    || { id: TABS.BASE, title: t(`shop.tabs.${TABS.BASE}`) }
  ));
  const currentForm = useRef<CustomFormRef | null>(null);
  const handleTabChange = useCallback(async (tab: TabFilterOption) => {
    if (await currentForm.current?.forceSubmit()) setCurrentTab(tab);
  }, []);

  const [globalCategoriesSet, setGlobalCategoriesSet] = useState(false);
  const handleFetchShopProductMenus = useCallback(async () => {
    const globalCategories = await categoriesApi.get(null);
    setGlobalCategoriesSet((
      !!globalCategories?.singleItems?.length
      || !!globalCategories?.categories?.length
    ));

    const shopCategories = await shopCategoriesapi.get({ shopId: id || '' });
    return shopCategories;
  }, [categoriesApi, id, shopCategoriesapi]);

  const handleRemoveSettings = useCallback(async () => {
    await shopCategoriesapi.update({
      shopId: id || '',
      data: {
        projectId,
        shopId: id,
        categories: null,
        singleItems: null,
      },
    });
    navigate(0 as To, {
      state: {
        currentTab: {
          id: TABS.SHOP_CATEGORIES,
          title: t(`shop.tabs.${TABS.SHOP_CATEGORIES}`),
        },
      },
    });
  }, [id, navigate, projectId, shopCategoriesapi, t]);

  const sharedResourceFormViewProps = {
    headline: shopName,
    additionalContent: (
      <TabFilter
        options={Object.values(TABS).map(tab => ({
          id: tab, title: t(`shop.tabs.${tab}`),
        }))}
        defaultOption={currentTab}
        onChange={handleTabChange}
        value={currentTab}
        sx={{ marginBottom: '32px' }}
      />
    ),
  };

  return (
    <>
      <DocumentTitle translationID="headlines.editShop" />

      {(!currentTab?.id || currentTab?.id === TABS.BASE) && (
        <ResourceFormView
          actionName="editShop"
          {...sharedResourceFormViewProps}
          Form={EditShopBaseForm}
          FormProps={{
            onRequestCoordinates: handleResolveCoordinates,
            ref: currentForm,
          }}
          fetch={handleFetchShop}
          submit={handleSubmitShop}
        />
      )}

      {currentTab?.id === TABS.APP && (
        <ResourceFormView
          actionName="editShop"
          {...sharedResourceFormViewProps}
          Form={EditShopAppForm}
          FormProps={{
            ref: currentForm,
          }}
          fetch={handleFetchShop}
          submit={handleSubmitShop}
        />
      )}

      {currentTab?.id === TABS.SHOP_DEVICES && (
        <ResourceFormView
          actionName="updateConfig"
          {...sharedResourceFormViewProps}
          Form={EditShopPosConfigForm}
          FormProps={{
            ref: currentForm,
          }}
          fetch={handleFetchShopDevices}
          submit={handleSubmitShopDevices}
        />
      )}

      {currentTab?.id === TABS.SHOP_CATEGORIES && (
        projectId && projectUsesOldCategoriesEditor(projectId) ? (
          <ResourceFormView
            actionName="updateShopCategories"
            {...sharedResourceFormViewProps}
            Form={CategoriesFormLegacy}
            FormProps={{
              ref: currentForm,
            }}
            fetch={() => legacyShopCategoriesApi.get({ shopId: id || '' })}
            submit={data => legacyShopCategoriesApi.update({ shopId: id || '', data })}
          />
        ) : (
          <ResourceFormView
            actionName="updateShopCategories"
            {...sharedResourceFormViewProps}
            Form={CategoriesForm}
            FormProps={{
              onProductLookup: handleProductLookup,
              ref: currentForm,
              additionalButtons: (
                <ConfirmButton
                  variant="contained"
                  color="error"
                  dialogTitle={t('shop.resetShopProductMenu.confirmationTitle')}
                  dialogBody={t('shop.resetShopProductMenu.confirmationBody')}
                  onConfirm={handleRemoveSettings}
                >
                  {t('shop.resetShopProductMenu.button')}
                </ConfirmButton>
              ),
            }}
            fetch={handleFetchShopProductMenus}
            submit={data => shopCategoriesapi.update({ shopId: id || '', data })}
            additionalContent={(
              <>
                {sharedResourceFormViewProps.additionalContent}
                <Stack direction="row" alignItems="center" sx={{ marginBottom: '32px' }}>
                  {globalCategoriesSet ? (
                    <span>
                      {t('shop.globalCategoriesHint.set.part1')}
                      <ContentLink to={`/${projectId}/checkout-devices/edit-categories`}>
                        {t('shop.globalCategoriesHint.globalSettingsLink')}
                      </ContentLink>
                      {t('shop.globalCategoriesHint.set.part2')}
                    </span>
                  ) : (
                    <span>
                      {t('shop.globalCategoriesHint.unset.part1')}
                      <ContentLink to={`/${projectId}/checkout-devices/edit-categories`}>
                        {t('shop.globalCategoriesHint.globalSettingsLink')}
                      </ContentLink>
                      {t('shop.globalCategoriesHint.unset.part2')}
                    </span>
                  )}
                </Stack>
              </>
            )}
          />
        )
      )}

      {(currentTab?.id === TABS.POS_DEVICES && !unloadPosDevices) && (
        <ResourceFormView
          actionName="updatePosDevices"
          {...sharedResourceFormViewProps}
          Form={ViewShopPosDevicesForm}
          FormProps={{
            writePermission,
            ref: currentForm,
            // NOTE this is a hack to force the form to reload the data.
            // Normally in this situation we would use the ResourceChartView
            // which supports external refreshes. But since the rest of the
            // forms must support external submitting we have to stick with
            // the ResourceFormView. Extending the ResourceFormView to support
            // external refreshes would be overkill for this single use case.
            onRefresh: () => {
              setTimeout(() => { setUnloadPosDevices(false); }, 0);
              setUnloadPosDevices(true);
            },
            shopId: id,
          }}
          fetch={handleFetchPosDevices}
          submit={() => { }}
        />
      )}
    </>
  );
}
