import React from 'react';
import { useResourceTranslator } from '../resource';
import { AdditionalAttributes } from '../additional-attributes';
import { CollapsibleAttributesPanel } from '../attributes';

export interface ProductAdditionalAttributesPanelProps {
  attributes: AdditionalAttributes;
}

export default function ProductAdditionalAttributesPanel({
  attributes,
}: ProductAdditionalAttributesPanelProps) {
  const t = useResourceTranslator();

  return (
    <CollapsibleAttributesPanel
      headline={t('fields.additionalAttributes')}
      attributes={attributes}
    />
  );
}
