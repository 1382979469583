import React from 'react';
import Translate from '../components/i18n/Translate';
import SimpleStateIndicator from '../components/SimpleStateIndicator';
import { DeviceStatus } from './CheckoutDeviceHelper';

interface CheckoutDevicesStatusIndicatorProps {
  status: DeviceStatus;
}

function CheckoutDevicesStatusIndicator({ status }: CheckoutDevicesStatusIndicatorProps) {
  if (!status) return null;
  if (status === DeviceStatus.NA) return null;

  const statusText = `checkoutDevices.status.${status}`;

  let isGreen = true;
  let isRed = false;
  switch (status) {
    case DeviceStatus.Online:
      isGreen = true;
      isRed = false;
      break;
    case DeviceStatus.Offline:
      isGreen = false;
      isRed = true;
      break;
    case DeviceStatus.Ok:
      isGreen = true;
      isRed = false;
      break;
    case DeviceStatus.Problem:
      isGreen = false;
      isRed = false;
      break;
    case DeviceStatus.Unknown:
      isGreen = false;
      isRed = false;
      break;
    default:
      break;
  }

  return (
    <SimpleStateIndicator
      useGreen={isGreen}
      useErrorColor={isRed}
      text={<Translate id={statusText} />}
    />
  );
}

export default CheckoutDevicesStatusIndicator;
