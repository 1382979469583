import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { FlexibleWidthXYPlot, YAxis, Hint, XAxis, HorizontalGridLines, VerticalGridLines, ChartLabel, LineSeries } from 'react-vis';
import { Translation, withTranslation } from 'react-i18next';
import calculateRegression from './Regression';

const styles = theme => ({
  hint: {
    borderRadius: '3px',
    width: '150px',
    right: '50%',
    marginLeft: `-${75 + theme.spacing(1)}px`,
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.3)',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

class StatisticPlot extends React.Component {
  constructor(props) {
    super(props);

    this.setAxisLabels = this.setAxisLabels.bind(this);
  }
  setRegressionData() {
    if (!this.props.withRegression) return [];
    // do not use today for calculation
    const validValues = this.props.values.filter(value => moment(value.xLabel).isBefore(moment().startOf('day')));
    return calculateRegression(validValues).regressionData;
  }
  setAxisLabels(xValue) {
    const { dateAsWeek, xLabelAsDate } = this.props;
    const label = this.props.values.find(v => v.x === xValue);
    if (!label) return null;

    if (!xLabelAsDate) {
      return label.xLabel;
    }
    const date = moment(label.xLabel);
    if (dateAsWeek) {
      return date.format('ddd');
    }

    switch (this.props.i18n.language) {
      case 'de':
        return date.format('DD.MM');
      default:
        return date.format('MM/DD');
    }
  }
  render() {
    const {
      values, hint, maxValue, translationLabelYKey, translationComparedKey,
      translationLabelXKey, translationHintKey, xLabelAsDate,
      translationData, children, compareHint, showHint, withRegression, stackY,
      totalHint, classes,
    } = this.props;
    const regressionData = this.setRegressionData();

    return (
      <Translation>
        { translate =>
          (
            <FlexibleWidthXYPlot height={250} xType="linear" yDomain={[0, maxValue]} stackBy={stackY ? 'y' : null}>
              <HorizontalGridLines />
              <VerticalGridLines tickTotal={values.length} />
              <YAxis />
              <XAxis
                position="start"
                tickTotal={values.length}
                tickFormat={this.setAxisLabels}
              />
              {children}
              {withRegression && <LineSeries
                data={regressionData}
                color="#f10061"
                strokeStyle="dashed"
              />}
              <ChartLabel
                text={translate(translationLabelYKey, translationData)}
                xPercent={0.02}
                yPercent={-0.17}
              />
              <ChartLabel
                text={xLabelAsDate ? translate('statistic.date') : translate(translationLabelXKey, translationData)}
                xPercent={0.5}
                yPercent={0.869}
              />
              {showHint && !!hint &&
                <Hint
                  className={classes.hint}
                  value={hint}
                  align={{ horizontal: 'right', vertical: 'top' }}
                >
                  {totalHint === null &&
                    <Typography variant="h6">
                      <strong>{hint.label}</strong>
                    </Typography>
                }
                  {totalHint !== null &&
                    <React.Fragment>
                      <Typography variant="caption">
                        {translate('statistic.total')}:
                        <strong> {totalHint}</strong>
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        {translate(translationHintKey, translationData)}:
                        <strong> {hint.label}</strong>
                      </Typography>
                      <br />
                    </React.Fragment>
                  }
                  {!!compareHint &&
                    <Typography variant="caption">
                      {translate(translationComparedKey, translationData)}:
                      <strong> {compareHint.label}</strong>
                    </Typography>
                  }
                </Hint>
              }
            </FlexibleWidthXYPlot>
          )}
      </Translation>
    );
  }
}

export default withTranslation()(withStyles(styles)(StatisticPlot));
