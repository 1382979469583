import Stack from '@mui/material/Stack';
import React, { FormHTMLAttributes, useMemo } from 'react';
import useUniqueId from '../useUniqueId';

export type FormProps = FormHTMLAttributes<HTMLFormElement>;

export default function Form({
  name,
  children,
  onSubmit,
  ...props
}: FormProps) {
  // Always ensure a form name is set for accessibility reasons
  // https://github.com/testing-library/dom-testing-library/issues/937
  const defaultName = useUniqueId('form_');

  const nameWithDefault = useMemo(
    () => name || defaultName,
    [name, defaultName],
  );

  return (
    <form
      name={nameWithDefault}
      onSubmit={onSubmit}
      noValidate
      {...props}
    >
      <Stack spacing={4}>
        {children}
      </Stack>
    </form>
  );
}

