import React, { CSSProperties, ReactNode } from 'react';

export interface TextWithSubTextProps {
  text: ReactNode;
  subText: ReactNode;
  highlightSubText?: boolean;
  containerStyle?: CSSProperties;
}

export default function TextWithSubText({
  text,
  subText,
  highlightSubText = false,
  containerStyle,
}: TextWithSubTextProps) {
  return (
    <div
      style={{
        // NOTE wrapping the divs into a flexbox is not necessary for most cases but ensures that
        // this component still works as expected when used in a flexbox context
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...containerStyle,
      }}
    >
      {text && (
        <div>
          {text}
        </div>
      )}
      {subText && (
        <div
          style={{
            fontWeight: highlightSubText ? 'bold' : 'normal',
            fontSize: highlightSubText ? '.95em' : 'smaller',
            color: highlightSubText ? 'dimgray' : 'gray',
          }}
        >
          {subText}
        </div>
      )}
    </div>
  );
}
