import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import useProjectConfigApi from './useProjectConfigApi';
import ViewProjectConfigForm from './ViewProjectConfigForm';
import { ApplicationState } from '../reducers/initialState';

export default function ViewProjectConfigView() {
  const api = useProjectConfigApi();
  const { t } = useTranslation();
  const navigate = useProjectNavigate();
  const writePermission = useSelector<ApplicationState, boolean>(state => (
    !!(state.navigation as any)?.projectConfig?.write
  ));

  const fetch = useCallback(async () => {
    const { projectConfig } = await api.getProjectConfig(null);
    return projectConfig;
  }, [api]);

  const navigateToEditForm = useCallback(() => {
    navigate('/project-config/edit');
  }, [navigate]);

  return (
    <>
      <DocumentTitle translationID="headlines.viewProjectConfig" />

      <ResourceFormView
        actionName="viewProjectConfig"
        headlineButtons={writePermission && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => { navigateToEditForm(); }}
          >
            {t('projectConfig.edit')}
          </Button>
        )}
        Form={ViewProjectConfigForm}
        fetch={fetch}
        submit={() => { }}
      />
    </>
  );
}
