import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useShopAccessApi, { UnblockUserProps } from './useShopAccessApi';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView, { ResourceChartViewRef } from '../charts/scaffold/ResourceChartView';
import BlockListTable from './BlockListTable';
import useResourceTranslator from '../resource/useResourceTranslator';
import Headline from '../components/Headline';
import Translate from '../components/i18n/Translate';

export default function BlockListView() {
  const api = useShopAccessApi();
  const location = useLocation();
  const t = useResourceTranslator();
  const { projectId } = useParams();
  const resourceChartViewRef = useRef<ResourceChartViewRef | null>(null);

  const [tableFilter, setTableFilter] = useState<string | undefined>();
  useEffect(() => {
    setTableFilter(location?.state?.filter);
  }, [location?.state?.filter]);

  const handleEntryRemove = useCallback(async (user: UnblockUserProps) => {
    await api.unblockUser(user);
    setTableFilter(undefined);
    resourceChartViewRef.current?.updateData();
  }, [api]);

  const handleDownload = useCallback(async () => {
    const csv = await api.getBlocklistCsv(null);

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
    );
    element.setAttribute('download', `${projectId}-shop-access-blocked-user.csv`);
    element.click();
    element.remove();
  }, [api, projectId]);

  return (
    <>
      <DocumentTitle translationID="shopAccess.headline" />

      <Headline to={`/${projectId}/shop-access/block-list/new`} linkText={t('shopAccess.blockList.addToBlockList')} withLink>
        <Translate id="shopAccess.blockList.headline" />
      </Headline>

      <ResourceChartView
        Chart={BlockListTable}
        ChartProps={{
          onEntryRemove: handleEntryRemove,
          filterOverwrite: tableFilter,
          onDownload: handleDownload,
        }}
        fetch={api.getBlocklist}
        ref={resourceChartViewRef}
      />
    </>
  );
}
