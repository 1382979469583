import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DocumentTitle from '../components/DocumentTitle';
import useUserManagementApi, { ChangePasswordProps } from './useUserManagementApi';
import { ApplicationState } from '../reducers/initialState';
import ResourceFormView from '../scaffold/ResourceFormView';
import ChangePasswordForm from './ChangePasswordForm';

export default function ChangePasswordView() {
  const { t } = useTranslation();
  const api = useUserManagementApi();
  const token = useSelector<ApplicationState, any>(state => state.token?.grants);
  const originAccount = token.origin !== 'account';

  const handleSubmit = useCallback(async (passwordChanges: FieldValues) => {
    await api.changePassword(passwordChanges as ChangePasswordProps);
  }, [api]);

  // Show hint if user is not of origin account
  return (
    <>
      <DocumentTitle translationID="userManagement.changePassword.headline" />
      {originAccount ? (
        <Alert severity="info" sx={{ marginTop: '32px' }}>
          <AlertTitle>{t('alert.externallyManagedPassword.title')}</AlertTitle>
          {t('alert.externallyManagedPassword.body')}
        </Alert>
      ) : (
        <ResourceFormView
          actionName="changePassword"
          Form={ChangePasswordForm}
          FormProps={{
            username: token?.sub,
          }}
          fetch={() => ({})}
          submit={handleSubmit}
        />
      )}

    </>
  );
}
