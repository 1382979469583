import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
// @ts-ignore
import OrderExportContainer from '../containers/OrderExportContainer';
// @ts-ignore
import LineItemExportContainer from '../containers/LineItemExportContainer';
import RouteWrapper from './RouteWrapper';
import { Access } from '../access';
import { ResourceProvider } from '../resource';
import OrderView from '../orders/OrderView';
import OrderListView from '../orders/OrderListView';

function OrderRoutes({ access }:{ access?: Access }) {
  return (
    <ResourceProvider name="orders">
      <Routes>
        {access?.read &&
          <>
            <Route path="/" element={<RouteWrapper><OrderListView /></RouteWrapper>} />
            <Route path="/export" element={<RouteWrapper><OrderExportContainer /></RouteWrapper>} />
            <Route path="/line-items/export" element={<RouteWrapper><LineItemExportContainer /></RouteWrapper>} />
            <Route path="/:id" element={<RouteWrapper><OrderView /></RouteWrapper>} />
          </>
        }
        <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default OrderRoutes;
