import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FieldValues } from 'react-hook-form';
import DocumentTitle from '../components/DocumentTitle';
import ResourceFormView from '../scaffold/ResourceFormView';
import useProjectNavigate from '../useProjectNavigate';
import useUserManagementApi, { RealmValidationRules, Realm, RealmRole, Permission } from './useUserManagementApi';
import EditUserForm from './EditUserForm';
import { ApplicationState } from '../reducers/initialState';
import PasswordOverwriteDialogView from './PasswordOverwriteDialogView';
import useValidationPolicyParser from './useValidationPolicyParser';

export default function EditUserView() {
  const api = useUserManagementApi();
  const navigate = useProjectNavigate();
  const { projectId, realmID, userID } = useParams();
  const projects = useSelector<ApplicationState, Record<string, any>[]>(state => state.projects);
  const token = useSelector<ApplicationState, any>(state => state.token?.grants);
  const [validationRules, setValidationRules] = useState<RealmValidationRules | undefined>();
  const [apiError, setApiError] = useState<string | undefined>();
  const [currentUserIsEditedUser, setCurrentUserIsEditedUser] = useState(false);
  const [showPassworOverwriteDialog, setShowPassworOverwriteDialog] = useState(false);
  const [realmAdminRoles, setRealmAdminRoles] = useState<Permission[]>();
  const { mapValidationPolicyToRules, mapPasswordRules } = useValidationPolicyParser();

  const handleGetUser = useCallback(async () => {
    const user = await api.getUser({ userID, filter: { realm: realmID } });
    setCurrentUserIsEditedUser(user?.sub === token?.sub);
    const realm = (await api.getRealms(null)).realms?.find(r => (r.id === realmID)) || {} as Realm;
    setValidationRules({
      subValidationRules: mapValidationPolicyToRules(realm?.subValidationPolicy),
      passwordValidationRules: mapValidationPolicyToRules(realm?.passwordValidationPolicy),
      allowedRoles: realm?.allowedRoles.filter(role => (
        role !== RealmRole.REALM_ADMIN
      )) || [],
      allowedProjects: realm?.allowedProjects ? (
        projects.filter(p => (realm.allowedProjects.includes(p.id)))
      ) : (
        projects
      ),
      passwordChoiceRule: {
        ...mapPasswordRules(realm?.passwordChoicePolicy),
        // Disable password overwriting for Google user
        ...(user?.origin === 'google' && { password: { enabled: false, required: false } }),
        ...(!user?.email && { email: { enabled: false, required: false } }),
      },
    });

    if (user?.roles) {
      setRealmAdminRoles(user.roles.filter(role => role.role === RealmRole.REALM_ADMIN));
      user.roles = user.roles.filter(role => role.role !== RealmRole.REALM_ADMIN);
    }

    return user;
  }, [api, mapPasswordRules, mapValidationPolicyToRules, projects, realmID, token?.sub, userID]);

  const handleUpdateUser = useCallback(async (user: FieldValues) => {
    const userWithRealmAdminRoles = {
      ...user,
      roles: [
        ...(user.roles || []),
        ...(realmAdminRoles || []),
      ],
    };
    await api.updateUser({ userID, user: userWithRealmAdminRoles, filter: { realm: realmID } });
  }, [api, realmAdminRoles, realmID, userID]);

  const handleDeleteUser = useCallback(async () => {
    try {
      await api.deleteUser({ userID, filter: { realm: realmID } });
      navigate('/user-management/');
    } catch (e: any) {
      setApiError(e.message);
    }
  }, [api, navigate, realmID, userID]);

  const handlePasswordReset = useCallback(async () => {
    await api.resetUserPassword({ userID, filter: { realm: realmID } });
  }, [api, realmID, userID]);

  return (
    <>
      <DocumentTitle translationID="userManagement.headline" />

      {showPassworOverwriteDialog && (
        <PasswordOverwriteDialogView
          userID={userID}
          filter={{ project: projectId, realm: realmID }}
          realmValidationRules={validationRules}
          onClose={() => { setShowPassworOverwriteDialog(false); }}
        />
      )}

      <ResourceFormView
        actionName="editUser"
        Form={EditUserForm}
        FormProps={{
          currentProject: projectId,
          onUserDelete: handleDeleteUser,
          onPasswordReset: handlePasswordReset,
          onPasswordOverwrite: () => { setShowPassworOverwriteDialog(true); },
          realmValidationRules: validationRules,
          apiError,
          currentUserIsEditedUser,
        }}
        fetch={handleGetUser}
        submit={handleUpdateUser}
        onSubmitted={() => {
          navigate('/user-management/');
        }}
      />
    </>
  );
}
