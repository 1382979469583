import React from 'react';
import { useTranslation } from 'react-i18next';
import { Shop } from './useShopApi';
import { buildLabelFromArray } from '../utils/stringUtils';

export interface MapPopupContentProps {
  shop: Shop;
}

export default function MapPopupContent({
  shop: {
    id,
    name,
    externalId,
    street,
    zip,
    city,
    country,
  },
}: MapPopupContentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <b>{name || externalId || id}</b>
      </div>
      {externalId && (
      <div>
        {t('shop.externalId')}: {externalId}
      </div>
      )}
      {street && (
      <div>
        {street}
      </div>
      )}
      {(zip || city) && (
      <div>
        {buildLabelFromArray({ input: [zip, city], separator: ' ' })}
      </div>
      )}
      {country && (
      <div>
        {country}
      </div>
      )}
      <a href={`shops/${id}`}>
        {t('headlines.viewShop')}
      </a>
    </div>
  );
}
